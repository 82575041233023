import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from '../shared/components/buttons/PrimaryButton';
import { Alert } from '../layout/theme/components';
import WelcomeContainer from './components/WelcomeContainer';

const Info = styled(Alert)`
  display: flex;
  align-self: flex-end;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 20% auto;
  }
`;

const LogoutComponent = () => (
  <WelcomeContainer>
    <Info>Poprawnie wylogowano z aplikacji.</Info>
    <Link to="/">
      <Button type="submit">ZALOGUJ PONOWNIE</Button>
    </Link>
  </WelcomeContainer>
);

export default LogoutComponent;
