import styled from 'styled-components/macro';

export const H0 = styled.div`
  font-size: 2.4em;
  font-weight: 700;
  color: ${({ defaultColor, theme }) =>
    defaultColor ? theme.textColors.primary : 'inherit'};
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    font-size: 2.6em;
  }
`;

export const H1 = styled.h1`
  font-size: 2.2em;
  font-weight: 700;
  color: ${({ defaultColor, theme }) =>
    defaultColor ? theme.textColors.primary : 'inherit'};
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    font-size: 2.6em;
  }
`;

export const H2 = styled.h2`
  font-size: 2em;
  font-weight: 700;
  color: ${({ defaultColor, theme }) =>
    defaultColor ? theme.textColors.primary : 'inherit'};
  word-break: break-word;
`;

export const Text = styled.p`
  font-size: ${({ small }) => (small ? '1.4em' : '1.6em')};
  margin: 0.5em 0;
  line-height: 1.2;
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  color: ${({ defaultColor, theme }) =>
    defaultColor ? theme.textColors.primary : 'inherit'};
  white-space: pre-line;
  word-break: break-word;
`;

export const Caption = styled.span`
  color: #8c8c8c;
  font-weight: 400;
  font-size: 1.3em;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.textColors.tertiary};
  font-size: ${({ big }) => (big ? '1.6em' : '1.3em')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

export const LabelColored = styled.span`
  color: ${({ theme }) => theme.textColors.secondary};
  font-size: ${({ big }) => (big ? '1.6em' : '1.3em')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`;

export const LinkedItem = styled.span`
  font-size: 1.3em;
  color: ${({ secondary, theme }) =>
    secondary ? theme.linkColors.secondary : theme.linkColors.primary};
  font-weight: 600;
  cursor: pointer;
`;

export const NavItem = styled.span`
  font-size: 1.4em;
  color: ${({ active, theme }) =>
    active ? theme.linkColor : theme.linkColors.inactive};
  font-weight: ${({ active }) => (active ? '700' : '400')};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    font-size: 1.5em;
  }
`;

export const Alert = styled.div`
  color: ${({ negative, theme }) =>
    negative ? theme.labels.negative : theme.labels.positive};
  font-weight: 400;
  font-size: 1.4em;
  word-break: break-word;
`;

export const InvalidText = styled.div`
  color: ${({ theme }) => theme.labels.negative};
  font-size: 1.3em;
  margin: 0 0 1em;
  bottom: 1em;
  width: 100%;
  text-align: left;
  word-break: break-word;
`;
