import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AvatarEditorComponent from './AvatarEditorComponent';
import AvatarEditor from 'react-avatar-editor';
import DefaultAvatar from './DefaultAvatar';

import { fetchUser } from '../../../data/user/actions';
import { postMediaBase64 } from '../../../helpers/api/media';
import { saveAvatar } from '../../../helpers/api/user';
import { resizeFile } from '../../../helpers/tools';

class AvatarEditorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      image: '',
      preview: null,
      valid: true,
      isLoading: false,
      error: null
    };
  }

  handleSaveAvatar = () => {
    const img = this.editor.getImage().toDataURL();
    this.setState(
      {
        preview: {
          img,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          borderRadius: this.state.borderRadius
        }
      },
      () => this.handleFileUpload(img)
    );
  };

  setEditorRef = editor => (this.editor = editor);

  handleNewImage = e =>
    this.setState({ image: e.target.files[0], valid: true, isLoading: true });

  handleRemoveFile = () => {
    const newScale = 1;
    this.setState({
      image: '',
      preview: null,
      scale: newScale
    });
  };

  handleScale = e => {
    if (this.state.image) {
      const scale = parseFloat(e.target.value);
      this.setState({ scale });
    }
  };

  handlePositionChange = position => this.setState({ position });

  handleFileUpload = file => {
    const { toggleModal } = this.props;
    postMediaBase64(file)
      .then(({ data }) => saveAvatar(data.id))
      .then(() => toggleModal(false))
      .then(() => this.props.fetchUser())
      .catch(error => this.setState({ error }));
  };

  checkFileSize = image => {
    const maxFileSize = 10 * 1024 * 1000;
    image.size <= maxFileSize
      ? this.setState({ image })
      : resizeFile(image).then(
          resizedImage =>
            resizedImage.size < maxFileSize
              ? this.setState({ image: resizedImage })
              : this.setState({ image: '', valid: false })
        );
  };

  render() {
    const { image, scale, position, valid, isLoading, error } = this.state;
    const { toggleModal } = this.props;

    return (
      <AvatarEditorComponent
        toggleModal={toggleModal}
        scale={scale}
        image={image}
        handleSaveAvatar={this.handleSaveAvatar}
        handleNewImage={this.handleNewImage}
        handleRemoveFile={this.handleRemoveFile}
        handleScale={this.handleScale}
        valid={valid}
        isLoading={isLoading}
        error={error}
      >
        {image ? (
          <AvatarEditor
            ref={this.setEditorRef}
            onChange={e => (e.target.value = null)}
            scale={parseFloat(scale)}
            image={image}
            borderRadius={100}
            position={position}
            onPositionChange={this.handlePositionChange}
            onImageReady={() => this.checkFileSize(image)}
            onLoadSuccess={() => this.setState({ isLoading: false })}
          />
        ) : (
          <DefaultAvatar onChange={this.handleNewImage} />
        )}
      </AvatarEditorComponent>
    );
  }
}

AvatarEditorComponent.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser())
});

export default connect(
  null,
  mapDispatchToProps
)(AvatarEditorContainer);
