import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Caption } from '../../../layout/theme/components';

const PreviewSectionWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 1em 0;
`;

const PreviewSection = props => {
  const { title, children } = props;
  return (
    <PreviewSectionWrapper>
      {title && <Caption>{title}</Caption>}
      {children}
    </PreviewSectionWrapper>
  );
};

PreviewSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};
PreviewSection.defaultProps = {
  title: null
};

export default PreviewSection;
