import React from 'react';

const Notification01 = () => (
  <svg
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h25v25H0z" />
    <path
      d="M.157 14.01C-.675 7.318 4.129 1.204 10.879.364c6.75-.84 12.906 3.911 13.739 10.603a12.161 12.161 0 0 1-1.879 8.13l2.197 5.636-5.718-2.088a12.405 12.405 0 0 1-5.322 1.969C7.146 25.454.99 20.702.157 14.01z"
      fill="#756ca4"
    />
    <path
      d="M15.706 5.82c0 .466-.176.865-.53 1.193a1.8 1.8 0 0 1-1.275.493c-.499 0-.924-.164-1.281-.493a1.567 1.567 0 0 1-.535-1.193c0-.467.179-.866.535-1.198a1.811 1.811 0 0 1 1.281-.497c.498 0 .923.166 1.275.497.354.332.53.731.53 1.198m-.192 11.249l-.203.829a39.92 39.92 0 0 1-1.456.549 3.84 3.84 0 0 1-1.262.188c-.737 0-1.31-.18-1.718-.538a1.747 1.747 0 0 1-.613-1.369c0-.214.015-.434.046-.659.031-.224.08-.477.148-.76l.76-2.69c.068-.258.125-.502.172-.733.046-.229.069-.44.069-.63 0-.344-.072-.584-.213-.72-.142-.134-.412-.202-.814-.202-.196 0-.398.031-.604.092-.206.061-.383.12-.532.175l.204-.83c.498-.202.974-.376 1.429-.52a4.255 4.255 0 0 1 1.292-.217c.732 0 1.296.177 1.692.53.396.354.594.813.594 1.378 0 .117-.012.323-.04.617a4.1 4.1 0 0 1-.153.811l-.757 2.68a7.26 7.26 0 0 0-.166.739c-.05.275-.074.485-.074.626 0 .355.08.599.239.728.16.128.436.193.828.193.184 0 .393-.033.626-.097.232-.064.401-.12.506-.17"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
);

export default Notification01;
