import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../theme/components';

const TextBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 2;
`;
const StyledText = styled(Text)`
  width: 100%;
  text-align: center;
  line-height: 1.4;

  @media (max-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin-top: 0;
    font-size: 1.4em;
  }
`;

const TutorialHeader = ({ children }) => {
  return (
    <TextBox>
      <StyledText>{children}</StyledText>
    </TextBox>
  );
};

export default TutorialHeader;
