import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import ArrowIcon from './../../../ArrowIcon';

const Arrows = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowBox = ({ next, onClick }) => (
  <Arrows>
    {next ? (
      <ArrowIcon onClick={onClick} flex transform />
    ) : (
      <ArrowIcon onClick={onClick} flex />
    )}
  </Arrows>
);

ArrowBox.propTypes = {
  next: PropTypes.bool,
  onClick: PropTypes.func
};

ArrowBox.defaultProps = {
  next: false,
  onClick: null
};

export default ArrowBox;
