import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components/macro';

import HamburgerIcon from './components/HamburgerIcon';
import SideBar from './SideBarComponent';

import { connect } from 'react-redux';
import { hideModal, showModal } from '../data/modal/actions';
import { toggler } from './../data/hooksReducers';
import TutorialContainer from './tutorial/TutorialContainer';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
`;

export const MenuStateContext = React.createContext(false);

const SideBarWrapper = ({
  showCommunityContacts,
  showCommunityVotePower,
  showUserVotePower,
  premiumCommunity,
  isRegularUser,
  openModal,
  closeModal,
}) => {
  const [state, dispatch] = useReducer(toggler, { show: false });
  const toggleMenu = () => dispatch({ type: 'toggleState' });
  const [openHelp, toggleHelp] = useState(false);
  const helpToggle = () => toggleHelp(!openHelp);

  useEffect(
    () => {
      if (!state.show && openHelp) return helpToggle;
    },
    [state.show]
  );

  const showTutorialModal = () => {
    openModal(<TutorialContainer closeTutorial={closeModal} />, true);
  };

  return (
    <MenuStateContext.Provider value={state.show}>
      <HamburgerIcon onClick={toggleMenu} active={state.show} />
      <SideBar
        showMenu={state.show}
        toggleTutorial={() => showTutorialModal()}
        showCommunityContacts={showCommunityContacts}
        showCommunityVotePower={showCommunityVotePower}
        showUserVotePower={showUserVotePower}
        premiumCommunity={premiumCommunity}
        helpToggle={helpToggle}
        openHelp={openHelp}
        isRegularUser={isRegularUser}
      />
      {state.show && <Backdrop onClick={toggleMenu} />}
    </MenuStateContext.Provider>
  );
};

SideBarWrapper.propTypes = {
  showCommunityContacts: PropTypes.bool.isRequired,
  showCommunityVotePower: PropTypes.bool.isRequired,
  showUserVotePower: PropTypes.bool.isRequired,
  premiumCommunity: PropTypes.bool,
  isRegularUser: PropTypes.bool,
};

SideBarWrapper.defaultProps = {
  premiumCommunity: false,
  isRegularUser: false,
};

const mapDispatchToProps = dispatch => ({
  openModal: (content, isWide) => dispatch(showModal(content, isWide)),
  closeModal: () => dispatch(hideModal()),
});

export default connect(
  null,
  mapDispatchToProps
)(SideBarWrapper);
