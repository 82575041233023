import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { saveMediaStep } from '../../helpers/api/case';
import { postMedia } from '../../helpers/api/media';
import Checkbox from '../../shared/components/forms/Checkbox';
import RadioInput from '../../shared/components/forms/RadioInput';
import Media from './MediaComponent';

class MediaContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaId: [],
      meterValue: '',
      isValid: false,
      attachment: []
    };
  }

  componentDidMount() {
    const { selectionType, medias, meterValue } = this.props;
    const multipleSelection = selectionType === 'multiple';
    !multipleSelection && this.setState({ mediaId: [medias[0].id] });
    meterValue && this.setState({ meterValue: meterValue });
  }

  saveStep = (mediaId, value, attachmentID) => {
    const { caseId } = this.props;
    saveMediaStep(caseId, mediaId, value, attachmentID).then(() =>
      this.props.handleNextStep()
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const { mediaId, attachment, meterValue } = this.state;
    const valueMeter = meterValue.toString().replace(',', '.');
    const isAttachment = !!attachment[0];
    const attachmentId = isAttachment ? attachment[0].id : null;
    if (isAttachment) {
      if (attachmentId) this.saveStep(mediaId, valueMeter, attachmentId);
      else
        postMedia(attachment[0]).then(({ data }) => {
          this.saveStep(mediaId, valueMeter, data.id);
        });
    } else this.saveStep(mediaId, valueMeter);
  };

  handleAttachment = attachment => this.setState({ attachment });

  handleInputChange = e => {
    const number = e.target.value;
    /^\d*,?\.?\d*$/.test(number)
      ? this.setState({ meterValue: number, isValid: true })
      : this.setState({ isValid: false });
  };

  removeElement = (arr, el) => {
    const index = arr.indexOf(el);
    arr.splice(index, 1);
  };

  handleSelect = e => {
    let mediaName = Number(e.target.name);
    const { mediaId } = this.state;
    mediaId.includes(mediaName)
      ? this.setState(previousState => ({
          mediaId: previousState.mediaId.filter(media => media !== mediaName)
        }))
      : this.setState(previousState => ({
          mediaId: [...previousState.mediaId, mediaName]
        }));
  };

  selectMedia = mediaId => {
    this.setState({ mediaId: [mediaId] });
  };

  renderMedia() {
    const { medias, selectionType } = this.props;
    const { mediaId } = this.state;
    const multipleSelection = selectionType === 'multiple';

    return medias.map(media => {
      return (
        <>
          {multipleSelection ? (
            <Checkbox
              key={media.id}
              name={media.id}
              value={media.id}
              type="checkbox"
              checked={mediaId.includes(media.id)}
              onChange={this.handleSelect}
              label={media.name}
              labelIconPath={media.media ? media.media.path : null}
            />
          ) : (
            <RadioInput
              key={media.id}
              name={media.id}
              value={media.id}
              checked={media.id === mediaId[0]}
              onChange={this.selectMedia}
              label={media.name}
              labelIconPath={media.media ? media.media.path : null}
            />
          )}
        </>
      );
    });
  }

  render() {
    const { attachment, meterValue, mediaId, isValid } = this.state;
    const multipleSelection = this.props.selectionType === 'multiple';

    return (
      <Media
        handleSubmit={this.handleSubmit}
        handleAttachment={file => this.handleAttachment(file)}
        files={attachment}
        handleInputChange={this.handleInputChange}
        meterValue={meterValue}
        isValid={isValid || attachment || mediaId}
        multipleSelection={multipleSelection}
      >
        {this.renderMedia()}
      </Media>
    );
  }
}

MediaContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  medias: PropTypes.arrayOf(PropTypes.object).isRequired,
  multipleChoice: PropTypes.bool.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  meterValue: PropTypes.number
};

export default MediaContainer;
