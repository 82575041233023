import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from '../shared/components/buttons/PrimaryButton';
import TextInput from '../shared/components/forms/TextInput';
import { Text, Alert } from '../layout/theme/components';
import WelcomeContainer from './components/WelcomeContainer';

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 6%;
`;
const SentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60vh;
  padding-top: 16%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 100%;
  }
`;

const NewPasswordComponent = ({
  handleSubmit,
  handleChange,
  newPassword,
  repeatPassword,
  isValid,
  invalidText,
  sent
}) => {
  return (
    <WelcomeContainer>
      {!sent ? (
        <form onSubmit={e => handleSubmit(e)}>
          <Text bold>Ustaw nowe hasło</Text>
          <InputsWrapper>
            <TextInput
              name="newPassword"
              type="password"
              value={newPassword}
              placeholder="Nowe hasło"
              onChange={e => handleChange(e.target.value, 'newPassword')}
              valid={isValid}
              isRequired
              autocomplete="new-password"
            />
            <TextInput
              name="repeatPassword"
              type="password"
              value={repeatPassword}
              placeholder="Powtórz nowe hasło"
              onChange={e => handleChange(e.target.value, 'repeatPassword')}
              valid={isValid}
              isRequired
              autocomplete="new-password"
              invalidText={invalidText}
            />
          </InputsWrapper>
          <Button type="submit" disabled={!isValid}>
            WYŚLIJ
          </Button>
        </form>
      ) : (
        <SentBox>
          <Alert>Hasło zostało zmienione!</Alert>
          <Link to="/">
            <Button>Zamknij</Button>
          </Link>
        </SentBox>
      )}
    </WelcomeContainer>
  );
};

NewPasswordComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  repeatPassword: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  sent: PropTypes.bool,
  invalidText: PropTypes.string
};

NewPasswordComponent.defaultProps = {
  sent: false,
  invalidText: ''
};

export default NewPasswordComponent;
