import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Container from '../../shared/components/Container';
import { H0, Text } from '../../layout/theme/components';

import LogoIcon from '../../../assets/logo';
import ErrorIcon from '../../../assets/errorIcon';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';

const StyledText = styled(Text)`
  word-break: break-word;
`;
const PictureBox = styled.div`
  width: 100%;
  max-width: 300px;
  max-height: 40vh;
  margin: 10% 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const LogoBox = styled.div`
  max-width: 225px;
  margin-top: 2%;
  svg {
    width: 100%;
  }
`;

const ActivationFailedComponent = () => {
  return (
    <Container>
      <H0>Token nieaktywny</H0>
      <PictureBox>
        <ErrorIcon />
      </PictureBox>
      <StyledText>
        Token wygasł lub nie istnieje.
        <br />
        Zarejestruj się ponownie
      </StyledText>
      <br />
      <Link to="/rejestracja">
        <PrimaryButton>Zarejestruj się!</PrimaryButton>
      </Link>
      <LogoBox>
        <LogoIcon />
      </LogoBox>
    </Container>
  );
};

export default ActivationFailedComponent;
