import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import ChristinIcon from './../../../assets/info01';
import NotificationIcon from './../../../assets/notification01';

const Christin = styled.div`
  position: fixed;
  top: 5em;
  right: -1em;
  width: 8em;
  z-index: 1;
  transform: ${({ toggleNotification }) =>
    toggleNotification ? 'translateX(0)' : 'translateX(50%)'};
  transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  cursor: pointer;

  div {
    position: absolute;
    top: -1%;
    right: 93%;
    width: 31%;
    opacity: ${({ toggleNotification }) => (toggleNotification ? '0' : '1')};
  }
`;

const ChristinToggler = ({ onClick, toggleNotification }) => (
  <Christin onClick={onClick} toggleNotification={toggleNotification}>
    <ChristinIcon />
    <div>
      <NotificationIcon toggleNotification={toggleNotification} />
    </div>
  </Christin>
);

ChristinToggler.propTypes = {
  onClick: PropTypes.func,
  toggleNotification: PropTypes.bool
};

ChristinToggler.defaultProps = {
  toggleNotification: false
};

export default ChristinToggler;
