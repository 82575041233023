import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchOpenCase } from '../../helpers/api/open';
import { daysPassed, formatDateTime } from '../../helpers/date';
import AttachmentsPreview from '../../shared/components/AttachmentsPreview';
import Loader from '../../shared/components/Loader';
import ActivitiesContainer from '../activities/ActivitiesContainer';
import ActivitiesStatus from '../activities/components/ActivitiesStatus';
import ActivitiesSummary from '../activities/components/ActivitiesSummary';
import VotingContainer from '../sounding/VotingContainer';
import CasePublicComponent from './CasePublicComponent';
import { CasePreviewWrapper } from './components/CasePreviewWrapper';
import PreviewCompanies from './components/PreviewCompanies';
import PreviewCosts from './components/PreviewCosts';
import PreviewDescription from './components/PreviewDescription';
import PreviewHeader from './components/PreviewHeader';
import PreviewInfo from './components/PreviewInfo';
import PreviewMedia from './components/PreviewMedia';
import PreviewMeeting from './components/PreviewMeeting';
import PreviewPlace from './components/PreviewPlace';
import PreviewSection from './components/PreviewSection';
import RegistrationLink from './components/RegistrationLink';
import ShareSection from './components/ShareSection';

const ogImageUrl = encodeURI('https://app.wspolnota.online/images/wo-image.png');

class CasePublicContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      casePreview: {},
      isLoading: true,
      error: null,
    };
    this.shareRef = React.createRef();
  }

  componentDidMount() {
    this.getCasePreview();
  }

  getCasePreview = () => {
    this.setState({ isLoading: true });
    const caseId = this.getKey();

    fetchOpenCase(caseId)
      .then(response => {
        this.setState({ casePreview: response.data, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  getKey = () => Number(this.props.match.params.id);

  scrollToShareSection = () => this.shareRef.current.scrollIntoView({ behavior: 'smooth' });

  render() {
    const { isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <Redirect to="/" />;

    const { isLogged } = this.props;
    const caseId = this.getKey();
    const preview = this.state.casePreview;
    const created = daysPassed(preview.created_at);
    const createdAt = formatDateTime(preview.created_at);
    const areAttachments = preview.attachments.length > 0;
    const userStatus = isLogged ? 'logged' : 'notLogged';

    return (
      <CasePublicComponent communityName={preview.community_name} userStatus={userStatus}>
        <MetaTags>
          <meta property="og:title" content={preview.topic} />
          <meta property="og:image" content={ogImageUrl} />
        </MetaTags>
        <CasePreviewWrapper withLine>
          <PreviewInfo
            created={created}
            created_at={createdAt}
            owner={preview.owner.display_name}
            userId={preview.owner.id}
            userRole={preview.owner.role}
            avatarPath={preview.owner.avatar && preview.owner.avatar.path}
            number={preview.case_number}
            range={preview.range}
            rangeSlug={preview.range_slug}
            rangeClickHandler={this.scrollToShareSection}
            showTooltip
          />
        </CasePreviewWrapper>
        {preview.companies.length > 0 && <PreviewCompanies taggedCompanies={preview.companies} />}
        <CasePreviewWrapper>
          <PreviewHeader topic={preview.topic} />
          {preview.place && <PreviewPlace place={preview.place} />}
          {preview.media.length > 0 && <PreviewMedia media={preview.media} meterValue={preview.meter_value} />}
          {preview.meeting_data.length !== 0 && <PreviewMeeting meeting={preview.meeting_data} />}
          <PreviewDescription description={preview.stepTopic && preview.stepTopic.description} />
          {areAttachments && (
            <PreviewSection>
              <AttachmentsPreview attachments={preview.attachments} isLabel />
            </PreviewSection>
          )}
          {(preview.community_cost || preview.user_cost) && (
            <PreviewCosts
              communityCost={preview.community_cost}
              userCost={preview.user_cost}
              costDescription={preview.cost_description}
              caseId={caseId}
            />
          )}
          <div ref={this.shareRef}>
            <ShareSection />
          </div>
          {preview.sounding_question && (
            <VotingContainer
              caseId={caseId}
              question={preview.sounding_question}
              soundingResults={preview.sounding}
              caseStatus={preview.status}
              totalApartments={preview.sounding.total_apartments}
              showResults
              open
            />
          )}
        </CasePreviewWrapper>
        <CasePreviewWrapper>
          <ActivitiesSummary usersCount={preview.total_commenters} commentsCount={preview.comments_count} />
          <ActivitiesStatus status="default" newActivitiesCount={preview.new_activities_count} />
          <RegistrationLink buttonText="Dodaj komentarz" />
          <ActivitiesContainer open canAddComment={false} communityCase={caseId} />
        </CasePreviewWrapper>
      </CasePublicComponent>
    );
  }
}

CasePublicContainer.propTypes = {
  isLogged: PropTypes.bool,
};
CasePublicContainer.defaultProps = {
  isLogged: false,
};

const mapStateToProps = state => ({
  isLogged: state.user.auth.loggedIn,
});

export default connect(mapStateToProps)(CasePublicContainer);
