import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import MortgageIcon from '../../../assets/features/mortgageIcon';
import { EXCEEDED_MESSAGES_AMOUNT } from '../../helpers/constants';
import { Alert, H0, H1, Text } from '../../layout/theme/components';
import Button from '../../shared/components/buttons/PrimaryButton';
import TextInput from '../../shared/components/forms/TextInput';
import UpgradeStepWrapper from './components/UpgradeStepWrapper';

const StyledHeader = styled(H0)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  margin-bottom: 1em;
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
  }
`;
const PictureBox = styled.div`
  margin: 1em 0;
  svg {
    width: 60%;
    max-height: 8vh;
  }
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.quaternary};
`;
const ButtonWrapper = styled.div`
  margin-top: 2em;
`;

const MortgageComponent = ({ mortgageNumber, updateForm, valid, isKeeper, submitForm, error }) => {
  return (
    <>
      <StyledHeader>
        Wypróbuj <span>Premium</span>
      </StyledHeader>
      <UpgradeStepWrapper>
        <H1>Jak to zrobić?</H1>
        <PictureBox>
          <MortgageIcon />
        </PictureBox>
        <TextInput
          name="mortgageNumber"
          value={mortgageNumber}
          placeholder="Wpisz numer księgi wieczystej"
          onChange={e => updateForm(e.target.value)}
          valid={valid}
          invalidText="Podana wartość jest nieprawidłowa."
        />
        {error && <Alert negative>{EXCEEDED_MESSAGES_AMOUNT}</Alert>}
        <br />
        <Text bold>Czym jest księga wieczysta?</Text>
        <Text small>
          Księga wieczysta jest <b>jawnym dokumentem,</b> w który znajdują się informacje o Waszej wspólnocie
          mieszkaniowej, w tym powierzchnie lokali. <br />
          <b>Na ich podstawie można obliczyć siłę głosu, aby dostępne funkcjonalności mogły działać poprawnie.</b>
        </Text>
        <Text bold>Nie znasz numeru księgi wieczystej? Powiadomimy zarządcę w Twoim imieniu!</Text>
        <Link
          to={{
            pathname: '/zarzadca',
            state: { notifyKeeper: isKeeper },
          }}
        >
          <StyledText bold>{isKeeper ? 'Kliknij, aby powiadomić zarządcę!' : 'Dodaj zarządcę +'}</StyledText>
        </Link>
      </UpgradeStepWrapper>
      <ButtonWrapper>
        <Button onClick={e => submitForm(e)}>WYŚLIJ</Button>
      </ButtonWrapper>
    </>
  );
};

MortgageComponent.propTypes = {
  mortgageNumber: PropTypes.string,
  updateForm: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  isKeeper: PropTypes.bool,
  submitForm: PropTypes.func,
  error: PropTypes.object,
};

MortgageComponent.defaultProps = {
  mortgageNumber: '',
  valid: false,
  isKeeper: false,
  error: null,
};

export default MortgageComponent;
