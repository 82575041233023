import React from 'react';
import styled from 'styled-components/macro';
import LogoIcon from '../../../assets/logo';
import PartyChrisitinImage from '../../../assets/partyChristin';
import { H0, Text } from '../../layout/theme/components';
import Container from '../../shared/components/Container';

const StyledText = styled(Text)`
  word-break: break-word;
`;
const PictureBox = styled.div`
  width: 100%;
  max-width: 300px;
  max-height: 40vh;
  margin: 10% 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const LogoBox = styled.div`
  max-width: 225px;
  margin-top: 2%;
  svg {
    width: 100%;
  }
`;

const UserActivationComponent = () => {
  return (
    <Container>
      <H0>Gratulacje!</H0>
      <PictureBox>
        <PartyChrisitinImage />
      </PictureBox>
      <Text bold>Twój email został potwierdzony.</Text>
      <StyledText>
        Na podany przed Ciebie adres email do 24h wyślemy szczegóły dotyczące
        Twojego nowego konta.
      </StyledText>
      <Text>Pozdrawiamy</Text>
      <LogoBox>
        <LogoIcon />
      </LogoBox>
    </Container>
  );
};

export default UserActivationComponent;
