import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 60vh;
  margin: 3em auto;
  padding: 10% 1.5em;
  background: #ffffff;
  color: ${({ theme }) => theme.textColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 10px;
  text-align: center;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 450px;
    min-height: 400px;
    padding: 2.5em;
  }
`;

const UpgradeStepWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

UpgradeStepWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default UpgradeStepWrapper;
