import React from 'react';

const castleLabel = () => {
  return (
    <svg
      viewBox="0 0 95 65"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h94.412v64.611H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h94.412v64.611H0z" />
      </clipPath>
      <g>
        <path
          d="M73.04 7.579h-5.7v2.568h5.7L71.879 8.82l1.161-1.241z"
          fill="#574d85"
        />
        <path d="M67.334 12.716V7.579" fillRule="nonzero" />
        <path
          d="M41.002 4.652v.005l7.184 9.677h.237l7.472 11.354h-3.128v11.133h2.438v11.4h8.224V29.1H61.887a.988.988 0 0 1-.984-.984.99.99 0 0 1 .984-.984l.031.001h.576l4.863-15.1 4.864 15.1h.576a.989.989 0 0 1 .953.983.989.989 0 0 1-.953.984h-1.512v35.511H11.706V29.1h-1.541a.988.988 0 0 1-.984-.984.99.99 0 0 1 .984-.984l.031.001h.575l4.864-15.1 4.864 15.1h.576a.989.989 0 0 1 .953.983.989.989 0 0 1-.953.984h-1.512v19.117H26.8v-11.4h2.439v-11.13h-3.227l7.472-11.354h.238l7.234-9.744.046.063zm.004-2.083V0h5.7l-1.165 1.242 1.161 1.327h-5.696v2.088l-.004-.005V2.569h.004zM21.317 7.579h-5.7v2.568h5.7L20.156 8.82l1.161-1.241z"
          fill="#574d85"
        />
        <path d="M15.616 12.716V7.579" fillRule="nonzero" />
        <path
          d="M6.341 5.503l.543 3.9c.012.09.053.175.115.241a.4.4 0 0 0 .229.122l3.691.573-3.691.573a.4.4 0 0 0-.229.122.439.439 0 0 0-.115.241l-.543 3.9-.543-3.9a.439.439 0 0 0-.115-.241.4.4 0 0 0-.229-.122l-3.691-.573 3.691-.573a.4.4 0 0 0 .229-.122.439.439 0 0 0 .115-.241l.543-3.9zM86.285 33.456l.965 6.916c.021.16.091.31.2.428a.713.713 0 0 0 .406.216l6.553 1.018-6.553 1.018a.712.712 0 0 0-.406.215.787.787 0 0 0-.2.429l-.965 6.916-.964-6.916a.787.787 0 0 0-.2-.429.712.712 0 0 0-.406-.215l-6.553-1.018 6.553-1.018a.713.713 0 0 0 .406-.216.785.785 0 0 0 .2-.428l.964-6.916zM4.578 41.413l.543 3.895a.435.435 0 0 0 .115.242.398.398 0 0 0 .229.121l3.691.573-3.691.573a.4.4 0 0 0-.229.122.439.439 0 0 0-.115.241l-.543 3.9-.543-3.9a.439.439 0 0 0-.115-.241.4.4 0 0 0-.229-.122L0 46.243l3.691-.573a.398.398 0 0 0 .229-.121.435.435 0 0 0 .115-.242l.543-3.894z"
          fill="#574d85"
        />
      </g>
    </svg>
  );
};

export default castleLabel;
