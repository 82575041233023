import { getAuthHeader, getRequestHeaders, handleResponse } from '../request';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAvailableCities = () => {
  const requestOptions = {
    method: 'GET',
    headers: getAuthHeader()
  };
  return fetch(`${apiUrl}/community/city`, requestOptions).then(handleResponse);
};

export const fetchContacts = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/community/contacts`, requestOptions).then(
    handleResponse
  );
};

export const postEvaluation = (attachmentId, vote, evaluation) => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      app_evaluation: {
        attachment: attachmentId,
        vote: vote,
        evaluation: evaluation ? evaluation : 'Brak'
      }
    })
  };
  return fetch(`${apiUrl}/evaluation`, requestOptions).then(response =>
    handleResponse(response)
  );
};

export const fetchApartmentSummary = apartment => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/community/${apartment}/summary`, requestOptions).then(
    handleResponse
  );
};

export const saveKeeper = keeper => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      keeper_information: {
        information: keeper
      }
    })
  };
  return fetch(`${apiUrl}/community/keeper-information`, requestOptions).then(
    handleResponse
  );
};

export const saveMortgage = number => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      mortgage_number: {
        number: number
      }
    })
  };
  return fetch(`${apiUrl}/apartment/mortgage`, requestOptions).then(
    handleResponse
  );
};

export const getAccessControlList = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/permission/list`, requestOptions).then(
    handleResponse
  );
};
