import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import ErrorIcon from '../../assets/errorIcon';
import { H0, H2, Text } from '../layout/theme/components';
import Button from './../shared/components/buttons/PrimaryButton';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: ${({ theme }) => theme.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
  }
`;
const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 1em 3em;
    flex: 1;
    max-width: 400px;
    width: 50%;
    height: 100%;
    justify-content: center;
  }
`;
const ImageWrapper = styled.div`
  ${sectionStyle};
  height: 50%;
  svg {
    width: 90%;
  }
`;
const TextWrapper = styled.div`
  ${sectionStyle};
  height: 50%;
  color: ${({ theme }) => theme.textColors.secondary};
  text-align: center;
`;
const StyledLink = styled(Link)`
  margin-top: 10%;
`;

const Error = () => (
  <ErrorWrapper>
    <ImageWrapper>
      <ErrorIcon />
    </ImageWrapper>
    <TextWrapper>
      <H0>Ups!</H0>
      <H2>Coś poszło nie tak!</H2>
      <Text>Poczekaj chwilę i spróbuj odświeżyć stronę.</Text>
      <StyledLink to="/">
        <Button small>WRÓĆ DO STRONY GŁÓWNEJ</Button>
      </StyledLink>
    </TextWrapper>
  </ErrorWrapper>
);

export default Error;
