import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import EmojiIcon from './../../../../assets/emojiIcon';

const EmojiBox = styled.div`
  position: absolute;
  bottom: ${({ emojiWithoutMention }) =>
    emojiWithoutMention ? '1.7em' : '-1.3em'};
  right: 0.5em;
  width: 2.5em;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 90%;
  }
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
`;

const EmojiPicker = ({
  toggleEmoji,
  handleClickEmoji,
  emojiWithoutMention
}) => {
  return (
    <Fragment>
      <EmojiBox
        onClick={() => handleClickEmoji()}
        emojiWithoutMention={emojiWithoutMention}
      >
        <EmojiIcon />
      </EmojiBox>
      {toggleEmoji && <Backdrop onClick={() => handleClickEmoji()} />}
    </Fragment>
  );
};

EmojiPicker.propTypes = {
  handleClickEmoji: PropTypes.func.isRequired,
  toggleEmoji: PropTypes.bool,
  emojiWithoutMention: PropTypes.bool
};

EmojiPicker.defaultProps = {
  toggleEmoji: false,
  emojiWithoutMention: false
};

export default EmojiPicker;
