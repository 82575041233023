import PropTypes from 'prop-types';
import React from 'react';
import Range from '../components/Range';
import RangesWrapper from '../components/RangesWrapper';

const Step11 = ({ nextSlide, ranges }) => (
  <RangesWrapper nextSlide={nextSlide}>
    <Range name={ranges[0].name} iconPath={ranges[0].icon.path} />
    <Range name={ranges[1].name} iconPath={ranges[1].icon.path} />
    <Range name={ranges[2].name} description={ranges[2].description} iconPath={ranges[2].icon.path} isChecked />
  </RangesWrapper>
);

Step11.propTypes = {
  ranges: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextSlide: PropTypes.func.isRequired,
};

export default Step11;
