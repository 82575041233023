import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { LabelColored } from '../../../layout/theme/components';
import PreviewSection from './PreviewSection';

const ColorLabel = styled(LabelColored).attrs({
  bold: true
})`
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.textColors.highlighted : theme.textColors.secondary};
`;
const StyledPreviewSection = styled(PreviewSection)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0;
`;

const PreviewPlace = ({ place }) => {
  return (
    <StyledPreviewSection title="Miejsce:">
      <ColorLabel>
        &nbsp;
        {place}
      </ColorLabel>
    </StyledPreviewSection>
  );
};

PreviewPlace.propTypes = {
  place: PropTypes.string.isRequired
};

export default PreviewPlace;
