import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { H2 } from '../../../layout/theme/components';
import DenyBubble from '../../../shared/components/DenyBubble';
import Modal from './../../../shared/components/Modal';

const Button = styled.div`
  width: 100%;
  text-align: right;
`;

const UntagUser = ({ deleteUserTag }) => {
  const [openModal, toggleModal] = useState(false);
  const modalToggle = () => toggleModal(!openModal);
  return (
    <>
      <Button onClick={modalToggle}>Opuść sprawę</Button>
      {openModal && (
        <Modal closeModal={modalToggle}>
          <DenyBubble
            leftButton="ANULUJ"
            leftClick={modalToggle}
            rightButton="OPUŚĆ"
            rightClick={deleteUserTag}
          >
            <H2>Czy na pewno chcesz opuścić sprawę?</H2>
          </DenyBubble>
        </Modal>
      )}
    </>
  );
};

UntagUser.propTypes = {
  deleteUserTag: PropTypes.func
};
UntagUser.defaultProps = {
  deleteUserTag: null
};

export default UntagUser;
