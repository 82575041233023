import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { fetchResolutionReport } from '../../helpers/api/case';
import { daysBetweenDates, formatDate } from '../../helpers/date';
import { floatNumbers, formatVote } from '../../helpers/tools';
import Loader from '../../shared/components/Loader';
import Table from '../../shared/components/Table';
import Error from '../../shared/Error';
import ReportChart from './components/ReportChart';
import ReportContent from './components/ReportContent';
import ReportHeader from './components/ReportHeader';
import ReportStatistics from './components/ReportStatistics';
import ReportSubheader from './components/ReportSubheader';
import ReportTitle from './components/ReportTitle';
import ReportWrapper from './components/ReportWrapper';
import VoteLabel from './components/VoteLabel';

const ReportContainer = ({ match }) => {
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getReport = () => {
    setIsLoading(true);
    fetchResolutionReport(match.params.id)
      .then(data => setReportData(data.data))
      .then(() => {
        setIsLoading(false);
        setTimeout(() => {
          window.print();
        }, 300);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  };

  useEffect(() => getReport(), []);

  const getResolutionDuration = () => {
    const { resolution } = reportData;
    const endDate = resolution.closed_at
      ? resolution.closed_at.date
      : new Date().toISOString();

    return daysBetweenDates(endDate, resolution.start_date.date);
  };

  const tableColumns = [
    { id: 'apartment', label: 'Lokal' },
    { id: 'name', label: 'Imię, nazwisko' },
    { id: 'share', label: 'Udział' },
    { id: 'vote', label: 'Głos' }
  ];
  const createRow = item => {
    const multiShares = item.shares.length > 1 ? true : false;

    if (multiShares) {
      return {
        apartment: <b>{item.display_name}</b>,
        name: '',
        share: <b>{formatVote(item.apartment_share)}</b>,
        subrows: item.shares.map(share => {
          const owners = share.users.map(user => (
            <div>{user.display_name}</div>
          ));
          return {
            apartment: `${share.apartment_share} w lokalu`,
            name: owners,
            share: formatVote(share.share),
            vote: <VoteLabel vote={share.vote} votedBy={share.voted_by} />
          };
        })
      };
    } else {
      const rowData = item.shares[0];
      const name = rowData
        ? rowData.users.map(user => <div>{user.display_name}</div>)
        : '';
      return {
        apartment: <b>{item.display_name}</b>,
        name: name,
        share: (
          <b>
            {rowData
              ? formatVote(rowData.share)
              : formatVote(item.apartment_share)}
          </b>
        ),
        vote: rowData && (
          <VoteLabel vote={rowData.vote} votedBy={rowData.voted_by} />
        )
      };
    }
  };

  if (error) return <Error />;
  if (isLoading) return <Loader />;

  const { results, archived_at, archived_by } = reportData.resolution;
  const roundedPositiveVotes = floatNumbers(results.positive_votes_percent, 2);
  const roundedNeutralVotes = floatNumbers(
    100 - results.positive_votes_percent - results.negative_votes_percent,
    2
  );
  const roundedNegativeVotes = floatNumbers(results.negative_votes_percent, 2);

  return (
    <ReportWrapper>
      <ReportHeader
        status={reportData.status}
        reportDate={archived_at ? archived_at.date : null}
        closedBy={archived_by ? archived_by.display_name : null}
      />
      <ReportTitle
        title={reportData.resolution.title}
        community={reportData.community && reportData.community.name}
      />
      <ReportContent
        content={reportData.resolution && reportData.resolution.content}
      />
      <ReportSubheader>Raport graficzny przebiegu głosowania</ReportSubheader>
      <ReportChart
        positiveVotes={roundedPositiveVotes}
        negativeVotes={roundedNegativeVotes}
        neutralVotes={roundedNeutralVotes}
        totalApartments={reportData.resolution.total_apartments}
        votedApartments={reportData.resolution.voted_apartments}
      />
      {reportData.status !== 'invalidated' && (
        <ReportStatistics
          startDate={formatDate(reportData.resolution.start_date.date)}
          duration={getResolutionDuration()}
          closed={reportData.resolution.closed_at ? true : false}
        />
      )}
      <ReportSubheader>Lista głosowania</ReportSubheader>
      <Table
        columns={tableColumns}
        rows={reportData.voting_list.map(item => createRow(item))}
      />
    </ReportWrapper>
  );
};

ReportContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string)
  }).isRequired
};

export default ReportContainer;
