import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Container from './../shared/components/Container';
import ArrowIcon from './../shared/components/ArrowIcon';

import PhoneIcon from './../../assets/phone';
import { H2 } from '../layout/theme/components';

const PhoneBox = styled.div`
  width: 32%;
  margin: 1%;
`;
const ContactsWrapper = styled.div`
  text-align: center;
`;

const ContactsComponent = props => {
  return (
    <Container>
      <Link to="/">
        <ArrowIcon />
      </Link>
      <H2>{props.header}</H2>
      <PhoneBox>
        <PhoneIcon />
      </PhoneBox>
      <ContactsWrapper>{props.children}</ContactsWrapper>
    </Container>
  );
};

ContactsComponent.propTypes = {
  header: PropTypes.string.isRequired
};

export default ContactsComponent;
