import React from 'react';
import styled from 'styled-components';

const Subheader = styled.div`
  font-size: 2.4em;
  font-weight: 500;
  width: 100%;
  text-align: left;
  margin: 1em 0;
`;

const ReportSubheader = props => {
  return <Subheader>{props.children}</Subheader>;
};

export default ReportSubheader;
