import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { H1 } from '../../../layout/theme/components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  word-break: break-word;
`;
const StyledH1 = styled(H1)`
  width: 100%;
  text-align: left;
  margin: 0 0 10px;
`;

const PreviewHeader = ({ topic }) => (
  <Wrapper>
    <StyledH1>{topic}</StyledH1>
  </Wrapper>
);

PreviewHeader.propTypes = {
  topic: PropTypes.string.isRequired
};

export default PreviewHeader;
