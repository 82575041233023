import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import ThumbNoIcon from './../../../assets/thumbNo';

const Thumb = css`
  position: relative;
  height: 100%;
  width: 2.5em;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  svg {
    position: absolute;
    top: 25%;
    left: 30%;
    width: 60%;
    z-index: 2;
  }
`;

const ThumbNo = styled.div`
  ${Thumb};
  fill: ${({ highlighted, theme }) =>
    highlighted === -1 ? theme.labels.negative : theme.buttonColors.inactive};
`;

const ThumbBackground = css`
  position: relative;
  width: 2em;
  height: 2em;
  z-index: 1;
  border-radius: 4px;
  background: lightgray;
`;

const BackgroundNo = styled.div`
  ${ThumbBackground};
  background: ${({ highlighted, theme }) =>
    highlighted === -1 && theme.labelsBackground.negative};
`;

const ThumbDown = memo(({ onClick, highlighted }) => (
  <ThumbNo onClick={onClick} highlighted={highlighted}>
    <BackgroundNo highlighted={highlighted} />
    <ThumbNoIcon />
    {/* TODO add label */}
  </ThumbNo>
));

ThumbDown.propTypes = {
  onClick: PropTypes.func,
  highlighted: PropTypes.number
};

ThumbDown.defaultProps = {
  onClick: null,
  highlighted: null
};

export default ThumbDown;
