import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { resolutionStatus } from '../../../helpers/constants';
import { formatDate } from '../../../helpers/date';
import Logo from '../../../shared/components/Logo';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 1.6em;
`;
const LogoBox = styled.div`
  width: 200px;
`;
const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 1.5;
`;
const Status = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: flex-end;
  align-items: center;
`;
const PositiveStatus = styled.span`
  color: ${({ theme }) => theme.labels.positive};
`;
const NegativeStatus = styled.span`
  color: ${({ theme }) => theme.labels.negative};
`;

const ResolutionStatus = ({ status }) => {
  switch (status) {
    case 'accepted':
      return <PositiveStatus>{resolutionStatus[status]}</PositiveStatus>;
    case 'rejected':
    case 'invalidated':
      return <NegativeStatus>{resolutionStatus[status]}</NegativeStatus>;
    default:
      return resolutionStatus[status];
  }
};

Status.propTypes = {
  status: PropTypes.string.isRequired
};

const ReportHeader = ({ status, reportDate, closedBy }) => {
  const printDate = new Date().toISOString();

  return (
    <HeaderWrapper>
      <LogoBox>
        <Logo />
      </LogoBox>
      <StatusBox>
        <div>Data wydruku: {formatDate(printDate)}</div>
        {reportDate && (
          <div>Data powstania protokołu: {formatDate(reportDate)}</div>
        )}
        {closedBy && <div>Głosowanie zamknięte przez: {closedBy}</div>}
        <Status>
          Status:&nbsp;&nbsp;
          <ResolutionStatus status={status} />
        </Status>
      </StatusBox>
    </HeaderWrapper>
  );
};

ReportHeader.propTypes = {
  status: PropTypes.string,
  closedBy: PropTypes.string
};

ReportHeader.defaultProps = {
  status: '',
  closedBy: null
};

export default ReportHeader;
