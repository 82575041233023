import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { userLabels, userRoles } from '../../../helpers/constants';
import { H2, Text } from '../../../layout/theme/components';
import TextButton from '../../../shared/components/buttons/TextButton';
import DenyBubble from '../../../shared/components/DenyBubble';
import AttachmentsPreview from './../../../shared/components/AttachmentsPreview';
import Modal from './../../../shared/components/Modal';
import UserLabel from './../../../shared/components/UserLabel';

import { createTextLinks } from '../../../helpers/tools';

const CommentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 1.5em;
`;
const AuthorLabel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  text-align: left;
  width: 77%;
  padding: 1em 0 0;
`;
const Description = styled(Text).attrs({
  small: true,
  defaultColor: true,
})`
  text-align: left;
  font-size: 1.4em;
  margin: 0.4em 0 0 1.8em;
  a {
    color: #1670d6;
  }
`;
const FileTile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 0.5em 0.5em 0 6em;
  margin: 0.5em 0 0.5em -3.5em;
`;
const DeletedLabel = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.textColors.tertiary};
  font-size: 1.1em;
  line-height: 2;
  padding-left: 2.3em;
`;
const DeleteButtonWrap = styled.div`
  position: absolute;
  right: 1em;
  top: 1.5em;
`;

class Comment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPreview: false,
    };
  }

  toggleModal(toggle) {
    this.setState({ openPreview: toggle });
  }

  renderModal() {
    const { openPreview } = this.state;
    const { deleteComment } = this.props;
    const { id } = this.props.data;
    if (openPreview) {
      return (
        <Modal closeModal={e => this.toggleModal(false, e)}>
          <DenyBubble
            leftClick={() => this.toggleModal()}
            rightClick={e => deleteComment(e, id)}
            leftButton="ANULUJ"
            rightButton="USUŃ"
          >
            <H2>Czy na pewno chcesz usunąć komentarz?</H2>
          </DenyBubble>
        </Modal>
      );
    }
    return null;
  }

  render() {
    const { description, user, anonymous, attachments, status } = this.props.data;
    const { username } = this.props;
    const newDescription = createTextLinks(description);

    return (
      <CommentWrapper>
        {status === 'deleted' ? (
          <DeletedLabel>Komentarz usunięty</DeletedLabel>
        ) : (
          <>
            <AuthorLabel>
              {anonymous ? (
                <UserLabel userName={userLabels.ROLE_ANONYMOUS} userRole={userRoles.ROLE_ANONYMOUS} showTooltip />
              ) : (
                <UserLabel
                  userName={user.display_name}
                  userRole={user.role}
                  userId={user.id}
                  avatarPath={user.avatar && user.avatar.path}
                  showUserDetails
                  showTooltip
                />
              )}
            </AuthorLabel>
            {user.username === username && (
              <DeleteButtonWrap>
                <TextButton small onClick={e => this.toggleModal(true, e)}>
                  Usuń
                </TextButton>
                {this.renderModal()}
              </DeleteButtonWrap>
            )}
            <Description dangerouslySetInnerHTML={{ __html: newDescription }} />
            {attachments.length > 0 && (
              <FileTile>
                <AttachmentsPreview attachments={attachments} />
              </FileTile>
            )}
          </>
        )}
      </CommentWrapper>
    );
  }
}

Comment.propTypes = {
  data: PropTypes.shape({
    anonymous: PropTypes.bool,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        mime_type: PropTypes.string,
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ),
    banned: PropTypes.number,
    description: PropTypes.string,
    user: PropTypes.shape({
      name_surname: PropTypes.string,
      role: PropTypes.string,
      avatar: PropTypes.object,
    }),
  }).isRequired,
  username: PropTypes.string,
};

Comment.defaultProps = {
  username: '',
};

export default Comment;
