import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveCost } from '../../helpers/api/case';
import CostComponent from './CostComponent';

class CostContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      tempCost: null,
      cost: null,
      error: null
    };
  }

  toggleModal = isOpen => this.setState({ openModal: isOpen, error: null });

  handleCostChange = value => this.setState({ tempCost: value });

  sendCost = () => {
    const { caseId } = this.props;
    const { tempCost } = this.state;
    if (!caseId) return false;
    saveCost(caseId, tempCost)
      .then(() => {
        this.setState({ cost: tempCost });
        this.toggleModal(false);
      })
      .catch(e => this.setState({ error: e.detail }));
  };

  render() {
    const { editable, cost: previousCost, hasCostEditPermission } = this.props;
    const { openModal, cost, error } = this.state;

    return (
      <CostComponent
        toggleModal={this.toggleModal}
        handleCostChange={this.handleCostChange}
        sendCost={this.sendCost}
        openModal={openModal}
        cost={cost || previousCost}
        editable={editable && hasCostEditPermission}
        error={error}
      />
    );
  }
}

CostContainer.propTypes = {
  caseId: PropTypes.number,
  editable: PropTypes.bool
};

CostContainer.defaultProps = {
  caseId: null,
  editable: false
};

const mapStateToProps = state => ({
  hasCostEditPermission: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.cost_edit
    : false
});

export default connect(
  mapStateToProps,
  null
)(CostContainer);
