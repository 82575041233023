import React from 'react';

const soundingIcon = () => {
  return (
    <svg
      viewBox="0 0 221 167"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <rect id="Artboard1" width="220.28" height="166.364" fill="none" />
      <clipPath id="_clip1">
        <rect
          id="Artboard11"
          serifid="Artboard1"
          width="220.28"
          height="166.364"
        />
      </clipPath>
      <g fillRule="nonzero" clipPath="url(#_clip1)">
        <path
          d="M148.189,51.583c14.508,0.007 26.445,11.944 26.452,26.452c0.008,4.508 -1.143,8.943 -3.341,12.879l22.531,0c14.507,0.009 26.442,11.945 26.449,26.452c-0.007,14.509 -11.943,26.445 -26.452,26.452l-121.738,0c-14.508,-0.007 -26.444,-11.944 -26.451,-26.452c-0.006,-4.507 1.145,-8.941 3.344,-12.875l-22.531,0c-14.509,-0.007 -26.445,-11.943 -26.452,-26.452c0.005,-14.51 11.942,-26.449 26.452,-26.456l121.737,0Z"
          fill="#edecf1"
        />
        <rect
          x="53.691"
          y="49.735"
          width="95.744"
          height="116.626"
          fill="#d1cde4"
        />
        <path
          d="M172.863,33.439l-23.427,16.3l0,116.625l23.427,-16.3l0,-116.625Z"
          fill="#bcb6da"
        />
        <path
          d="M173.372,33.306l-23.427,16.3l-96.254,0l23.427,-16.3l96.254,0Z"
          fill="#9289bc"
        />
        <path
          d="M148.667,37.777l-10.572,7.355l-59.7,0l10.573,-7.355l59.699,0Z"
          fill="#fff"
        />
        <rect x="53.691" y="86.65" width="95.744" height="45.29" fill="#fff" />
        <path
          d="M79.71,102.977l1.239,-3.382c0.855,-2.335 0.3,-5.9 -2.071,-7.169c-0.833,-0.446 -1.797,-0.587 -2.723,-0.4c-0.503,0.129 -0.935,0.453 -1.2,0.9c-0.223,0.53 -0.35,1.094 -0.377,1.668c-0.262,1.446 -0.96,2.778 -2,3.816c-1.827,1.782 -7.5,6.923 -7.5,6.923l0,18.829l19.612,0c1.933,-0.031 3.507,-1.63 3.507,-3.564c0,-0.593 -0.148,-1.177 -0.431,-1.699c1.556,-1.013 2.074,-3.081 1.177,-4.707c1.556,-1.013 2.074,-3.081 1.177,-4.707c0.988,-0.667 1.581,-1.784 1.581,-2.976c0,-1.722 -1.238,-3.214 -2.931,-3.53c-0.186,-0.033 -9.06,-0.002 -9.06,-0.002Z"
          fill="#57c543"
        />
        <path
          d="M118.511,117.149l-1.239,3.382c-0.855,2.335 -0.3,5.9 2.071,7.17c0.833,0.445 1.797,0.587 2.723,0.4c0.503,-0.129 0.935,-0.453 1.2,-0.9c0.223,-0.53 0.35,-1.094 0.377,-1.668c0.262,-1.446 0.96,-2.778 2,-3.816c1.827,-1.782 7.5,-6.923 7.5,-6.923l0,-18.83l-19.616,0c-1.933,0.031 -3.507,1.63 -3.507,3.564c0,0.593 0.148,1.177 0.431,1.699c-1.556,1.013 -2.074,3.081 -1.177,4.707c-1.556,1.013 -2.074,3.081 -1.177,4.707c-0.988,0.667 -1.582,1.784 -1.582,2.976c0,1.722 1.239,3.213 2.931,3.53c0.19,0.035 9.065,0.002 9.065,0.002Z"
          fill="#f96060"
        />
        <path d="M98.918,41.455l29.226,0" />
        <path d="M98.918,41.455l29.226,0" />
        <rect
          x="62.793"
          y="145.305"
          width="9.757"
          height="8.655"
          fill="#f96060"
        />
        <rect
          x="72.523"
          y="145.304"
          width="64.361"
          height="8.655"
          fill="#fff"
        />
        <path
          d="M45.163,23.989l-0.606,-3.482c-0.42,-2.404 -2.636,-5.165 -5.278,-5.076c-0.927,0.03 -1.815,0.381 -2.511,0.994c-0.363,0.357 -0.572,0.843 -0.578,1.353c0.071,0.559 0.239,1.102 0.498,1.602c0.488,1.357 0.549,2.831 0.176,4.223c-0.677,2.411 -2.979,9.564 -2.979,9.564l9.238,16.001l16.668,-9.623c1.626,-0.977 2.177,-3.108 1.228,-4.75c-0.29,-0.504 -0.702,-0.927 -1.197,-1.231c0.824,-1.625 0.249,-3.635 -1.309,-4.578c0.824,-1.625 0.249,-3.635 -1.309,-4.578c0.513,-1.051 0.469,-2.292 -0.116,-3.306c-0.845,-1.463 -2.629,-2.123 -4.223,-1.56c-0.178,0.063 -7.702,4.447 -7.702,4.447Z"
          fill="#57c543"
        />
        <path
          d="M207.544,105.988l1.503,-2.131c1.035,-1.472 1.317,-4.069 -0.106,-5.4c-0.498,-0.468 -1.145,-0.747 -1.827,-0.791c-0.376,-0.004 -0.738,0.141 -1.007,0.404c-0.255,0.328 -0.45,0.699 -0.577,1.095c-0.454,0.962 -1.191,1.762 -2.113,2.293c-1.611,0.903 -6.543,3.433 -6.543,3.433l-3.529,13.164l13.717,3.678c1.357,0.341 2.758,-0.482 3.12,-1.834c0.111,-0.415 0.117,-0.851 0.017,-1.269c1.278,-0.416 2.027,-1.766 1.705,-3.071c1.278,-0.416 2.028,-1.765 1.706,-3.07c0.814,-0.282 1.438,-0.951 1.661,-1.784c0.322,-1.203 -0.263,-2.477 -1.386,-3.017c-0.132,-0.059 -6.341,-1.7 -6.341,-1.7Z"
          fill="#57c543"
        />
        <path
          d="M165.197,16.054l-1.503,2.131c-1.035,1.472 -1.317,4.069 0.106,5.4c0.498,0.468 1.145,0.747 1.827,0.791c0.376,0.004 0.738,-0.141 1.007,-0.404c0.255,-0.328 0.45,-0.699 0.577,-1.095c0.454,-0.962 1.191,-1.762 2.113,-2.293c1.611,-0.903 6.543,-3.433 6.543,-3.433l3.529,-13.164l-13.717,-3.678c-1.357,-0.341 -2.758,0.482 -3.12,1.834c-0.111,0.415 -0.117,0.851 -0.017,1.269c-1.278,0.416 -2.027,1.766 -1.705,3.071c-1.278,0.416 -2.028,1.765 -1.706,3.07c-0.814,0.282 -1.438,0.951 -1.661,1.784c-0.322,1.203 0.263,2.477 1.386,3.017c0.132,0.059 6.341,1.7 6.341,1.7Z"
          fill="#f96060"
        />
        <path
          d="M33.896,136.254l-0.931,-2.011c-0.645,-1.39 -2.44,-2.703 -4.03,-2.22c-0.556,0.169 -1.037,0.527 -1.358,1.011c-0.163,0.275 -0.21,0.604 -0.131,0.914c0.134,0.328 0.324,0.63 0.563,0.891c0.516,0.744 0.793,1.628 0.793,2.533c-0.019,1.57 -0.253,6.286 -0.253,6.286l8.203,8.203l8.547,-8.548c0.829,-0.857 0.818,-2.241 -0.026,-3.085c-0.257,-0.257 -0.575,-0.447 -0.924,-0.552c0.235,-1.118 -0.44,-2.243 -1.537,-2.563c0.234,-1.119 -0.441,-2.243 -1.538,-2.563c0.14,-0.722 -0.088,-1.468 -0.608,-1.988c-0.752,-0.753 -1.946,-0.861 -2.822,-0.256c-0.092,0.07 -3.948,3.948 -3.948,3.948Z"
          fill="#57c543"
        />
        <path
          d="M14.64,82.663l-1.277,1.811c-0.882,1.254 -1.122,3.466 0.092,4.602c0.424,0.397 0.974,0.634 1.554,0.67c0.32,0.004 0.629,-0.12 0.858,-0.344c0.217,-0.279 0.383,-0.595 0.49,-0.933c0.386,-0.819 1.013,-1.5 1.797,-1.953c1.37,-0.769 5.572,-2.923 5.572,-2.923l3.004,-11.206l-11.677,-3.131c-1.157,-0.289 -2.35,0.413 -2.659,1.565c-0.094,0.352 -0.1,0.721 -0.016,1.076c-1.086,0.356 -1.723,1.503 -1.451,2.614c-1.087,0.356 -1.724,1.503 -1.452,2.613c-0.695,0.239 -1.228,0.81 -1.418,1.52c-0.276,1.028 0.227,2.116 1.189,2.573c0.107,0.044 5.394,1.446 5.394,1.446Z"
          fill="#f96060"
        />
      </g>
    </svg>
  );
};

export default soundingIcon;
