import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: auto;
  word-break: break-word;
  background: ${({ theme }) => theme.backgroundColors.primary};
  text-align: left;
  padding: 1em 0;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: ${({ theme }) => `1px solid ${theme.buttonColors.inactive}`};
`;

export const CasePreviewWrapper = ({ children, withLine }) => (
  <PreviewWrapper>
    {children}
    {withLine && <Line />}
  </PreviewWrapper>
);

CasePreviewWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
