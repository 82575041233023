import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import TextButton from '../../../shared/components/buttons/TextButton';
import { Text } from '../../../layout/theme/components';
import { VOTES } from '../../../helpers/constants';
import ThumbYes from '../../../../assets/thumbYes';
import ThumbNo from '../../../../assets/thumbNo';

const VotedWrapper = styled.div`
  text-align: right;
`;
const VoteStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6em;
  margin: 0 auto;
  padding: 0 5%;
  background: ${({ color }) => color};
  color: #454545;
`;
const VoteIcon = styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  width: 23px;
  height: 23px;
  margin: 0 2em 0.5em 0;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);

  svg {
    fill: #ffffff;
    height: 19px;
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
`;

const ResolutionVoted = memo(
  ({ changeVote, userVote, isVotingClosed, votedBy }) => {
    const voteProps = Object.values(VOTES).find(
      elem => elem.value === userVote
    );

    return (
      <VotedWrapper>
        {!isVotingClosed && (
          <TextButton small onClick={changeVote}>
            Zmień
          </TextButton>
        )}
        <VoteStatus color={voteProps.color}>
          <VoteIcon>{userVote === 1 ? <ThumbYes /> : <ThumbNo />}</VoteIcon>
          <Text>
            Oddano głos: <strong>{voteProps.label}</strong>
            {votedBy && (
              <small>
                <br />
                przez: {votedBy}
              </small>
            )}
          </Text>
        </VoteStatus>
      </VotedWrapper>
    );
  }
);

ResolutionVoted.propTypes = {
  changeVote: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  userVote: PropTypes.number.isRequired,
  isVotingClosed: PropTypes.bool,
  votedBy: PropTypes.string
};
ResolutionVoted.defaultProps = {
  isVotingClosed: false,
  votedBy: null
};

export default ResolutionVoted;
