import { store } from './../store';
import * as Session from './localStorage';

export const getRequestHeaders = () => {
  const currentResource = store.getState().user.currentResource;
  if (currentResource !== null) {
    return {
      Authorization: 'Bearer ' + JSON.parse(Session.getAccessToken()),
      'Content-Resource': JSON.parse(currentResource),
    };
  } else {
    return {
      Authorization: 'Bearer ' + JSON.parse(Session.getAccessToken()),
    };
  }
};

export const getAuthHeader = () => {
  return {
    Authorization: 'Bearer ' + JSON.parse(Session.getAccessToken()),
  };
};

export const handleResponse = response => {
  return response.text().then(text => {
    const data = text ? JSON.parse(text) : {};

    if (!response.ok) {
      if (response.status === 401) {
        if (window.location.pathname.startsWith('/sprawa')) {
          window.location.replace('/public' + window.location.pathname);
        } else if (window.location.pathname !== '/logowanie') {
          Session.resetLocalStorage();
          window.location.replace('/logowanie');
        }
      } else {
        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }
    }

    return data;
  });
};
