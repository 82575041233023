import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import HighFive from '../../../assets/highFive';
import { H0, H2, Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';

const Header = styled.div`
  margin: 3% 0 2%;
  color: #585858;
`;
const HighFiveBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 5% 0;
`;
const StyledLink = styled(Link)`
  margin: 1em 0;
`;

const AfterRegistrationComponent = () => {
  return (
    <>
      <Header>
        <H0 defaultColor>Zgłoszenie przyjęte!</H0>
        <Text>Po potwierdzeniu aplikacja będzie gotowa do użycia!</Text>
      </Header>
      <HighFiveBox>
        <HighFive width="200px" />
      </HighFiveBox>
      <H2>Więcej znaczy lepiej:</H2>
      <StyledLink to="/zaproszenie">
        <PrimaryButton>poinformuj swoich sąsiadów</PrimaryButton>
      </StyledLink>
      <StyledLink to="/logowanie">
        <TextButton>Wróć do STRONY LOGOWANIA</TextButton>
      </StyledLink>
    </>
  );
};

export default AfterRegistrationComponent;
