import { getRequestHeaders, handleResponse } from '../request';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCategories = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/category`, requestOptions).then(response =>
    handleResponse(response)
  );
};

export const fetchSubCategories = id => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/category/children/${id}`, requestOptions).then(
    response => handleResponse(response)
  );
};
