import React from 'react';
import PropTypes from 'prop-types';
import { VOTES } from '../../../helpers/constants';
import styled from 'styled-components';

const Status = styled.div`
  color: ${({ color }) => color};
`;
const StatusLabel = styled.span`
  text-transform: uppercase;
`;

const VoteLabel = props => {
  const { vote, votedBy } = props;
  const voteProps = Object.values(VOTES).find(item => vote === item.value);

  return (
    <>
      {vote !== 0 && (
        <Status color={voteProps.color} small>
          <StatusLabel>{voteProps.label}</StatusLabel>
          {votedBy && ` (${votedBy})`}
        </Status>
      )}
    </>
  );
};

VoteLabel.propTypes = {
  vote: PropTypes.number.isRequired,
  votedBy: PropTypes.string
};

VoteLabel.defaultProps = {
  votedBy: null
};

export default VoteLabel;
