import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const ButtonsWraper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 10px;
  position: relative;
`;

class Buttons extends Component {
  render() {
    return <ButtonsWraper>{this.props.children}</ButtonsWraper>;
  }
}

Buttons.propTypes = {
  children: PropTypes.node.isRequired
};

export default Buttons;
