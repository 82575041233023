import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Container from './../shared/components/Container';
import ArrowIcon from './../shared/components/ArrowIcon';

const FinancesWrapper = styled.div`
  text-align: center;
  word-break: normal;
`;

const FinancesComponent = ({ children, premiumColor }) => {
  return (
    <Container premiumColor={premiumColor}>
      <Link to="/">
        <ArrowIcon />
      </Link>
      <FinancesWrapper>{children}</FinancesWrapper>
    </Container>
  );
};

export default FinancesComponent;
