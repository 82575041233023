import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { loadReCaptcha } from 'react-recaptcha-google';
import { connect } from 'react-redux';
import { fetchPassword } from './../helpers/api/user';
import { isEmailValid, isPhoneNumber } from './../helpers/validation';
import Password from './PasswordRemindComponent';

class PasswordRemindContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialling: '+48',
      username: '',
      valid: true,
      sent: false,
      error: null,
      isLoading: false
    };
  }

  componentDidMount() {
    loadReCaptcha();
  }

  componentDidUpdate(prevProps) {
    if (this.props.usernameType !== prevProps.usernameType) {
      this.resetForm();
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, valid: true, error: null });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { dialling, username } = this.state;
    const { usernameType } = this.props;
    const user = usernameType === 'sms' ? dialling.concat(username) : username;

    this.setState({ isLoading: true });

    if (usernameType && this.isFormValid()) {
      fetchPassword(user, usernameType)
        .then(() =>
          this.setState({ sent: true, valid: true, isLoading: false })
        )
        .catch(error => this.setState({ error, isLoading: false }));
    } else {
      this.setState({ valid: false, isLoading: false });
    }
  };

  resetForm = () => {
    this.setState({
      username: '',
      valid: true,
      error: null,
      isLoading: false
    });
  };

  isFormValid = () => {
    const { dialling, username } = this.state;
    const { usernameType } = this.props;

    if (usernameType === 'email') {
      return isEmailValid(username);
    } else {
      return isPhoneNumber(dialling, username);
    }
  };

  render() {
    const { username, dialling, valid, sent, error, isLoading } = this.state;
    const { usernameType } = this.props;
    const sentText =
      usernameType === 'email'
        ? 'Wysłano hasło na podany adres e-mail'
        : 'Wysłano hasło SMS';
    // todo: loader not working with captcha; removed temporarily

    return (
      <Password
        valid={valid}
        username={username}
        dialling={dialling}
        sent={sent}
        error={error}
        loading={isLoading}
        sentText={sentText}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

PasswordRemindContainer.propTypes = {
  usernameType: PropTypes.string
};

PasswordRemindContainer.defaultProps = {
  usernameType: ''
};

const mapStateToProps = state => ({
  usernameType: state.user.usernameType
});

export default connect(mapStateToProps)(PasswordRemindContainer);
