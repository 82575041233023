import React from 'react';

const Phone = () => (
  <svg
    viewBox="0 0 65 44"
    xmlns="http://www.w3.org/2000/svg"
    xmlnserif="http://www.serif.com/"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h64.2v43.834H0z" />
    <clipPath id="a">
      <path d="M0 0h64.2v43.834H0z" />
    </clipPath>
    <g>
      <path
        d="M23.829 15.416c0-2.235 18.173-2.235 18.173 0 0 5.812 4.719 10.53 10.53 10.53.373 0 .741-.019 1.103-.057v8.448a8.315 8.315 0 0 1-8.311 8.311H20.508c-4.588 0-8.312-3.724-8.312-8.311v-8.448c.363.038.731.057 1.103.057 5.812 0 10.53-4.718 10.53-10.53z"
        fill="#fc90a8"
      />
      <circle cx="32.904" cy="31.549" r="7.621" fill="#fcd1db" />
      <circle cx="32.916" cy="26.646" r="1.181" fill="#9d96be" />
      <circle cx="32.904" cy="36.796" r="1.181" fill="#9d96be" />
      <circle cx="37.935" cy="31.549" r="1.181" fill="#9d96be" />
      <circle cx="27.917" cy="31.549" r="1.181" fill="#9d96be" />
      <circle cx="29.492" cy="28.181" r="1.181" fill="#9d96be" />
      <circle cx="36.229" cy="28.181" r="1.181" fill="#9d96be" />
      <circle cx="36.229" cy="34.612" r="1.181" fill="#9d96be" />
      <circle cx="29.492" cy="34.612" r="1.181" fill="#9d96be" />
      <circle cx="32.904" cy="31.549" r="1.506" fill="#fff" />
      <path
        d="M12.175 27.622c7.342 0 13.295-5.952 13.295-13.295h-2.417c0 6.008-4.871 10.878-10.878 10.878v2.417zM53.635 27.035c-7.342 0-13.295-5.952-13.295-13.295h2.417c0 6.008 4.871 10.878 10.878 10.878v2.417z"
        fill="#ef5b7b"
      />
      <path
        d="M42.892 13.216a.94.94 0 0 0-.939-.94H24.009a.94.94 0 0 0-.94.94v1.88c0 .519.421.94.94.94h17.944a.94.94 0 0 0 .939-.94v-1.88z"
        fill="#ee5073"
      />
      <g fillRule="nonzero">
        <path
          d="M52.882000000000005 12.864c1.505-2.432.342-6.515.036-7.247l-.06-.254-.187-.225C46.99-3.041 28.26 1.103 25.974 1.641 24.196 2.06 9.602 5.833 4.99 13.287a9.404 9.404 0 0 0-1.314 3.527l-.067.284.06.254c.112 1.046 1.066 6.519 4.667 7.011 1.39.209 7.849-1.112 11.643-5.491l.273-.265.116-.363c.067-.284.772-2.126.659-5.45.878-.542 2.947-1.432 7.06-2.468 4.064-.957 6.313-1.085 7.341-.992 1.431 2.948 2.836 4.359 2.945 4.535l.265.272.362.116c5.351 2.225 11.69.397 12.919-.362.351-.217.672-.56.963-1.031z"
          fill="#fa6f8e"
        />
        <path
          d="M20.901 15.718c.133-2.522-16.566 5.97-14.625 7.718.562.507 1.238.869 2.047.98 1.39.209 7.848-1.112 11.643-5.491l.388-.627c.026-.108.46-.914.547-2.58zM36.805 11.862c-1.273-2.182 17.461-2.28 16.537.163-.268.708-.703 1.34-1.372 1.809-1.139.822-7.486 2.606-12.864.451l-.633-.38c-.072-.084-.827-.602-1.668-2.043z"
          fill="#cd4966"
        />
      </g>
      <path
        d="M63.607 42.882c0-.644-.523-1.167-1.167-1.167H1.76a1.168 1.168 0 0 0 0 2.334h60.68c.644 0 1.167-.523 1.167-1.167z"
        fill="#756ca4"
      />
    </g>
  </svg>
);

export default Phone;
