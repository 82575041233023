import React from 'react';

const companyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.588" height="5.58">
      <path
        data-name="Path 1750"
        d="M.368 2.484l2.139 2.333 3.694-4.5"
        fill="none"
        stroke="#fff"
      />
    </svg>
  );
};

export default companyIcon;
