import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Range from './RangeComponent';
import RadioInput from '../../shared/components/forms/RadioInput';

import { saveRangeStep } from '../../helpers/api/case';

class RangeContainer extends Component {
  constructor(props) {
    super(props);

    const selectedRange = props.ranges.find(range => range.selected);

    this.state = {
      rangeId: selectedRange ? selectedRange.id : props.ranges[0].id
    };

    this.selectRange = this.selectRange.bind(this);
    this.saveStep = this.saveStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  saveStep(rangeId) {
    const { caseId } = this.props;
    saveRangeStep(caseId, rangeId).then(() => this.props.handleNextStep());
  }

  handleSubmit(e) {
    e.preventDefault();
    const { rangeId } = this.state;
    this.saveStep(rangeId);
  }

  selectRange(rangeId) {
    this.setState({ rangeId: rangeId });
  }

  renderRanges() {
    const { ranges } = this.props;
    const { rangeId } = this.state;

    return ranges.map(range => (
      <RadioInput
        key={range.id}
        name="range"
        value={range.id}
        checked={range.id === rangeId}
        onChange={this.selectRange}
        label={range.name}
        labelIconPath={range.media ? range.media.path : null}
        description={range.description}
      />
    ));
  }

  render() {
    return (
      <Fragment>
        <Range handleSubmit={this.handleSubmit}>{this.renderRanges()}</Range>
      </Fragment>
    );
  }
}

RangeContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  ranges: PropTypes.array.isRequired,
  handleNextStep: PropTypes.func.isRequired
};

export default RangeContainer;
