import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const ArrowBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 4px;
  transform: ${({ rotate }) =>
    rotate ? 'rotate(-135deg)' : 'rotate(-315deg)'};
  top: ${({ rotate }) => (rotate ? '1px' : '-1px')};
  transition: all 0.2s;
  z-index: 2;
  position: relative;
  cursor: pointer;
`;
const Arrow = styled.i`
  border-style: solid;
  border-color: ${({ theme, inactive }) =>
    inactive ? theme.buttonColors.inactive : theme.buttonColors.secondary};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
`;

const DropdownArrow = ({ rotate, onClick, inactive }) => (
  <ArrowBox onClick={onClick} rotate={rotate ? 1 : 0}>
    <Arrow inactive={inactive ? 1 : 0} />
  </ArrowBox>
);

DropdownArrow.propTypes = {
  rotate: PropTypes.bool,
  onClick: PropTypes.func,
  inactive: PropTypes.bool
};
DropdownArrow.defaultProps = {
  rotate: false,
  onClick: null,
  inactive: false
};

export default DropdownArrow;
