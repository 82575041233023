import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import plusIcon from './../../../../assets/plusIcon.svg';

const Circle = styled.div`
  background: ${({ theme }) => theme.buttonColors.primary};
  border: none;
  width: 6em;
  height: 6em;
  padding: 0.3em;
  display: inline-block;
  border-radius: 50%;
  line-height: 50%;
  outline: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.button};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 46%;
  }
`;

const AddCase = memo(({ to, className }) => (
  <Link to={to} className={className}>
    <Circle>
      <img alt="addCase" src={plusIcon} />
    </Circle>
  </Link>
));

AddCase.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string
};

AddCase.defaultProps = {
  to: '',
  className: ''
};

export default AddCase;
