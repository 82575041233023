import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Caption, H2 } from '../../layout/theme/components';
import PrimaryButton from './buttons/PrimaryButton';
import Container from './Container';
import Loader from './Loader';
import ModalWrapper from './ModalWrapper';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const ButtonWrapper = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  margin-bottom: 3.5em;
`;
const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
`;

const FullModalContainer = ({ children, isLoading, buttonType, buttonText, onClick, header, disabled, caption }) => {
  return (
    <ModalWrapper>
      <Container>
        <ContentWrapper>
          <HeaderBox>
            {header && <H2>{header}</H2>}
            {caption && <Caption>{caption}</Caption>}
          </HeaderBox>
          {children}
        </ContentWrapper>
        <ButtonWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <PrimaryButton type={buttonType} onClick={onClick} disabled={disabled}>
              {buttonText}
            </PrimaryButton>
          )}
        </ButtonWrapper>
      </Container>
    </ModalWrapper>
  );
};

FullModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
FullModalContainer.defaultProps = {
  isLoading: false,
  disabled: false,
  buttonType: '',
  buttonText: '',
  header: false,
  caption: false,
};

export default FullModalContainer;
