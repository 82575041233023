import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Megaphone from '../../../../assets/megaphone';
import AddResolution from '../../../../assets/resolution/addResolution';
import { Text } from '../../../layout/theme/components';

const NoResultWrapper = styled.div`
  max-width: 450px;
  padding: 0 1.5em;
  line-height: 1.5;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #454545;
`;
const IconWrapper = styled.div`
  width: 120px;
`;
const Paragraph = styled.div`
  margin: 10px 0 30px;
`;
const Question = styled.div`
  margin: 20px 0 5px;
`;

const renderPageContent = type => {
  switch (type) {
    case 'myCases':
      return (
        <Text small>
          <b>Gdzie są Twoje sprawy?</b>
          <Paragraph>Dodaj sprawę teraz i rozwiąż problem we wspólnocie!</Paragraph>
          <b>Im więcej dodanych spraw tym więcej rozwiązanych problemów!</b>
        </Text>
      );
    case 'resolutions':
      return (
        <Text small bold>
          Nie ma jeszcze żadnej uchwały
        </Text>
      );
    default:
      return (
        <Text small>
          <b>Co zrobić teraz?</b>
          <Question>
            <b>1. Zaproś swoich sąsiadów</b>
          </Question>
          Więcej sąsiadów to skuteczne rozwiązanie problemów!
          <Question>
            <b>2. Załóż pierwszą sprawę</b>
          </Question>
          Rozwiąż problemy swojej wspólnoty.
        </Text>
      );
  }
};

const NoResultsCases = ({ type }) => (
  <NoResultWrapper>
    <IconWrapper>{type === 'resolutions' ? <AddResolution /> : <Megaphone />}</IconWrapper>
    {renderPageContent(type)}
  </NoResultWrapper>
);

NoResultsCases.propTypes = {
  type: PropTypes.string,
};

NoResultsCases.defaultProps = {
  type: null,
};

export default NoResultsCases;
