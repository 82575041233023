import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Text } from '../../../../layout/theme/components';

const AvatarDetailTextWrapper = styled.div`
  display: flex;
  width: 80%;
  padding: 15px 0 10px 0;
  border-top: ${({ horizontalLine, theme }) =>
      horizontalLine ? theme.borderColor : 'transparent'}
    1px solid;
`;
const AvatarDetailPropertyText = styled.div`
  width: 40%;
`;
const AvatarDetailValueWrapper = styled.div`
  color: ${({ theme }) => theme.textColors.secondary};
  width: 60%;
`;
const AvatarDisabledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.tertiary};
`;

const AvatarDetails = ({ email, phone, apartments }) => (
  <>
    <AvatarDetailTextWrapper>
      <AvatarDetailPropertyText small defaultColor>
        <Text small defaultColor>
          Telefon:
        </Text>
        <Text small defaultColor>
          E-mail:
        </Text>
      </AvatarDetailPropertyText>
      <AvatarDetailValueWrapper>
        {!phone ? (
          <AvatarDisabledText small>Brak numeru telefonu</AvatarDisabledText>
        ) : (
          <a href={`tel:${phone}`}>
            <Text small>{phone}</Text>
          </a>
        )}
        {!email ? (
          <AvatarDisabledText small>Brak adresu email</AvatarDisabledText>
        ) : (
          <a href={`mailto:${email}`}>
            <Text small>{email}</Text>
          </a>
        )}
      </AvatarDetailValueWrapper>
    </AvatarDetailTextWrapper>
    {apartments.length !== 0 && (
      <AvatarDetailTextWrapper horizontalLine>
        <AvatarDetailPropertyText small defaultColor>
          <Text small defaultColor>
            Lokale:
          </Text>
        </AvatarDetailPropertyText>
        <AvatarDetailValueWrapper>
          {apartments.map(apartment => (
            <Text small defaultColor key={apartment.id}>
              {apartment.display_name}
            </Text>
          ))}
        </AvatarDetailValueWrapper>
      </AvatarDetailTextWrapper>
    )}
  </>
);

AvatarDetails.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  apartments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string
    })
  )
};

AvatarDetails.defaultProps = {
  email: '',
  phone: '',
  apartments: []
};

export default AvatarDetails;
