import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const StyledMenuButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textColors.primary};
  font-size: 1.4em;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 2em;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 1.2em;
    height: 1.2em;
    margin-right: 1em;
    background: ${({ theme }) => theme.textColors.secondary};
    border-radius: 4px;
    svg {
      height: 50%;
      fill: white;
    }
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin-bottom: 20%;
  }
`;

const MenuButton = memo(({ to, onClick, icon, text }) => (
  <StyledMenuButton as={!to && 'div'} to={to} onClick={onClick}>
    {icon && <span>{icon}</span>}
    {text}
  </StyledMenuButton>
));

MenuButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};
MenuButton.defaultProps = {
  to: '',
  onClick: null
};

export default MenuButton;
