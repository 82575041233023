import { getRequestHeaders, handleResponse } from '../request';
const apiUrl = process.env.REACT_APP_API_URL;

export const loginToFinances = (user, code, pin) => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      user,
      code,
      pin
    })
  };
  return fetch(`${apiUrl}/weles/login`, requestOptions).then(handleResponse);
};

export const fetchFinancial = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/weles/financial`, requestOptions).then(response =>
    handleResponse(response)
  );
};

export const postFinancial = pin => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      pin
    })
  };
  return fetch(`${apiUrl}/weles/financial`, requestOptions).then(response =>
    handleResponse(response)
  );
};

export const logoutFromFinances = () => {
  const requestOptions = {
    method: 'DELETE',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/weles`, requestOptions).then(response =>
    handleResponse(response)
  );
};
