import React from 'react';
import PrivateRangeIcon from '../../assets/range/privateRangeIcon';
import PublicRangeIcon from '../../assets/range/publicRangeIcon';

export const RANGE_ICONS = {
  apartment: <PrivateRangeIcon />,
  community: <PrivateRangeIcon />,
  public: <PublicRangeIcon />,
};
export const openCaseCTA = {
  notLogged: {
    header: 'Łączymy mieszkańców, zarządców i firmy w jednej aplikacji online by podejmować skuteczne działania!',
    headerButton: 'Super, chcę dołączyć',
    footer: 'Chciałbyś tak u siebie? Zacznij zmieniać swoją wspólnotę już teraz!',
    footerButton: 'Chcę dołączyć',
  },
  logged: {
    header: 'Chcesz być częścią tej wspólnoty?',
    headerButton: null,
    footer: 'Chcesz być częścią tej wspólnoty? Dołącz już teraz!',
    footerButton: null,
  },
};

export const VOTES = {
  positive: { value: 1, label: 'Za', color: '#56C442' },
  neutral: { value: 0, label: 'Brak głosu', color: '#B7B7B7' },
  negative: { value: -1, label: 'Przeciw', color: '#F86060' },
};

export const INVALID_INPUT_TEXT = 'Niepoprawna długość tekstu.';

export const NETWORK_ERROR_TEXT = 'Wystąpił błąd, spróbuj jeszcze raz.';

export const EXCEEDED_MESSAGES_AMOUNT = 'Twój limit wiadomości został osiągnięty. Odczekaj 1 min. by wysłać ponownie.';

export const INVALID_INPUT_FORMAT = 'Niepoprawny format danych. Proszę wpisać liczbę.';

export const INVALID_FILE = {
  size: 'Rozmiar pliku nie może przekraczać 10MB',
  extension: 'Dozwolone są pliki z rozszerzeniem .png, .jpg, .jpeg lub .pdf',
  quantity: 'Maksymalna ilość załączników to ',
  format: 'Niepoprawny format pliku.',
};

export const FREQUENCY_ERROR =
  'Zamknięcie głosowania jest możliwe po osiągnięciu większości bezwzględnej za lub przeciw.';

export const mentionAlert = (mentions, plural) => {
  if (plural) {
    return `Firmy ${mentions} zostaną powiadomione i uzyskają dostęp do sprawy (w tym komentarzy sprzed jej dołączenia).`;
  } else {
    return `Firma ${mentions} zostanie powiadomiona i uzyska dostęp do sprawy (w tym komentarzy sprzed jej dołączenia).`;
  }
};

export const statusLabels = {
  active: 'Aktywny',
  verification: 'Weryfikacja',
  blocked: 'Zablokowany',
  inactive: '',
};

export const levelLabels = {
  basic: 'Basic',
  premium: 'Premium',
};

export const userRoles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_WARDEN: 'ROLE_WARDEN',
  ROLE_KEEPER: 'ROLE_KEEPER',
  ROLE_COMPANY: 'ROLE_COMPANY',
  ROLE_USER: 'ROLE_USER',
  ROLE_ANONYMOUS: 'ROLE_ANONYMOUS',
};

export const userLabels = {
  ROLE_ADMIN: 'Pracownik Wspólnota Online',
  ROLE_WARDEN: 'Opiekun',
  ROLE_KEEPER: 'Zarządca',
  ROLE_COMPANY: 'Firma',
  ROLE_USER: 'Mieszkaniec',
  ROLE_ANONYMOUS: 'Anonim',
};

export const resolutionStatus = {
  accepted: 'Podjęta',
  invalidated: 'Unieważniona',
  rejected: 'Odrzucona',
  resolution_in_progress: 'W trakcie głosowania',
  archived: 'Zarchiwizowana',
};

export const financesStatus = {
  LACK_OF_INTEGRATION: 0,
  NO_INTEGRATION_WITH_WELES: 1,
  SUCCESS_INTEGRATION: 2,
};

export const financesInputNames = {
  USER: 'user',
  PIN: 'pin',
  CODE: 'code',
};

export const financesPropertyNames = {
  UPDATED_AT: 'updated_at',
  HAVE: 'have',
  TO_PAY: 'to_pay',
  BALANCE: 'balance',
  LINK: 'weles_link',
};

export const financesBalanceStatus = {
  PENDING: 'pending',
  DUE: 'due',
  BALANCE: 'balance',
  SURPLUS: 'surplus',
};

export const financesBalanceText = {
  pending: 'Saldo:',
  due: 'Łącznie do zapłaty:',
  balance: 'Saldo:',
  surplus: 'Nadpłata:',
};

export const financesDescriptionText = {
  pending: 'Łączenie...',
  due: 'w tym po terminie płatności:',
  balance: 'Dziękujemy za terminowe regulowanie płatności!',
  surplus: 'Dziękujemy za terminowe regulowanie płatności!',
};

export const termsUrl =
  'https://wspolnota.online/wp-content/themes/wspolnota-online/assets/img/polityka-prywatnosci-i-plikow-cookies.pdf';
export const productPageUrl = 'https://wspolnota.online/';
