import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  color: ${({ theme }) => theme.textColors.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  text-align: left;
  padding-bottom: 0.5em;
  margin: 1em;
  max-width: 900px;
  font-size: 1.3em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 100%;
    margin: 1em auto;
  }
`;

export const ArchivesHeader = () => {
  return <Header>Sprawy archiwalne:</Header>;
};
