import React from 'react';

const unvoted = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
    clipRule="evenodd"
    viewBox="0 0 69 65"
  >
    <path fill="none" d="M0 0h69v64.186H0z" />
    <path
      fill="#f2f2f2"
      d="M69 9c0-4.967-4.033-9-9-9H9C4.033 0 0 4.033 0 9v46.186c0 4.967 4.033 9 9 9h51c4.967 0 9-4.033 9-9V9z"
    />
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M50.016 10.371c0-.942-.774-1.718-1.716-1.72l-28.093-.067a1.73 1.73 0 00-1.722 1.717l-.099 42.759v.012a1.73 1.73 0 001.717 1.713l28.092.067h.012a1.728 1.728 0 001.713-1.717l.096-42.764z"
    />
    <path
      fill="#56d9be"
      fillRule="nonzero"
      d="M26.034 13.234a.192.192 0 01.151.063c.04.043.062.1.059.159l-.01 1.306c.002.184-.043.367-.13.529a.912.912 0 01-.356.357c-.157.087-.335.13-.514.126a1.04 1.04 0 01-.52-.133.896.896 0 01-.353-.364 1.079 1.079 0 01-.127-.531l.009-1.304a.209.209 0 01.066-.156.226.226 0 01.167-.063c.057 0 .112.024.151.066.042.04.065.097.063.155l-.009 1.307a.608.608 0 00.073.307.532.532 0 00.48.285.616.616 0 00.291-.07.583.583 0 00.301-.515l.008-1.305a.228.228 0 01.055-.158.186.186 0 01.145-.061zm2.954.148a.192.192 0 01.111.185.247.247 0 01-.055.147.177.177 0 01-.148.075.253.253 0 01-.123-.034.898.898 0 00-.385-.086.89.89 0 00-.441.1.723.723 0 00-.29.3.923.923 0 00-.1.441.858.858 0 00.224.646.836.836 0 00.6.224.817.817 0 00.383-.079.272.272 0 01.115-.029.201.201 0 01.158.081.228.228 0 01.022.254.197.197 0 01-.084.07 1.343 1.343 0 01-.6.138 1.42 1.42 0 01-.648-.156 1.143 1.143 0 01-.467-.442 1.344 1.344 0 01-.172-.71c-.002-.23.057-.457.172-.657.11-.192.272-.35.467-.455.206-.109.436-.163.668-.158.206 0 .41.05.593.145zm2.76-.105a.21.21 0 01.222.221l-.014 2.084a.213.213 0 01-.066.158.236.236 0 01-.166.061.219.219 0 01-.22-.221l.006-.841-1.149-.006-.006.838a.207.207 0 01-.069.158.232.232 0 01-.159.061.215.215 0 01-.223-.224l.014-2.081a.209.209 0 01.066-.156.226.226 0 01.167-.063c.058 0 .113.024.153.066.043.04.066.096.065.155l-.006.829 1.15.009.006-.829a.202.202 0 01.07-.156.225.225 0 01.159-.063zm12.381 2.33a.231.231 0 01.017.086.206.206 0 01-.062.154.197.197 0 01-.141.059.215.215 0 01-.117-.041.263.263 0 01-.08-.109l-.184-.438-1.076-.007-.191.438a.232.232 0 01-.081.107.186.186 0 01-.114.036.186.186 0 01-.138-.051.191.191 0 01-.048-.139c0-.023.004-.046.011-.068l.913-2.149a.23.23 0 01.087-.11.178.178 0 01.13-.034.2.2 0 01.12.038.217.217 0 01.08.106l.874 2.122zm-1.463-.709h.721l-.358-.843-.363.843z"
    />
    <path
      fill="#e9e9e9"
      fillRule="nonzero"
      d="M45.812 21.038l-22.459.02v1.98l22.46-.028-.001-1.972zm.001 26.278l-22.5.007v.662l22.5-.007v-.662zm.001 1.573l-22.5.007v.661l22.5-.006v-.662zm.001 1.571l-22.5.007v.661l22.5-.006v-.662zm-.003-26.466l-22.459.02v1.98l22.46-.028-.001-1.972zm0 3.07l-22.459.02v1.98l22.46-.028-.001-1.972zm0 2.955l-22.459.02v1.98l22.46-.028-.001-1.972zM34.5 32.975l-11.147.02v1.98l11.147-.028v-1.972zm0 3.07l-11.147.02v1.98l11.147-.028v-1.972zm0 2.956l-11.147.02v1.98l11.147-.028v-1.972zm0 3.069l-11.147.02v1.98l11.147-.024V42.07z"
    />
    <path
      fill="#aed6dc"
      fillRule="nonzero"
      d="M45.812 12.212l-22.459.061v6.019l22.46-.073-.001-6.007z"
    />
    <path fill="#f5f5f5" d="M36.043 33.011h9.209v11.937h-9.209z" />
    <path
      fill="#56d9be"
      fillRule="nonzero"
      d="M30.205 41.935a1.75 1.75 0 012.458.173l6.096 7.015c.627.722.55 1.832-.172 2.459a1.752 1.752 0 01-2.459-.172l-6.096-7.016a1.751 1.751 0 01.173-2.459z"
    />
    <path
      fill="#56d9be"
      fillRule="nonzero"
      d="M36.59 51.442c-.633-.728-.915-2.639-.19-3.269 3.356-2.916 5.141-4.567 7.483-6.464 9.557-7.738 15.377-8.998 15.377-8.998s-5.271 3.768-12.114 9.778a82.625 82.625 0 00-8.53 9.096c.012-.144-1.395.583-2.026-.143z"
    />
    <circle cx="19.595" cy="9.747" r="2.687" fill="#f53d65" />
  </svg>
);

export default unvoted;
