import React from 'react';

const ThumbDunno = () => (
  <svg
    viewBox="0 0 25 21"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h25.047v20.571H0z" />
    <path
      d="M8.513 9.292l-2.608-.956c-1.8-.66-4.55-.233-5.527 1.597-.319.596-.479 1.428-.31 2.099.097.385.352.723.693.925.393.232.851.208 1.287.29 1.104.21 2.154.732 2.942 1.54 1.374 1.408 5.337 5.784 5.337 5.784h14.518V5.449c.001-2.041-2.278-3.378-4.057-2.372-.769-1.199-2.387-1.61-3.63-.907-.768-1.2-2.387-1.61-3.629-.907-1.326-2.07-4.607-1.378-5.016 1.04-.025.146 0 6.989 0 6.989z"
      fillRule="nonzero"
    />
  </svg>
);

export default ThumbDunno;
