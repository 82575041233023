import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import HighFive from '../../../assets/highFive';
import { EXCEEDED_MESSAGES_AMOUNT } from '../../helpers/constants';
import { Alert, H0, LinkedItem, Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import Container from '../../shared/components/Container';
import TextInput from '../../shared/components/forms/TextInput';
import Logo from '../../shared/components/Logo';
import InvitingData from './components/InvitingData';

const StyledH0 = styled(H0)`
  margin: 3% 0 2%;
`;
const Form = styled.form`
  width: 100%;
  margin-bottom: 1em;
`;
const HighFiveBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
`;
const InputGroup = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  color: #585858;
`;
const InputLabel = styled(Text)`
  text-align: left;
  margin-top: 1em;
`;
const AddEmail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin: 1em 0 2em;
`;

const InvitingRegistrationComponent = ({
  inputs,
  inputValue,
  name,
  onChangeInput,
  handleNameChange,
  onClickLink,
  removeInput,
  handleSubmit,
  valid,
  error,
  showAddButton,
}) => {
  return (
    <Container>
      <Logo />
      <StyledH0>Zaproś sąsiada!</StyledH0>
      <HighFiveBox>
        <HighFive width="200px" />
      </HighFiveBox>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <InputLabel small>Twoje imię:</InputLabel>
          <TextInput name="name" value={name} placeholder="Twoje imię" onChange={handleNameChange} />
        </InputGroup>
        <InputGroup>
          <InputLabel small>Kogo chcesz zaprosić:</InputLabel>
          <InvitingData
            inputs={inputs}
            inputValue={inputValue}
            removeInput={inputName => removeInput(inputName)}
            onChangeInput={e => onChangeInput(e)}
            valid={valid}
          />
          {error && <Alert negative>{EXCEEDED_MESSAGES_AMOUNT}</Alert>}
          <AddEmail>
            {showAddButton && <LinkedItem onClick={e => onClickLink(e, 'email')}>Dodaj e-mail +</LinkedItem>}
          </AddEmail>
        </InputGroup>
        <PrimaryButton type="submit">WYŚLIJ</PrimaryButton>
      </Form>
      <Link to="/logowanie">
        <TextButton>Wróć do STRONY LOGOWANIA</TextButton>
      </Link>
    </Container>
  );
};

InvitingRegistrationComponent.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  removeInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  error: PropTypes.object,
  showAddButton: PropTypes.bool,
};

InvitingRegistrationComponent.defaultProps = {
  inputValue: '',
  valid: true,
  error: null,
  showAddButton: true,
};

export default InvitingRegistrationComponent;
