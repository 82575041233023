import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import SearchDropdown from '../shared/components/SearchDropdown';
import UserLabel from '../shared/components/UserLabel';

const ProfileWrapper = styled.div`
  position: relative;
  text-align: left;
`;
// const ListItem = styled.div`
//   display: flex;
//   align-items: center;
//   height: 2em;
//   margin: 1em;
//   &:before {
//     content: '';
//     display: inline-block;
//     height: 4px;
//     width: 4px;
//     margin-right: 0.6em;
//     background: ${({ theme, active }) => (active ? theme.textColors.secondary : theme.textColors.tertiary)};
//     border-radius: 50%;
//   }
// `;
// const StyledNavItem = styled(NavItem)`
//   flex: 1;
//   word-break: break-word;
// `;

const ProfileComponent = memo(
  ({ resources, name, clickHandler, selected, role, avatarPath, currentResourceStreet }) => {
    const options = resources.map(item => {
      return { name: item.display_name, value: item.id };
    });

    return (
      <ProfileWrapper>
        <Link to="/profil">
          <UserLabel userRole={role} userName={name} avatarPath={avatarPath} />
        </Link>
        {/* <Dropdown dropHeader={currentResourceStreet}>
          {resources.map(resource => (
            <ListItem key={resource.id} active={selected === resource.id} onClick={() => clickHandler(resource.id)}>
              <StyledNavItem active={selected === resource.id ? true : false}>{resource.display_name}</StyledNavItem>
            </ListItem>
          ))}
        </Dropdown> */}
        <SearchDropdown
          options={options}
          selected={selected}
          placeholder="Wyszukaj wspólnotę"
          clickHandler={clickHandler}
        />
      </ProfileWrapper>
    );
  }
);

ProfileComponent.propTypes = {
  resources: PropTypes.array.isRequired,
  clickHandler: PropTypes.func.isRequired,
  currentResourceStreet: PropTypes.string.isRequired,
  selected: PropTypes.number,
  role: PropTypes.string,
  avatarPath: PropTypes.string,
  name: PropTypes.string,
};

ProfileComponent.defaultTypes = {
  selected: null,
  isKeeper: false,
  avatarPath: '',
  name: null,
};

export default ProfileComponent;
