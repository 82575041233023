import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Caption, H2, InvalidText } from '../../layout/theme/components';
import TextInput from '../../shared/components/forms/TextInput';
import Modal from '../../shared/components/Modal';
import DenyBubble from '../../shared/components/DenyBubble';

const StyledInvalidText = styled(InvalidText)`
  text-align: center;
  margin: 25px 0 0 0;
`;

const FinancesModalComponent = ({
  updateBalance,
  handleChange,
  setOpenModal,
  error
}) => {
  return (
    <Modal closeModal={() => setOpenModal(false)}>
      <DenyBubble
        leftButton="ANULUJ"
        rightButton="ODŚWIEŻ"
        leftClick={() => setOpenModal(false)}
        rightClick={() => updateBalance()}
      >
        <H2>Wpisz PIN</H2>
        <Caption>Twój PIN z Weles3</Caption>
        <TextInput
          name="pin"
          type="password"
          placeholder="PIN"
          onChange={e => handleChange(e.target.value)}
          isRequired
        />
      </DenyBubble>
      {error && <StyledInvalidText>{error}</StyledInvalidText>}
    </Modal>
  );
};

FinancesModalComponent.PropTypes = {
  updateBalance: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  error: PropTypes.string
};

FinancesModalComponent.defaultProps = {
  error: ''
};

export default FinancesModalComponent;
