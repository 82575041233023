import React from 'react';

const commentCasesIcon = () => {
  return (
    <svg
      viewBox="0 0 70 64"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h69.844v63.009H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h69.844v63.009H0z" />
      </clipPath>
      <g fillRule="nonzero" clipPath="url(#a)">
        <path fill="#fff" d="M36.798 22.572h9.283v6.872h-9.283z" />
        <path fill="#fff" d="M41.44 28.287h4.64v14.006h-4.64z" />
        <path
          d="M0 8.577C.003 3.873 3.873.003 8.577.001h46.548c4.703.003 8.572 3.873 8.575 8.576V25.73c-.003 4.704-3.873 8.574-8.577 8.576H43l7.963 14.7-17.518-14.7H8.577C3.873 34.304.002 30.433 0 25.729V8.577z"
          fill="#d1cde4"
        />
        <path
          d="M21.176 13.788a2.805 2.805 0 0 1 2.79 2.79 2.805 2.805 0 0 1-2.79 2.79 2.805 2.805 0 0 1-2.79-2.79 2.805 2.805 0 0 1 2.79-2.79zM32.336 13.788a2.805 2.805 0 0 1 2.79 2.79 2.805 2.805 0 0 1-2.79 2.79 2.805 2.805 0 0 1-2.79-2.79 2.805 2.805 0 0 1 2.79-2.79zM42.526 13.788a2.805 2.805 0 0 1 2.79 2.79 2.805 2.805 0 0 1-2.79 2.79 2.805 2.805 0 0 1-2.79-2.79 2.805 2.805 0 0 1 2.79-2.79z"
          fill="#fff"
        />
        <path
          d="M69.844 34.362c-.002-3.333-2.743-6.074-6.076-6.076h-32.98c-3.333.002-6.074 2.743-6.076 6.076v12.153c.002 3.333 2.743 6.074 6.076 6.076h8.592l-5.642 10.417L46.15 52.591h17.618c3.333-.002 6.074-2.743 6.076-6.076V34.362z"
          fill="#2fe6c7"
        />
        <path
          d="M54.841 38.055a1.988 1.988 0 0 0-1.977 1.977 1.988 1.988 0 0 0 1.977 1.977 1.988 1.988 0 0 0 1.977-1.977 1.988 1.988 0 0 0-1.977-1.977zM46.935 38.055a1.988 1.988 0 0 0-1.977 1.977 1.988 1.988 0 0 0 1.977 1.977 1.988 1.988 0 0 0 1.977-1.977 1.988 1.988 0 0 0-1.977-1.977zM39.715 38.055a1.988 1.988 0 0 0-1.977 1.977 1.988 1.988 0 0 0 1.977 1.977 1.988 1.988 0 0 0 1.977-1.977 1.988 1.988 0 0 0-1.977-1.977z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default commentCasesIcon;
