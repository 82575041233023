import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import ProfileContainer from '../user/ProfileContainer';

import ContactIcon from '../../assets/contact';
import HelpIcon from '../../assets/help';
import LogoIcon from '../../assets/logoIcon';
import LogoutIcon from '../../assets/logout';
import { isMobile } from '../helpers/tools';
import DropdownArrow from '../shared/components/DropdownArrow';
import CommunityIcon from './../../assets/communityIcon';
import FinancesIcon from './../../assets/financesIcon';
import PercentIcon from './../../assets/percentIcon';
import TextButton from './../shared/components/buttons/TextButton';
import MenuButton from './components/MenuButton';

const MenuBar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: ${() => (isMobile() ? `${window.innerHeight}px` : '100vh')};
  width: 70%;
  max-width: 300px;
  top: 0;
  left: 0;
  padding: 7em 2em 3em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  z-index: 3;
  transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(-110%)')};
  transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  box-shadow: ${({ active }) => (active ? '3px 2px 37px -7px rgba(135, 135, 135, 1)' : 'none')};
`;
const IconsBoxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  color: ${({ theme }) => theme.textColors.primary};
  margin: 12% 0 0 2em;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
`;
const HelpBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 4em;
`;
const Dropbox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding-right: 2em;
  }
`;

const CommunityIconMemo = memo(CommunityIcon);
const LogoutIconMemo = memo(LogoutIcon);
const FinancesIconMemo = memo(FinancesIcon);
const HelpIconMemo = memo(HelpIcon);
const ContactIconMemo = memo(ContactIcon);

const SideBarComponent = ({
  showMenu,
  toggleTutorial,
  helpToggle,
  openHelp,
  showCommunityContacts,
  showCommunityVotePower,
  showUserVotePower,
  premiumCommunity,
}) => {
  const contactsPath = showCommunityContacts ? '/kontakty' : 'funkcje/contactNumbers';
  const financesPath = premiumCommunity ? '/finanse' : '/funkcje/finances';
  const getLinkPath = showCommunityVotePower ? '/kalkulator' : '/funkcje/votePower';

  return (
    <MenuBar active={showMenu}>
      <ProfileContainer />
      <IconsBoxes>
        <MenuButton to="/wspolnota" icon={<CommunityIconMemo />} text="Wspólnota" />
        <MenuButton to={financesPath} icon={<FinancesIconMemo />} text="Finanse" />
        {showUserVotePower && <MenuButton to={getLinkPath} icon={<PercentIcon />} text="Siła głosu" />}{' '}
        <Dropbox onClick={helpToggle}>
          <MenuButton icon={<HelpIconMemo />} text="Pomoc" />
          <div>
            <DropdownArrow rotate={openHelp} inactive />
          </div>
        </Dropbox>
        {openHelp && (
          <HelpBox>
            <MenuButton onClick={toggleTutorial} text="Samouczek" />
            <MenuButton to="/uchwala" text="Cyfrowa Uchwała" />
          </HelpBox>
        )}
        <MenuButton to={contactsPath} icon={<ContactIconMemo />} text="Kontakty" />
        <MenuButton to="/wylogowanie" icon={<LogoutIconMemo />} text="Wyloguj" />
      </IconsBoxes>
      <LogoBox>
        <LogoIcon />
      </LogoBox>
      <Link to="/ocena">
        <TextButton small>OCEŃ APLIKACJĘ</TextButton>
      </Link>
    </MenuBar>
  );
};

SideBarComponent.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
  helpToggle: PropTypes.func.isRequired,
  openHelp: PropTypes.bool.isRequired,
  showCommunityContacts: PropTypes.bool.isRequired,
  showCommunityVotePower: PropTypes.bool.isRequired,
  showUserVotePower: PropTypes.bool.isRequired,
};

export default SideBarComponent;
