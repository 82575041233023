import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import PageWrapper from './PageWrapper';
import TutorialHeader from './TutorialHeader';

const BuildingTutorialWrapper = styled.div`
  width: 100%;
  margin-top: 15%;
`;

const RangesWrapper = ({ nextSlide, children }) => {
  return (
    <PageWrapper onButtonClick={nextSlide}>
      <TutorialHeader>
        <b>Każda sprawa ma przypisaną widoczność</b>
        <br />
        Automatycznie ukrywamy sprawy, które Cię nie dotyczą
      </TutorialHeader>
      <BuildingTutorialWrapper>{children}</BuildingTutorialWrapper>
    </PageWrapper>
  );
};

RangesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  nextSlide: PropTypes.func.isRequired,
};

export default RangesWrapper;
