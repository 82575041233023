import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import PostPigeonAsset from '../../../../assets/postPigeon';
import { H0, Text } from '../../../layout/theme/components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';

const SentDataWrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.textColors.secondary};
`;
const StyledText = styled(Text)`
  margin: 10% 0;
`;
const PostPigeonAssetBox = styled.div`
  margin: 5% 0;
`;

const SentDataError = ({ sentDataError }) => {
  return (
    <SentDataWrapper>
      <div>
        <H0>Zgłoszenie przyjęte!</H0>
        <StyledText>Zrobimy korektę najszybciej, jak to możliwe!</StyledText>
        <PostPigeonAssetBox>
          <PostPigeonAsset />
        </PostPigeonAssetBox>
        <SecondaryButton onClick={sentDataError}>Zamknij</SecondaryButton>
      </div>
    </SentDataWrapper>
  );
};

SentDataError.propTypes = {
  sentDataError: PropTypes.func.isRequired,
};

export default SentDataError;
