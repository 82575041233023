import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Sounding from './SoundingComponent';

import { valueLength } from '../../helpers/validation';
import { saveSoundingStep } from '../../helpers/api/case';

class SoundingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sounding: props.sounding ? props.sounding : false,
      question: props.question ? props.question : '',
      valid: true
    };

    this.toggleSounding = this.toggleSounding.bind(this);
    this.saveStep = this.saveStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({
      question: e.target.value
    });
  };

  saveStep(sounding, question) {
    const { caseId } = this.props;
    saveSoundingStep(caseId, sounding, question).then(() =>
      this.props.handleNextStep()
    );
  }

  toggleSounding() {
    this.setState({ sounding: !this.state.sounding });
  }

  isValid = () => {
    const { question, sounding } = this.state;
    return !sounding || valueLength(question, 250, 3);
  };

  handleSubmit(e) {
    e.preventDefault();
    const sounding = this.state.sounding;
    const question = sounding ? e.target.question.value : '';
    if (this.isValid()) {
      this.setState({ valid: true });
      this.saveStep(sounding, question);
    } else {
      this.setState({ valid: false });
    }
  }

  render() {
    const { sounding, question, valid } = this.state;
    return (
      <Sounding
        isSoundingActive={sounding}
        submitHandler={this.handleSubmit}
        soundingChangeHandler={this.toggleSounding}
        handleChange={this.handleChange}
        question={question}
        valid={valid}
      />
    );
  }
}

SoundingContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  sounding: PropTypes.bool,
  question: PropTypes.string
};

SoundingContainer.defaultProps = {
  sounding: false,
  question: ''
};

export default SoundingContainer;
