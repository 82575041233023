import React from 'react';

export default () => (
  <svg
    viewBox="0 0 214 181"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <rect id="ilu01" width="213" height="180.5" fill="none" />
    <clipPath id="_clip1">
      <rect width="213" height="180.5" />
    </clipPath>
    <g clipPath="url(#_clip1)">
      <path
        d="M48.898,121.046c2.88,0 5.217,2.338 5.217,5.217c0,0.921 -0.24,1.787 -0.66,2.539l4.444,0c2.879,0 5.216,2.337 5.216,5.216c0,2.879 -2.337,5.216 -5.216,5.216l-24.008,0c-2.879,0 -5.216,-2.337 -5.216,-5.216c0,-0.921 0.239,-1.787 0.659,-2.539l-4.443,0c-2.879,0 -5.216,-2.337 -5.216,-5.216c0,-2.879 2.337,-5.217 5.216,-5.217l24.007,0Z"
        fill="#e7e7e7"
      />
      <path
        d="M48.991,119.047c3.894,0.075 7.344,3.589 7.109,7.689c0,0 6.8,0.132 8.545,4.719c1.621,4.26 -1.738,9.69 -6.655,9.779c-8.063,0.049 -16.127,0.049 -24.19,0c-3.903,-0.071 -7.344,-3.615 -7.11,-7.689c0,0 -6.967,-0.225 -8.607,-4.886c-1.493,-4.242 1.783,-9.517 6.715,-9.612c8.065,-0.049 16.129,-0.049 24.193,0Zm-24.094,3.999c-3.161,0.019 -4.646,6.349 -0.049,6.433c0,0 4.486,0 4.486,0l1.754,2.961c-1.04,1.899 0.341,4.75 2.76,4.794c8.058,0.049 16.121,0.26 24.175,-0.002c3.116,-0.139 4.419,-6.348 -0.081,-6.43c0,0 -4.487,0 -4.487,0l-1.754,-2.961c1.04,-1.898 -0.347,-4.748 -2.76,-4.795c-8.015,-0.048 -16.029,0 -24.044,0Z"
        fill="none"
      />
      <path
        d="M15.578,87.08c-2.879,0 -5.216,2.337 -5.216,5.216c0,0.922 0.239,1.788 0.659,2.539l-4.443,0c-2.879,0 -5.216,2.338 -5.216,5.217c0,2.879 2.337,5.216 5.216,5.216l24.007,0c2.879,0 5.217,-2.337 5.217,-5.216c0,-0.922 -0.24,-1.788 -0.66,-2.539l4.444,0c2.879,0 5.216,-2.338 5.216,-5.217c0,-2.879 -2.337,-5.216 -5.216,-5.216l-24.008,0Z"
        fill="#dadada"
      />
      <path
        d="M39.678,85.08c5.542,0.106 9.43,7.897 5.01,12.318c-1.312,1.312 -3.131,2.078 -5.01,2.114c0,0 -1.896,0.001 -1.896,0.001c0.031,0.427 0.025,0.851 -0.017,1.273c-0.352,3.47 -3.528,6.413 -7.086,6.481c-8.065,0.057 -16.13,0.057 -24.195,0c-5.545,-0.106 -9.429,-7.896 -5.008,-12.317c1.313,-1.314 3.14,-2.078 5.008,-2.114c0,0 1.898,-0.001 1.898,-0.001c-0.029,-0.397 -0.026,-0.79 0.008,-1.182c0.309,-3.494 3.436,-6.503 7.096,-6.573c8.064,-0.048 16.128,-0.048 24.192,0Zm-24.094,4c-2.195,0.013 -3.913,2.641 -2.865,4.69l-1.698,3.065c-3.016,0 -6.592,-0.469 -7.491,2.185c-0.643,1.902 0.92,4.208 3.007,4.248c8.058,0.057 16.12,0.25 24.174,-0.002c2.174,-0.098 3.789,-2.779 2.68,-4.787l1.751,-2.966c3.038,0 6.666,0.454 7.516,-2.263c0.593,-1.893 -0.995,-4.131 -3.03,-4.17c-8.015,-0.048 -16.029,0 -24.044,0Z"
        fill="none"
      />
      <path
        d="M95.619,36.049c7.482,0 13.556,6.074 13.556,13.556c0,2.394 -0.622,4.645 -1.713,6.598l11.546,0c7.482,0 13.556,6.074 13.556,13.556c0,7.482 -6.074,13.556 -13.556,13.556l-62.388,0c-7.482,0 -13.556,-6.074 -13.556,-13.556c0,-2.395 0.622,-4.645 1.714,-6.598l-11.547,0c-7.482,0 -13.556,-6.075 -13.556,-13.556c0,-7.482 6.074,-13.556 13.556,-13.556l62.388,0Z"
        fill="#f4f4f4"
      />
      <path
        d="M95.82,34.05c9.535,0.183 17.639,10.299 14.673,20.12l-0.01,0.033c3.565,0 6.522,-0.49 10.891,0.179c9.091,1.392 15.651,12.099 12.11,21.079c-2.244,5.692 -8.021,9.736 -14.275,9.853c-20.93,0.137 -41.86,0.137 -62.789,0c-9.6,-0.18 -17.638,-10.36 -14.674,-20.121l0.01,-0.032c-3.516,0 -6.419,0.449 -10.698,-0.151c-9.177,-1.288 -15.867,-12.079 -12.302,-21.108c2.246,-5.691 8.021,-9.732 14.274,-9.852c20.93,-0.137 41.861,-0.137 62.79,0Zm-62.583,3.999c-7.52,0.049 -13.693,8.619 -10.708,15.921c1.725,4.22 6.063,7.16 10.708,7.191l11.541,0l1.749,2.969c-3.809,6.878 1.169,17.02 9.943,17.184c20.896,0.137 41.793,0.137 62.688,0c7.424,-0.139 13.521,-8.658 10.553,-15.92c-1.725,-4.221 -6.063,-7.163 -10.709,-7.191l-11.54,0l-1.75,-2.968c3.822,-6.908 -1.167,-17.017 -9.943,-17.185c-20.844,-0.137 -41.688,-0.001 -62.532,-0.001Z"
        fill="none"
      />
      <path
        d="M163.031,1.504c9.844,0 17.835,7.992 17.835,17.835c0,3.15 -0.818,6.111 -2.254,8.681l15.191,0c9.844,0 17.835,7.992 17.835,17.835c0,9.843 -7.991,17.835 -17.835,17.835l-82.081,0c-9.843,0 -17.835,-7.992 -17.835,-17.835c0,-3.15 0.819,-6.111 2.255,-8.681l-15.192,0c-9.843,0 -17.835,-7.992 -17.835,-17.835c0,-9.843 7.991,-17.835 17.835,-17.835l82.081,0Z"
        fill="#fbfbfb"
      />
      <path
        d="M62.344,5.202c-3.553,0 -6.437,2.885 -6.437,6.437c0,1.137 0.296,2.205 0.814,3.133l-5.483,0c-3.552,0 -6.436,2.884 -6.436,6.436c0,3.553 2.884,6.437 6.436,6.437l29.623,0c3.552,0 6.436,-2.884 6.436,-6.437c0,-1.137 -0.295,-2.205 -0.814,-3.133l5.483,0c3.552,0 6.436,-2.884 6.436,-6.436c0,-3.552 -2.884,-6.437 -6.436,-6.437l-29.622,0Z"
        fill="#dedede"
      />
      <path
        d="M109.471,137.075c0,-3.852 -3.128,-6.98 -6.981,-6.98l-5.948,0c-3.852,0 -6.98,3.128 -6.98,6.98l0,8.983c0,3.852 3.128,6.98 6.98,6.98l5.948,0c3.853,0 6.981,-3.128 6.981,-6.98l0,-8.983Z"
        fill="#fbe3e9"
      />
      <path
        d="M132.353,91.062c0,-18.123 -14.713,-32.837 -32.837,-32.837c-18.123,0 -32.836,14.714 -32.836,32.837l0,19.871c0,18.123 14.713,32.837 32.836,32.837c18.124,0 32.837,-14.714 32.837,-32.837l0,-19.871Z"
        fill="#fcecf0"
      />
      <path
        d="M99.738,56.726c17.723,0.173 33.949,15.335 34.114,34.114c0.042,10.907 1.423,22.148 -2.013,31.698c-4.898,13.617 -19.155,23.255 -33.648,22.707c-17.327,-0.655 -32.827,-15.859 -33.011,-34.09c-0.028,-11.036 -1.36,-22.303 2.013,-31.697c4.645,-12.936 17.56,-22.414 31.659,-22.727c0.296,-0.004 0.591,-0.006 0.886,-0.005Zm-0.424,3c-16.174,0.158 -30.876,14.068 -31.132,30.931c-0.068,6.758 -0.002,13.516 -0.002,20.274c0.031,12.401 8.046,24.338 19.497,29.022c18.481,7.561 42.979,-6.584 43.175,-28.817c0.04,-10.387 1.433,-21.199 -1.708,-30.302c-4.165,-12.068 -16.198,-20.9 -29.223,-21.106c-0.202,-0.002 -0.405,-0.003 -0.607,-0.002Z"
        fill="none"
      />
      <path
        d="M132.371,93.947c3.82,0 6.922,3.101 6.922,6.922c0,3.82 -3.102,6.922 -6.922,6.922c-3.82,0 -3.82,-13.844 0,-13.844Z"
        fill="#fcecf0"
      />
      <path
        d="M65.481,107.449c-3.82,0 -6.922,-3.101 -6.922,-6.922c0,-3.82 3.102,-6.922 6.922,-6.922c3.82,0 3.82,13.844 0,13.844Z"
        fill="#fcecf0"
      />
      <path
        d="M129.505,163.475l-60.341,0c-1.854,-0.099 -2.57,-2.3 -0.821,-3.997c8.143,-7.793 19.224,-12.325 30.436,-12.496c0.278,-0.003 0.555,-0.004 0.833,-0.003c11.527,0.111 22.791,4.721 31.11,12.886c0,0 0.57,0.533 0.719,1.11c0.305,1.182 -0.684,2.433 -1.936,2.5Zm6.574,-9.642l0,0l0,0l0,0Z"
        fill="#f8cfd8"
      />
      <circle
        cx="144.354"
        cy="156.826"
        r="17.431"
        fill="#d1cedd"
        fillOpacity="0.502"
      />
      <path
        d="M144.579,139.397c9.808,0.186 18.447,9.803 17.037,19.866c-1.152,8.224 -8.917,14.994 -17.262,14.994c-11.616,0 -21.366,-13.962 -15.523,-25.363c2.871,-5.603 8.893,-9.376 15.298,-9.497c0.225,-0.002 0.225,-0.002 0.45,0Zm-0.412,2.999c-9.539,0.186 -17.288,11.419 -12.822,20.682c3.912,8.112 16.209,10.63 22.963,4.192c7.925,-7.554 3.721,-24.612 -9.768,-24.874c-0.186,-0.001 -0.186,-0.001 -0.373,0Z"
        fill="#756ca4"
        fillRule="nonzero"
      />
      <path
        d="M155.27,167.646c0.331,0.033 0.639,0.166 0.891,0.382c3.386,3.218 6.607,6.605 9.91,9.907c1.067,1.123 -0.652,3.388 -2.066,2.174c-3.385,-3.218 -6.607,-6.604 -9.91,-9.907c-0.861,-0.906 -0.374,-2.635 1.175,-2.556Z"
        fill="#756ca4"
        fillRule="nonzero"
      />
      <path
        d="M143.515,147.502c0.998,0.099 1.344,1.612 -0.113,2.052c-4.021,1.272 -6.623,5.782 -5.523,10.019c0,0 0.063,0.789 -0.441,1.101c-1.717,1.065 -2.682,-4.092 -0.703,-7.934c1.301,-2.525 3.682,-4.443 6.449,-5.204c0,0 0.001,-0.045 0.331,-0.034Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        d="M85.076,111.058c0,-1.025 -0.832,-1.857 -1.857,-1.857l-7.065,0c-1.024,0 -1.856,0.832 -1.856,1.857c0,1.024 0.832,1.856 1.856,1.856l7.065,0c1.025,0 1.857,-0.832 1.857,-1.856Z"
        fill="#fadee4"
      />
      <path
        d="M126.923,111.058c0,-1.025 -0.832,-1.857 -1.857,-1.857l-7.065,0c-1.025,0 -1.857,0.832 -1.857,1.857c0,1.024 0.832,1.856 1.857,1.856l7.065,0c1.025,0 1.857,-0.832 1.857,-1.856Z"
        fill="#fadee4"
      />
      <path
        d="M88.512,90.673c2.87,0.051 5.675,1.229 7.722,3.248c0,0 0.406,3.119 -1.664,1.174c-3.431,-3.139 -9.162,-3.183 -12.641,0.25c0,0 -0.819,0.513 -1.359,0.041c-1.672,-1.458 3.902,-4.737 7.942,-4.713Z"
        fill="#8781a8"
        fillRule="nonzero"
      />
      <path
        d="M97.105,113.822c-0.055,0.257 -0.083,0.519 -0.083,0.782c0,2.047 1.663,3.709 3.71,3.709c2.048,0 3.71,-1.662 3.71,-3.709c0,-0.263 -0.028,-0.525 -0.083,-0.782l-7.254,0Z"
        fill="#fdd5de"
      />
      <path
        d="M90.672,100.689c1.938,0.088 2.779,3.872 0,3.998c-2.579,0.039 -5.488,0.612 -6.406,-1.016c-0.671,-1.19 0.257,-2.917 1.682,-2.982c1.574,-0.024 3.15,-0.024 4.724,0Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle cx="88.31" cy="103.746" r="2.724" fill="#616161" />
      <path
        d="M88.406,100.023c3.187,0.124 5.258,5.502 1.353,7.153c-2.633,1.113 -6.259,-1.828 -4.844,-4.962c0.609,-1.349 1.829,-2.212 3.491,-2.191Zm-0.141,2c-1.637,0.063 -2.482,3.383 0,3.446c1.624,0.041 2.75,-3.481 0,-3.446Z"
        fill="none"
      />
      <path
        d="M117.845,100.689c1.913,0.087 2.835,3.87 0,3.998c-2.93,0.045 -6.431,0.655 -6.655,-1.818c-0.099,-1.082 0.818,-2.129 1.931,-2.18c1.575,-0.024 3.15,-0.024 4.724,0Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle cx="115.483" cy="103.746" r="2.724" fill="#616161" />
      <path
        d="M115.579,100.023c3.217,0.126 5.218,5.52 1.353,7.153c-2.768,1.17 -6.552,-2.368 -4.585,-5.438c0.697,-1.089 1.748,-1.734 3.232,-1.715Zm-0.14,2c-1.628,0.063 -2.51,3.382 -0.001,3.446c1.747,0.044 2.295,-3.475 0.001,-3.446Z"
        fill="none"
      />
      <path
        d="M70.405,73.695c-0.279,0.818 -0.511,1.653 -0.693,2.505l0,9.37c0.026,0.097 0.043,0.197 0.052,0.3c0.126,4.515 0.002,9.034 0.002,13.552c-0.047,1.689 -3.196,2.438 -3.394,0.095c-0.115,-4.11 -0.023,-8.223 -0.005,-12.335l-0.052,0l0,-14.088c2.897,-13.519 16.952,-23.436 33.201,-23.436c16.249,0 30.305,9.917 33.201,23.436l0,14.088l-0.027,0c0.074,4.079 -0.027,8.16 -0.027,12.24c-0.047,1.689 -3.196,2.438 -3.394,0.095c-0.115,-4.11 -0.023,-8.223 -0.005,-12.335l-0.052,0l0,-1.623c-7.261,8.468 -22.456,9.566 -35.85,2.052c-7.055,-3.958 -11.689,-8.773 -13.93,-13.916l-9.027,0Z"
        fill="#8781a8"
      />
      <path
        d="M99.707,49.659c13.834,0.099 28.294,7.34 32.53,21.558c0.185,0.62 0.343,1.246 0.48,1.877c0,0 -0.027,14.088 -0.027,14.088c0.069,4.092 0.144,8.22 -0.029,12.279c-0.121,1.698 -3.187,2.208 -3.392,0.056c-0.102,-3.863 -0.037,-7.711 -0.009,-11.564l-0.048,-0.771c0,0 0.905,-2.525 -0.704,-0.851c-9.995,10.253 -29.349,6.54 -40.79,-2.458c-3.43,-2.698 -6.444,-6.058 -8.233,-10.057c0,0 -9.08,-0.121 -9.08,-0.121c-0.277,0.822 -0.508,1.657 -0.693,2.505l0,9.37c0.338,1.337 0.103,2.72 0.107,4.11c0.012,3.261 0.084,6.537 -0.054,9.781c-0.122,1.698 -3.188,2.208 -3.393,0.056c-0.102,-3.863 -0.036,-7.711 -0.009,-11.564c0,0 -0.048,-0.771 -0.048,-0.771l0,-14.088c3.061,-14.056 18.99,-23.469 33.392,-23.435Zm-0.372,2c-13.404,0.095 -27.702,7.33 -31,21.564c0,0 -0.655,1.085 0.366,-0.575c0.347,-0.564 0.686,-0.895 1.704,-0.953l9.027,0c2.323,0.123 2.15,2.107 3.207,3.792c9.335,14.879 34.327,21.177 45.06,8.764c0,0 1.856,-1.336 3.018,-0.004l0,-10.936c-2.941,-13.092 -18.001,-21.681 -31.382,-21.652Z"
        fill="none"
      />
      <path
        d="M123.09,97.189c1.05,0.124 1.315,1.942 -0.078,1.997l-15.505,0c-1.078,-0.043 -1.451,-1.943 0,-2c5.195,0 10.393,-0.201 15.583,0.003Z"
        fill="#8781a8"
        fillRule="nonzero"
      />
      <path
        d="M65.948,75.328c22.249,-6.789 44.47,-6.916 66.661,0c11,-18.234 -14.923,-35.321 -33.331,-35.321c-18.408,0 -41.626,14.541 -33.33,35.321Z"
        fill="#a8a8a8"
      />
      <path
        d="M73.339,73.711c18.302,-4.251 35.585,-4.279 52.153,0c8.633,-18.235 -11.388,-33.704 -25.834,-33.704c-14.446,0 -32.829,12.924 -26.319,33.704Z"
        fill="#e2e2e2"
      />
      <path
        d="M99.836,39.008c14.105,0.18 28.572,11.77 28.742,25.598c0.038,3.127 -0.694,6.238 -1.977,9.088c-0.751,1.667 -1.717,0.891 -2.514,0.694c-16.56,-4.035 -33.902,-3.538 -50.523,0.297c0,0 -0.85,0.429 -1.332,-1.176c-2.146,-7.337 -1.436,-15.465 3.064,-21.938c5.513,-7.931 15.199,-12.601 24.54,-12.563Zm-0.341,2c-11.524,0.118 -23.245,7.643 -25.941,19.285c-0.928,4.005 -0.627,8.222 0.461,12.18c0,0 6.401,-1.328 11.481,-1.992c13.018,-1.701 26.295,-1.147 39.068,1.965l0.362,0.089c1.802,-4.173 2.172,-8.945 0.745,-13.327c-3.595,-11.044 -15.367,-18.239 -26.176,-18.2Z"
        fill="none"
      />
      <path
        d="M132.717,74.696c-18.119,-4.914 -49.633,-5.743 -66.402,0c-8.633,15.746 19.149,14.552 33.595,14.552c14.446,0 39.318,3.392 32.807,-14.552Z"
        fill="#a8a8a8"
      />
      <path
        d="M99.588,43.471c0.353,0.077 0.404,0.158 0.428,0.495l0,27.193c-0.039,0.556 -0.955,0.624 -1,0l0,-27.193c0.03,-0.412 0.148,-0.525 0.572,-0.495Z"
        fill="#c8c8c8"
        fillRule="nonzero"
      />
      <path
        d="M99.701,70.159c11.23,0.047 22.407,1.513 33.257,4.198c0,0 2.504,2.667 -1.502,1.691c-21.32,-5.11 -43.603,-5.095 -64.897,0.25c0,0 -0.898,0.045 -1.164,-0.578c-0.722,-1.695 3.631,-2.119 7.008,-2.809c8.98,-1.837 18.128,-2.756 27.298,-2.752Z"
        fill="#c8c8c8"
        fillRule="nonzero"
      />
      <circle cx="99.516" cy="40.007" r="3.959" fill="#a8a8a8" />
      <path
        d="M99.618,36.05c3.394,0.129 5.549,5.865 1.439,7.605c-2.837,1.2 -6.788,-2.089 -5.066,-5.449c0.687,-1.341 1.926,-2.178 3.627,-2.156Zm-0.153,1.999c-2.161,0.083 -2.134,4.927 0.768,3.782c0.22,-0.087 0.424,-0.214 0.598,-0.374c1.156,-1.052 0.389,-3.43 -1.366,-3.408Z"
        fill="#a8a8a8"
        fillRule="nonzero"
      />
      <path
        d="M99.781,37.021c0.617,0.035 0.591,0.893 -0.356,1.025c-1.101,0.183 -1.571,1.306 -1.912,2.363c-0.179,0.477 -1.031,0.471 -0.963,-0.412c0.123,-1.609 1.565,-2.993 3.231,-2.976Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle cx="92.818" cy="127.211" r="2.608" fill="#64616e" />
      <path
        d="M92.885,124.604c2.45,0.093 3.805,5.117 0,5.214c-1.485,0.037 -2.85,-1.399 -2.654,-2.939c0.162,-1.271 1.337,-2.292 2.654,-2.275Zm-0.076,2.214c-0.475,0.018 -0.285,1.187 0.275,0.682c0.237,-0.214 0.075,-0.686 -0.275,-0.682Z"
        fill="none"
      />
      <path
        d="M91.89,126.212c1,0.082 1.187,1.901 0,1.997c-1.322,0.036 -2.828,-0.153 -3.759,0.074c-2.663,0.651 -2.227,4.785 -3.339,5.439c-1.384,0.813 -1.835,-3.13 0.012,-5.475c1.534,-1.948 4.356,-2.109 7.086,-2.035Z"
        fill="#756ca4"
        fillRule="nonzero"
      />
      <path
        d="M84.527,131.886c0.976,0.159 1.718,0.992 1.758,1.986c0,8.165 0.041,16.331 0,24.496c-0.112,5.935 -4.992,11.487 -11.044,11.526l-6.315,0c-1.522,-0.062 -1.503,-0.502 -1.807,-1.143c-0.573,-1.209 0.358,-2.799 1.807,-2.857c3.262,0 6.709,0.53 9.161,-0.656c2.576,-1.245 4.184,-4.113 4.198,-7.024l0,-24.342c0.02,-0.495 0.072,-0.576 0.16,-0.784c0.338,-0.792 1.19,-1.274 2.082,-1.202Z"
        fill="#756ca4"
        fillRule="nonzero"
      />
      <path
        d="M88.281,131.861c0.97,0.082 1.285,1.889 0,1.997c-2.644,0.074 -5.29,0.001 -7.935,0.001c-0.969,-0.027 -1.492,-1.877 -0.056,-1.998c2.663,-0.075 5.328,-0.075 7.991,0Z"
        fill="#b4afcc"
        fillRule="nonzero"
      />
      <path
        d="M58.889,159.768c0,5.785 4.494,10.475 10.037,10.475c5.544,0 10.038,-4.69 10.038,-10.475l-20.075,0Z"
        fill="#756ca4"
      />
      <path
        d="M78.963,159.904c-0.121,6.64 -7.352,12.425 -13.944,9.516c-3.68,-1.625 -6.106,-5.567 -6.13,-9.652l20.075,0c0,0.046 -0.001,0.091 -0.001,0.136Zm-16.485,2.864c2.013,4.891 10.315,5.861 12.846,0.12l0.05,-0.12c-4.298,0 -8.597,0 -12.896,0Z"
        fill="none"
      />
      <path
        d="M78.964,154.021c0,-1.659 -1.347,-3.006 -3.005,-3.006l-14.065,0c-1.659,0 -3.005,1.347 -3.005,3.006l0,6.011c0,1.659 1.346,3.005 3.005,3.005l14.065,0c1.658,0 3.005,-1.346 3.005,-3.005l0,-6.011Z"
        fill="#756ca4"
      />
      <path
        d="M76.036,151.016c1.538,0.059 2.868,1.379 2.927,2.927c0.052,4.114 0.588,8.96 -2.927,9.093c-4.739,0.06 -9.48,0.06 -14.219,0c-1.538,-0.058 -2.868,-1.378 -2.927,-2.927c-0.052,-4.113 -0.588,-8.959 2.927,-9.093c4.739,-0.06 9.48,-0.06 14.219,0Zm-14.122,2.999c-0.04,0.001 -0.025,0.014 -0.025,0.025c0,1.991 0,3.982 0,5.972c0,0.045 0.015,0.025 0.025,0.025c4.675,0 9.35,0 14.025,0c0.038,0 0.025,-0.013 0.025,-0.025c0,-1.99 0,-3.981 0,-5.972c0,-0.032 0.002,-0.025 -0.025,-0.025c-4.675,0 -9.35,0 -14.025,0Z"
        fill="none"
      />
      <path
        d="M63.159,159.133c1.053,0.071 0.848,1.723 1.334,2.87c0.758,1.791 2.693,2.714 4.582,2.951c1.035,0.18 1.126,2.032 -0.411,1.984c-3.345,-0.15 -6.357,-2.867 -6.54,-6.457c-0.033,-0.656 0.273,-1.364 1.035,-1.348Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        d="M78.964,151.842c0,-0.437 -0.354,-0.791 -0.791,-0.791l-18.493,0c-0.437,0 -0.791,0.354 -0.791,0.791l0,1.582c0,0.437 0.354,0.791 0.791,0.791l18.493,0c0.437,0 0.791,-0.354 0.791,-0.791l0,-1.582Z"
        fill="#64616e"
      />
      <path
        d="M78.214,151.052c1.32,0.1 1.3,3.063 0,3.162c-6.19,0.157 -12.385,0.157 -18.575,0c-1.32,-0.1 -1.301,-3.063 0,-3.162c6.19,-0.157 12.385,-0.157 18.575,0Z"
        fill="none"
      />
      <path
        d="M53.35,132.44c-2.523,0 -4.572,2.048 -4.572,4.572c0,0.808 0.21,1.567 0.578,2.225l-3.895,0c-2.523,0 -4.572,2.049 -4.572,4.572c0,2.524 2.049,4.573 4.572,4.573l21.042,0c2.524,0 4.573,-2.049 4.573,-4.573c0,-0.807 -0.21,-1.566 -0.578,-2.225l3.894,0c2.523,0 4.572,-2.049 4.572,-4.572c0,-2.524 -2.049,-4.572 -4.572,-4.572l-21.042,0Z"
        fill="#c8c8c8"
      />
      <path
        d="M74.561,130.442c4.678,0.176 8.146,6.221 5.189,10.376c-1.208,1.697 -3.231,2.738 -5.345,2.766c0,0 -1.335,-0.343 -1.329,0.23c0,0 -1.335,6.37 -6.404,6.566c-7.074,0.085 -14.149,0.001 -21.223,0.001c-5,-0.06 -8.648,-7.008 -4.745,-11.105c1.217,-1.277 2.946,-2.015 4.744,-2.039c0,0 1.335,0.344 1.33,-0.228c0,0 0.176,-2.65 1.603,-4.298c1.195,-1.38 2.974,-2.201 4.801,-2.269c7.125,-0.094 14.253,-0.094 21.379,0Zm-21.198,3.998c-1.821,0.024 -3.169,2.167 -2.254,3.835l-1.753,2.962c-3.216,0 -7.621,0.133 -6.289,3.515c0.114,0.289 0.282,0.556 0.491,0.786c0.463,0.51 1.137,0.816 1.836,0.843c7.059,0.085 14.118,0.085 21.177,0c1.75,-0.068 3.046,-2.124 2.218,-3.752c0,0 1.709,-3.045 1.709,-3.045c3.222,0 7.61,-0.174 6.289,-3.512c-0.368,-0.929 -1.311,-1.593 -2.328,-1.631c-7.031,-0.092 -14.064,-0.001 -21.096,-0.001Z"
        fill="none"
      />
      <path
        d="M27.812,81.347c1.815,0 3.288,1.474 3.288,3.288c0,0.581 -0.151,1.127 -0.416,1.601l2.801,0c1.815,0 3.289,1.473 3.289,3.288c0,1.815 -1.474,3.288 -3.289,3.288l-15.133,0c-1.815,0 -3.288,-1.473 -3.288,-3.288c0,-0.581 0.15,-1.127 0.415,-1.6l-2.801,0c-1.814,0 -3.288,-1.474 -3.288,-3.289c0,-1.814 1.473,-3.288 3.288,-3.288l15.134,0Z"
        fill="#e7e7e7"
      />
      <path
        d="M27.947,79.349c2.624,0.101 4.904,2.24 5.138,4.88c0,0 7.425,1.913 5.32,7.236c-0.757,1.915 -2.695,3.265 -4.784,3.346c-5.094,0.062 -10.188,0.001 -15.281,0.001c-2.643,-0.032 -5.022,-2.182 -5.261,-4.884c0,0 -7.399,-1.967 -5.321,-7.232c0.756,-1.915 2.696,-3.267 4.785,-3.347c5.134,-0.063 10.27,-0.062 15.404,0Zm-15.257,3.998c-1.332,0.016 -1.568,2.558 0,2.577l2.789,0l1.754,2.961c-0.428,0.781 0.14,1.89 1.085,1.927c5.078,0.061 10.16,0.195 15.234,-0.001c1.228,-0.078 1.769,-2.553 -0.079,-2.575l-2.789,0l-1.754,-2.961c0.432,-0.788 -0.145,-1.891 -1.084,-1.927c-5.052,-0.061 -10.104,-0.001 -15.156,-0.001Z"
        fill="none"
      />
    </g>
  </svg>
);
