import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { openCaseCTA, productPageUrl } from '../../helpers/constants';
import { H2, Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import Logo from '../../shared/components/Logo';

const CasePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  padding-bottom: 3%;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    justify-content: flex-start;
    flex: 1;
    width: 70%;
    max-width: 900px;
    word-break: break-word;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5em 1em 1em;
`;
const CommunityLabel = styled.div`
  color: ${({ theme }) => theme.textColors.secondary};
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 1em;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.textColors.secondary};
  font-size: 1.6em;
  text-decoration: underline;
  margin: 1em 0;
`;
const PreviewContent = styled.div`
  padding: 0 6%;
`;
const CasePublicComponent = ({ children, communityName, userStatus }) => {
  return (
    <CasePreviewWrapper>
      <Header>
        <a href={productPageUrl} target="_blank" rel="noopener noreferrer">
          <Logo width="200px" />
        </a>
        <CommunityLabel>{communityName}</CommunityLabel>
      </Header>
      <CTABox
        text={openCaseCTA[userStatus].header}
        buttonText={openCaseCTA[userStatus].headerButton}
      />
      <PreviewContent>{children}</PreviewContent>
      <CTABox
        text={openCaseCTA[userStatus].footer}
        buttonText={openCaseCTA[userStatus].footerButton}
      />
      <StyledLink
        href={productPageUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        wspolnota.online
      </StyledLink>
    </CasePreviewWrapper>
  );
};

CasePublicComponent.propTypes = {
  communityName: PropTypes.string.isRequired,
  userStatus: PropTypes.string
};
CasePublicComponent.defaultProps = {
  userStatus: 'notLogged'
};

export default CasePublicComponent;

const CTAWrapper = styled.div`
  width: 100%;
  padding: 1em 1em 2em;
  background: #ddf8f2;
  text-align: center;
`;
const StyledH2 = styled(H2)`
  font-weight: 500;
`;
const MailtoLink = styled.a`
  color: ${({ theme }) => theme.textColors.secondary};
`;
const CTABox = ({ text, buttonText }) => {
  return (
    <CTAWrapper>
      <StyledH2>{text}</StyledH2>
      <Link to="/rejestracja" target="_blank">
        {buttonText ? (
          <PrimaryButton>{buttonText}</PrimaryButton>
        ) : (
          <Text>
            Skontaktuj się z nami na{' '}
            <MailtoLink href="mailto:pomoc@wspolnota.online">
              pomoc@wspolnota.online
            </MailtoLink>
          </Text>
        )}
      </Link>
    </CTAWrapper>
  );
};
CTABox.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};
