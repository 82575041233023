import React from 'react';

const User = () => (
  <svg
    viewBox="0 0 6 9"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <rect id="LUDZIK" width="5.194" height="8.024" fill="none" />
    <g fill="#d1cde4">
      <circle cx="2.629" cy="1.82" r="1.82" />
      <path d="M0.221,6.358c0,-1.329 1.079,-2.408 2.408,-2.408c1.329,0 2.407,1.079 2.407,2.408l0,1.592l-4.815,0l0,-1.592Z" />
    </g>
  </svg>
);

export default User;
