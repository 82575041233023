import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Arrow from '../components/Arrow';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';
import AddCaseIcon from './../../../../assets/addCaseIcon';
import casePanel from './../../../../assets/tutorial/casePanel.svg';
import listCases from './../../../../assets/tutorial/listCases.svg';

const arrowIcon = keyframes`
  0% {
    right: 40%;
  }
  100% {
    right: 5%;
  }
`;
const animationArrow = () =>
  css`
    ${arrowIcon} 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  `;
const ListCasesImage = styled.img`
  position: relative;
  width: 100%;
  opacity: 0.2;
`;
const WrapperArrow = styled.div`
  position: absolute;
  animation: ${({ isActive }) => isActive && animationArrow};
  animation-delay: 0.5s;
  animation-fill-mode: both;
  bottom: 40%;
  transform: rotate(180deg) scale(1.2);
  right: ${({ freezeArrow }) => freezeArrow && '5%'};
`;
const AnimationBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 2em;
`;
const AddCaseBox = styled.div`
  position: absolute;
  width: 18%;
  top: 86%;
  right: 2em;
  opacity: 0.2;
  svg {
    width: 100%;
  }
`;
const CasePanelBox = styled.div`
  position: absolute;
  top: 21%;
  background: ${({ theme }) => theme.backgroundColors.primary};
  width: 100%;
  border-radius: 10px;
  border: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.borderColor}` : `1px solid ${theme.buttonColors.primary}`};
  transform: scale(1.1);
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
  z-index: 10;
  img {
    width: 95%;
    margin: 0 auto;
  }
`;
const ButtonBorder = styled.span`
  position: absolute;
  border: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.buttonColors.primary}` : `2px solid ${theme.backgroundColors.primary}`};
  width: 28.5%;
  height: 28%;
  bottom: 9%;
  right: 5%;
  border-radius: 18px;
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
`;

const Step5 = ({ nextSlide, activeSlide }) => {
  const isActive = activeSlide === 4;
  const freezeArrow = activeSlide === 3 || activeSlide === 5;
  return (
    <PageWrapper onButtonClick={nextSlide}>
      <TutorialHeader>
        Lub od razu zagłosuj na <b>TAK</b> lub <b>NIE</b>
      </TutorialHeader>
      <AnimationBox>
        <CasePanelBox isActive={isActive}>
          <img alt="casePanel" src={casePanel} />
          <ButtonBorder isActive={isActive} />
        </CasePanelBox>
        <ListCasesImage alt="listCases" src={listCases} isActive={isActive} />
        <WrapperArrow isActive={isActive} freezeArrow={freezeArrow}>
          <Arrow turn="down" />
        </WrapperArrow>
        <AddCaseBox isActive={isActive}>
          <AddCaseIcon />
        </AddCaseBox>
      </AnimationBox>
    </PageWrapper>
  );
};

Step5.propTypes = {
  nextSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.number.isRequired,
};

export default Step5;
