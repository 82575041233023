import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import TooltipWrapper from './TooltipWrapper';

const FrequencyBarBox = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: left;
  word-break: break-word;
`;
const FrequencyChart = styled.div`
  position: relative;
  height: 1.6em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
`;
const FrequencyHalfPoint = styled.div`
  position: absolute;
  top: 0;
  left: 49%;
  height: 100%;
  border-right: 2px solid ${({ theme }) => theme.backgroundColors.primary};
`;
const FrequencyBar = styled.div`
  width: ${({ percent }) => `${percent}%`};
  max-width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.backgroundColors.quaternary};
`;

const ProgressBar = memo(({ percent, tooltip }) => {
  return (
    <FrequencyBarBox>
      <TooltipWrapper tooltip={tooltip} position="topLeft">
        <FrequencyChart>
          <FrequencyHalfPoint />
          <FrequencyBar percent={percent} />
        </FrequencyChart>
      </TooltipWrapper>
    </FrequencyBarBox>
  );
});

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
};
ProgressBar.defaultProps = {
  tooltip: null,
};

export default ProgressBar;
