import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import lighten from 'polished/lib/color/lighten';

import TooltipWrapper from '../TooltipWrapper';

import ThumbYes from '../../../../assets/thumbYes';
import ThumbNo from '../../../../assets/thumbNo';
import { NavItem } from '../../../layout/theme/components';

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 190px;
  justify-self: center;
  align-items: center;
  padding-bottom: ${({notVoted}) => notVoted ? '0' : '1.5em'};
  margin: ${({ withCaption }) => withCaption ? '0 auto' : '0 auto 2em'};
`;
const Radio = styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 5px;
  background: ${({ isActive, radio, theme }) => {
    if (isActive) {
      switch (radio) {
        case 'yes': {
          return lighten(0.3, theme.labels.positive);
        }
        default: {
          return lighten(0.3, theme.labels.negative);
        }
      }
    } else {
      return lighten(0.15, 'lightgray');
    }
  }};
  svg {
    fill: ${({ isActive, radio, theme }) => {
      if (isActive) {
        switch (radio) {
          case 'yes': {
            return theme.labels.positive;
          }
          default: {
            return theme.labels.negative;
          }
        }
      } else {
        return lighten(0.05, 'lightgray');
      }
    }};
    height: 19px;
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
  &:nth-child(2) svg {
    height: 15px;
  }
`;
const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledNavItem = styled(NavItem)`
  margin-top: .5em;
`;

const ThumbRadio = ({ toolTips, changeHandler, withCaption }) => {
  const [vote, setVote] = useState(0);
  useEffect(() => changeHandler(vote), [vote]);
  useEffect(() => withCaption ? setVote(0) : setVote(1), []);

  return (
    <RadioWrapper withCaption={withCaption} notVoted={vote===0}>
      <TooltipWrapper tooltip={toolTips && 'Tak'}>
        <RadioBox onClick={() => setVote(1)}>
          <Radio radio="yes" isActive={vote === 1}>
            <ThumbYes />
          </Radio>
          {withCaption && <StyledNavItem active={vote === 1}>Głos na tak</StyledNavItem>}
        </RadioBox>
      </TooltipWrapper>
      <TooltipWrapper tooltip={toolTips && 'Nie'}>
        <RadioBox onClick={() => setVote(-1)}>
          <Radio radio="no" isActive={vote === -1}>
            <ThumbNo />
          </Radio>
          {withCaption && <StyledNavItem active={vote === -1}>Głos na nie</StyledNavItem>}
        </RadioBox>
      </TooltipWrapper>
    </RadioWrapper>
  );
};

ThumbRadio.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  toolTips: PropTypes.bool,
  withCaption: PropTypes.bool
};

ThumbRadio.defaultProps = {
  toolTips: false,
  withCaption: false
};

export default ThumbRadio;
