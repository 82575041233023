import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CulpritComponent from './CulpritComponent';
import { saveCulpritStep } from '../../helpers/api/case';

class CulpritContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        address: props.address ? props.address : '',
        description: props.description ? props.description : ''
      }
    };

    this.submitForm = this.submitForm.bind(this);
  }

  saveStep() {
    const { caseId } = this.props;
    const { address, description } = this.state.form;
    saveCulpritStep(caseId, description, address).then(() =>
      this.props.handleNextStep()
    );
  }

  submitForm(e) {
    e.preventDefault();
    this.saveStep();
  }

  updateForm(field, value) {
    const temp = { ...this.state.form };
    temp[field] = value;
    this.setState({ form: temp });
  }

  render() {
    const { address, description } = this.state.form;
    return (
      <CulpritComponent
        address={address}
        description={description}
        submitHandler={this.submitForm}
        handleChange={(field, value) => this.updateForm(field, value)}
      />
    );
  }
}

CulpritContainer.propTypes = {
  address: PropTypes.string,
  description: PropTypes.string,
  caseId: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired
};

CulpritContainer.defaultProps = {
  address: '',
  description: ''
};

export default CulpritContainer;
