import React from 'react';

const liveSearch = () => (
  <svg
    viewBox="0 0 51 42"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h50.543v41.855H0z" />
    <path fill="#fff" d="M1.414 5.468h32.113v28.769H1.414z" />
    <path
      d="M33.527 34.237H1.414V5.468h32.113v28.769zM2.414 6.468v26.769h30.113V6.468H2.414z"
      fill="#f2f2f2"
    />
    <path
      d="M28.215 11.901l-20.03.011.001 1.022 20.029-.011v-1.022zM28.216 14.329l-20.029.011.001 1.023 20.029-.011-.001-1.023zM28.218 16.758l-20.03.011.001 1.022 20.029-.011v-1.022zM28.219 19.186l-20.029.011.001 1.022 20.029-.011-.001-1.022zM28.221 21.614l-20.03.011.001 1.023 20.029-.011v-1.023zM28.219 24.342l-20.029.011.001 1.023 20.029-.011-.001-1.023zM28.221 26.771l-20.03.011.001 1.022 20.029-.011v-1.022z"
      fill="#f4f4f4"
    />
    <path
      d="M30.983 1.337c1.34 0 2.44 1.038 2.537 2.354h.007v3.117H1.414V3.691h.007a2.547 2.547 0 0 1 2.538-2.354h27.024z"
      fill="#f2f2f2"
    />
    <circle cx="5.285" cy="4.31" r="0.939" fill="#fff" />
    <circle cx="8.932" cy="4.31" r="0.939" fill="#e6e6e6" />
    <path
      d="M35.857 33.094a13.614 13.614 0 0 1-3.683 3.044c-4.898 2.746-11.426 2.175-15.801-1.547-3.049-2.594-4.884-6.533-4.918-10.631v-.168c.048-4.893 2.821-9.674 7.061-12.086 3.982-2.264 9.065-2.409 13.179-.298 4.546 2.332 7.618 7.315 7.614 12.519-.002 2.259-.591 4.495-1.639 6.488a.805.805 0 0 1 .083.051l11.553 7.971c.368.254.462.76.21 1.13l-.914 1.341a.805.805 0 0 1-1.123.21l-11.553-7.971a.6.6 0 0 1-.069-.053zm-22.715-9.143c.028 3.434 1.505 6.797 4.03 9.088 2.88 2.613 7.032 3.723 10.813 2.895 4.358-.954 8.051-4.448 9.221-8.835 1.178-4.417-.301-9.444-3.787-12.49-2.924-2.554-7.086-3.588-10.855-2.691-5.102 1.214-9.21 5.943-9.414 11.514-.006.173-.008.346-.008.519z"
      fill="#d8d4e8"
    />
    <circle
      cx="25.536"
      cy="23.892"
      r="12.391"
      fill="#c6d6f2"
      fillOpacity="0.384"
    />
    <path
      d="M25.536 13.322c3.093-.27-.135 1.663-4.466 5.461-4.419 3.875-6.659 9.149-6.445 5.889.448-6.823 4.098-10.756 10.911-11.35z"
      fill="#fff"
    />
  </svg>
);

export default liveSearch;
