import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CostContainer from '../cost/CostContainer';
import TextInput from '../../shared/components/forms/TextInput';
import FileInput from '../../shared/components/forms/FileInput';
import Textarea from '../../shared/components/forms/Textarea';
import Button from '../../shared/components/buttons/PrimaryButton';
import { InvalidText, Alert, H2 } from './../../layout/theme/components';

import {
  NETWORK_ERROR_TEXT,
  INVALID_INPUT_TEXT
} from '../../helpers/constants';

const TopicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const Header = styled(H2)`
  width: 100%;
`;
const FormSection = styled.form`
  width: 100%;
`;
const FieldsWrapper = styled.div`
  width: 100%;
  padding-bottom: 2em;
`;

const TopicComponent = props => {
  const {
    handleSubmit,
    handleChange,
    handleFileChange,
    removeFile,
    topic,
    topicValid,
    description,
    descriptionValid,
    updateMentions,
    files,
    mentionMessage,
    isValid,
    error,
    caseId,
    cost
  } = props;

  return (
    <TopicWrapper>
      <Header>Temat sprawy:</Header>
      <FormSection onSubmit={e => handleSubmit(e)}>
        <FieldsWrapper>
          <TextInput
            name="topic"
            type="text"
            placeholder="Temat sprawy"
            value={topic}
            isRequired
            autoFocus
            onChange={({ target: { value } }) => handleChange(value, 'topic')}
            valid={topicValid}
            invalidText={INVALID_INPUT_TEXT}
          />
          <Textarea
            changeHandler={value => handleChange(value, 'description')}
            placeholder="Opis"
            value={description}
            valid={descriptionValid}
            invalidText={INVALID_INPUT_TEXT}
            isMention
            updateMentions={updateMentions}
            counter
          />
          {error && <InvalidText>{NETWORK_ERROR_TEXT}</InvalidText>}
          <FileInput
            changeHandler={(value, oversized) =>
              handleFileChange(value, oversized)
            }
            files={files}
            multiple
            removeFile={removeFile}
          />
          {mentionMessage && <Alert negative>{mentionMessage}</Alert>}
          <CostContainer caseId={caseId} cost={cost} editable />
        </FieldsWrapper>
        <Button type="submit" disabled={!isValid}>
          DALEJ
        </Button>
      </FormSection>
    </TopicWrapper>
  );
};

TopicComponent.propTypes = {
  caseId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  topic: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  topicValid: PropTypes.bool.isRequired,
  descriptionValid: PropTypes.bool.isRequired,
  removeFile: PropTypes.func.isRequired,
  description: PropTypes.string,
  error: PropTypes.object,
  updateMentions: PropTypes.func,
  mentionMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TopicComponent.defaultProps = {
  description: '',
  error: null,
  mentionMessage: false
};

export default TopicComponent;
