import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components/macro';

const TitleWrapper = styled.div`
  width: 100%;
  padding: 15px;
  color: #454545;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2.4em;
  line-height: 1.3em;
`;

const ReportTitle = ({ title, community }) => {
  return (
    <TitleWrapper>
      Protokół z głosowania Uchwały "{title}" Wspólnoty Mieszkaniowej{' '}
      {community}
    </TitleWrapper>
  );
};

ReportTitle.propTypes = {
  title: PropTypes.string,
  community: PropTypes.string
};

ReportTitle.defaultProps = {
  title: null,
  community: ''
};

export default ReportTitle;
