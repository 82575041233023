import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const DotsWrapper = styled.div`
  margin: 8% 0 0;
  display: flex;
  justify-content: center;
`;

const Dot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 100%;
  margin: 0 1.5em;
  background: ${({ theme, current }) =>
    current ? theme.buttonColors.primary : theme.buttonColors.inactive};
`;

const ProgressDots = ({ currentStep, allSteps }) => {
  const renderDots = () => {
    const dots = [];
    for (let i = 1; i <= allSteps; i++) {
      dots.push(<Dot key={i} current={i === currentStep} />);
    }
    return dots;
  };
  return <DotsWrapper>{renderDots()}</DotsWrapper>;
};

ProgressDots.propTypes = {
  currentStep: PropTypes.number.isRequired,
  allSteps: PropTypes.number.isRequired
};

export default ProgressDots;
