import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AnonymFemale from '../../../assets/anonym/anonymFemale';
import NotAnonymFemale from '../../../assets/anonym/notAnonymFemale';
import AnonymMale from '../../../assets/anonym/anonymMale';
import NotAnonymMale from '../../../assets/anonym/notAnonymMale';
import { H2 } from '../../layout/theme/components';

const sectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;
const Header = styled(H2)`
  ${sectionStyle};
`;
const Options = styled.div`
  ${sectionStyle};
  flex: 1;
  padding: 2%;
`;
const Option = styled.div`
  ${sectionStyle};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  filter: grayscale(${({ active }) => (active ? '0' : '20%')});
  opacity: ${({ active }) => (active ? '1' : '0.8')};
  padding: 4%;
  svg {
    display: block;
    height: 14vh;
    width: 100%;
    opacity: ${({ active }) => (active ? '1' : '0.4')};
  }
  label {
    display: block;
    width: 100%;
    color: ${({ active, theme }) =>
      active ? theme.linkColors.primary : theme.linkColors.inactive};
    font-size: 1.6em;
    font-weight: 600;
    margin-top: 0.8em;
    small {
      font-weight: 400;
      font-size: 0.7em;
      color: ${({ theme }) => theme.textColors.tertiary};
    }
  }
`;

const AnonymousComponent = props => (
  <Fragment>
    <Header>Dodaj sprawę jako:</Header>
    <Options>
      <Option
        active={!props.isAnonymous}
        onClick={() => props.clickHandler(false)}
      >
        {props.isFemale ? <NotAnonymFemale /> : <NotAnonymMale />}
        <label>{props.name}</label>
      </Option>
      {props.addAsAnonymous && (
        <Option
          active={props.isAnonymous}
          onClick={() => props.clickHandler(true)}
        >
          {props.isFemale ? <AnonymFemale /> : <AnonymMale />}
          <label>
            Chcę pozostać anonimowy
            <br />
            <small>(Imię i nazwisko nie będzie wyświetlane)</small>
          </label>
        </Option>
      )}
    </Options>
  </Fragment>
);

AnonymousComponent.propTypes = {
  addAsAnonymous: PropTypes.bool.isRequired,
  isFemale: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default AnonymousComponent;
