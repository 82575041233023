import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import ArchiveIcon from '../../../../assets/icons/ArchiveIcon';
import UnarchiveIcon from '../../../../assets/icons/UnarchiveIcon';
import { hideModal, showModal } from '../../../data/modal/actions';
import { changeCaseStatus } from '../../../helpers/api/case';
import { NETWORK_ERROR_TEXT } from '../../../helpers/constants';
import { Alert, H2 } from '../../../layout/theme/components';
import DenyBubble from '../../../shared/components/DenyBubble';

const togglerContent = {
  archived: {
    button: 'Przywróć',
    icon: <UnarchiveIcon />,
    header: 'Czy na pewno chcesz przywrócić sprawę?',
  },
  published: {
    button: 'Zarchiwizuj',
    icon: <ArchiveIcon />,
    header: 'Czy na pewno chcesz zarchiwizować sprawę?',
  },
};

const Button = styled.div`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.1em;
  color: ${({ theme }) => theme.textColors.secondary};
  align-items: center;
  display: flex;
`;
const IconWrapper = styled.div`
  margin-right: 3px;
  svg {
    width: 17px;
    height: 17px;
  }
`;

const ArchiveToggler = ({ currentStatus, caseId, callback, openModal, closeModal }) => {
  let newStatus;
  let content;

  if (currentStatus === 'archived') {
    newStatus = 'published';
    content = togglerContent['archived'];
  } else {
    newStatus = 'archived';
    content = togglerContent['published'];
  }
  const [error, setError] = useState(null);

  // const modalToggle = e => {
  //   if (!modal) e.stopPropagation();
  // };

  const updateCaseStatus = () => {
    changeCaseStatus(caseId, newStatus)
      .then(() => {
        closeModal();
        if (callback) callback();
      })
      .catch(() => setError(NETWORK_ERROR_TEXT));
  };

  const showConfirmationModal = () => {
    openModal(
      <DenyBubble leftButton="ANULUJ" leftClick={closeModal} rightButton="TAK" rightClick={updateCaseStatus}>
        <H2>{content.header}</H2>
        {error && <Alert negative>{error}</Alert>}
      </DenyBubble>
    );
  };

  return (
    <Button onClick={showConfirmationModal}>
      <IconWrapper>{content.icon}</IconWrapper>
      {content.button}
    </Button>
  );
};

ArchiveToggler.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  caseId: PropTypes.number.isRequired,
  callback: PropTypes.func,
};
ArchiveToggler.defaultProps = {
  callback: null,
};

const mapDispatchToProps = dispatch => ({
  openModal: content => dispatch(showModal(content, false)),
  closeModal: () => dispatch(hideModal()),
});

export default connect(
  null,
  mapDispatchToProps
)(ArchiveToggler);
