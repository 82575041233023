import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from '../data/user/actions';
import { saveActivationCode } from '../helpers/localStorage';
import { isEmailValid, isPhoneNumber } from './../helpers/validation';
import Login from './LoginComponent';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialling: '+48',
      username: '',
      password: '',
      valid: true,
      passValid: true,
      invalidText: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const passValid = nextProps.auth.error ? false : true;
    const invalidText =
      nextProps.auth.error === 'user apartment missing'
        ? 'Nie posiadasz przypisanego mieszkania. Skontaktuj się z nami na pomoc@wspolnota.online'
        : 'Podane hasło jest nieprawidłowe.';
    this.setState({ passValid, invalidText: invalidText });
  }

  componentDidUpdate(prevProps) {
    if (this.props.usernameType !== prevProps.usernameType) {
      this.resetForm();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value.trim(),
      valid: true,
      passValid: true
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { dialling, username, password } = this.state;
    const { usernameType } = this.props;
    const user = usernameType === 'sms' ? dialling.concat(username) : username;

    if (usernameType && this.isFormValid()) {
      saveActivationCode(password);
      this.props.login(user, password);
    } else {
      this.setState({ valid: false });
    }
  };

  isFormValid = () => {
    const { dialling, username, password } = this.state;
    const { usernameType } = this.props;
    if (usernameType === 'email') {
      return isEmailValid(username);
    } else {
      return isPhoneNumber(dialling, username) && password.length > 0;
    }
  };

  resetForm = () =>
    this.setState({
      username: '',
      password: '',
      valid: true,
      passValid: true,
      invalidText: ''
    });

  render() {
    const {
      dialling,
      username,
      password,
      valid,
      passValid,
      invalidText
    } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (this.props.auth.loggedIn) {
      return <Redirect to={from} />;
    }

    return (
      <Login
        username={username}
        dialling={dialling}
        password={password}
        valid={valid}
        passValid={passValid}
        invalidText={invalidText}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  usernameType: PropTypes.string
};

LoginContainer.defaultProps = {
  usernameType: 'email'
};

const mapStateToProps = state => ({
  auth: state.user.auth,
  usernameType: state.user.usernameType
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => {
    dispatch(login(username, password));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
