import PropTypes from 'prop-types';
import React from 'react';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import ThumbRadio from '../../../shared/components/forms/ThumbRadio';

const SoundingThumbs = ({
  chooseThumb,
  isThumbChosen,
  allowCommunityVote,
  openSoundingPlug,
  voteHandler
}) => (
  <>
    <ThumbRadio changeHandler={c => chooseThumb(c)} withCaption toolTips />
    {isThumbChosen && (
      <SecondaryButton
        onClick={allowCommunityVote ? voteHandler : openSoundingPlug}
      >
        Wyślij
      </SecondaryButton>
    )}
  </>
);

SoundingThumbs.propTypes = {
  chooseThumb: PropTypes.func.isRequired,
  isThumbChosen: PropTypes.bool,
  allowCommunityVote: PropTypes.bool,
  openSoundingPlug: PropTypes.func,
  voteHandler: PropTypes.func
};

SoundingThumbs.defaultProps = {
  isThumbChosen: false,
  allowCommunityVote: false,
  openSoundingPlug: false,
  voteHandler: null
};

export default SoundingThumbs;
