import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const importAll = r => {
  const months = {};
  r.keys().map(item => (months[item.replace('./', '')] = r(item)));
  return months;
};
const allMonths = importAll(require.context('../../../../assets/months'), false, /\.svg$/);

const months = {
  '01': 'styczeń',
  '02': 'luty',
  '03': 'marzec',
  '04': 'kwiecień',
  '05': 'maj',
  '06': 'czerwiec',
  '07': 'lipiec',
  '08': 'sierpień',
  '09': 'wrzesień',
  '10': 'październik',
  '11': 'listopad',
  '12': 'grudzień',
};

const MonthPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${({ background }) => background});
  background-size: cover;
  background-repeat: no-repeat;
  height: 3em;
  margin-left: 0.5em;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 5em;
    width: auto;
  }
`;
const MonthLabel = styled.span`
  text-transform: capitalize;
  color: white;
  font-size: 1.4em;
  font-weight: 600;
`;

const MonthTile = ({ month, year }) => {
  return (
    <MonthPicture background={allMonths[`${month.slice(-2)}.svg`]}>
      <MonthLabel>
        {months[month.slice(-2)]} {year && year}
      </MonthLabel>
    </MonthPicture>
  );
};

MonthTile.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.string,
};

MonthTile.defaultProps = {
  year: null,
};

export default MonthTile;
