import React, { Component, Fragment } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import TextInput from '../../../shared/components/forms/TextInput';
import DeleteButton from './DeleteButton';

const InputBox = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

class InvitingData extends Component {
  renderInput() {
    const { inputs, inputValue, onChangeInput, removeInput } = this.props;

    return inputs.map((input, index) => {
      if (input.type === 'email') {
        return (
          <InputBox key={index}>
            <TextInput
              name={input.name}
              placeholder="e-mail"
              value={inputValue['name']}
              onChange={e => onChangeInput(e, input['name'])}
              valid={input['valid']}
              invalidText="Niepoprawny format e-mail"
            />
            {inputs.length !== 1 && (
              <DeleteButton onClick={() => removeInput(input['name'])} />
            )}
          </InputBox>
        );
      } else {
        return (
          <InputBox key={index}>
            <TextInput
              name={input.name}
              placeholder="telefon"
              value={inputValue['name']}
              onChange={e => onChangeInput(e, input['name'])}
              valid={input['valid']}
              invalidText="Niepoprawny format numeru telefonu"
            />
            <DeleteButton onClick={() => removeInput(input['name'])} />
          </InputBox>
        );
      }
    });
  }
  render() {
    return <Fragment>{this.renderInput()}</Fragment>;
  }
}

InvitingData.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  removeInput: PropTypes.func.isRequired,
  inputValue: PropTypes.string
};

InvitingData.defaultProps = {
  inputValue: ''
};

export default InvitingData;
