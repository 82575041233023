import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import AttachmentIcon from './../../../../assets/attachment';
import pdfIcon from './../../../../assets/pdfIcon.svg';
import { Caption, Text } from '../../../layout/theme/components';

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8em;
  background: ${({ theme }) => theme.backgroundColors.secondary};
`;
const FileIcon = styled.img`
  height: 30px;
  margin-right: 1em;
`;
const FileAttachment = ({ name }) => {
  return (
    <FileWrapper>
      <FileIcon src={pdfIcon} alt="" />
      <Text small>{name}</Text>
    </FileWrapper>
  );
};

const ImageWrapper = styled.img`
  max-width: 100%;
`;
const ImageAttachment = ({ name, src }) => {
  return (
    <>
      <ImageWrapper src={src} alt="" />
      <Text small>{name}</Text>
    </>
  );
};

const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: ${({ theme }) => theme.textColors.tertiary};
`;
const LabelBox = styled.div`
  color: ${({ theme }) => theme.textColors.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
  text-align: left;
  padding-bottom: 4px;
  margin: 10px 0 5px;

  svg {
    fill: ${({ theme }) => theme.textColors.tertiary};
    width: 1em;
    margin-right: 0.5em;
  }
`;
const Attachment = styled.div`
  width: 100%;
  text-align: left;
  color: #4b5081;
  margin: 0.5em 0;
`;

class Attachments extends Component {
  renderAttachment(attach) {
    switch (attach.mime_type) {
      case 'application/pdf':
        return <FileAttachment name={attach.name} />;
      default:
        return <ImageAttachment src={attach.path} name={attach.name} />;
    }
  }

  render() {
    const { isLabel, attachments, togglePreview } = this.props;

    return (
      <AttachmentWrapper>
        {isLabel && (
          <LabelBox>
            <AttachmentIcon />
            <Caption>Załączniki</Caption>
          </LabelBox>
        )}
        {attachments.map((attach, index) => {
          return (
            <Attachment key={index} onClick={() => togglePreview(index)}>
              {this.renderAttachment(attach)}
            </Attachment>
          );
        })}
      </AttachmentWrapper>
    );
  }
}

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      mime_type: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  togglePreview: PropTypes.func.isRequired,
  isLabel: PropTypes.bool
};

Attachments.defaultProps = {
  isLabel: false
};

export default Attachments;
