import React from 'react';

const addCompanies = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="64.186">
    <g data-name="Group 3181" transform="translate(1849 -466)">
      <rect
        data-name="Rectangle 1638"
        width="69"
        height="64.186"
        rx="9"
        transform="translate(-1849 466)"
        fill="#f2f2f2"
      />
      <path
        data-name="Path 14007"
        d="M-1799.088 517.769h-29.532v-7.766a14.766 14.766 0 1 1 29.532 0z"
        fill="#d1ebef"
      />
      <path
        data-name="Path 14008"
        d="M-1814.374 500.58l-3.561-5.342h7.123z"
        fill="#6b6b6b"
      />
      <path
        data-name="Path 14009"
        d="M-1814.373 498.8l3.561 14.246-3.561 3.064-3.562-3.064z"
        fill="#6b6b6b"
      />
      <path
        data-name="Path 14010"
        d="M-1822.406 479.907l17.111 2.013v6.543a8.556 8.556 0 1 1-17.111 0z"
        fill="#fff"
      />
      <path
        data-name="Path 14011"
        d="M-1825.426 473.868h12.078a8.052 8.052 0 0 1 8.052 8.052h-12.078a8.052 8.052 0 0 1-8.052-8.052z"
        fill="#aed6dc"
      />
      <path
        data-name="Path 14012"
        d="M-1828.8 494.86a5.952 5.952 0 0 0-5.952 5.952l-.001 16.368a4.452 4.452 0 0 0 8.733 1.238.737.737 0 0 0 .196-.493v-16.367a2.976 2.976 0 0 0-5.951 0l-.001 13.392a.744.744 0 1 0 1.488 0v-13.391a1.488 1.488 0 1 1 2.97 0l.006 15.623a2.976 2.976 0 0 1-5.951 0l-.002-16.369a4.464 4.464 0 1 1 8.928 0v14.88a.744.744 0 1 0 1.488 0l.004-14.877a5.952 5.952 0 0 0-5.955-5.956z"
        fill="#aed6dc"
      />
    </g>
  </svg>
);

export default addCompanies;
