import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { resolutionStatus } from '../../../helpers/constants';
import CheckIcon from '../../../../assets/checkIcon';
import RejectedIcon from '../../../../assets/rejectedIcon';
import { Text } from '../../../layout/theme/components';

const StatusWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  font-weight: 600;

  svg {
    margin-left: 0.5em;
    width: 1em;
  }
`;

const StatusText = styled(Text).attrs({
  small: true
})`
  margin: 0;
`;

const PositiveStatus = styled(StatusText)`
  color: ${({ theme }) => theme.labels.positive};
`;
const NegativeStatus = styled(StatusText)`
  color: ${({ theme }) => theme.labels.negative};
`;

const Status = ({ status, noIcon, invalidateDesc }) => {
  switch (status) {
    case 'accepted':
      return (
        <>
          <PositiveStatus>{resolutionStatus[status]}</PositiveStatus>
          {!noIcon && <CheckIcon />}
        </>
      );
    case 'rejected':
      return (
        <>
          <NegativeStatus>{resolutionStatus[status]}</NegativeStatus>
          {!noIcon && <RejectedIcon />}
        </>
      );
    case 'invalidated':
      return (
        <NegativeStatus>
          {resolutionStatus[status]} {invalidateDesc && `: ${invalidateDesc}`}
        </NegativeStatus>
      );
    default:
      return resolutionStatus[status];
  }
};

Status.propTypes = {
  status: PropTypes.string.isRequired
};

const ResolutionStatus = ({ status, noIcon, invalidateDesc }) => {
  return (
    <StatusWrapper>
      <Status status={status} noIcon={noIcon} invalidateDesc={invalidateDesc} />
    </StatusWrapper>
  );
};

ResolutionStatus.propTypes = {
  status: PropTypes.string,
  invalidateDesc: PropTypes.string
};

ResolutionStatus.defaultProps = {
  status: 'resolution_in_progress',
  invalidateDesc: null
};

export default ResolutionStatus;
