import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SliderHeader from './components/SliderHeader';
import SliderTop from './components/SliderTop';
import SliderWrapper from './components/SliderWrapper';
import BottomSlidesWrapper from './components/BottomSlidesWrapper';
import ArrowBox from './components/ArrowBox';
import SlideBottom from './components/SlideBottom';

class SliderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
      attachments: []
    };
  }

  componentWillMount() {
    const { clickedAttachment, attachments } = this.props;
    clickedAttachment && this.setActiveSlide(clickedAttachment);
    attachments && this.setState({ attachments });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeSlide !== this.state.activeSlide) {
      this.setState(prevState => ({ resetScale: !prevState.resetScale }));
    }
  }

  nextSlide = () => {
    const { activeSlide, attachments } = this.state;
    activeSlide !== attachments.length - 1
      ? this.setState({ activeSlide: activeSlide + 1 })
      : this.setState({ activeSlide: 0 });
  };

  prevSlide = () => {
    const { activeSlide, attachments } = this.state;
    activeSlide !== 0
      ? this.setState({ activeSlide: activeSlide - 1 })
      : this.setState({ activeSlide: attachments.length - 1 });
  };

  getNearbySlide = next => {
    const { activeSlide, attachments } = this.state;
    const slide = next ? activeSlide + 1 : activeSlide - 1;
    return slide === attachments.length
      ? 0
      : slide === -1
        ? attachments.length - 1
        : slide;
  };

  setActiveSlide = id =>
    typeof id === 'number' && this.setState({ activeSlide: id });

  isPDF = id => this.state.attachments[id].mime_type === 'application/pdf';

  renderBottomSlides() {
    const { activeSlide, attachments } = this.state;
    const prevSlide = this.getNearbySlide();
    const nextSlide = this.getNearbySlide('next');

    const isActiveSlidePDF = this.isPDF(activeSlide);
    const isPrevSlidePDF = this.isPDF(prevSlide);
    const isNextSlidePDF = this.isPDF(nextSlide);
    switch (attachments.length) {
      case 1: {
        return (
          <SlideBottom
            path={attachments[activeSlide].path}
            isPDF={isActiveSlidePDF}
            activeSlide
          />
        );
      }
      case 2: {
        return (
          <>
            <SlideBottom
              onClick={() => this.setActiveSlide(0)}
              path={attachments[activeSlide].path}
              isPDF={isActiveSlidePDF}
              activeSlide
            />
            <SlideBottom
              onClick={() => this.setActiveSlide(1)}
              path={attachments[nextSlide].path}
              isPDF={isNextSlidePDF}
            />
          </>
        );
      }
      default: {
        return (
          <>
            <SlideBottom
              onClick={() => this.setActiveSlide(prevSlide)}
              path={attachments[prevSlide].path}
              isPDF={isPrevSlidePDF}
            />
            <SlideBottom
              path={attachments[activeSlide].path}
              isPDF={isActiveSlidePDF}
              activeSlide
            />
            <SlideBottom
              onClick={() => this.setActiveSlide(nextSlide)}
              path={attachments[nextSlide].path}
              isPDF={isNextSlidePDF}
            />
          </>
        );
      }
    }
  }

  render() {
    const { activeSlide, attachments } = this.state;
    const { togglePreview } = this.props;
    const isActiveSlidePDF = this.isPDF(activeSlide);
    const oneAttachment = attachments.length === 1;
    return (
      <SliderWrapper>
        <SliderHeader
          attachments={attachments}
          activeSlide={activeSlide}
          exitOnClick={togglePreview}
        />
        <SliderTop
          path={attachments[activeSlide].path}
          isPDF={isActiveSlidePDF}
        />
        <BottomSlidesWrapper>
          {!oneAttachment && <ArrowBox onClick={this.prevSlide} />}
          {this.renderBottomSlides()}
          {!oneAttachment && <ArrowBox onClick={this.nextSlide} next />}
        </BottomSlidesWrapper>
      </SliderWrapper>
    );
  }
}

SliderContainer.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      mime_type: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  clickedAttachment: PropTypes.number,
  togglePreview: PropTypes.func
};

SliderContainer.defaultProps = {
  clickedAttachment: 0,
  togglePreview: null
};

export default SliderContainer;
