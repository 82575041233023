import React from 'react';
import styled from 'styled-components/macro';
import { Alert, H2, Text } from '../../../layout/theme/components';

const CaptionContainer = styled.div`
  margin: 2em 0;
  color: #585858;
`;

const ClosingModalContent = () => (
  <>
    <H2>Czy na pewno chcesz zamknąć głosowanie?</H2>
    <CaptionContainer>
      <Alert negative>
        Ważne! Po zamknięciu głosowania dodanie głosów złożonych poza aplikacją (papierowe listy, karty do głosowania
        lub e-mail) nie będzie możliwe. Aby zostały doliczone do wyników głosowania, wybierz "Anuluj" i dodaj je teraz.
      </Alert>
      <Text small>Po zamknięciu głosowania, wszystkie osoby należące do wspólnoty otrzymają powiadomienie.</Text>
    </CaptionContainer>
  </>
);

export default ClosingModalContent;
