import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { H2 } from '../../../layout/theme/components';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const FormSection = styled.form`
  ${sectionStyle};
  flex: 1;
`;
const Header = styled(H2)`
  ${sectionStyle};
  margin-bottom: 15%;
`;
const FieldsWrapper = styled.div`
  ${sectionStyle};
  justify-content: flex-start;
  margin-bottom: 5%;
  flex: 1;
`;

const ResolutionWrapper = ({
  title,
  children,
  handleSubmit
}) => (
  <>
    <Header>{title}</Header>
    <FormSection onSubmit={e => handleSubmit(e)}>
      <FieldsWrapper>{children}</FieldsWrapper>
      <PrimaryButton type="submit">DALEJ</PrimaryButton>
    </FormSection>
  </>
);

ResolutionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default ResolutionWrapper;
