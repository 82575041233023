import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { saveMortgage } from '../../helpers/api/community';
import { hasHtml, valueLength } from '../../helpers/validation';
import Loader from '../../shared/components/Loader';
import MortgageComponent from './MortgageComponent';

export default class MortgageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        mortgageNumber: ''
      },
      isLoading: false,
      error: null,
      valid: true
    };
  }

  handleSaveMortgage() {
    const { mortgageNumber } = this.state.form;
    const { saveHandler } = this.props;
    this.setState({ isLoading: true });

    saveMortgage(mortgageNumber)
      .then(() => saveHandler())
      .catch(error => this.setState({ error, isLoading: false }));
  }

  submitForm(e) {
    e.preventDefault();
    const { mortgageNumber } = this.state.form;

    const isValid =
      !hasHtml(mortgageNumber) && valueLength(mortgageNumber, 150, 1);
    if (isValid) {
      this.handleSaveMortgage();
    } else {
      this.setState({ valid: false });
    }
  }

  updateForm(field, value) {
    const temp = { ...this.state.form };
    temp[field] = value;
    this.setState({ form: temp });
  }

  render() {
    const { form, valid, isLoading, error } = this.state;
    const { isKeeper } = this.props;

    if (isLoading) return <Loader />;

    return (
      <MortgageComponent
        mortgageNumber={form.mortgageNumber}
        updateForm={value => this.updateForm('mortgageNumber', value)}
        valid={valid}
        isKeeper={isKeeper}
        submitForm={e => this.submitForm(e)}
        error={error}
      />
    );
  }
}

MortgageContainer.propTypes = {
  isKeeper: PropTypes.bool.isRequired,
  saveHandler: PropTypes.func.isRequired
};
