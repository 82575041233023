import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { deleteAccount } from '../../../helpers/api/user';
import { H2 } from '../../../layout/theme/components';
import TextButton from '../../../shared/components/buttons/TextButton';
import DenyBubble from '../../../shared/components/DenyBubble';
import Modal from '../../../shared/components/Modal';

class DeleteAccount extends Component {
  state = {
    openModal: false,
    logout: true,
    loading: false,
  };

  toggleModal(toggle, event, callback) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ openModal: toggle }, () => {
      if (callback) {
        callback();
      }
    });
  }

  deleteUserAccount = () => {
    this.setState({ loading: true });
    deleteAccount().then(() => this.setState({ logout: false, loading: false }));
  };

  renderModal() {
    const { openModal, logout, loading } = this.state;

    if (!logout) return <Redirect to="/wylogowanie" />;
    if (openModal) {
      return (
        <Modal closeModal={e => this.toggleModal(false, e)}>
          <DenyBubble
            leftButton="ANULUJ"
            leftClick={e => this.toggleModal(false, e)}
            rightButton="TAK"
            rightClick={this.deleteUserAccount}
            logout
            isLoading={loading}
          >
            <H2>Wyślij prośbę o usunięcie konta.</H2>
          </DenyBubble>
        </Modal>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <TextButton onClick={e => this.toggleModal(true, e)} small>
          USUŃ MOJE KONTO
        </TextButton>
        {this.renderModal()}
      </>
    );
  }
}

export default DeleteAccount;
