import React from 'react';

const BackArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="23"
    viewBox="13.03 65.855 15 23"
  >
    <path d="M7.971 17.494l-.907 2.55L0 11.531l2.515-5.208 3.848 8.423 1.244-2.956 5.315 12.733-4.951-7.029zM13.624 10.271l-1.974 1.342-.394-9.18 4.492-2.434-1.787 7.623 2.422-1.466-3.045 11.283.286-7.168z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.216 66.647a.05.05 0 0 1-.01.011v-.001l-7.936 7.935-2.763 2.764-.002.001 2.763 2.763.002-.001 7.938 7.937.002-.001c.002.003.004.008.008.01a1.953 1.953 0 1 0 2.762-2.763c-.002-.003-.006-.005-.01-.008l.002-.001-7.938-7.937 7.936-7.936-.002-.001.01-.009a1.949 1.949 0 0 0 0-2.762 1.952 1.952 0 0 0-2.762-.001z"
    />
  </svg>
);

export default BackArrow;
