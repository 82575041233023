import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../shared/components/forms/TextInput';
import ResolutionEditor from './ResolutionEditor';

import { INVALID_INPUT_TEXT } from '../../helpers/constants';
import { saveResolution } from '../../helpers/api/case';
import { postMedia } from '../../helpers/api/media';
import PrepareResolutionComponent from './PrepareResolutionComponent';

const PrepareResolutionContainer = ({
  caseId,
  handleNextStep,
  file,
  subtype,
  title,
  content,
  attachments
}) => {
  const [resolutionTitle, setResolutionTitle] = useState('');
  const [titleValid, setTitleValid] = useState(true);
  const [textEditor, setTextEditor] = useState('');
  const [attachment, addAttachment] = useState([]);
  const [savedAttachments, setSavedAttachments] = useState([]);
  const [resolution, addResolution] = useState([]);
  const [attachSizeValid, setAttachSizeValid] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isEditor = subtype === 'template';
  useEffect(() => {
    subtype === 'file' && file && addResolution([file]);
    title && setResolutionTitle(title);
    content && setTextEditor(content);
    attachments && setSavedAttachments(attachments);
    setIsLoading(false);
  }, []);
  const handleChangeEditor = value => {
    setTextEditor(value);
    setError(null);
  };
  const handleChangeTitle = value => {
    setResolutionTitle(value);
    setTitleValid(true);
  };
  const handleFileChange = (value, sizeValid) => {
    addAttachment([...attachment, ...value]);
    setAttachSizeValid(sizeValid);
  };
  const handleRemoveFile = (fileIndex, sizeValid) => {
    setAttachSizeValid(sizeValid);

    if (attachment.length > 0) {
      const filteredAttachments = attachment.filter(
        (_, index) => fileIndex !== index
      );
      addAttachment(filteredAttachments);
    } else {
      const filteredAttachemnts = savedAttachments.filter(
        (_, index) => fileIndex !== index
      );
      setSavedAttachments(filteredAttachemnts);
    }
  };
  const handleFilesUpload = files => {
    const promises = files.map(file => {
      return postMedia(file).then(data => data.data.id);
    });
    return Promise.all(promises);
  };
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  const saveStep = attachments => {
    isEditor
      ? saveResolution(caseId, resolutionTitle, textEditor, attachments)
          .then(() => handleNextStep())
          .catch(error => setError(error))
      : saveResolution(caseId, resolutionTitle, '', attachments)
          .then(() => handleNextStep())
          .catch(error => setError(error));
  };
  const checkValidation = () => {
    const titleValid = resolutionTitle.length > 0;
    !titleValid && scrollToTop();
    setTitleValid(titleValid);

    return titleValid;
  };
  const handleSubmit = e => {
    e.preventDefault();
    const isValid = checkValidation();
    const isAttachment = !!attachment[0];
    const savedIds = savedAttachments.map(data => data.id);
    if (isAttachment && isValid) {
      handleFilesUpload(attachment).then(data =>
        saveStep([...data, ...savedIds])
      );
    } else if (isValid) saveStep(savedIds);
  };
  const isError = {
    networkError: error && error.status !== 400,
    textError: error && error.status === 400
  };

  return (
    <PrepareResolutionComponent
      header="Przygotuj uchwałę:"
      handleSubmit={handleSubmit}
      attachment={[...attachment, ...savedAttachments]}
      handleFileChange={(value, isFileValid) =>
        handleFileChange(value, isFileValid)
      }
      handleRemoveFile={(index, sizeValid) =>
        handleRemoveFile(index, sizeValid)
      }
      withEditor={!file}
      resolution={resolution[0]}
      attachSizeValid={attachSizeValid}
      isError={isError}
    >
      <TextInput
        name="title"
        type="text"
        placeholder="Wpisz numer i tytuł uchwały"
        value={resolutionTitle}
        onChange={e => handleChangeTitle(e.target.value)}
        invalidText={INVALID_INPUT_TEXT}
        valid={titleValid}
      />
      {!isLoading &&
        isEditor && (
          <ResolutionEditor
            textEditor={textEditor}
            handleChange={handleChangeEditor}
          />
        )}
    </PrepareResolutionComponent>
  );
};

PrepareResolutionContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  subtype: PropTypes.string.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      mime_type: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  file: PropTypes.shape({
    mime_type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    path: PropTypes.string
  })
};

PrepareResolutionContainer.defaultProps = {
  title: '',
  content: '',
  attachments: [],
  file: null
};

export default PrepareResolutionContainer;
