import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 15% 1.5em 5%;
  padding-bottom: ${({ noBotttomPadding }) => noBotttomPadding && '0'};
  background: ${({ premiumColor, theme }) =>
    premiumColor ? theme.backgroundColors.premium : theme.backgroundColors.primary};
  color: ${({ premiumColor, theme }) => (premiumColor ? theme.textColors.background : 'inherit')};
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 5% 1.5em 2%;
    padding-bottom: ${({ noBotttomPadding }) => noBotttomPadding && '0'};
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ responsive }) => (responsive ? 'flex-start' : 'center')};
  text-align: center;
  word-break: break-all;
  width: 100%;
  max-width: ${({ wide }) => (wide ? '1280px' : '450px')};
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: ${({ responsive }) => responsive && 'row'};
    justify-content: ${({ responsive }) => responsive && 'center'};
  }
`;

const Container = ({ children, responsive, noBotttomPadding, premiumColor, wide }) => (
  <StyledContainer noBotttomPadding={noBotttomPadding} premiumColor={premiumColor}>
    <StyledWrapper responsive={responsive} wide={wide}>
      {children}
    </StyledWrapper>
  </StyledContainer>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  responsive: PropTypes.bool,
  noBotttomPadding: PropTypes.bool,
  premiumColor: PropTypes.bool,
  wide: PropTypes.bool,
};

Container.defaultProps = {
  responsive: false,
  noBotttomPadding: false,
  premiumColor: false,
  wide: false,
};

export default Container;
