import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { LinkedItem } from '../layout/theme/components';
import Button from '../shared/components/buttons/PrimaryButton';
import TextButton from '../shared/components/buttons/TextButton';
import TextInput from '../shared/components/forms/TextInput';
import WelcomeContainer from './components/WelcomeContainer';
import UsernameInputToggler from './UsernameInputToggler';

const LoginComponent = ({
  handleSubmit,
  handleChange,
  valid,
  passValid,
  invalidText,
  dialling,
  username,
  password
}) => (
  <WelcomeContainer>
    <form onSubmit={e => handleSubmit(e)}>
      <UsernameInputToggler
        dialling={dialling}
        username={username}
        handleChange={handleChange}
        valid={valid}
      />
      <TextInput
        name="password"
        type="password"
        value={password}
        placeholder="Hasło"
        onChange={handleChange}
        isRequired
        valid={passValid}
        invalidText={invalidText}
      />
      <br />
      <Link to="/nowykod">
        <LinkedItem>Nie pamiętam hasła</LinkedItem>
      </Link>
      <Button type="submit">ZALOGUJ</Button>
    </form>
    <TextButton label="Nie masz konta?" primary>
      <Link to="/rejestracja">Zarejestruj się!</Link>
    </TextButton>
  </WelcomeContainer>
);

LoginComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  dialling: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  valid: PropTypes.bool,
  passValid: PropTypes.bool,
  invalidText: PropTypes.string
};

LoginComponent.defaultProps = {
  valid: true,
  passValid: true,
  dialling: '',
  username: '',
  password: '',
  invalidText: ''
};

export default LoginComponent;
