import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import NotificationIcon from '../../../assets/NotificationIcon';
import { H2 } from '../../layout/theme/components';
import TextButton from '../../shared/components/buttons/TextButton';
import ArrowIcon from './../../shared/components/ArrowIcon';
import Container from './../../shared/components/Container';

const StyledH2 = styled(H2)`
  display: flex;
  align-items: center;
  word-break: normal;

  svg {
    height: 20px;
    margin-right: 8px;
  }
`;
const ListingWrapper = styled.div`
  width: 100%;
  text-align: left;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

const NotificationsComponent = ({ children, handleBulkRemoveNewFlag }) => {
  return (
    <Container>
      <Link
        to={{
          pathname: '/',
          state: { updateUser: true },
        }}
      >
        <ArrowIcon />
      </Link>
      <StyledH2>
        <NotificationIcon /> Powiadomienia
      </StyledH2>
      <ButtonWrapper>
        <TextButton small onClick={() => handleBulkRemoveNewFlag()}>
          Oznacz wszystkie jako przeczytane
        </TextButton>
      </ButtonWrapper>
      <ListingWrapper>{children}</ListingWrapper>
    </Container>
  );
};

export default NotificationsComponent;
