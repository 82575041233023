import React from 'react';

const mortgageIcon = () => {
  return (
    <svg
      viewBox="0 0 193 75"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h193v74.355H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h193v74.355H0z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="M189.677 48.555c-59.369-13.6-121.521-12.959-186.082 0l-.076 9.102c63.184-6.685 124.656-7.425 186.163-.826l-.005-8.276z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M87.251 52.125a9.42 9.42 0 0 0 2.756 6.656c3.652 3.649 9.659 3.649 13.311 0a9.415 9.415 0 0 0 2.757-6.656h-4.704a4.708 4.708 0 0 1-1.38 3.329c-1.826 1.824-4.83 1.824-6.656 0a4.705 4.705 0 0 1-1.379-3.328l-4.705-.001z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <path
          d="M192.996 58.099a1.376 1.376 0 0 0-1.262-1.466l-89.124-6.688a1.375 1.375 0 0 0-1.468 1.254l-.2 2.727a1.375 1.375 0 0 0 1.259 1.473l89.124 6.688a1.374 1.374 0 0 0 1.466-1.261l.205-2.727zM.004 58.099a1.374 1.374 0 0 1 1.261-1.466l89.124-6.688a1.374 1.374 0 0 1 1.467 1.254l.2 2.727a1.373 1.373 0 0 1-1.257 1.473L1.675 62.083a1.374 1.374 0 0 1-1.466-1.261l-.205-2.723z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <path
          d="M3.596 54.817a.372.372 0 0 1 .319-.425 194.16 194.16 0 0 0 29.522-2.3C46.948 49.876 60.62 45.03 67.9 44.845c15.356-.391 28.153 7.2 28.153 7.2a.402.402 0 0 1 .371.408l.051.833a.372.372 0 0 1-.319.425c-.184.023-12.729-7.594-28.09-7.203-7.187.183-20.589 4.976-33.972 7.162a203.826 203.826 0 0 1-30.076 2.388.401.401 0 0 1-.37-.408l-.052-.833z"
          fill="#ccc"
          fillRule="nonzero"
        />
        <path
          d="M3.759 51.918a.372.372 0 0 1 .319-.425 194.16 194.16 0 0 0 29.522-2.3c13.507-2.217 27.179-7.063 34.459-7.248 15.356-.391 28.153 7.2 28.153 7.2a.402.402 0 0 1 .371.408l.052.833a.372.372 0 0 1-.319.425c-.184.023-12.729-7.594-28.085-7.2-7.187.181-20.589 4.97-33.972 7.16a203.893 203.893 0 0 1-30.081 2.387.401.401 0 0 1-.37-.408l-.049-.832zM189.516 54.817a.372.372 0 0 0-.319-.425 194.16 194.16 0 0 1-29.522-2.3c-13.51-2.216-27.183-7.062-34.463-7.247-15.356-.391-28.152 7.2-28.152 7.2a.402.402 0 0 0-.371.408l-.052.833a.372.372 0 0 0 .319.425c.184.023 12.729-7.594 28.085-7.2 7.187.183 20.589 4.976 33.972 7.162a203.893 203.893 0 0 0 30.081 2.387.402.402 0 0 0 .371-.408l.051-.835z"
          fill="#ccc"
          fillRule="nonzero"
        />
        <path
          d="M189.354 51.918a.37.37 0 0 0-.319-.425 194.137 194.137 0 0 1-29.521-2.3c-13.51-2.216-27.183-7.062-34.463-7.247-15.356-.391-28.153 7.2-28.153 7.2a.402.402 0 0 0-.371.408l-.051.833a.37.37 0 0 0 .319.425c.184.023 12.728-7.594 28.085-7.2 7.187.183 20.589 4.976 33.972 7.162a203.893 203.893 0 0 0 30.081 2.387.401.401 0 0 0 .37-.408l.051-.835z"
          fill="#ccc"
          fillRule="nonzero"
        />
        <path
          d="M96.546 50.444a2.396 2.396 0 0 1 2.383 2.384 2.396 2.396 0 0 1-2.383 2.384 2.397 2.397 0 0 1-2.383-2.384 2.397 2.397 0 0 1 2.383-2.384z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <clipPath id="b">
          <path d="M189.68 48.557l-34.541-19.024c-23.655-7.028-43.392-12.651-58.5-.27v18.266c21.746-11.487 24.159-11.017 59.086-1.863 9.396 2.463 22.26 2.814 33.955 2.891z" />
        </clipPath>
        <g clipPath="url(#b)">
          <path
            d="M-.098 21.958l193.196-.001.001 55.37-193.198-.001.001-55.368z"
            fill="#fbf7f5"
            fillRule="nonzero"
          />
        </g>
        <clipPath id="c">
          <path d="M3.519 48.557l34.533-19.024c23.655-7.028 43.392-12.651 58.5-.27v18.266C74.81 36.041 72.397 36.511 37.469 45.666c-9.398 2.463-22.262 2.814-33.95 2.891z" />
        </clipPath>
        <g clipPath="url(#c)">
          <path
            d="M-.098 21.96l193.197-.001-.001 55.37-193.196-.002V21.96z"
            fill="#fbf7f5"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M3.596 49.063a.372.372 0 0 1 .319-.425 194.16 194.16 0 0 0 29.522-2.3c13.511-2.216 27.183-7.062 34.463-7.247 15.356-.391 28.153 7.2 28.153 7.2a.402.402 0 0 1 .371.408l.051.833a.37.37 0 0 1-.319.425c-.184.023-12.729-7.594-28.09-7.201-7.187.183-20.589 4.976-33.972 7.162a203.961 203.961 0 0 1-30.076 2.387.401.401 0 0 1-.37-.408l-.052-.834zM189.516 49.063a.372.372 0 0 0-.319-.425 194.16 194.16 0 0 1-29.522-2.3c-13.51-2.216-27.183-7.062-34.463-7.247-15.356-.391-28.152 7.2-28.152 7.2a.402.402 0 0 0-.371.408l-.052.833a.37.37 0 0 0 .319.425c.184.023 12.729-7.594 28.085-7.2 7.187.183 20.589 4.976 33.972 7.162a203.893 203.893 0 0 0 30.081 2.387.402.402 0 0 0 .371-.408l.051-.835z"
          fill="#ccc"
          fillRule="nonzero"
        />
        <path
          d="M85.488 49.161s-10.028 3.8-11.322 11.234a87.864 87.864 0 0 0-.877 13.96c.079 4.669-3.987-3.762-4.515-5.448-.528-1.686-4.882 5.227-4.882 5.227-1.316-2.341.237-20.963 10.718-27.189a44.163 44.163 0 0 1 10.878 2.216z"
          fill="#fabb4a"
          fillRule="nonzero"
        />
        <g fillRule="nonzero">
          <path
            d="M145.853 6.814a27.1 27.1 0 0 0 2.717.137A26.618 26.618 0 0 0 166.536 0a26.618 26.618 0 0 0 17.966 6.951 27.1 27.1 0 0 0 2.717-.137c.037.508.056 1.021.056 1.539 0 29.24-20.739 39.647-20.739 39.647s-20.739-10.406-20.739-39.647c0-.517.019-1.03.056-1.539z"
            fill="#a5eede"
          />
          <path
            d="M166.536 48s-20.739-10.406-20.739-39.647c0-.517.019-1.03.056-1.539a27.1 27.1 0 0 0 2.717.137A26.618 26.618 0 0 0 166.536 0v48z"
            fill="#56dbbe"
          />
          <path
            d="M149.771 10.107a21.316 21.316 0 0 0 16.763-5.649 21.316 21.316 0 0 0 16.763 5.649c.031.421.046.846.046 1.275 0 24.238-16.811 32.863-16.811 32.863s-16.807-8.625-16.807-32.863c0-.429.015-.854.046-1.275z"
            fill="#56dabe"
          />
          <path
            d="M166.536 44.246s-16.811-8.626-16.811-32.864c0-.429.015-.854.046-1.275a21.316 21.316 0 0 0 16.763-5.649l.002 39.788z"
            fill="#4dc5ac"
          />
        </g>
      </g>
    </svg>
  );
};

export default mortgageIcon;
