import React from 'react';

const exclamationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="16"
    viewBox="0 0 11 16"
  >
    <defs />
    <g transform="translate(-283 -509)">
      <g transform="translate(35 -40)">
        <rect
          fill="#f86060"
          width="11"
          height="11"
          rx="2"
          transform="translate(248 552)"
        />
        <text
          fill="#ffffff"
          fontSize="13px"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontWeight="600"
          letterSpacing="0.07em"
          transform="translate(252 562)"
        >
          <tspan x="0" y="0">
            !
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default exclamationIcon;
