import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentResource } from '../../data/user/actions';
import { fetchCasePreview, fetchResolutionReport, fetchTaggedCompany, fetchUserVote } from '../../helpers/api/case';
import { untagUser } from '../../helpers/api/user';
import { userLabels, userRoles } from '../../helpers/constants';
import { daysPassed, formatDateTime, getDistanceWithMilliseconds } from '../../helpers/date';
import AttachmentsPreview from '../../shared/components/AttachmentsPreview';
import Loader from '../../shared/components/Loader';
import Error from '../../shared/Error';
import Activities from '../activities/ActivitiesContainer';
import ActivitiesStatus from '../activities/components/ActivitiesStatus';
import ActivitiesSummary from '../activities/components/ActivitiesSummary';
import CaseHeader from '../components/CaseHeader';
import { StepWrapper } from '../components/StepWrapper';
import Voting from '../sounding/VotingContainer';
import VotingListContainer from '../voting/VotingListContainer';
import { getOccupantsVotes } from './../../helpers/api/case';
import CasePreviewComponent from './CasePreviewComponent';
import ArchiveToggler from './components/ArchiveToggler';
import { CasePreviewWrapper } from './components/CasePreviewWrapper';
import PreviewCompanies from './components/PreviewCompanies';
import PreviewCosts from './components/PreviewCosts';
import PreviewDescription from './components/PreviewDescription';
import PreviewHeader from './components/PreviewHeader';
import PreviewInfo from './components/PreviewInfo';
import PreviewMedia from './components/PreviewMedia';
import PreviewMeeting from './components/PreviewMeeting';
import PreviewPdfFile from './components/PreviewPdfFile';
import PreviewPlace from './components/PreviewPlace';
import PreviewSection from './components/PreviewSection';
import ResolutionStatus from './components/ResolutionStatus';
import ResolutionTimer from './components/ResolutionTimer';
import ShareSection from './components/ShareSection';
import UntagUser from './components/UntagUser';

export const ResolutionContext = React.createContext(false);
class CasePreviewContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      casePreview: {},
      isLoading: true,
      error: null,
      taggedCompanies: [],
      votesList: [],
      userVote: 'loading',
    };
    this.shareRef = React.createRef();
  }

  componentDidMount() {
    this.getCasePreview();
  }

  getCasePreview = () => {
    const { resources } = this.props;
    this.setState({ isLoading: true });
    const caseId = this.getKey();
    const casePreview = fetchCasePreview(caseId);
    const taggedCompany = fetchTaggedCompany(caseId);

    Promise.all([casePreview, taggedCompany])
      .then(values => {
        const caseData = values[0].data;
        const taggedData = values[1].data;
        const newResource = resources.find(item => item.community_id === caseData.community.id).id;
        if (caseData) {
          this.props.setCurrentResource(newResource);
          this.setState({
            casePreview: caseData,
            taggedCompanies: taggedData,
          });

          if (caseData.resolution) {
            fetchUserVote(caseId)
              .then(value =>
                this.setState({
                  userVote: Number(value.data.vote),
                })
              )
              .catch(error => this.setState({ error }));

            if (caseData.resolution.archived) {
              fetchResolutionReport(caseId)
                .then(value =>
                  this.setState({
                    votesList: value.data.voting_list,
                    casePreview: {
                      ...caseData,
                      ...{ resolution: value.data.resolution },
                    },
                    isLoading: false,
                  })
                )
                .catch(error => this.setState({ error, isLoading: false }));
            } else {
              getOccupantsVotes(caseId)
                .then(value =>
                  this.setState({
                    votesList: value.data,
                    isLoading: false,
                  })
                )
                .catch(error => this.setState({ error, isLoading: false }));
            }
          } else {
            this.setState({ isLoading: false, userVote: caseData.user_vote });
          }
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  getKey = () => Number(this.props.match.params.id);

  getActivitiesStatus() {
    const { casePreview } = this.state;
    let status = 'checked';
    if (casePreview.comments_count === 0) {
      status = 'empty';
    } else if (casePreview.new_activity) {
      status = 'new';
    }
    return status;
  }

  openSoundingPlug = () => this.props.history.push('/funkcje/sounding');

  deleteUserTag = () => untagUser(this.getKey()).then(() => this.props.history.push('/'));

  scrollToShareSection = () => this.shareRef.current.scrollIntoView({ behavior: 'smooth' });

  renderCasePreview() {
    const { taggedCompanies, votesList, isLoading, casePreview: preview, userVote } = this.state;
    const { canArchive } = this.props;
    const resolution = preview.resolution;
    const meeting = preview.meeting_data;
    const created = daysPassed(preview.created_at);
    const createdAt = formatDateTime(preview.created_at);
    const areAttachments = preview.attachments.length > 0;
    const caseId = this.getKey();
    const mentioned = preview.mentioned;
    const isResolution = resolution ? true : false;
    const isResolutionFile = resolution && resolution.file;
    const isResolutionInProgress = resolution && preview.status === 'resolution_in_progress';
    const isResolutionArchived = resolution && preview.status === 'archived';
    const isVoteList = votesList.length > 0;
    const votingLasts = getDistanceWithMilliseconds(preview.created_at);
    const isPublic = preview.range_slug === 'public';
    const isArchived = preview.status === 'archived';
    const { Provider } = ResolutionContext;

    return (
      <Provider value={isResolution}>
        <CasePreviewComponent isStickyLabel={isResolution}>
          <CasePreviewWrapper withLine>
            {/* TODO: Jak back-end będzie zrobiony, to obsłużyć atrybut
            'anonymous' */}
            <PreviewInfo
              created={created}
              created_at={createdAt}
              resolution={resolution}
              owner={
                preview.owner.display_name === 'anonymous'
                  ? userLabels[userRoles.ROLE_ANONYMOUS]
                  : preview.owner.display_name
              }
              userId={preview.owner.id}
              userRole={
                preview.owner.display_name === userLabels[userRoles.ROLE_ANONYMOUS]
                  ? userRoles.ROLE_ANONYMOUS
                  : preview.owner.role
              }
              avatarPath={preview.owner.avatar && preview.owner.avatar.path}
              number={preview.case_number}
              range={preview.range}
              rangeSlug={preview.range_slug}
              rangeClickHandler={isPublic ? this.scrollToShareSection : null}
              showTooltip
              showUserDetails
              actions={[
                {
                  component: (
                    <ArchiveToggler caseId={caseId} currentStatus={preview.status} callback={this.getCasePreview} />
                  ),
                  active: canArchive && !isResolutionInProgress && !isResolutionArchived,
                },
                {
                  component: <UntagUser deleteUserTag={this.deleteUserTag} />,
                  active: mentioned,
                },
              ]}
            />
            {resolution && <ResolutionStatus status={preview.status} invalidateDesc={preview.invalidate_description} />}
            {isResolutionInProgress && <ResolutionTimer votingLasts={votingLasts} />}
          </CasePreviewWrapper>
          {taggedCompanies && <PreviewCompanies taggedCompanies={taggedCompanies} />}
          <CasePreviewWrapper>
            <PreviewHeader topic={preview.topic} />
            {isResolutionFile && <PreviewPdfFile path={resolution.file.path} needAuth={true} />}
            {preview.place && <PreviewPlace place={preview.place} />}
            {preview.media.length > 0 && <PreviewMedia media={preview.media} meterValue={preview.meter_value} />}
            {meeting.length !== 0 && <PreviewMeeting meeting={meeting} />}
            <PreviewDescription
              description={preview.stepTopic && preview.stepTopic.description}
              resolutionContent={resolution && resolution.content}
            />
            {areAttachments && (
              <PreviewSection>
                <AttachmentsPreview attachments={preview.attachments} isLabel />
              </PreviewSection>
            )}
            <PreviewCosts
              communityCost={preview.community_cost}
              userCost={preview.user_cost}
              costDescription={preview.cost_description}
              caseId={caseId}
              editable={!isArchived}
            />
            {isPublic && (
              <div ref={this.shareRef}>
                <ShareSection />
              </div>
            )}
            {(preview.sounding_question || resolution) &&
              userVote !== 'loading' && (
                <Voting
                  caseId={caseId}
                  question={preview.sounding_question}
                  userVote={userVote}
                  votedBy={preview.voted_by}
                  isVotingClosed={!isResolutionInProgress}
                  soundingResults={resolution ? resolution.results : preview.sounding}
                  openSoundingPlug={this.openSoundingPlug}
                  resolution={resolution}
                  caseStatus={preview.status}
                  totalApartments={resolution ? resolution.total_apartments : preview.sounding.total_apartments}
                  votedApartments={resolution ? resolution.voted_apartments : null}
                  showResults
                  caseArchived={isArchived}
                  getCasePreview={this.getCasePreview}
                />
              )}
          </CasePreviewWrapper>
          {isVoteList && (
            <CasePreviewWrapper>
              <VotingListContainer
                votesList={votesList}
                caseId={caseId}
                resolutionTitle={resolution.title}
                resolutionOpened={isResolutionInProgress}
                getCasePreview={this.getCasePreview}
                isLoading={isLoading}
              />
            </CasePreviewWrapper>
          )}
          <CasePreviewWrapper>
            <ActivitiesSummary usersCount={preview.total_commenters} commentsCount={preview.comments_count} />
            <ActivitiesStatus status={this.getActivitiesStatus()} newActivitiesCount={preview.new_activities_count} />
            <Activities canAddComment={!isArchived} communityCase={caseId} />
          </CasePreviewWrapper>
        </CasePreviewComponent>
      </Provider>
    );
  }

  render() {
    const { isLoading, error, casePreview } = this.state;
    const { history, location } = this.props;

    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <StepWrapper>
        <CaseHeader
          goBackHandler={location.state && location.state.goBack ? history.goBack : () => history.push('/')}
          categoryIcon={casePreview.icons.category ? casePreview.icons.category.path : ''}
          rangeIcon={casePreview.icons.range ? casePreview.icons.range.path : ''}
          categoryColor={casePreview.main_category_color}
        >
          {casePreview.category_name}
        </CaseHeader>
        {this.renderCasePreview()}
      </StepWrapper>
    );
  }
}

const mapStateToProps = state => ({
  canArchive: state.user.accessControlList.data ? state.user.accessControlList.data.user.case_archive : false,
  resources: state.user.profile.data && state.user.profile.data.resources,
});
const mapDispatchToProps = dispatch => ({
  setCurrentResource: resourceId => dispatch(setCurrentResource(resourceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasePreviewContainer);
