import { getAuthHeader, handleResponse } from '../request';

const apiUrl = process.env.REACT_APP_API_URL;

export const addFirebaseDevice = (token, device) => {
  const requestOptions = {
    method: 'POST',
    headers: getAuthHeader(),
    body: JSON.stringify({
      token: token,
      device: device
    })
  };
  return fetch(`${apiUrl}/firebase/device`, requestOptions).then(
    handleResponse
  );
};

export const removeFirebaseDevice = device => {
  const requestOptions = {
    method: 'DELETE',
    headers: getAuthHeader()
  };
  return fetch(`${apiUrl}/firebase/device?name=${device}`, requestOptions).then(
    response => handleResponse(response)
  );
};
