import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { Text } from '../../../../../layout/theme/components';

import Cancel from './../../../../../../assets/tutorial/cancelTutorial';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ButtonIcon = styled.button`
  background: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5em;

  svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.buttonColors.primary};
  }
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.secondary};
`;

const SliderHeader = ({ attachments, activeSlide, exitOnClick }) => (
  <Header>
    <StyledText small>
      {`${attachments[activeSlide].name} (${activeSlide + 1} z ${
        attachments.length
      })`}
    </StyledText>
    <ButtonIcon onClick={exitOnClick}>
      <Cancel />
    </ButtonIcon>
  </Header>
);

SliderHeader.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      mime_type: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  activeSlide: PropTypes.number,
  exitOnClick: PropTypes.func
};

SliderHeader.defaultProps = {
  activeSlide: 0,
  exitOnClick: null
};

export default SliderHeader;
