import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { closeVoting, invalidateVoting } from '../../helpers/api/case';
import { INVALID_INPUT_TEXT } from '../../helpers/constants';
import { formatVote } from '../../helpers/tools';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import DenyBubble from '../../shared/components/DenyBubble';
import DropdownList from '../../shared/components/DropdownList';
import Textarea from '../../shared/components/forms/Textarea';
import FullModalContainer from '../../shared/components/FullModalContainer';
import Modal from '../../shared/components/Modal';
import Table from '../../shared/components/Table';
import VotingContainer from '../sounding/VotingContainer';
import ButtonsWrapper from './components/ButtonsWrapper';
import ClosingModalContent from './components/ClosingModalContent';
import ErrorComponent from './components/ErrorComponent';
import VoteStatus from './components/VoteStatus';

const VotingListContainer = ({
  caseId,
  votesList,
  resolutionOpened,
  canVoteByUser,
  canCancelResolution,
  canCloseResolution,
  getCasePreview,
  resolutionTitle,
}) => {
  const [openVotingModal, votingModalToggle] = useState(false);
  const [showClosingModal, closingModalToggle] = useState(false);
  const [showCancelModal, cancelModalToggle] = useState(false);
  const [cancelText, setCancelText] = useState('');
  const [user, setUser] = useState('');
  const [userApartment, setUserApartment] = useState('');
  const [error, setError] = useState(null);

  const toggleVotingModal = (userId, userApartment) => {
    userId && setUser(userId);
    userApartment && setUserApartment(userApartment);

    votingModalToggle(!openVotingModal);
    if (userId === '') getCasePreview();
  };
  const toggleClosingModal = () => closingModalToggle(!showClosingModal);

  const closingVoting = () => {
    closeVoting(caseId)
      .then(() => closingModalToggle(false))
      .then(() => getCasePreview())
      .catch(error => setError(error));
  };

  const toggleCancelModal = () => {
    cancelModalToggle(!showCancelModal);
    setCancelText('');
    setError(null);
  };
  const handleCancelText = value => {
    setCancelText(value);
    setError(null);
  };

  const cancelVoting = () => {
    invalidateVoting(caseId, cancelText)
      .then(() => closingModalToggle(false))
      .then(() => getCasePreview())
      .catch(error => setError(error));
  };

  const canVoteForUser = (vote, keeperVote) => {
    const canVote = canVoteByUser && resolutionOpened;
    const canChangeVote = vote === 0 ? true : keeperVote;

    return canVote && canChangeVote;
  };

  const columns = [
    { id: 'apartment', label: 'Lokal' },
    { id: 'name', label: 'Imię, nazwisko' },
    { id: 'share', label: 'Udział', tooltip: 'Udział we wspólnocie' },
    { id: 'vote', label: 'Głos' },
  ];
  const createRow = item => {
    const multiShares = item.shares.length > 1 ? true : false;

    if (multiShares) {
      return {
        apartment: <b>{item.display_name}</b>,
        name: '',
        share: <b>{formatVote(item.apartment_share)}</b>,
        subrows: item.shares.map((share, index) => {
          const owners = share.users.map(
            user => user.id !== null && <div key={`share-${index}`}>{user.display_name}</div>
          );
          return {
            apartment: `${share.apartment_share} w lokalu`,
            name: owners,
            share: formatVote(share.share),
            vote: (
              <VoteStatus
                vote={share.vote}
                votedBy={share.voted_by}
                onClick={() => toggleVotingModal(share.users[0].id, item.display_name)}
                canVoteByUser={canVoteForUser(share.vote, share.keeper_vote) && share.users[0].id !== null}
              />
            ),
          };
        }),
      };
    } else {
      const rowData = item.shares[0];
      const name = rowData
        ? rowData.users.map(user => user.id !== null && <div key={user.id}>{user.display_name}</div>)
        : '';
      return {
        apartment: <b>{item.display_name}</b>,
        name: name,
        share: <b>{rowData ? formatVote(rowData.share) : formatVote(item.apartment_share)}</b>,
        vote: rowData && (
          <VoteStatus
            vote={rowData.vote}
            votedBy={rowData.voted_by}
            onClick={() => toggleVotingModal(rowData.users[0].id, item.display_name)}
            canVoteByUser={canVoteForUser(rowData.vote, rowData.keeper_vote) && rowData.users[0].id !== null}
          />
        ),
      };
    }
  };

  return (
    <>
      <DropdownList header={'Lista głosowania'} open>
        <Table columns={columns} rows={votesList.map(item => createRow(item))} />
      </DropdownList>
      {resolutionOpened && (
        <ButtonsWrapper>
          {canCloseResolution && <PrimaryButton onClick={toggleClosingModal}>ZAMKNIJ GŁOSOWANIE</PrimaryButton>}
          {canCancelResolution && <TextButton onClick={toggleCancelModal}>UNIEWAŻNIJ UCHWAŁĘ</TextButton>}
        </ButtonsWrapper>
      )}
      {openVotingModal && (
        <Modal closeModal={() => toggleVotingModal()}>
          <VotingContainer
            caseId={caseId}
            headerTitle={userApartment}
            userId={user}
            voteForUserCallback={() => toggleVotingModal('', '')}
            resolution
            inModal
          />
          {error && <ErrorComponent errorStatus={error.status} />}
        </Modal>
      )}
      {showClosingModal && (
        <Modal closeModal={toggleClosingModal}>
          <DenyBubble
            leftButton="ANULUJ"
            leftClick={toggleClosingModal}
            rightButton="ZATWIERDŹ"
            rightClick={() => closingVoting()}
          >
            <ClosingModalContent />
          </DenyBubble>
          {error && <ErrorComponent errorStatus={error.status} />}
        </Modal>
      )}
      {showCancelModal && (
        <Modal closeModal={toggleCancelModal} isWide>
          <FullModalContainer
            onClick={value => cancelVoting(value)}
            buttonText={'UNIEWAŻNIJ'}
            header={'Unieważnienie uchwały'}
            caption={`Tytuł: ${resolutionTitle}`}
          >
            <Textarea
              placeholder="Wpisz powód unieważnienia"
              value={cancelText}
              changeHandler={value => handleCancelText(value)}
              invalidText={INVALID_INPUT_TEXT}
            />
            {error && <ErrorComponent errorStatus={error.status} />}
          </FullModalContainer>
        </Modal>
      )}
    </>
  );
};

VotingListContainer.propTypes = {
  resolutionOpened: PropTypes.bool.isRequired,
  canCloseResolution: PropTypes.bool,
  canCancelResolution: PropTypes.bool,
  votesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
      voted: PropTypes.number,
    })
  ),
  resolutionTitle: PropTypes.string,
};
VotingListContainer.defaultProps = {
  votesList: null,
  resolutionTitle: '',
};

const mapStateToProps = state => ({
  canVoteByUser: state.user.accessControlList.data ? state.user.accessControlList.data.user.voting_by_user : false,
  canCancelResolution: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.resolution_cancel
    : false,
  canCloseResolution: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.resolution_closing
    : false,
});

export default connect(mapStateToProps)(VotingListContainer);
