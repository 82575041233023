import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React from 'react';
import styled from 'styled-components/macro';
import { isMobile } from '../../helpers/tools';

const TooltipContent = styled.div`
  max-width: 300px;
`;

const TooltipWrapper = ({ children, tooltip, position, displayOnMobile }) => {
  const displayTooltip = !isMobile() || (isMobile() && displayOnMobile);
  return tooltip && displayTooltip ? (
    <Tooltip
      placement={position}
      trigger={'hover'}
      overlay={tooltip ? <TooltipContent>{tooltip}</TooltipContent> : null}
    >
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.string,
  displayOnMobile: PropTypes.bool,
};

TooltipWrapper.defaultProps = {
  tooltip: null,
  position: 'top',
  displayOnMobile: true,
};

export default TooltipWrapper;
