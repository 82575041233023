import { getRequestHeaders, handleResponse } from '../request';
const apiUrl = process.env.REACT_APP_API_URL;

/**
 * It sends media as FormData to server
 * @param {File} attachment
 * @return {Promise<Response | never>}
 * @todo multiple attachment support
 */
export const postMedia = attachment => {
  const body = new FormData();
  body.append('create_file[file]', attachment);
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body,
  };
  return fetch(`${apiUrl}/media/upload`, requestOptions).then(handleResponse);
};

export const postMediaBase64 = attachment => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      insert_file: {
        file: attachment,
      },
    }),
  };
  return fetch(`${apiUrl}/media/insert`, requestOptions).then(handleResponse);
};

export const fetchFile = fileId => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/${fileId}/pdf`, requestOptions).then(response => {
    return response.blob().then(blob => {
      return blob;
    });
  });
};
