import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import LogoImg from '../../../assets/logo.js';

const LogoWrapper = styled.div`
  width: ${({ width }) => width};
  max-width: 300px;
`;

const Logo = memo(({ width }) => (
  <LogoWrapper width={width}>
    <LogoImg />
  </LogoWrapper>
));

Logo.propTypes = {
  width: PropTypes.string
};
Logo.defaultProps = {
  width: '100%'
};

export default Logo;
