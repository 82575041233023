import { combineReducers } from 'redux';

import * as CaseActions from './actionTypes';

const activeFilter = (state = 'allCases', action) => {
  switch (action.type) {
    case CaseActions.SET_ACTIVE_FILTER:
      return action.filter;
    default:
      return state;
  }
};

const query = (state = { query: '', shouldClear: false }, action) => {
  switch (action.type) {
    case CaseActions.SEARCHED_QUERY:
      return {
        query: action.value
      };
    case CaseActions.CLEAR_QUERY:
      return {
        shouldClear: action.bool
      };
    default:
      return state;
  }
};

const windowYOffset = (state = 0, action) => {
  switch (action.type) {
    case CaseActions.SET_OFFSET:
      return action.value;
    default:
      return state;
  }
};

const unvotedResolutionsNumber = (
  state = { data: null, isLoading: true },
  action
) => {
  switch (action.type) {
    case CaseActions.SET_UNVOTED_NUMBER_STARTED:
      return { data: null, isLoading: true };
    case CaseActions.SET_UNVOTED_NUMBER_SUCCEED:
      return { data: action.value, isLoading: false };
    default:
      return state;
  }
};

export const cases = combineReducers({
  activeFilter,
  query,
  windowYOffset,
  unvotedResolutionsNumber
});
