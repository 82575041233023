import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Container from './components/Container';
import Logo from './components/Logo';
import { H0 } from '../layout/theme/components';
import TextButton from './components/buttons/TextButton';
import SentOpinion from '../../assets/sentOpinion';

const StyledH0 = styled(H0)`
  margin: 3% 0 2%;
`;
const BoxPicture = styled.div`
  max-height: 35vh;
  height: 100%;
  margin: 5% 0;

  svg {
    height: 100%;
  }
`;
const StyledLink = styled(Link)`
  margin: 1em 0;
`;

const SentPageUnregistered = props => {
  const { header, children } = props;
  return (
    <Container wide>
      <Logo />
      <StyledH0>{header}</StyledH0>
      <BoxPicture>
        <SentOpinion />
      </BoxPicture>
      {children}
      <StyledLink to="/logowanie">
        <TextButton>Wróć do STRONY LOGOWANIA</TextButton>
      </StyledLink>
    </Container>
  );
};

SentPageUnregistered.propTypes = {
  header: PropTypes.string
};

SentPageUnregistered.defaultProps = {
  header: 'Zgłoszenie przyjęte!'
};

export default SentPageUnregistered;
