import React from 'react';

const basicActiveIcon = () => {
  return (
    <svg
      viewBox="0 0 195 105"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h195v105H0z" />
      <clipPath id="a">
        <path serifid="basic" d="M0 0h195v105H0z" />
      </clipPath>
      <g>
        <path fill="none" d="M0 0h195v104.844H0z" />
        <path fill="#ae9059" d="M154.763 90.501h2.289v7.846h-2.289z" />
        <path
          d="M38.337 68.451l7.776-6.847v4.975l-7.776 1.872z"
          fill="#ae9059"
          fillRule="nonzero"
        />
        <path
          d="M126.817 61.531v30.034H46.115V61.531l40.35-35.77 40.352 35.77z"
          fill="#fef4e1"
          fillRule="nonzero"
        />
        <path
          d="M86.465 25.761l-19.88 65.805h-20.47V61.532l40.35-35.771z"
          fill="#fdebc8"
          fillRule="nonzero"
        />
        <path
          d="M94.537 70.473c.211-4.279 3.788-7.684 8.072-7.684 4.284 0 7.862 3.405 8.073 7.684h.01v17.909H94.527V70.473h.01z"
          fill="#514b81"
          fillRule="nonzero"
        />
        <path
          d="M106.297 75.584c.76 0 1.383.624 1.384 1.384a1.392 1.392 0 0 1-1.384 1.384 1.392 1.392 0 0 1-1.384-1.384 1.39 1.39 0 0 1 1.384-1.384z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path fill="#fbcc6b" d="M61.677 59.563h21.93v22.662h-21.93z" />
        <path
          d="M85.282 81.219a.337.337 0 0 0-.336-.335H59.664a.337.337 0 0 0-.336.335v.67c0 .185.152.336.336.336h25.282a.337.337 0 0 0 .336-.336v-.67zM85.847 62.28H59.434l2.515-4.811h21.38l2.518 4.811z"
          fill="#514b81"
          fillRule="nonzero"
        />
        <path fill="#fff" d="M71.608 62.281h1.609v18.603h-1.609z" />
        <path fill="#fff" d="M61.677 70.778h21.93v1.609h-21.93z" />
        <path
          d="M85.139 22.398h6.213L38.417 68.451h-6.014l52.736-46.053z"
          fill="#fbcc6b"
          fillRule="nonzero"
        />
        <path fill="#fdebc8" d="M124.43 63.675h25.892v25.193H124.43z" />
        <path
          d="M110.873 22.398H85.1l.316 2.739 43.636 43.315h28.104l-46.283-46.054z"
          fill="#fbcc6b"
          fillRule="nonzero"
        />
        <path fill="#f9d28a" d="M124.43 88.154h25.892v8.622H124.43z" />
        <path fill="#fcdea6" d="M46.115 88.118h78.315v8.658H46.115z" />
        <path fill="#998153" d="M119.509 33.099h7.847v12.326h-7.847z" />
        <path
          d="M124.57 33.1h-5.061v12.325L124.57 33.1z"
          fill="#ae9059"
          fillRule="nonzero"
        />
        <path
          d="M156.066 43.993s-11.28 26.345-11.28 37.634c.001 6.188 5.092 11.279 11.28 11.283 6.188-.004 11.279-5.095 11.28-11.283 0-11.289-11.28-37.634-11.28-37.634z"
          fill="#56dabd"
          fillRule="nonzero"
        />
        <path
          d="M156.066 43.993V92.91s11.02.162 11.28-11.281c.258-11.288-11.28-37.636-11.28-37.636z"
          fill="#7de2cc"
          fillRule="nonzero"
        />
        <path
          d="M195 98.098a2.33 2.33 0 0 0-2.381-2.248H2.381A2.33 2.33 0 0 0 0 98.098v4.5a2.33 2.33 0 0 0 2.381 2.245h190.238a2.33 2.33 0 0 0 2.381-2.248v-4.497z"
          fill="#59e9c9"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default basicActiveIcon;
