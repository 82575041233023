import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import plusIcon from './../../../assets/plusIcon.svg';

const CategoryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 12vh;
  width: 100%;
  cursor: pointer;
  padding: 0 1em;
  border-radius: 10px;
  margin: 0.5em 0;
  background: ${({ background }) => (background ? background : '#8e8e8e')};
  &:first-of-type {
    margin: 0 0 0.5em;
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex: none;
    width: 32%;
    margin: 0.5em;
    padding: 1em;
    min-height: 35%;
    &:first-of-type {
      margin: 0.5em;
    }
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 40%;
  height: 100%;
  overflow: hidden;
  img {
    height: 80%;
    max-height: 10vh;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-family: 'Montserrat';
  font-size: 1.4em;
  line-height: 125%;
  width: 60%;
  text-align: right;
  color: #ffffff;
`;
const PlusIconWrapper = styled.div`
  width: 5%;
  margin-left: 5%;
  img {
    width: 100%;
  }
`;

const CategoryItem = memo(({ title, iconPath, background, onClick }) => (
  <CategoryWrapper background={background} onClick={onClick}>
    <ImageWrapper>
      <img alt="" src={iconPath} />
    </ImageWrapper>
    <Title>{title}</Title>
    <PlusIconWrapper>
      <img alt="plusIcon" src={plusIcon} />
    </PlusIconWrapper>
  </CategoryWrapper>
));

CategoryItem.propTypes = {
  title: PropTypes.string.isRequired,
  iconPath: PropTypes.string,
  background: PropTypes.string
};

CategoryItem.defaultProps = {
  iconPath: null,
  background: '#8e8e8e'
};

export default CategoryItem;
