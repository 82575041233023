import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../layout/theme/components';

const ContactNumber = styled.div`
  width: 100%;
  padding: 5% 0;
`;
const StyledText = styled(Text)`
  color: ${({ theme, global }) =>
    global ? theme.textColors.quaternary : theme.textColors.secondary};
`;

const Links = styled.div`
  line-height: 1.5em;
  margin-top: 1em;
  color: ${({ theme }) => theme.textColors.primary};
`;
const Link = styled.a`
  display: block;
`;

const Contact = ({
  responsibility,
  firstName,
  lastName,
  phone,
  email,
  global
}) => {
  return (
    <ContactNumber>
      <StyledText global={global} bold>
        {responsibility}
      </StyledText>
      <Links>
        <Text bold>
          {firstName} {lastName}
        </Text>
        <Text>
          {phone && <Link href={'tel:' + phone}>{phone}</Link>}
          {email && <Link href={'mailto:' + email}>{email}</Link>}
        </Text>
      </Links>
    </ContactNumber>
  );
};

Contact.propTypes = {
  responsibility: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  global: PropTypes.bool
};
Contact.defaultProps = {
  global: false
};
export default Contact;
