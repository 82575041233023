import React from 'react';

const externalLinkIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.25"
    height="9.643"
    viewBox="0 0 11.25 9.643"
  >
    <defs />
    <g transform="translate(0 -36.547)">
      <g transform="translate(0 36.547)">
        <path
          d="M8.638,77.911h-.4a.194.194,0,0,0-.2.2v2.009a1.007,1.007,0,0,1-1,1H1.808a.967.967,0,0,1-.709-.295.967.967,0,0,1-.3-.709V74.9a.967.967,0,0,1,.3-.709.967.967,0,0,1,.709-.3h4.42a.193.193,0,0,0,.2-.2v-.4a.193.193,0,0,0-.2-.2H1.808a1.742,1.742,0,0,0-1.278.53A1.742,1.742,0,0,0,0,74.9v5.223A1.741,1.741,0,0,0,.53,81.4a1.742,1.742,0,0,0,1.278.531H7.031a1.811,1.811,0,0,0,1.808-1.808V78.112a.194.194,0,0,0-.2-.2Z"
          transform="translate(0 -72.286)"
          fill={fill}
        />
        <path
          d="M202.4,36.666a.386.386,0,0,0-.283-.119H198.9a.4.4,0,0,0-.282.684l1.1,1.1-4.093,4.093a.2.2,0,0,0,0,.289l.716.716a.2.2,0,0,0,.289,0l4.093-4.093,1.1,1.1a.4.4,0,0,0,.684-.282V36.949A.386.386,0,0,0,202.4,36.666Z"
          transform="translate(-191.268 -36.547)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default externalLinkIcon;
