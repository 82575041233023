import React from 'react';

const checkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.779 12.02">
    <path
      fill="#7fd997"
      d="M5.768 11.91l-.111.111L0 6.364l2.121-2.121 3.647 3.646L13.657 0l2.121 2.121-9.9 9.9z"
    />
  </svg>
);

export default checkIcon;
