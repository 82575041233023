import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { userRoles } from '../../../helpers/constants';
import DropdownList from '../../../shared/components/DropdownList';
import UserLabel from '../../../shared/components/UserLabel';
import PreviewSection from './PreviewSection';

const CompanyBox = styled.div`
  margin: 0 1em 1em 0;
  &:last-child {
    margin: 0;
  }
`;

const Companies = ({ taggedCompanies }) => {
  return taggedCompanies.map(company => (
    <CompanyBox key={company.id}>
      <UserLabel
        userRole={userRoles.ROLE_COMPANY}
        userName={company.company_name}
        avatarPath={company.avatar ? company.avatar.path : null}
        showTooltip
      />
    </CompanyBox>
  ));
};

Companies.propTypes = {
  taggedCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company_name: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.object)
    })
  )
};

Companies.defaultProps = {
  taggedCompanies: null
};

const PreviewCompanies = ({ taggedCompanies }) => (
  <PreviewSection>
    <DropdownList header={`Firmy (${taggedCompanies.length})`}>
      <Companies taggedCompanies={taggedCompanies} />
    </DropdownList>
  </PreviewSection>
);

PreviewCompanies.propTypes = {
  taggedCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company_name: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.object)
    })
  )
};

PreviewCompanies.defaultProps = {
  taggedCompanies: null
};

export default PreviewCompanies;
