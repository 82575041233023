import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import KeeperIcon from '../../../../../assets/keeperIcon';
import AdminIcon from '../../../../../assets/adminIcon';
import CompanyIcon from '../../../../../assets/companyIcon';

import { userRoles } from '../../../../helpers/constants';

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.4em;
  bottom: 0;
  width: 1.1em;
  height: 1.1em;
  border-radius: 3px;
  background: ${({ userRole, theme }) => {
    switch (userRole) {
      case userRoles.ROLE_WARDEN: {
        return theme.userColors.warden;
      }
      case userRoles.ROLE_KEEPER: {
        return theme.userColors.keeper;
      }
      case userRoles.ROLE_COMPANY: {
        return theme.userColors.company;
      }
      default: {
        return 'none';
      }
    }
  }};
`;

const AvatarBadge = ({ userRole }) => {
  const renderIcon = () => {
    switch (userRole) {
      case userRoles.ROLE_KEEPER: {
        return <KeeperIcon />;
      }
      case userRoles.ROLE_WARDEN: {
        return <AdminIcon />;
      }
      case userRoles.ROLE_COMPANY: {
        return <CompanyIcon />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <IconWrapper userRole={userRole === userRoles.ROLE_USER ? null : userRole}>
      {renderIcon()}
    </IconWrapper>
  );
};

AvatarBadge.propTypes = {
  userRole: PropTypes.string
};

AvatarBadge.defaultProps = {
  userRole: null
};

export default AvatarBadge;
