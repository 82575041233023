import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Caption } from '../../../layout/theme/components';
import PopupMenu from '../../../shared/components/PopupMenu';
import RangeLabel from '../../../shared/components/RangeLabel';
import UserLabel from '../../../shared/components/UserLabel';
import PreviewSection from './PreviewSection';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PreviewInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.2;
  color: ${({ theme }) => theme.textColors.tertiary};
  background: ${({ theme }) => theme.backgroundColors.primary};
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  text-align: left;
`;
const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 30%;
  text-align: right;
`;
const BoxLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
`;
const LabelWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.buttonColors.inactive};
  padding-bottom: 1em;
  color: ${({ theme }) => theme.textColors.tertiary};
  font-size: 1.5em;
  width: 100%;
  text-align: center;
`;

const PreviewInfo = ({
  created,
  created_at,
  owner,
  userRole,
  userId,
  avatarPath,
  number,
  range,
  rangeSlug,
  rangeClickHandler,
  previewMark,
  showTooltip,
  showUserDetails,
  actions,
}) => (
  <>
    {previewMark && <LabelWrapper>Podgląd sprawy</LabelWrapper>}
    <PreviewSection>
      <Wrapper>
        <RangeLabel label={range} slug={rangeSlug} onClick={rangeClickHandler} />
        {actions && <PopupMenu actions={actions} />}
      </Wrapper>
      <PreviewInfoWrapper>
        <LeftSide>
          <Caption>
            dodano {created} temu ({created_at})
          </Caption>
          <BoxLabel>
            <UserLabel
              userName={owner}
              userRole={userRole}
              userId={userId}
              avatarPath={avatarPath}
              showTooltip={showTooltip}
              showUserDetails={showUserDetails}
            />
          </BoxLabel>
        </LeftSide>
        <RightSide>
          <Caption>
            Nr sprawy
            <br />
            {number}
          </Caption>
        </RightSide>
      </PreviewInfoWrapper>
    </PreviewSection>
  </>
);

PreviewInfo.propTypes = {
  created: PropTypes.string,
  created_at: PropTypes.string,
  owner: PropTypes.string,
  userRole: PropTypes.string,
  userId: PropTypes.number,
  avatarPath: PropTypes.string,
  number: PropTypes.string,
  range: PropTypes.string,
  rangeSlug: PropTypes.string,
  rangeClickHandler: PropTypes.func,
  previewMark: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showUserDetails: PropTypes.bool,
  actions: PropTypes.array,
};

PreviewInfo.defaultProps = {
  created: '',
  created_at: '',
  owner: '',
  userRole: '',
  userId: null,
  avatarPath: '',
  number: '',
  range: '',
  rangeSlug: '',
  rangeClickHandler: null,
  previewMark: false,
  showTooltip: false,
  showUserDetails: false,
  actions: null,
};

export default PreviewInfo;
