import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BasicInactiveIcon from '../../../../assets/features/basicInactiveIcon';
import PremiumInactiveIcon from '../../../../assets/features/premiumInactiveIcon';

const LevelThumbnail = ({ level }) => {
  const premium = level === 'premium';
  const basic = level === 'basic';

  return (
    <Fragment>
      {premium && <PremiumInactiveIcon />}
      {basic && <BasicInactiveIcon />}
    </Fragment>
  );
};

LevelThumbnail.propTypes = {
  level: PropTypes.string.isRequired
};

export default LevelThumbnail;
