import React from 'react';

function ArchiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.867 16.867"
      width="16.867"
      height="16.867"
    >
      <path
        fill="#675e97"
        d="M14.435 3.675l-.977-1.18a1.02 1.02 0 00-.808-.387H4.216a1.044 1.044 0 00-.815.387l-.97 1.181a1.376 1.376 0 00-.323.892v8.785a1.41 1.41 0 001.406 1.405h9.839a1.41 1.41 0 001.406-1.406V4.568a1.376 1.376 0 00-.324-.893zm-6 8.623L4.568 8.433h2.46V7.027h2.811v1.406h2.46zM3.598 3.514l.569-.7H12.6l.661.7z"
      />
      <path fill="none" d="M0 0h16.867v16.867H0z" />
    </svg>
  );
}

export default ArchiveIcon;
