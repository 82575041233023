import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { H0, Text } from '../../layout/theme/components';
import ArrowIcon from '../../shared/components/ArrowIcon';
import TextButton from '../../shared/components/buttons/TextButton';
import Container from '../../shared/components/Container';
import TrialButton from './components/TrialButton';

const Annotation = styled(Text)`
  color: ${({ theme }) => theme.textColors.tertiary};
  margin: 0 auto 5%;
`;
const PictureBox = styled.div`
  width: ${({ largeImage }) => (largeImage ? '70%' : '50%')};
  svg {
    max-width: 250px;
    width: 100%;
  }
`;
const MainHeader = styled(H0)`
  color: ${({ theme }) => theme.textColors.quaternary};
  margin: 10% auto 5%;
`;
const Description = styled.div`
  width: 100%;
  text-align: center;
  word-break: break-word;
  margin-bottom: 5%;
`;
const BasicPremiumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10%;
`;
const DescriptionText = styled(Text)`
  margin-top: 5%;
  color: ${({ theme }) => theme.backgroundColors.primary};

  a {
    text-decoration: underline;
  }
`;
const AdditionalDescriptionText = styled(DescriptionText)`
  margin-top: 0;
  color: ${({ theme }) => theme.textColors.tertiary};
`;

const FeatureComponent = ({ image, largeImage, header, description, additionalDescription, showBasicPremium }) => {
  return (
    <Container premiumColor>
      <Link to="/">
        <ArrowIcon />
      </Link>
      {showBasicPremium && (
        <BasicPremiumWrapper>
          <Annotation>Niedostępne w Basic</Annotation>
          <TrialButton showInfo plug />
        </BasicPremiumWrapper>
      )}
      <PictureBox largeImage={largeImage}>{image}</PictureBox>
      <Description>
        <MainHeader>{header}</MainHeader>
        <DescriptionText>{description}</DescriptionText>
      </Description>
      {additionalDescription && (
        <Description>
          <AdditionalDescriptionText>{additionalDescription}</AdditionalDescriptionText>
        </Description>
      )}
      {showBasicPremium && (
        <Link to="/poziomy/premium">
          <TextButton invert>CO JESZCZE ZYSKACIE?</TextButton>
        </Link>
      )}
    </Container>
  );
};

FeatureComponent.propTypes = {
  image: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  additionalDescription: PropTypes.string,
  showBasicPremium: PropTypes.bool.isRequired,
  largeImage: PropTypes.bool.isRequired,
};

export default FeatureComponent;
