import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import TextButton from '../../../shared/components/buttons/TextButton';
import { Text } from '../../../layout/theme/components';

const VoteStatus = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.textColors.secondary};
`;
const VoteIcon = styled.span`
  margin: 0 0.5em;
`;

const Voted = memo(({ changeVote, icon, label, isSoundingClosed }) => {
  return (
    <VoteStatus>
      <Text>
        Twój głos: <strong>{label}</strong>
      </Text>
      <VoteIcon>{icon}</VoteIcon>
      {!isSoundingClosed && (
        <TextButton small onClick={changeVote}>
          Zmień
        </TextButton>
      )}
    </VoteStatus>
  );
});

Voted.propTypes = {
  changeVote: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  isSoundingClosed: PropTypes.bool
};
Voted.defaultProps = {
  isSoundingClosed: false
};

export default Voted;
