import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AddResolutionIcon from '../../assets/resolution/addResolution';
import { H0, Text } from '../layout/theme/components';
import ArrowIcon from '../shared/components/ArrowIcon';
import Container from '../shared/components/Container';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.primary};
  text-align: left;
`;
const IconBox = styled.div`
  margin-top: 2em;
  svg {
    width: 100%;
  }
`;
const TextBox = styled.div`
  text-align: left;
  margin-top: 3em;
  word-break: break-word;
`;

const ResolutionComponent = () => (
  <Container>
    <Link to="/">
      <ArrowIcon />
    </Link>
    <H0>Cyfrowa Uchwała</H0>
    <IconBox>
      <AddResolutionIcon />
    </IconBox>
    <TextBox>
      <StyledText bold>Czym jest Cyfrowa Uchwała?</StyledText>
      <Text small>
        To najskuteczniejszy sposób podejmowania decyzji w Twojej wspólnocie.
        <br />
        <br />
        <b>Jesteś zarządcą?</b> Stwórz uchwałę teraz: użyj czystej strony, wgraj treść uchwały w formie pliku PDF lub
        skorzystaj z przygotowanych przez nas szablonów uchwał, które możesz swobodnie edytować. Uzupełnij nazwę
        uchwały, sprawdź treść, dodaj załączniki, jeśli potrzebujesz. To wszystko! Głosowanie rozpoczyna się w momencie
        opublikowania uchwały.
        <br />
        <br />
        <b>Jesteś właścicielem lokalu?</b> O opublikowaniu uchwały przez zarządcę zostaniesz natychmiast poinformowany
        za pomocą emaila. Otrzymasz też powiadomienie w aplikacji. Oddaj głos i gotowe! Jeżeli dzisiaj nie masz czasu
        oddać głosu, to nic nie szkodzi - przypomnimy ci o tym ponownie jutro!
        <br />
        <br />
        Po opublikowaniu uchwały zarówno zarządca jak i właściciele lokali mają do niej dostęp i mogą w każdej chwili
        zapoznać się z jej treścią. Po zebraniu stosownej ilości głosów zarządca zamyka głosowanie, a my zawiadamiamy
        właścicieli lokali o podjęciu (lub odrzuceniu) uchwały.
        <br />
        <br />
        Raport z głosowania jest dostępny dla zarządcy i właścicieli zarówno w trakcie, jak i po zakończeniu głosowania.
      </Text>
    </TextBox>
  </Container>
);

export default ResolutionComponent;
