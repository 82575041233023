import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { MentionsInput, Mention } from 'react-mentions';

import EmojiPicker from './../../../case/activities/components/EmojiPicker';
import { fetchUsersToMention } from '../../../helpers/api/user';
import CharsCounter from '../CharsCounter';
import { maxChars } from '../../../helpers/validation';

// custom params for emoji
const emojiStyles = {
  withoutMention: {
    top: '86%',
    right: '-0.5%',
    position: 'absolute',
    width: '100%',
    zIndex: 2
  },
  withMention: {
    top: '112%',
    right: '-0.5%',
    position: 'absolute',
    width: '100%',
    zIndex: 2
  }
};
const emojiCategoriesNames = {
  search: 'Szukaj',
  notfound: 'Nie znaleziono Emoji',
  skintext: 'Wybierz domyślny kolor skóry',
  categories: {
    search: '',
    recent: '',
    people: '',
    nature: '',
    foods: '',
    activity: '',
    places: '',
    objects: '',
    symbols: '',
    flags: '',
    custom: ''
  }
};
// custom params for react-mentions
const textareaStyles = {
  input: {
    width: '100%',
    border: 0,
    resize: 'none',
    fontSize: '1.5em',
    padding: '0.5em',
    margin: '1em 0',
    borderRadius: '5px',
    backgroundColor: '#f1f1f1',
    minHeight: 63,
    outline: 0,
    '&placeholder': {
      color: '#B5B5B5'
    }
  },
  suggestions: {
    position: 'absolute',
    top: '100%',
    right: '-0.5%',
    width: '100%',
    zIndex: 2,
    background: '#ffffff',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '5px',
    maxHeight: '150px',
    overflowY: 'auto'
  }
};
const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 1em;
  margin: 2.5em 0 0;
`;
const AreaNode = styled(TextareaAutosize)`
  width: 100%;
  border: 0;
  resize: none;
  font-size: 1.5em;
  padding: 0.5em;
  margin: 1em 0;
  border-radius: 5px;
  background: ${({ theme }) => theme.backgroundColors.secondary};
  &::placeholder {
    color: ${({ theme }) => theme.textColors.tertiary};
  }
  &:focus,
  &:active {
    outline: none;
  }
`;
const UserPicker = styled.span`
  position: absolute;
  bottom: -0.5em;
  left: 0.5em;
  cursor: pointer;
  z-index: 10;
  color: #cfcfcf;
  font-size: 1.3em;
`;
const MentionItem = styled.div`
  ${ellipsis('100%')};
  color: ${({ theme }) => theme.textColors.tertiary};
  width: 100%;
  padding: 5px 15px;
  background: ${({ focused, theme }) =>
    focused
      ? theme.backgroundColors.secondary
      : theme.backgroundColors.primary};
  text-align: left;
  white-space: nowrap;
`;
const MentionName = styled.span`
  font-size: 1.4em;
  color: ${({ theme }) => theme.textColors.primary};
  span b {
    color: #1670d6;
    font-weight: 400;
  }
`;
const MentionDesc = styled.span`
  font-size: 1.3em;
  color: #cfcfcf;
  margin-left: 1em;
  span {
    color: #1670d6;
  }
`;
const InvalidText = styled.div`
  position: absolute;
  bottom: -3.5em;
  color: ${({ theme }) => theme.labels.negative};
  font-size: 1.3em;
  margin: 0 0 1em;
`;

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueWithMention: props.value,
      toggleEmoji: false
    };
  }

  isValueLengthValid(value) {
    const { valueWithMention } = this.state;
    return value
      ? valueWithMention.length + value.length <= maxChars['description']
      : valueWithMention.length < maxChars['description'];
  }

  openMentionList() {
    const { valueWithMention } = this.state;
    const valueLengthValid = this.isValueLengthValid();

    if (
      valueLengthValid &&
      (valueWithMention === 0 || valueWithMention.endsWith(' '))
    ) {
      this.setState({ valueWithMention: `${valueWithMention}@` });
    } else if (valueLengthValid && !valueWithMention.endsWith('@')) {
      this.setState({ valueWithMention: `${valueWithMention} @` });
    }
    this.mentionInput.focus();
  }

  changeTextareaWithMention = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    if (newValue.length <= maxChars['description']) {
      this.props.changeHandler(newPlainTextValue);
      this.props.updateMentions(mentions);
      this.setState({ valueWithMention: newValue });
    }
  };

  getUserData(query, callback) {
    const getTagsArr = tags => tags.map(tag => tag.name);

    fetchUsersToMention(query)
      .then(users =>
        users.data.map(user => {
          const tags = getTagsArr(user.tags);
          return {
            display: `${user.company_name}`,
            tags: `${tags.join(', ')}`,
            id: user.id
          };
        })
      )
      .then(callback);
  }

  handleClickEmoji() {
    this.setState(state => ({
      toggleEmoji: !state.toggleEmoji
    }));
  }

  addEmoji = e => {
    if (e.unified.length <= 5) {
      let emojiPic = String.fromCodePoint(`0x${e.unified}`);
      const newValue = this.state.valueWithMention + emojiPic;
      this.isValueLengthValid(emojiPic) &&
        this.setState({ valueWithMention: newValue });
      this.props.changeHandler(newValue);
    } else {
      let sym = e.unified.split('-');
      let codesArray = [];
      sym.forEach(el => codesArray.push('0x' + el));
      let emojiPic = String.fromCodePoint(...codesArray);
      const newValue = this.state.valueWithMention + emojiPic;
      this.isValueLengthValid(emojiPic) &&
        this.setState({ valueWithMention: newValue });
      this.props.changeHandler(newValue);
    }
    this.handleClickEmoji();
  };

  render() {
    const {
      placeholder,
      value,
      valid,
      invalidText,
      changeHandler,
      isEmoji,
      isMention,
      counter
    } = this.props;
    const { valueWithMention, toggleEmoji } = this.state;
    const keyboardType = 'email-address' || 'ascii-capable';
    return (
      <TextWrapper>
        {counter && (
          <CharsCounter
            chars={valueWithMention.length}
            maxChars={maxChars['description']}
          />
        )}
        {isMention ? (
          <>
            <MentionsInput
              value={valueWithMention}
              placeholder={placeholder}
              allowSpaceInQuery
              keyboardtype={isEmoji ? keyboardType : 0}
              onChange={this.changeTextareaWithMention}
              style={textareaStyles}
              inputRef={ref => {
                this.mentionInput = ref;
              }}
            >
              <Mention
                trigger="@"
                data={this.getUserData}
                displayTransform={(id, display) => `@${display}`}
                appendSpaceOnAdd
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => {
                  let highlightedQuery = suggestion.tags.replace(
                    search,
                    `<span>${search}</span>`
                  );
                  return (
                    <MentionItem focused={focused}>
                      <MentionName>@{highlightedDisplay}</MentionName>
                      <MentionDesc
                        dangerouslySetInnerHTML={{ __html: highlightedQuery }}
                      />
                    </MentionItem>
                  );
                }}
              />
            </MentionsInput>
            <UserPicker onClick={() => this.openMentionList()}>
              @ Znajdź firmę
            </UserPicker>
          </>
        ) : (
          <AreaNode
            minRows={isEmoji ? 4 : 3}
            value={value}
            placeholder={placeholder}
            onChange={e => changeHandler(e.target.value)}
            keyboardtype={isEmoji ? keyboardType : 0}
          />
        )}
        {isEmoji && (
          <>
            <EmojiPicker
              toggleEmoji={toggleEmoji}
              handleClickEmoji={() => this.handleClickEmoji()}
              emojiWithoutMention={!isMention}
            />
            {toggleEmoji && (
              <Picker
                onSelect={this.addEmoji}
                showPreview={false}
                emojiTooltip={false}
                style={
                  isMention
                    ? emojiStyles['withMention']
                    : emojiStyles['withoutMention']
                }
                sheetSize={32}
                i18n={emojiCategoriesNames}
              />
            )}
          </>
        )}
        {!valid && <InvalidText>{invalidText}</InvalidText>}
      </TextWrapper>
    );
  }
}

Textarea.propTypes = {
  placeholder: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  invalidText: PropTypes.string,
  value: PropTypes.string,
  handleClickEmoji: PropTypes.func,
  addEmoji: PropTypes.func,
  toggleEmoji: PropTypes.bool,
  isEmoji: PropTypes.bool,
  isMention: PropTypes.bool,
  updateMentions: PropTypes.func,
  counter: PropTypes.bool
};

Textarea.defaultProps = {
  placeholder: 'Napisz komentarz',
  valid: true,
  invalidText: '',
  value: '',
  toggleEmoji: false,
  isEmoji: false,
  isMention: false,
  counter: false
};

export default Textarea;
