import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledButton = styled.button`
  display: inline-block;
  background: ${({ theme }) => theme.backgroundColors.quaternary};
  color: white;
  border: 2px solid ${({ theme }) => theme.backgroundColors.quaternary};
  font-size: ${({ small }) => (small && '0.9em') || '1.1em'};
  padding: ${({ small }) => (small && '.8em 2em') || '1em 2.5em'};
  border-radius: 25px;
  outline: 0;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.backgroundColors.quaternary};
  &:hover {
    background: ${({ theme }) => theme.backgroundColors.primary};
    color: ${({ theme }) => theme.buttonColors.quaternary};
    border: 2px solid ${({ theme }) => theme.backgroundColors.quaternary};
  }
`;

const Button = ({ onClick, children, small }) => {
  return (
    <StyledButton onClick={onClick} small={small}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  small: PropTypes.bool
};

Button.defaultProps = {
  onClick: null,
  small: false
};

export default Button;
