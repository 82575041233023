import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const EventWrapper = styled.div`
  width: 100%;
  padding: 1em 0.5em 1em 2.5em;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 1.8em 0.5em 1.5em 2.5em;
  }
`;

const EventLabel = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.3em;
  color: #ffffff;
`;

const SoundingEvent = props => {
  return (
    <EventWrapper>
      <EventLabel>
        {props.eventType === 'closed' ? 'Zamknięto' : 'Rozpoczęto'} głosowanie
        sondażowe
      </EventLabel>
    </EventWrapper>
  );
};

SoundingEvent.propTypes = {
  eventType: PropTypes.string.isRequired
};

export default SoundingEvent;
