import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import AddCompaniesIcon from '../../../../assets/resolution/addCompanies';
import AddResolutionIcon from '../../../../assets/resolution/addResolution';
import CreateCasesIcon from '../../../../assets/resolution/createCases';
import { userLabels } from '../../../helpers/constants';
import { H0, H2, Text } from '../../../layout/theme/components';
import OptionLabel from '../../../user/welcome/components/OptionLabel';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const StyledH0 = styled(H0)`
  margin-bottom: 7%;
`;
const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.textColors.secondary};
  margin: 7% 0;
`;
const StyledText = styled(Text)`
  margin: 0;
`;
const Options = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ManagerWelcomePage = ({ closeWelcomePage, role }) => (
  <>
    <StyledH0>Witaj</StyledH0>
    <StyledText>Zalogowałeś się jako</StyledText>
    <Text bold>{userLabels[role]}</Text>
    <StyledH2>Co możesz teraz zrobić?</StyledH2>
    <Options>
      <OptionLabel icon={<CreateCasesIcon />} option="1. Twórz sprawy" />
      <OptionLabel icon={<AddResolutionIcon />} option="2. Dodawaj uchwały" />
      <OptionLabel icon={<AddCompaniesIcon />} option="3. Dodawaj firmy" />
    </Options>
    <PrimaryButton onClick={closeWelcomePage}>Rozpocznij</PrimaryButton>
  </>
);

ManagerWelcomePage.propTypes = {
  closeWelcomePage: PropTypes.func,
  role: PropTypes.string.isRequired
};
ManagerWelcomePage.defaultProps = {
  closeWelcomePage: null
};

export default ManagerWelcomePage;
