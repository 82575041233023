import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { LabelColored, Text } from '../../../layout/theme/components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import TextButton from '../../../shared/components/buttons/TextButton';

import AttachmentIcon from '../../../../assets/attachment';
import CommentIcon from '../../../../assets/comment';
import draftIcon from '../../../../assets/draftIcon';
import ThumbNo from '../../../../assets/thumbNo';
import ThumbYes from '../../../../assets/thumbYes';

import { roundNumbers } from '../../../helpers/tools';
import PopupMenu from '../../../shared/components/PopupMenu';
import ArchiveToggler from '../../preview/components/ArchiveToggler';
import ResolutionStatus from '../../preview/components/ResolutionStatus';

const ListItem = styled.div`
  cursor: pointer;
  min-height: 100px;
  padding: 1em 1.5em;
  border: 1px solid ${({ highlighted, theme }) => (highlighted ? theme.buttonColors.primary : theme.borderColor)};
  border-radius: 10px;
  margin: 0 1em 1em;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 0 auto 1em;
  }
`;
const CaseDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
`;
const TopicText = styled(Text)`
  margin: 0.5em 0.2em;
  text-align: left;
`;
const CaseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const Counter = styled.span`
  display: flex;
  justify-self: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  font-size: 1.2em;
  margin: 0 0.5em;

  svg {
    height: 1em;
    margin-left: 0.3em;

    &:last-of-type {
      margin-left: 0.5em;
    }
  }
  + button {
    display: flex;
    align-self: flex-end;
  }
`;
const YourVote = styled.span`
  font-weight: 100;
`;

const Dot = styled.div`
  position: absolute;
  left: 1em;
  top: 1em;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ theme }) => theme.buttonColors.primary};
`;
const DraftInfo = styled.div`
  text-align: right;
  font-size: 1.2em;
  color: ${({ theme }) => theme.labels.negative};
`;

const DeleteDraft = styled(TextButton)`
  display: flex;
  font-size: 0.9em;
  padding-right: 0;
`;
const ResolutionIcon = styled.img`
  width: 26px;
  height: 25px;
`;
const VoteBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 7em;
`;
const ThumbBox = styled.div`
  width: 1em;
  height: 1em;
  svg {
    width: 1em;
    fill: ${({ color, theme }) => {
      switch (color) {
        case 'positive': {
          return theme.labels.positive;
        }
        default: {
          return theme.labels.negative;
        }
      }
    }};
  }
`;

const ResolutionItem = ({
  caseId,
  topic,
  newCase,
  newActivity,
  openSounding,
  userVote,
  userVoteLabel,
  clickHandler,
  isDraft,
  status,
  showDeleteModal,
  resolution,
  commentsCount,
  hasAttachment,
  canVote,
  canArchive,
  refreshListing,
}) => {
  const votesResults = resolution.results;
  const roundedPositiveVotes = roundNumbers(votesResults.positive_votes_percent, 0);
  const roundedNegativeVotes = roundNumbers(votesResults.negative_votes_percent, 0);

  return (
    <ListItem highlighted={newCase} onClick={clickHandler}>
      {newActivity && <Dot />}
      <CaseDetails>
        <TopicText isDraft={isDraft} bold>
          {topic}
        </TopicText>
        {isDraft && (
          <>
            <DeleteDraft small onClick={showDeleteModal}>
              Usuń
            </DeleteDraft>
          </>
        )}
        <PopupMenu
          actions={[
            {
              component: <ArchiveToggler caseId={caseId} currentStatus={status} callback={refreshListing} />,
              active: canArchive,
            },
          ]}
        />
      </CaseDetails>
      <CaseDetails>
        <div>
          {isDraft ? (
            <ResolutionIcon as={draftIcon} />
          ) : (
            <>
              <VoteBox>
                <ThumbBox color="positive">
                  <ThumbYes />
                </ThumbBox>
                <LabelColored>{roundedPositiveVotes}%</LabelColored>
                <ThumbBox color="negative">
                  <ThumbNo />
                </ThumbBox>
                <LabelColored>{roundedNegativeVotes}%</LabelColored>
              </VoteBox>
            </>
          )}
        </div>
        {isDraft ? (
          <DraftInfo>Wersja robocza</DraftInfo>
        ) : (
          <CaseActions>
            {
              <Counter>
                {commentsCount}
                {<CommentIcon />}
                {hasAttachment && <AttachmentIcon />}
              </Counter>
            }
            {canVote ? (
              <SecondaryButton disabled={userVote !== null || status === 'closed'} onClick={openSounding}>
                {userVote !== null ? (
                  <>
                    <YourVote>twój głos:</YourVote> {userVoteLabel}
                  </>
                ) : (
                  'głosuj'
                )}
              </SecondaryButton>
            ) : (
              <ResolutionStatus status={status} />
            )}
          </CaseActions>
        )}
      </CaseDetails>
    </ListItem>
  );
};

ResolutionItem.propTypes = {
  topic: PropTypes.string,
  hasAttachment: PropTypes.bool.isRequired,
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clickHandler: PropTypes.func.isRequired,
  openSounding: PropTypes.func.isRequired,
  isDraft: PropTypes.bool.isRequired,
  showDeleteModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userVote: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userVoteLabel: PropTypes.string,
  newCase: PropTypes.bool,
  newActivity: PropTypes.bool,
  canVote: PropTypes.bool,
  canArchive: PropTypes.bool,
  refreshListing: PropTypes.func.isRequired,
};

ResolutionItem.defaultProps = {
  topic: '',
  newCase: false,
  newActivity: false,
  userVote: null,
  userVoteLabel: '',
  canVote: false,
  canArchive: false,
};

export default ResolutionItem;
