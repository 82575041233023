import React from 'react';

const addCaseIcon = () => (
  <svg
    viewBox="0 0 55 55"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <path fill="none" d="M0 0h55v55H0z" />
    <path
      d="M54.601 27.494c0-14.376-11.671-26.048-26.047-26.048h-1.241c-14.376 0-26.048   11.672-26.048 26.048 0 14.376 11.672 26.048 26.048 26.048h1.241c14.376 0 26.047-11.672   26.047-26.048z"
      fill="#f63d66"
    />
    <path
      d="M18.518 27.494h18.831M27.933 18.079V36.91"
      fill="#f63d66"
      stroke="#fff"
      strokeWidth="3"
    />
  </svg>
);

export default addCaseIcon;
