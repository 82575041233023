import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import TextInput from '../../shared/components/forms/TextInput';
import Button from '../../shared/components/buttons/PrimaryButton';
import SoundingPicture from '../../../assets/soundingPicture';
import Checkbox from '../../shared/components/forms/Checkbox';

import { INVALID_INPUT_TEXT } from '../../helpers/constants';
import { H2 } from '../../layout/theme/components';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled(H2)`
  ${sectionStyle};
`;
const FormSection = styled.form`
  ${sectionStyle};
  flex: 1;
`;
const FieldsWrapper = styled.div`
  ${sectionStyle};
  justify-content: flex-start;
  width: 100%;
`;
const SoundingPictureWrapper = styled.div`
  margin: 2% 0;
  svg {
    height: 20vh;
  }
`;
const CheckboxWrapper = styled.div`
  width: auto;
`;

const SoundingComponent = props => (
  <Fragment>
    <Header>Czy chcesz zadać pytanie sondażowe?</Header>
    <FormSection id="sounding-form" onSubmit={e => props.submitHandler(e)}>
      <FieldsWrapper>
        <CheckboxWrapper>
          <Checkbox
            name="sounding"
            type="checkbox"
            onChange={props.soundingChangeHandler}
            defaultChecked="false"
            checked={props.isSoundingActive}
            label="Tak, chcę"
          />
        </CheckboxWrapper>
        {props.isSoundingActive && (
          <TextInput
            name="question"
            type="text"
            placeholder="Dodaj pytanie"
            value={props.question}
            onChange={props.handleChange}
            valid={props.valid}
            invalidText={INVALID_INPUT_TEXT}
            hint={
              'Charakter pytania powinien być zamknięty. Mieszkańcy głosują tylko TAK lub NIE'
            }
            isRequired
            autoFocus
          />
        )}
      </FieldsWrapper>
      <SoundingPictureWrapper>
        <SoundingPicture />
      </SoundingPictureWrapper>
      <Button type="submit">DALEJ</Button>
    </FormSection>
  </Fragment>
);

SoundingComponent.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  soundingChangeHandler: PropTypes.func.isRequired,
  isSoundingActive: PropTypes.bool.isRequired
};

export default SoundingComponent;
