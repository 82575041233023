import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userRoles } from '../../helpers/constants';
import Loader from '../../shared/components/Loader';
import Error from '../../shared/Error';
import CommunityStatusComponent from './CommunityStatusComponent';

class CommunityStatusContainer extends Component {
  state = {
    levelStatuses: {
      basic: '',
      premium: '',
    },
  };

  componentDidMount() {
    if (this.props.resourceData) this.getStatuses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.resourceData !== prevProps.resourceData) {
      this.getStatuses();
    }
  }

  getPremiumStatus() {
    const { resourceData } = this.props;
    if (resourceData.status === 'premium') {
      return 'active';
    } else if (resourceData.status === 'trial') {
      return 'trial';
    } else if (resourceData.mortgage_number_exists && !resourceData.trial_exists) {
      return 'verification';
    } else {
      return null;
    }
  }

  getBasicStatus() {
    const { resourceData } = this.props;
    if (resourceData.status === 'basic') {
      return 'active';
    } else {
      return 'inactive';
    }
  }

  getStatuses() {
    const basicStatus = this.getBasicStatus();
    const premiumStatus = this.getPremiumStatus();
    const levelStatuses = { ...this.state.levelStatuses };

    levelStatuses.basic = basicStatus;
    levelStatuses.premium = premiumStatus;
    this.setState({ levelStatuses });
  }

  getKeepers = () => {
    const keepers = this.props.resourceData.keepers;
    let keepersArray = [];
    keepers &&
      keepers.forEach(keeper => {
        keepersArray.push(keeper.display_name);
      });

    return keepersArray;
  };

  render() {
    const { resourceData, isLoading, error, isRegularUser } = this.props;
    const { levelStatuses } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <CommunityStatusComponent
        isRegularUser={isRegularUser}
        keepers={this.getKeepers()}
        name={resourceData.community_name}
        residentsNumber={resourceData.residents_number}
        residentsPercent={resourceData.residents_percent}
        levelStatuses={levelStatuses}
        keeperExists={resourceData.keeper_exists}
      />
    );
  }
}

CommunityStatusContainer.propTypes = {
  resourceData: PropTypes.object,
  isLoading: PropTypes.bool,
  isRegularUser: PropTypes.bool,
};

CommunityStatusContainer.defaultProps = {
  isLoading: true,
  isRegularUser: false,
};

const mapStateToProps = state => ({
  resourceData: state.user.currentDataResource && state.user.currentDataResource.data,
  isLoading: state.user.currentDataResource && state.user.currentDataResource.isLoading,
  isRegularUser: state.user.profile.data && state.user.profile.data.role === userRoles.ROLE_USER,
});

export default connect(
  mapStateToProps,
  null
)(CommunityStatusContainer);
