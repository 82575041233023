import React, { useEffect, useState } from 'react';
import { fetchAvailableCities } from '../../helpers/api/community';
import { fetchRegister } from '../../helpers/api/user';
import Container from '../../shared/components/Container';
import Loader from '../../shared/components/Loader';
import Logo from '../../shared/components/Logo';
import AfterRegistrationComponent from './AfterRegistrationComponent';
import RegistrationComponent from './RegistrationComponent';

export const RegistrationContainer = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    town: '',
    address: '',
    termsAccepted: false,
  });
  const [cities, setCities] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [registered, setRegistered] = useState(false);

  useEffect(() => getCities(), []);

  const getCities = () => {
    fetchAvailableCities()
      .then(data => {
        setCities(
          data.data.map(city => {
            return {
              label: city.town,
              value: city.town,
            };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError('Wystąpił błąd podczas pobierania dostępnych miast. Odśwież stronę.');
      });
  };

  const handleInputChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  };

  const handleCityChange = value =>
    setUserData({
      ...userData,
      town: value,
    });

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    fetchRegister(userData)
      .then(() => {
        setLoading(false);
        setRegistered(true);
      })
      .catch(error => {
        setLoading(false);
        if (error.detail.includes('field missing')) {
          setError('Musisz wypełnić wszystkie pola i zaakceptować zgody.');
        } else {
          setError(
            'Rejestracja konta niemożliwa - w celu ustalenia przyczyny skontaktuj się z nami na pomoc@wspolnota.online'
          );
        }
      });
  };

  return (
    <Container>
      <Logo width="250px" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!registered ? (
            <RegistrationComponent
              formData={userData}
              cities={cities}
              handleInputChange={handleInputChange}
              handleCityChange={handleCityChange}
              handleSubmit={handleSubmit}
              error={error}
            />
          ) : (
            <AfterRegistrationComponent />
          )}
        </>
      )}
    </Container>
  );
};
