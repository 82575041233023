import React from 'react';

const ranges = () => {
  return (
    <svg
      viewBox="0 0 165 176"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h164.759v175.666H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h164.759v175.666H0z" />
      </clipPath>
      <g>
        <circle
          cx="81.309"
          cy="81.31"
          r="62.052"
          fill="#3ec7a9"
          fillOpacity="0.24"
        />
        <circle
          cx="82.379"
          cy="82.379"
          r="82.379"
          fill="#3ec7a9"
          fillOpacity="0.24"
        />
        <circle
          cx="81.31"
          cy="81.31"
          r="40.655"
          fill="#3ec7a9"
          fillOpacity="0.24"
        />
        <g fillRule="nonzero">
          <path
            d="M95.677 167.297h52.045V45.37l-26.025-13.274-26.02 13.274v47.85"
            fill="#9d96bf"
          />
          <path
            d="M115.856 60.239a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.448a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V60.239z"
            fill="#fef8de"
          />
          <path
            d="M117.417 60.026h-11.789l1.341-3.908h9.106l1.342 3.908z"
            fill="#756ba4"
          />
          <path
            d="M136.062 60.239a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.448a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V60.239z"
            fill="#fef8de"
          />
          <path
            d="M137.622 60.026h-11.788l1.341-3.908h9.106l1.341 3.908z"
            fill="#756ba4"
          />
          <path
            d="M116.646 73.304c1.56.117 2.086 3.053-.077 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.322 0 6.651-.077 9.977.002zM136.853 73.304c1.56.117 2.086 3.053-.078 3.107h-9.902c-1.55-.039-2.36-3.05 0-3.109 3.325 0 6.653-.077 9.98.002z"
            fill="#7471a1"
          />
          <path
            d="M115.856 89.103a2.179 2.179 0 0 0-2.167-2.165h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.451a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V89.103z"
            fill="#fef8de"
          />
          <path
            d="M117.417 88.893h-11.789l1.341-3.908h9.106l1.342 3.908z"
            fill="#756ba4"
          />
          <path
            d="M136.062 89.103a2.179 2.179 0 0 0-2.168-2.165h-4.333a2.178 2.178 0 0 0-2.167 2.167v12.451a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V89.103z"
            fill="#fef8de"
          />
          <path
            d="M137.622 88.893h-11.788l1.341-3.908h9.106l1.341 3.908z"
            fill="#756ba4"
          />
          <path
            d="M116.646 102.169c1.56.117 2.086 3.053-.077 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.322 0 6.651-.081 9.977.002zM136.853 102.169c1.56.117 2.086 3.053-.078 3.107h-9.902c-1.55-.039-2.36-3.05 0-3.109 3.325 0 6.653-.081 9.98.002z"
            fill="#7471a1"
          />
          <path
            d="M115.856 117.968a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.454c0 1.189.978 2.167 2.167 2.168h4.334a2.179 2.179 0 0 0 2.167-2.168v-12.454z"
            fill="#fef8de"
          />
          <path
            d="M117.417 117.754h-11.789l1.342-3.91h9.106l1.341 3.91z"
            fill="#756ba4"
          />
          <path
            d="M136.062 117.968a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.454c0 1.189.978 2.167 2.167 2.168h4.334a2.178 2.178 0 0 0 2.167-2.167v-12.455z"
            fill="#fef8de"
          />
          <path
            d="M137.622 117.754h-11.788l1.341-3.91h9.106l1.341 3.91z"
            fill="#756ba4"
          />
          <path
            d="M152.901 46.625h-62.4l7.1-14.529h48.2l7.1 14.529z"
            fill="#8178ad"
          />
          <path
            d="M116.646 130.79c1.56.117 2.086 3.053-.077 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.322 0 6.651-.081 9.977.002zM136.853 130.79c1.56.117 2.086 3.053-.078 3.107h-9.902c-1.55-.039-2.36-3.05 0-3.109 3.325 0 6.653-.081 9.98.002z"
            fill="#7471a1"
          />
          <path
            d="M82.484 85.046L54.259 99.623v67.674h56.45V99.623L82.484 85.046z"
            fill="#beb9d8"
          />
          <path
            d="M76.641 109.998a2.178 2.178 0 0 0-2.167-2.167H70.14a2.178 2.178 0 0 0-2.167 2.167v12.452a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167v-12.452z"
            fill="#fef8de"
          />
          <path
            d="M78.202 109.785H66.413l1.341-3.908h9.106l1.342 3.908z"
            fill="#756ba4"
          />
          <path
            d="M96.846 109.998a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.452a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167v-12.452z"
            fill="#fef8de"
          />
          <path
            d="M98.407 109.785H86.619l1.339-3.908h9.106l1.343 3.908z"
            fill="#756ba4"
          />
          <path
            d="M77.432 123.065c1.56.117 2.086 3.053-.078 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.323 0 6.652-.081 9.978.002zM97.634 123.065c1.56.117 2.086 3.053-.078 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.326 0 6.655-.081 9.978.002z"
            fill="#7471a1"
          />
          <path
            d="M113.595 99.574h-62.4l7.1-14.529h48.2l7.1 14.529z"
            fill="#8178ad"
          />
          <path
            d="M76.641 138.62a2.178 2.178 0 0 0-2.167-2.167H70.14a2.178 2.178 0 0 0-2.167 2.167v12.452a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V138.62z"
            fill="#fef8de"
          />
          <path
            d="M78.202 138.407H66.413l1.341-3.908h9.106l1.342 3.908z"
            fill="#756ba4"
          />
          <path
            d="M96.846 138.62a2.178 2.178 0 0 0-2.167-2.167h-4.334a2.178 2.178 0 0 0-2.167 2.167v12.452a2.178 2.178 0 0 0 2.167 2.167h4.334a2.178 2.178 0 0 0 2.167-2.167V138.62z"
            fill="#fef8de"
          />
          <path
            d="M98.407 138.407H86.619l1.339-3.908h9.106l1.343 3.908z"
            fill="#756ba4"
          />
          <path
            d="M77.432 151.686c1.56.117 2.086 3.053-.078 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.323 0 6.652-.081 9.978.002zM97.634 151.686c1.56.117 2.086 3.053-.078 3.107h-9.9c-1.55-.039-2.36-3.05 0-3.109 3.326 0 6.655-.081 9.978.002z"
            fill="#7471a1"
          />
        </g>
        <path
          d="M161.742 169.955c0-2.76-2.24-5-5-5H44.632c-2.76 0-5 2.24-5 5v.711c0 2.76 2.24 5 5 5h112.11c2.76 0 5-2.24 5-5v-.711z"
          fill="#3ec7a9"
        />
      </g>
    </svg>
  );
};

export default ranges;
