import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Caption } from '../../../layout/theme/components';

const EventWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 2.5em;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  word-break: break-word;
`;
const StyledCaption = styled(Caption)`
  text-align: left;
`;

const TagEvent = ({ type, company }) => {
  return (
    <EventWrapper>
      {type === 'leave' ? (
        <StyledCaption>Firma @{company} opuściła sprawę</StyledCaption>
      ) : (
        <StyledCaption>Firma @{company} dołączyła do sprawy</StyledCaption>
      )}
    </EventWrapper>
  );
};

TagEvent.propTypes = {
  type: PropTypes.string,
  company: PropTypes.string
};
TagEvent.defaultProps = {
  type: '',
  company: ''
};

export default TagEvent;
