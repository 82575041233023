import React from 'react';
import styled from 'styled-components/macro';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  min-height: 100vh;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
  }
`;

export const StepWrapper = props => <FlexWrapper>{props.children}</FlexWrapper>;
