import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import rocketPicture from './../../../../assets/resolution/rocketPicture.svg';
import clockPicture from './../../../../assets/resolution/clockPicture.svg';
import closedPicture from './../../../../assets/resolution/closedPicture.svg';
import { Text } from '../../../layout/theme/components';

const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;
`;
const StatsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  margin: 0.5em;
  padding: 1.5em;
  border: 1px solid #e8e8e8;
  min-width: 250px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const ImgWrapper = styled.span`
  height: 75px;
  text-align: right;
  margin-right: 1.5em;

  img {
    height: 100%;
  }
`;
const Label = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
`;
const LabelValue = styled.span`
  font-size: 2.4em;
  color: ${({ theme }) => theme.textColors.secondary};
`;
const StyledText = styled(Text)`
  margin: 0;
`;

const ReportStatistics = props => {
  const { startDate, duration, closed } = props;

  return (
    <StatsWrapper>
      <StatsBox>
        <ImgWrapper>
          <img alt="" src={rocketPicture} />
        </ImgWrapper>
        <Label>
          <StyledText small>Data rozpoczęcia głosowania:</StyledText>
          <LabelValue>{startDate} r.</LabelValue>
        </Label>
      </StatsBox>
      <StatsBox>
        <ImgWrapper>
          <img alt="" src={closed ? closedPicture : clockPicture} />
        </ImgWrapper>
        <Label>
          <StyledText small>
            {closed ? 'Głosowanie zajęło:' : 'Głosowanie trwa od:'}
          </StyledText>
          <LabelValue>{duration}</LabelValue>
        </Label>
      </StatsBox>
    </StatsWrapper>
  );
};

ReportStatistics.propTypes = {
  startDate: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  closed: PropTypes.bool
};

ReportStatistics.defaultProps = {
  closed: false
};

export default ReportStatistics;
