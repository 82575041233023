import React from 'react';

const help = () => (
  <svg
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h11.714v11.714H0z" />
    <path
      d="M8.361 1.514c0 .365-.138.676-.414.932a1.406 1.406 0 0 1-.995.386c-.39 0-.722-.129-1.001-.386a1.222 1.222 0 0 1-.418-.932c0-.364.14-.675.418-.935A1.415 1.415 0 0 1 6.952.191c.388 0 .72.13.995.388.277.26.414.571.414.935m-.15 8.787l-.158.647c-.475.188-.855.33-1.138.428a2.975 2.975 0 0 1-.985.148c-.576 0-1.023-.141-1.342-.421a1.362 1.362 0 0 1-.478-1.069c0-.167.011-.339.035-.515.024-.175.063-.372.116-.593l.593-2.101c.053-.201.098-.392.134-.572.037-.18.054-.344.054-.493 0-.268-.055-.456-.166-.562-.11-.105-.322-.158-.635-.158-.153 0-.311.024-.472.072a6.765 6.765 0 0 0-.415.137l.158-.648c.389-.158.761-.294 1.117-.406a3.33 3.33 0 0 1 1.009-.17c.571 0 1.012.138 1.321.414.31.276.464.635.464 1.076 0 .092-.01.252-.031.482-.022.23-.061.442-.119.634l-.592 2.092a6.087 6.087 0 0 0-.13.577 2.961 2.961 0 0 0-.057.489c0 .278.062.468.186.569.125.1.341.151.647.151.143 0 .307-.026.489-.076.181-.05.313-.094.395-.132"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
);

export default help;
