import React from 'react';

const Contact = () => (
  <svg
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h11.714v11.714H0z" />
    <path
      d="M2.425 7.916C1.48 6.212 1.173 4.157 1.88 2.398c.401-.996.95-1.686 1.895-1.939a2.284 2.284 0 0 1 1.178 0l1.021 3.809a2.275 2.275 0 0 1-2.736.369 4.8 4.8 0 0 0 .609 2.545 4.792 4.792 0 0 0 1.9 1.799 2.272 2.272 0 0 1 1.687-2.184l2.788 2.788a2.29 2.29 0 0 1-.589 1.021c-.692.691-1.564.821-2.627.671C5.13 11.01 3.503 9.716 2.5 8.046l-.001.001-.007-.012-.031-.053-.03-.053-.007-.012.001-.001z"
      fill="#fff"
    />
  </svg>
);

export default Contact;
