import React from 'react';

function UnarchiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#675e97"
        d="M20.55 5.22l-1.39-1.68A1.509 1.509 0 0018 3H6a1.451 1.451 0 00-1.15.55L3.46 5.22A1.985 1.985 0 003 6.5V19a2 2 0 002 2h14a2.006 2.006 0 002-2V6.5a2.033 2.033 0 00-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5zM5.12 5l.82-1h12l.93 1z"
      />
    </svg>
  );
}

export default UnarchiveIcon;
