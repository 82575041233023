import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmailValid, isPhoneNumber } from '../../helpers/validation';
import { Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import Loader from '../../shared/components/Loader';
import SentPage from '../../shared/SentPage';
import SentPageUnregistered from '../../shared/SentPageUnregistered';
import { fetchFriend } from './../../helpers/api/user';
import InvitingComponent from './InvitingComponent';
import InvitingRegistrationComponent from './InvitingRegistrationComponent';

class InvitingContainer extends Component {
  state = {
    inputs: [
      {
        name: `name${new Date().getTime()}`,
        type: 'email',
        valid: true,
        value: '',
      },
    ],
    inputValue: '',
    name: '',
    valid: true,
    sent: false,
    isLoading: false,
    error: null,
  };

  onChangeInput = e => {
    const inputs = { ...this.state.inputs };
    this.setState({ valid: true });
    const findIndex = Object.values(inputs).find(input => {
      return input.name === e.target.name;
    });
    findIndex.valid = true;
    findIndex.value = e.target.value;
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  onClickLink = (e, type) => {
    e.preventDefault();
    const inputData = {
      name: `name${new Date().getTime()}`,
      type: type,
      valid: true,
      value: '',
    };
    this.setState({
      inputs: [...this.state.inputs, inputData],
    });
  };

  removeInput = inputName => {
    const { inputs } = this.state;
    const wrongData = [];
    const findIndex = inputs.findIndex(input => {
      return input.name === inputName;
    });
    inputs.splice(findIndex, 1);

    inputs.forEach(input => {
      if (input.valid === false) wrongData.push(input);
    });
    if ((wrongData.length === 1 && inputs[inputName] === wrongData[0]) || wrongData.length === 0) {
      this.setState({ valid: true });
    }
    this.setState({ inputs });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ isLoading: true });
    const { username } = this.props;
    const { inputs, name } = this.state;
    const senderName = username ? username : name;
    const data = this.getEmailObject(inputs);
    const wrongData = this.isDataValid();

    if (wrongData.length === 0) {
      fetchFriend(senderName, data)
        .then(() => this.setState({ sent: true, isLoading: false }))
        .catch(error => this.setState({ error, isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  };

  getEmailObject = inputs => {
    const data = [];
    inputs.forEach(input => {
      data.push({ email: input.value });
    });
    return data;
  };

  // todo: add dialling to phone number handling
  isDataValid = () => {
    const { inputs } = this.state;
    const wrongData = [];

    inputs.forEach(input => {
      if (input.type === 'email') {
        if (!isEmailValid(input.value)) {
          input['valid'] = false;
          this.setState({ valid: false });
          wrongData.push(input);
        }
      } else if (input.type === 'phone') {
        if (isPhoneNumber(input.value)) {
          input['valid'] = false;
          this.setState({ valid: false });
          wrongData.push(input);
        }
      }
    });
    return wrongData;
  };

  resetView = () => this.setState({ sent: false });

  renderSentPage = () => {
    const { isLogged } = this.props;

    if (isLogged) {
      return <SentPage header="Wysłano zaproszenia, Dobra robota!" premiumColor />;
    } else {
      return (
        <SentPageUnregistered header="Wysłano!">
          <Text>
            Z każdym wysłanym zaproszeniem będzie łatwiej podejmować decyzje w Waszej wspólnocie!
            <br />
            <b>Masz ochotę wysłać jeszcze jedno?</b>
          </Text>
          <PrimaryButton onClick={this.resetView}>Wyślij</PrimaryButton>
        </SentPageUnregistered>
      );
    }
  };

  render() {
    const { inputs, inputValue, name, valid, sent, error, isLoading } = this.state;
    const { username, isLogged } = this.props;

    const showAddButton = inputs.length < 10;

    if (isLoading) return <Loader />;
    if (sent) return this.renderSentPage();

    if (isLogged)
      return (
        <InvitingComponent
          inputs={inputs}
          inputValue={inputValue}
          onChangeInput={e => this.onChangeInput(e)}
          onClickLink={(e, type) => this.onClickLink(e, type)}
          removeInput={inputName => this.removeInput(inputName)}
          handleSubmit={e => this.handleSubmit(e)}
          goBack={() => window.location.replace('/')}
          valid={valid}
          error={error}
          showAddButton={showAddButton}
          username={username}
        />
      );

    return (
      <InvitingRegistrationComponent
        inputs={inputs}
        inputValue={inputValue}
        name={name}
        onChangeInput={e => this.onChangeInput(e)}
        handleNameChange={e => this.handleNameChange(e)}
        onClickLink={(e, type) => this.onClickLink(e, type)}
        removeInput={inputName => this.removeInput(inputName)}
        handleSubmit={e => this.handleSubmit(e)}
        valid={valid}
        error={error}
        showAddButton={showAddButton}
      />
    );
  }
}

InvitingContainer.propTypes = {
  username: PropTypes.string,
  isLogged: PropTypes.bool,
};

InvitingContainer.defaultProps = {
  username: '',
  isLogged: false,
};

const mapStateToProps = state => ({
  username: state.user.profile.data ? state.user.profile.data.display_name : '',
  isLogged: state.user.auth.loggedIn,
});

export default connect(mapStateToProps)(InvitingContainer);
