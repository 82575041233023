import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import PostPigeonImage from '../../assets/postPigeon';
import SentOpinionIcon from '../../assets/sentOpinion';
import { H0, Text } from '../layout/theme/components';
import PrimaryButton from './components/buttons/PrimaryButton';

const SentPageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  padding: 15% 5%;
  background: ${({ premiumColor, theme }) =>
    premiumColor ? theme.backgroundColors.premium : theme.backgroundColors.primary};
  color: ${({ premiumColor, theme }) => (premiumColor ? theme.backgroundColors.primary : theme.textColors.secondary)};
`;
const BoxPicture = styled.div`
  width: 22em;
  margin: 5% 0;
`;
const BoxText = styled.div`
  line-height: 0.7em;
  margin-bottom: 5%;
  word-break: break-word;
`;

const SentPage = ({ header, text, closeButton, premiumColor }) => (
  <SentPageWrapper premiumColor={premiumColor}>
    <H0>{header}</H0>
    <BoxPicture>{premiumColor ? <PostPigeonImage /> : <SentOpinionIcon />}</BoxPicture>
    <BoxText>
      <Text>{text}</Text>
    </BoxText>
    <Link to="/">
      <PrimaryButton>{closeButton}</PrimaryButton>
    </Link>
  </SentPageWrapper>
);

SentPage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  closeButton: PropTypes.string,
  premiumColor: PropTypes.bool,
};

SentPage.defaultProps = {
  header: 'Zgłoszenie przyjęte!',
  text: null,
  closeButton: 'Zamknij',
  premiumColor: false,
};

export default SentPage;
