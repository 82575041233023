import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './data/reducers';

const enhancers = [];
// enable redux devtool on development mode
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(ReduxThunk),
  ...enhancers
);

export const store = createStore(reducers, composedEnhancers);
