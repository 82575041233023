import React from 'react';

const adminIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2.674" height="4.284">
      <g data-name="Group 616" transform="translate(-3.163 -2.358)" fill="#fff">
        <circle
          data-name="Ellipse 41"
          cx="0.985"
          cy="0.985"
          r="0.985"
          transform="translate(3.515 2.358)"
        />
        <path
          data-name="Path 1657"
          d="M3.163 5.665a1.337 1.337 0 0 1 2.674 0v.977H3.163v-.977z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default adminIcon;
