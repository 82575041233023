import React from 'react';
import styled, { css } from 'styled-components';

import WelcomePicture from '../../../assets/welcome';
import { isMobile } from '../../helpers/tools';
import Logo from '../../shared/components/Logo';

const LoginWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${() => (isMobile() ? `${window.innerHeight}px` : '100vh')};
  width: 100%;
  color: ${({ theme }) => theme.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
    align-items: center;
  }
`;
const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 5%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 1em 6em;
    flex: 1;
    max-width: 400px;
    width: 50%;
  }
`;
const WelcomeSection = styled.div`
  ${sectionStyle};
  min-height: 0;

  svg {
    width: 100%;
    max-height: 25vh;
  }
`;
const FormSection = styled.div`
  ${sectionStyle};
  flex: 1 1 1px;
  justify-content: space-between;
  text-align: center;
  border-left: 1px solid ${({ theme }) => theme.borderColor};

  svg {
    max-width: 400px;
  }
  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    margin-top: 10%;
    flex: 1;
  }
  button {
    margin: 2em auto 0;
  }
`;

const WelcomeContainer = props => {
  return (
    <LoginWrapper>
      <WelcomeSection>
        <WelcomePicture />
      </WelcomeSection>
      <FormSection>
        <Logo />
        {props.children}
      </FormSection>
    </LoginWrapper>
  );
};

export default WelcomeContainer;
