import { handleResponse } from '../request';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchOpenCase = caseId => {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(`${apiUrl}/open/case/${caseId}`, requestOptions).then(
    handleResponse
  );
};

/**
 * It gets activities relative to selected apartment and case;
 * @param {number} caseId
 * @param {number} page
 * @param {number} limit
 */
export const fetchOpenCaseActivities = (caseId, page = 1, limit = 10) => {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(
    `${apiUrl}/open/case/${caseId}/activities?page=${page}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
};
