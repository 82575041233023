import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 3em 0 0;
`;

const ButtonsWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

ButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default ButtonsWrapper;
