import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useTimer } from 'react-compound-timer';

const TimerWrapper = styled.pre`
  width: 100%;
  margin: 0.5em 0;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.4em;
  color: ${({ theme }) => theme.textColors.highlighted};
`;
const Text = styled.span`
  font-weight: 500;
  font-size: 0.6em;
`;

const ResolutionTimer = ({ votingLasts }) => {
  const { value } = useTimer({ initialTime: votingLasts });

  const getNumber = number => `${number < 10 ? `0${number}` : number}`;

  return (
    <TimerWrapper>
      {getNumber(value.d)} <Text>dni : </Text> {getNumber(value.h)}{' '}
      <Text>godz. : </Text> {getNumber(value.m)} <Text>min. : </Text>{' '}
      {getNumber(value.s)} <Text>s.</Text>
    </TimerWrapper>
  );
};

ResolutionTimer.propTypes = {
  votingLasts: PropTypes.number.isRequired
};

export default ResolutionTimer;
