import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { NavItem } from '../../layout/theme/components';

const Tabs = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  padding: ${({ hide }) => (hide ? '0' : '2.5em 1em 1.5em')};
  transform: ${({ hide }) => (hide ? 'translateY(-100%)' : 'translateY(0)')};
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: 1;
  box-shadow: ${({ theme }) => theme.boxShadow.element};
  background: ${({ theme }) => theme.backgroundColors.primary};
`;
const Tab = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em 0.5em;
  white-space: nowrap;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 1em 2em;
  }
`;
const Dot = styled.div`
  width: 7px;
  height: 7px;
  margin-right: 0.5em;
  border-radius: 50%;
  background: ${({ theme }) => theme.buttonColors.primary};
`;

const Filter = memo(({ activeFilter, clickHandler, hideFilter, unvotedResolutionsNumber, showResolutionFilter }) => {
  const unvotedResolutions = unvotedResolutionsNumber > 0;
  return (
    <Tabs hide={hideFilter}>
      <Tab onClick={() => clickHandler('allCases')}>
        <NavItem active={activeFilter === 'allCases'}>Wszystkie sprawy</NavItem>
      </Tab>
      <Tab onClick={() => clickHandler('myCases')}>
        <NavItem active={activeFilter === 'myCases'}>Moje sprawy</NavItem>
      </Tab>
      {showResolutionFilter && (
        <Tab onClick={() => clickHandler('resolutions')}>
          {unvotedResolutions && <Dot />}
          <NavItem active={activeFilter === 'resolutions'}>
            Uchwały
            {unvotedResolutions && ` (${unvotedResolutionsNumber})`}
          </NavItem>
        </Tab>
      )}
    </Tabs>
  );
});

Filter.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  unvotedResolutionsNumber: PropTypes.number,
  showResolutionFilter: PropTypes.bool,
};

Filter.defaultProps = {
  hide: false,
  unvotedResolutionsNumber: 0,
  showResolutionFilter: false,
};

export default Filter;
