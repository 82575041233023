import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../theme/components';
import RadioInput from '../components/RadioInput';

const RangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin: 10% auto;
  filter: ${({ checked }) => (checked ? 'none' : 'grayscale(100%)')};
  opacity: ${({ checked }) => (checked ? '1' : '0.5')};
`;
const ImageWrapper = styled.div`
  width: 30px;

  img {
    width: 100%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  margin: 0;
  padding-left: 1.5em;
`;
const Header = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.textColors.secondary};
  text-align: left;
  font-weight: 600;
  font-size: 1.6em;
  margin 0 auto;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  text-align: left;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-end;
  height: 100%;
`;

const Range = ({ name, description, iconPath, isChecked }) => (
  <RangeWrapper checked={isChecked}>
    <ImageWrapper>
      <img alt="" src={iconPath} />
    </ImageWrapper>
    <TextBox>
      <Header>{name}</Header>
      {description && (
        <DescriptionWrapper>
          <Text small>{description}</Text>
        </DescriptionWrapper>
      )}
    </TextBox>
    <InputWrapper>
      <RadioInput name={name} checked={isChecked} />
    </InputWrapper>
  </RangeWrapper>
);

Range.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  isChecked: PropTypes.bool
};

Range.defaultProps = {
  description: null,
  isChecked: false
};

export default Range;
