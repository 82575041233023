import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { saveMeetingStep } from '../../helpers/api/case';
import { getDate, getTime } from '../../helpers/date';
import MeetingComponent from './MeetingComponent';

class MeetingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        date: props.datetime ? new Date(props.datetime) : null,
        time: props.datetime ? new Date(props.datetime) : null,
        place: props.place ? props.place : null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
  }

  getDatetime() {
    const { date, time } = this.state.form;
    if (date && time) {
      return `${getDate(date)} ${getTime(time)}`;
    } else {
      return '';
    }
  }

  saveStep() {
    const { caseId } = this.props;
    const { place } = this.state.form;
    saveMeetingStep(caseId, this.getDatetime(), place).then(() => this.props.handleNextStep());
  }

  submitForm(e) {
    e.preventDefault();
    this.saveStep();
  }

  updateForm(field, value) {
    const temp = { ...this.state.form };
    temp[field] = value;
    this.setState({ form: temp });
  }

  render() {
    const { date, time, place } = this.state.form;
    return (
      <MeetingComponent
        date={date}
        time={time}
        place={place}
        submitHandler={this.submitForm}
        handleChange={(field, value) => this.updateForm(field, value)}
      />
    );
  }
}

MeetingContainer.propTypes = {
  datetime: PropTypes.string,
  place: PropTypes.string,
  caseId: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

MeetingContainer.defaultProps = {
  datetime: null,
  place: null,
};

export default MeetingContainer;
