import isPast from 'date-fns/isPast';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { statusLabels } from '../../../helpers/constants';
import { formatDate } from '../../../helpers/date';
import { clickedFreeTrial, clickedInviteNeighbours } from '../../../helpers/tools';
import { H0, Text } from '../../../layout/theme/components';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';

const TextBox = styled.div`
  word-break: break-word;
`;
const StyledText = styled(Text)`
  color: ${({ theme, premium }) => (premium ? theme.textColors.quaternary : theme.backgroundColors.primary)};
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
    font-weight: bold;
  }
`;
const StyledAlert = styled(Text)`
  color: ${({ highlighted, theme }) => (highlighted ? '#FABB4A' : theme.labels.positive)};
`;
const StyledPrimaryButton = styled.button`
  background: ${({ theme }) => theme.backgroundColors.quaternary};
  color: white;
  border: 2px solid ${({ theme }) => theme.backgroundColors.quaternary};
  font-size: 0.9em;
  padding: 0.8em 2em;
  display: inline-block;
  border-radius: 25px;
  outline: 0;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.boxShadow.button};
  &:hover {
    background: ${({ theme }) => theme.backgroundColors.primary};
    color: ${({ theme }) => theme.textColors.quaternary};
  }
`;
const PremiumHeader = styled(H0)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  margin-bottom: 5%;
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
  }
`;

const TrialButton = props => {
  const trialUsed = props.resourceData.trial_exists;
  const trialEndDate = props.resourceData.trial_end_date ? formatDate(props.resourceData.trial_end_date) : null;
  const trialEnded = trialUsed && isPast(trialEndDate);
  const voted = false;
  return (
    !props.isLoading && (
      <>
        {props.showInfo &&
          (trialUsed ? (
            <TextBox>
              <StyledText premium small>
                {trialEnded ? 'Koniec okresu próbnego' : `Okres testowy do ${trialEndDate}`}
              </StyledText>
              {props.plug ? (
                <PremiumHeader>
                  {' '}
                  Chcesz kontynuować&nbsp;
                  <span>Premium</span>?
                </PremiumHeader>
              ) : (
                <StyledText small>
                  Chcesz kontynuować&nbsp;
                  <span>Premium</span>?
                </StyledText>
              )}
            </TextBox>
          ) : (
            <TextBox>
              <StyledText small>
                Wypróbuj <span>Premium</span>
              </StyledText>
            </TextBox>
          ))}
        {trialUsed ? (
          <Link to="/odblokuj">
            <StyledPrimaryButton>Głosuj</StyledPrimaryButton>
          </Link>
        ) : voted ? (
          <Link to="/zaproszenie">
            <PrimaryButton onClick={clickedInviteNeighbours()} small>
              Zaproś sąsiadów
            </PrimaryButton>
          </Link>
        ) : props.verification ? (
          <StyledAlert small highlighted>
            {statusLabels['verification']}
          </StyledAlert>
        ) : (
          <Link to="/odblokuj">
            <PrimaryButton onClick={clickedFreeTrial()} small>
              Wypróbuj bezpłatnie
            </PrimaryButton>
          </Link>
        )}
      </>
    )
  );
};

TrialButton.Proptypes = {
  showInfo: PropTypes.bool,
  plug: PropTypes.bool,
  isLoading: PropTypes.bool,
};

TrialButton.defaultProps = {
  showInfo: false,
  plug: false,
  isLoading: true,
};

const mapStateToProps = state => ({
  resourceData: state.user.currentDataResource ? state.user.currentDataResource.data : '',
  verification: state.user.currentDataResource.data ? state.user.currentDataResource.data.mortgage_number_exists : '',
  isLoading: state.user.currentDataResource ? state.user.currentDataResource.isLoading : '',
});

export default connect(
  mapStateToProps,
  null
)(TrialButton);
