import React from 'react';

const defaultAvatar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.963"
    height="47.417"
    viewBox="0 0 25.963 47.417"
    fill="#ffffff"
  >
    <defs />
    <path
      className="a"
      d="M8.623,15.81a10.42,10.42,0,0,1,.171-4.236,14.417,14.417,0,0,1,1.469-3.553,19.375,19.375,0,0,1,2.152-3.006q1.2-1.366,2.289-2.63a17.789,17.789,0,0,0,1.811-2.46,4.779,4.779,0,0,0,.717-2.494A4.014,4.014,0,0,0,15.967-5.78a4.886,4.886,0,0,0-3.314-1.093,6.407,6.407,0,0,0-3.382.888A15.018,15.018,0,0,0,6.436-3.731L.9-8.787A18.674,18.674,0,0,1,6.607-13.3,15.791,15.791,0,0,1,13.952-15a18.214,18.214,0,0,1,5.09.683,11.32,11.32,0,0,1,4.1,2.118,9.842,9.842,0,0,1,2.733,3.655,12.74,12.74,0,0,1,.991,5.227A9.163,9.163,0,0,1,26.113.471,14.871,14.871,0,0,1,24.235,3.58q-1.127,1.435-2.391,2.767A29.31,29.31,0,0,0,19.52,9.114a12.834,12.834,0,0,0-1.674,3.04,7.918,7.918,0,0,0-.41,3.655ZM13,32.413a5.666,5.666,0,0,1-4.3-1.811,6.272,6.272,0,0,1-1.708-4.475,6.361,6.361,0,0,1,1.708-4.544A5.666,5.666,0,0,1,13,19.773a5.775,5.775,0,0,1,4.339,1.811,6.291,6.291,0,0,1,1.742,4.544A6.2,6.2,0,0,1,17.334,30.6,5.775,5.775,0,0,1,13,32.413Z"
      transform="translate(-.902 15.004)"
    />
  </svg>
);

export default defaultAvatar;
