import React from 'react';

const KeeperIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="5.48" height="4.37">
    <path
      data-name="Path 1655"
      d="M.89 4.37L0 1.607l1.6.635L2.74 0l1.144 2.242 1.6-.635L4.59 4.37H.89z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default KeeperIcon;
