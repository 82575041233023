import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '../../shared/components/buttons/PrimaryButton';
import { H2 } from '../../layout/theme/components';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled(H2)`
  ${sectionStyle};
`;
const FieldsWrapper = styled.div`
  ${sectionStyle};
  justify-content: flex-start;
  margin: 5% 0;
  flex: 1;
`;
const FormSection = styled.form`
  ${sectionStyle};
  flex: 1;
`;

const RangeComponent = props => (
  <Fragment>
    <Header>Sprawa widoczna dla:</Header>
    <FormSection onSubmit={e => props.handleSubmit(e)}>
      <FieldsWrapper>{props.children}</FieldsWrapper>
      <Button type="submit">DALEJ</Button>
    </FormSection>
  </Fragment>
);

RangeComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default RangeComponent;
