import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Text } from './../../../layout/theme/components';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.textColors.tertiary};
`;
const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: ${({ labelRight }) => (labelRight ? 'row-reverse' : 'row')};
  justify-content: ${({ labelRight }) =>
    labelRight ? 'flex-end' : 'flex-start'};
  align-items: center;
  width: 100%;
  height: 5%;
  color: ${({ checked, theme }) =>
    checked ? theme.textColors.secondary : '#585858'};
  font-weight: ${({ checked }) => (checked ? '600' : '400')};
  cursor: pointer;
  margin-bottom: 6%;
  svg {
    display: inline;
    margin-right: 5px;
    vertical-align: bottom;
  }
  input[type='checkbox'] {
    display: none;
  }
  img {
    width: 2em;
    margin-right: 1em;
    filter: ${({ checked }) => (checked ? 'none' : 'grayscale(70%)')};
    opacity: ${({ checked }) => (checked ? '1' : '0.5')};
  }
`;
const Check = styled.span`
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  border: 1px solid #585858;
  vertical-align: bottom;
  margin: ${({ labelRight }) => (labelRight ? '0 1em 0 0' : '0 0 0 1em')};
  white-space: nowrap;
  &:after {
    position: absolute;
    content: ${({ checked }) => (checked ? "'\\2713'" : "''")};
    color: ${({ theme }) => theme.labels.positive};
    font-size: 24px;
    top: -5px;
    left: 0;
  }
`;
const TextWrapper = styled.div`
  flex: 1;
  text-align: left;
  white-space: nowrap;
  word-break: break-word;

  a {
    color: ${({ theme }) => theme.linkColors.secondary};
    text-decoration: underline;
  }
`;

class Checkbox extends Component {
  render() {
    const {
      name,
      checked,
      onChange,
      label,
      labelIconPath,
      labelRight
    } = this.props;

    return (
      <CheckboxWrapper>
        <CheckboxLabel checked={checked} labelRight={labelRight}>
          {labelIconPath && <img alt="" src={labelIconPath} />}
          <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
          />
          {label && (
            <TextWrapper>
              <Text
                dangerouslySetInnerHTML={{ __html: label }}
                bold={checked}
              />
            </TextWrapper>
          )}
          <Check checked={checked} labelRight={labelRight} />
        </CheckboxLabel>
      </CheckboxWrapper>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  labelRight: PropTypes.bool
};

Checkbox.defaultProps = {
  onChange: null,
  label: null,
  labelRight: false
};

export default Checkbox;
