import React from 'react';

const NotAnonymFemale = () => (
  <svg
    viewBox="0 0 143 106"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <path fill="none" d="M0 0h142.333v105.3H0z" />
    <path
      d="M47.237 23.403c-9.181 0-16.635 7.454-16.635 16.636 0 2.938.764 5.7 2.103 8.097h-14.17C9.354 48.136 1.9 55.59 1.9 64.771c0 9.182 7.454 16.636 16.635 16.636h76.561c9.181 0 16.635-7.454 16.635-16.636 0-2.938-.763-5.7-2.103-8.097h14.17c9.181 0 16.635-7.454 16.635-16.635 0-9.182-7.454-16.636-16.635-16.636H47.237z"
      fill="#f7f7f7"
    />
    <path
      d="M39.601 41.078c.545-16.485 14.08-29.681 30.697-29.681 16.617 0 30.151 13.196 30.696 29.681h.017v55.791H39.584V41.078h.017z"
      fill="#f78fa6"
    />
    <path
      d="M44.981 50.856c.449-13.595 11.612-24.479 25.317-24.479 13.704 0 24.867 10.884 25.316 24.479h.014v46.013H44.967V50.856h.014z"
      fill="#e57790"
    />
    <path d="M93.116 94.418z" fill="#767191" />
    <path
      d="M93.431 100.785a32.918 32.918 0 0 0-46.548 0h46.548z"
      fill="#f8cfd8"
      stroke="#f8cfd8"
      strokeWidth="4"
    />
    <path
      d="M75.618 85.613a1.911 1.911 0 0 0-1.91-1.911h-6.82a1.912 1.912 0 0 0-1.911 1.911v8.442c.014 3.352 10.654 3.058 10.641 0v-8.442z"
      fill="#fbe3e9"
    />
    <path
      d="M95.628 46.469c0-13.981-11.35-25.331-25.33-25.331-13.981 0-25.331 11.35-25.331 25.331V63.02c0 13.98 11.35 25.33 25.331 25.33 13.98 0 25.33-11.35 25.33-25.33V46.469z"
      fill="#fcecf0"
    />
    <path
      d="M95.642 48.694a5.342 5.342 0 0 1 5.339 5.34 5.341 5.341 0 0 1-5.339 5.339c-2.947 0-2.947-10.679 0-10.679zM44.043 59.11a5.343 5.343 0 0 1-5.34-5.34 5.342 5.342 0 0 1 5.34-5.339c2.947 0 2.947 10.679 0 10.679z"
      fill="#fcecf0"
    />
    <path
      d="M70.25 77.893a2.141 2.141 0 1 0-4.232-.45c0 .151.017.302.048.45h4.184z"
      fill="#f78ea5"
    />
    <path
      d="M74.529 77.893a2.141 2.141 0 0 0-2.092-2.59 2.14 2.14 0 0 0-2.091 2.59h4.183zM68.206 77.893a2.14 2.14 0 0 0 2.092 2.59 2.14 2.14 0 0 0 2.091-2.59h-4.183z"
      fill="#f78ea5"
    />
    <path d="M68.33 66.57a2.013 2.013 0 1 0 3.935 0H68.33z" fill="#fedee5" />
    <circle cx="55.74" cy="64.442" r="3.347" fill="#f9a8ba" />
    <circle cx="86.264" cy="64.442" r="3.347" fill="#f9a8ba" />
    <path
      d="M42.892 56.827v1.917M97.916 56.827v1.917"
      fill="none"
      stroke="#afaacb"
      strokeWidth="2"
    />
    <path
      d="M60.864 44.521h35.412c0-16.196-11.631-29.326-25.978-29.326-14.348 0-25.978 13.13-25.978 29.326h12.888l2.185-12.447"
      fill="#f78fa6"
    />
    <path
      d="M60.863 31.841h35.413c0-16.197-11.631-29.326-25.978-29.326-14.348 0-25.978 13.129-25.978 29.326h16.543z"
      fill="#8781a8"
    />
    <path
      d="M95.628 51.072v-7.796M44.967 49.907V38.618"
      fill="none"
      stroke="#e57790"
      strokeWidth="2"
    />
    <path
      d="M29.718 44.521S45.933 68.189 56.28 68.189c6.531 0 11.834-5.302 11.834-11.834 0-6.531-5.303-11.834-11.834-11.834H29.718zM111.31 44.823S95.095 68.189 84.748 68.189c-6.532 0-11.835-5.302-11.835-11.834 0-6.531 5.303-11.834 11.835-11.834 10.347 0 26.562.302 26.562.302z"
      fill="#6a6488"
    />
    <path
      d="M39.554 39.813c-14.228-1.57-20.472-7.798-20.472-10.784 0-4.83 21.317-5.161 51.364-5.161 30.047 0 53.495.331 53.495 5.161 0 2.986-8.375 9.214-22.603 10.784-.847-3.538-14.367-6.342-30.892-6.342s-30.045 2.804-30.892 6.342z"
      fill="#afaacb"
    />
    <path
      d="M39.554 39.813c-14.228-1.57-20.472-7.798-20.472-10.784 0-4.83 21.317-5.161 51.364-5.161 30.047 0 53.495.331 53.495 5.161 0 2.986-8.375 9.214-22.603 10.784-.847-3.538-14.367-6.342-30.892-6.342s-30.045 2.804-30.892 6.342z"
      fill="#afaacb"
    />
    <path
      d="M101.011 39.813c-.775-5.48-14.271-9.929-30.713-9.929-16.443 0-29.939 4.449-30.714 9.929 5.119-2.964 17.119-5.371 30.714-5.371 13.594 0 25.594 2.407 30.713 5.371z"
      fill="#8781a8"
    />
    <path
      d="M85.737 43.022c8.295.021 16.589.145 24.882.289 0 0 3.53.141 1.354 3.167-6.228 8.609-12.942 17.715-22.356 22.011-8.343 3.808-19.81-4.343-17.995-14.491 1.142-6.388 7.322-10.983 14.115-10.976zm-1.123 3c-6.928.136-12.533 8.474-9.012 15.146 3.008 5.701 10.4 7.352 18.114 1.126 5.458-4.406 10.022-9.872 14.301-15.51l.384-.511c-7.924-.132-15.834-.301-23.787-.251zM56.452 43.022c8.986.177 16.27 11.011 11.551 19.689-3.926 7.218-13.627 9.206-22.584 1.849-6.592-5.415-12.058-12.176-16.888-19.117 0 0-.64-2.332 1.187-2.422 8.911 0 17.823-.055 26.734.001zm-23.436 3.504c5.266 7.027 10.815 14.291 18.397 18.579 7.127 4.031 18.332-3.473 14.39-12.769-1.576-3.715-5.434-6.289-9.528-6.315H32.642l.374.505z"
      fill="#afaacb"
      fillRule="nonzero"
    />
    <path
      d="M70.413 47.364c2.388.089 4.633 2.228 4.371 4.655-.161 1.49-2.595 1.76-3.022-.463-.325-1.49-2.682-1.409-2.981.556-.297 1.538-3.862 2.218-2.807-1.485.546-1.917 2.17-3.294 4.439-3.263z"
      fill="#afaacb"
      fillRule="nonzero"
    />
    <path
      d="M62.317 54.856c2.645.155.384 6.064-3.884 7.481a8.104 8.104 0 0 1-3.833.308c-1.858-.301-2.766-3.629.288-2.996 2.615.508 5.004-1.411 5.997-3.885.258-.565.656-.917 1.432-.908zM90.618 54.856c2.666.162.431 6.046-3.887 7.481-2.845.946-7.25-.166-5.382-2.302.749-.856 2.217-.134 3.598-.344 2.982-.453 3.633-4.864 5.671-4.835z"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
);

export default NotAnonymFemale;
