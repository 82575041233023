import React, { useState } from 'react';

import FinancesLoginComponent from './FinancesLoginComponent';

import { loginToFinances } from '../../helpers/api/finances';
import { financesInputNames } from '../../helpers/constants';

const FinancesLoginContainer = () => {
  const [user, setUser] = useState('');
  const [code, setCode] = useState('');
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    loginToFinances(user, code, pin)
      .then(() => window.location.reload())
      .catch(e => {
        setError(e.detail);
        setLoading(false);
      });
  };

  const handleInputChange = e => {
    switch (e.name) {
      case financesInputNames.USER:
        return setUser(e.value);
      case financesInputNames.PIN:
        return setPin(e.value);
      case financesInputNames.CODE:
        return setCode(e.value);
      default:
        return null;
    }
  };

  return (
    <FinancesLoginComponent
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default FinancesLoginContainer;
