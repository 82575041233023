import React, { useState, useEffect } from 'react';

import FinancesBalanceComponent from './FinancesBalanceComponent';
import FinancesModalComponent from '../components/FinancesModalComponent';
import {
  fetchFinancial,
  postFinancial,
  logoutFromFinances
} from '../../helpers/api/finances';
import { financesBalanceStatus } from '../../helpers/constants';
import Loader from '../../shared/components/Loader';

const FinancesBalanceContainer = () => {
  const [weles, setWeles] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isOpenModal, setOpenModal] = useState(false);
  const [pin, setPin] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => checkFinancial(), [isLoading]);

  const getProperty = property => weles[property];

  const setRefreshable = () => {
    Object.entries(weles).length !== 0 && Boolean(weles.refreshable);
  };

  const formatPrice = text =>
    text.startsWith('-') ? `${text.slice(1)} zł` : `${text} zł`;

  const logout = () =>
    logoutFromFinances().then(() => window.location.reload());

  const checkFinancial = () => {
    postFinancial(pin)
      .then(() => fetchFinancial())
      .then(({ data }) => {
        setWeles(data);
        setOpenModal(false);
      })
      .catch(e => {
        setOpenModal(true);
        setError(e.detail);
      })
      .finally(() => setLoading(false));
  };

  const renderModal = () => {
    if (isOpenModal) {
      return (
        <FinancesModalComponent
          updateBalance={checkFinancial}
          handleChange={setPin}
          setOpenModal={setOpenModal}
          error={error}
        />
      );
    }
    return null;
  };

  const getStatus = () => {
    const balance = Number(weles.balance) || null;
    switch (true) {
      case balance < 0:
        return financesBalanceStatus.DUE;
      case balance === 0:
        return financesBalanceStatus.BALANCE;
      case balance > 0:
        return financesBalanceStatus.SURPLUS;
      default:
        return financesBalanceStatus.PENDING;
    }
  };

  if (isLoading) return <Loader />;
  return (
    <FinancesBalanceComponent
      getProperty={getProperty}
      refreshable={setRefreshable()}
      logoutFromFinances={logout}
      renderModal={renderModal}
      setOpenModal={setOpenModal}
      formatPrice={formatPrice}
      status={getStatus()}
    />
  );
};

export default FinancesBalanceContainer;
