import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components/macro';
import { getRequestHeaders } from '../../../helpers/request';
import TextButton from '../../../shared/components/buttons/TextButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;

  .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
  }
`;
const Controls = styled.div`
  margin: 10px 0;
`;

const PreviewPdfFile = ({ path, needAuth }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const fileData = {
    url: path,
    httpHeaders: needAuth ? getRequestHeaders() : {},
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Wrapper>
      <Document
        file={fileData}
        onLoadSuccess={onDocumentLoadSuccess}
        error="Plik nie został znaleziony lub jest uszkodzony."
        loading="trwa ładowanie dokumentu"
        className={'PDFDocument'}
      >
        <Page pageNumber={pageNumber} className={'PDFPage'} />
      </Document>
      {numPages > 1 && (
        <Controls>
          <TextButton small disabled={pageNumber <= 1} onClick={previousPage}>
            Poprzednia
          </TextButton>
          <span>
            Strona {pageNumber || (numPages ? 1 : '--')} z {numPages || '--'}
          </span>
          <TextButton small disabled={pageNumber >= numPages} onClick={nextPage}>
            Następna
          </TextButton>
        </Controls>
      )}
    </Wrapper>
  );
};

PreviewPdfFile.propTypes = {
  path: PropTypes.string.isRequired,
  needAuth: PropTypes.bool.isRequired,
};

export default PreviewPdfFile;
