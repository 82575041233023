import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { publishCase } from '../../helpers/api/case';
import { userLabels, userRoles } from '../../helpers/constants';
import { daysPassed, formatDateTime } from '../../helpers/date';
import { Caption, H2 } from '../../layout/theme/components';
import AttachmentsPreview from '../../shared/components/AttachmentsPreview';
import Button from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import Loader from '../../shared/components/Loader';
import ButtonsWrapper from '../components/ButtonsWrapper';
import { CasePreviewWrapper } from '../preview/components/CasePreviewWrapper';
import PreviewPdfFile from '../preview/components/PreviewPdfFile';
import PreviewSection from '../preview/components/PreviewSection';
import { PreviewWrapper } from '../preview/components/PreviewWrapper';
import SoundingQuestion from '../sounding/components/SoundingQuestion';
import PreviewCosts from './../../case/preview/components/PreviewCosts';
import PreviewDescription from './../../case/preview/components/PreviewDescription';
import PreviewHeader from './../../case/preview/components/PreviewHeader';
import PreviewInfo from './../../case/preview/components/PreviewInfo';
import PreviewMedia from './../../case/preview/components/PreviewMedia';
import PreviewMeeting from './../../case/preview/components/PreviewMeeting';
import PreviewPlace from './../../case/preview/components/PreviewPlace';
import DenyBubble from './../../shared/components/DenyBubble';
import Modal from './../../shared/components/Modal';

class PreviewContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      loading: false,
    };

    this.saveStep = this.saveStep.bind(this);
  }

  saveStep() {
    const { caseId, handleAfterPublish } = this.props;
    this.setState({ loading: true });
    publishCase(caseId).then(() => {
      handleAfterPublish();
      this.setState({ loading: false });
    });
  }

  toggleModal(toggle, event, callback) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ openModal: toggle }, () => (callback ? callback() : null));
  }

  addResponse(status, response) {
    const { handleResponse } = this.props;
    this.toggleModal(false, null, () => handleResponse(status, response));
  }

  renderModal() {
    const { removeCase } = this.props;
    const { openModal } = this.state;
    if (openModal) {
      return (
        <Modal closeModal={e => this.toggleModal(false, e)}>
          <DenyBubble
            leftClick={() => this.toggleModal()}
            rightClick={removeCase}
            leftButton="ANULUJ"
            rightButton="TAK"
          >
            <H2>Czy na pewno chcesz odrzucić sprawę?</H2>
            <Caption>Odrzucenie sprawy spowoduje usunięcie wprowadzonych danych.</Caption>
          </DenyBubble>
        </Modal>
      );
    }
    return null;
  }

  render() {
    const { caseDetails } = this.props;
    const { loading } = this.state;
    const created = daysPassed(caseDetails.created_at);
    const createdAt = formatDateTime(caseDetails.created_at);
    const areAttachments = caseDetails.attachments.length > 0;
    const resolution = caseDetails.resolution;
    const isResolutionFile = resolution && resolution.file;
    const meeting = caseDetails.meeting_data;
    const buttonText = resolution
      ? 'Wybierz "Publikuj", aby poddać uchwałę pod głosowanie. Po opublikowaniu nie będzie można edytować uchwały.'
      : null;

    return (
      <Fragment>
        <PreviewWrapper>
          {/* TODO: Jak back-end będzie zrobiony, to obsłużyć atrybut 'anonymous' */}
          <CasePreviewWrapper withLine>
            <PreviewInfo
              created={created}
              created_at={createdAt}
              owner={
                caseDetails.owner.display_name === 'anonymous'
                  ? userLabels[userRoles.ROLE_ANONYMOUS]
                  : caseDetails.owner.display_name
              }
              userRole={caseDetails.owner.role}
              avatarPath={caseDetails.owner.avatar && caseDetails.owner.avatar.path}
              number={caseDetails.case_number}
              range={caseDetails.range}
              rangeSlug={caseDetails.range_slug}
              previewMark
            />
          </CasePreviewWrapper>
          <PreviewHeader topic={resolution ? resolution.title : caseDetails.topic} />
          {isResolutionFile && <PreviewPdfFile path={resolution.file.path} needAuth={true} />}
          {caseDetails.place && <PreviewPlace place={caseDetails.place} />}
          {caseDetails.media.length > 0 && (
            <PreviewMedia media={caseDetails.media} meterValue={caseDetails.meter_value} />
          )}
          {meeting.length !== 0 && <PreviewMeeting meeting={meeting} />}
          <PreviewDescription
            description={caseDetails.stepTopic && caseDetails.stepTopic.description}
            resolutionContent={resolution && resolution.content}
          />
          {areAttachments && (
            <PreviewSection>
              <AttachmentsPreview attachments={caseDetails.attachments} isLabel />
            </PreviewSection>
          )}
          {(caseDetails.community_cost || caseDetails.user_cost) && (
            <PreviewCosts
              communityCost={caseDetails.community_cost}
              userCost={caseDetails.user_cost}
              costDescription={caseDetails.cost_description}
            />
          )}
          {caseDetails.sounding_question && (
            <PreviewSection title="Pytanie sondażowe:">
              <SoundingQuestion question={caseDetails.sounding_question} />
            </PreviewSection>
          )}
        </PreviewWrapper>
        {loading ? (
          <ButtonsWrapper>
            <Loader />
          </ButtonsWrapper>
        ) : (
          <ButtonsWrapper buttonText={buttonText}>
            <TextButton onClick={e => this.toggleModal(true, e)}>odrzuć</TextButton>
            {this.renderModal()}
            <Button onClick={this.saveStep}>PUBLIKUJ</Button>
          </ButtonsWrapper>
        )}
      </Fragment>
    );
  }
}

PreviewContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  handleAfterPublish: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  removeCase: PropTypes.func.isRequired,
};

export default PreviewContainer;
