import PropTypes from 'prop-types';
import React from 'react';

import FeatureComponent from './FeatureComponent';

import { connect } from 'react-redux';
import { userRoles } from '../../helpers/constants';
import { featureContent } from './components/Content';

const FeatureContainer = ({
  match: {
    params: { feature },
  },
  isKeeper,
}) => {
  const {
    thumbnailImage,
    largeImage,
    header,
    keeperDescription,
    description,
    additionalDescription,
    showBasicPremium,
  } = featureContent[feature];

  return (
    <FeatureComponent
      image={thumbnailImage}
      largeImage={largeImage}
      header={header}
      description={isKeeper && keeperDescription ? keeperDescription : description}
      additionalDescription={isKeeper ? null : additionalDescription}
      showBasicPremium={showBasicPremium}
    />
  );
};

FeatureContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      feature: PropTypes.string.isRequired,
    }),
  }),
  isKeeper: PropTypes.bool,
};

const mapStateToProps = state => ({
  isKeeper:
    state.user.profile.data &&
    (state.user.profile.data.role === userRoles.ROLE_WARDEN || state.user.profile.data.role === userRoles.ROLE_KEEPER),
});

export default connect(mapStateToProps)(FeatureContainer);
