import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import ThreeDotsIcon from '../../../assets/icons/ThreeDotsIcon';

const MenuWrapper = styled.div`
  z-index: 1;
  text-align: right;
  position: relative;
  cursor: pointer;
`;
const Menu = styled.div`
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.borderColor};
  display: block;
  position: absolute;
  right: 0;
  top: 100%;
  width: 115px;
`;
const MenuItem = styled.div`
  width: 100%;
  padding: 1em;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${({ theme }) => theme.backgroundColors.secondary};
  }
`;

const PopupMenu = props => {
  const { actions } = props;
  const availableActions = actions.filter(action => action.active);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const menuRef = useRef(null);
  const triggerRef = useRef(null);

  const handleClickOutside = event => {
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      event.stopPropagation();
      setIsCollapsed(!isCollapsed);
    } else if (menuRef.current && !menuRef.current.contains(event.target)) {
      event.stopPropagation();
      setIsCollapsed(false);
    }
  };

  const handleOnItemClick = event => {
    if (event) {
      event.stopPropagation();
    }
    setIsCollapsed(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  if (availableActions.length < 1) return null;

  return (
    <MenuWrapper>
      <div ref={triggerRef}>
        <ThreeDotsIcon />
      </div>
      {isCollapsed && (
        <Menu ref={menuRef}>
          {availableActions.map((action, index) => (
            <MenuItem key={index} onClick={handleOnItemClick}>
              {action.component}
            </MenuItem>
          ))}
        </Menu>
      )}
    </MenuWrapper>
  );
};

PopupMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node.isRequired,
      active: PropTypes.bool,
    }).isRequired
  ),
};

export default PopupMenu;
