import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import PreviewCosts from '../../preview/components/PreviewCosts';

const CostEventWrapper = styled.div`
  width: 100%;
  padding-left: 2em;
  text-align: left;
`;

const CostEvent = ({ communityCost, userCost }) => {
  return (
    <CostEventWrapper>
      <PreviewCosts communityCost={communityCost} userCost={userCost} />
    </CostEventWrapper>
  );
};

CostEvent.propTypes = {
  communityCost: PropTypes.number,
  userCost: PropTypes.number
};

CostEvent.defaultProps = {
  communityCost: 0,
  userCost: 0
};

export default CostEvent;
