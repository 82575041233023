import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { EXCEEDED_MESSAGES_AMOUNT, INVALID_INPUT_TEXT } from '../../helpers/constants';
import { Alert, H0, H1, Text } from '../../layout/theme/components';
import ArrowIcon from '../../shared/components/ArrowIcon';
import Button from '../../shared/components/buttons/PrimaryButton';
import Container from '../../shared/components/Container';
import Textarea from '../../shared/components/forms/Textarea';
import GiveKeeperIcon from './../../../assets/features/giveKeeper';
import UpgradeStepWrapper from './components/UpgradeStepWrapper';

const PictureBox = styled.div`
  margin: 1em 0;
  svg {
    max-height: 15vh;
  }
`;
const StyledText = styled(Text)`
  margin-top: 2em;
  word-break: break-word;
`;
const StyledHeader = styled(H0)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
  }
`;

const KeeperComponent = ({ goBack, keeper, updateForm, valid, submitForm, error }) => {
  return (
    <Container premiumColor>
      <ArrowIcon onClick={goBack} />
      <StyledHeader>
        Wypróbuj <span>Premium</span>
      </StyledHeader>
      <UpgradeStepWrapper>
        <H1>Houston, zgłoś się!</H1>
        <PictureBox>
          <GiveKeeperIcon />
        </PictureBox>
        <Textarea
          name="keeper"
          changeHandler={value => updateForm(value, 'keeper')}
          placeholder="Wpisz nazwę i dane kontaktowe zarządcy"
          value={keeper}
          valid={valid}
          invalidText={INVALID_INPUT_TEXT}
        />
        {error && <Alert negative>{EXCEEDED_MESSAGES_AMOUNT}</Alert>}
        <StyledText small>
          Każdy statek potrzebuje kapitana.
          <br />
          <b>Powiedz nam, kto jest twoim zarządcą, a my wyślemy mu zaproszenie w Twoim imieniu!</b>
        </StyledText>
      </UpgradeStepWrapper>
      <Button onClick={e => submitForm(e)}>Wyślij</Button>
    </Container>
  );
};

KeeperComponent.propTypes = {
  goBack: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  keeper: PropTypes.string,
  valid: PropTypes.bool,
  error: PropTypes.object,
};

KeeperComponent.defaultProps = {
  keeper: '',
  valid: false,
  error: null,
};

export default KeeperComponent;
