import React from 'react';

const partyChristin = () => {
  return (
    <svg
      viewBox="0 0 265 209"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path fill="none" d="M0 0h264.237v208.5H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h264.237v208.5H0z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="M201.534 61.425l-.021-.007c.077-.232.123-.472.139-.716a2.862 2.862 0 0 0-2.662-3.02c-.244-.016-.488 0-.728.047l-.549-2.794a5.689 5.689 0 0 1 1.456-.1c3.116.2 5.517 2.925 5.324 6.041a5.69 5.69 0 0 1-.265 1.393l.021.007a2.864 2.864 0 0 0-.139.716 2.862 2.862 0 0 0 2.662 3.02c.244.016.488-.001.728-.048l.548 2.794a5.684 5.684 0 0 1-1.455.1c-3.116-.199-5.518-2.924-5.325-6.041.03-.473.12-.941.266-1.392z"
          fill="#cdc7ea"
          fillRule="nonzero"
        />
        <path
          d="M256.367 173.002h-.022a2.87 2.87 0 0 1-.01.729 2.862 2.862 0 0 1-3.221 2.416 2.834 2.834 0 0 1-.7-.195l-1.1 2.624c.45.189.923.32 1.406.389 3.092.438 5.997-1.742 6.441-4.833.067-.47.075-.946.024-1.418h.022a2.824 2.824 0 0 1 .01-.729 2.861 2.861 0 0 1 3.22-2.417c.241.035.476.1.7.195l1.1-2.624a5.682 5.682 0 0 0-1.406-.39c-3.091-.435-5.994 1.745-6.437 4.835-.068.47-.077.946-.027 1.418zM203.62 19.631h-.022a2.782 2.782 0 0 0-.187-.705 2.861 2.861 0 0 0-3.712-1.56 2.818 2.818 0 0 0-.634.36l-1.71-2.276c.39-.294.817-.536 1.269-.72 2.892-1.177 6.24.231 7.423 3.12.18.439.304.899.369 1.369h.022c.032.24.095.476.187.7a2.861 2.861 0 0 0 3.712 1.56c.226-.092.439-.213.634-.359l1.71 2.276c-.39.294-.817.536-1.269.72-2.892 1.177-6.24-.231-7.423-3.12a5.71 5.71 0 0 1-.369-1.365z"
          fill="#56d9be"
          fillRule="nonzero"
        />
        <path
          d="M83.533 89.135c.864-25.688 22.232-46.349 47.935-46.349 25.703 0 47.071 20.661 47.935 46.349h.027v87.123H83.505V89.135h.028z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M91.933 104.405c.713-21.186 18.335-38.226 39.533-38.226 21.199-.001 38.822 17.039 39.535 38.226h.022v71.853H91.91v-71.853h.023z"
          fill="#e4778f"
          fillRule="nonzero"
        />
        <path
          d="M96.349 180.148c.674-8.938 16.685-16.089 36.337-16.089 19.652 0 35.663 7.151 36.337 16.089h.022v28.353H96.326v-28.353h.023z"
          fill="#f8adbe"
          fillRule="nonzero"
        />
        <path
          d="M163.597 186.825a52.467 52.467 0 0 0 37.087-15.361 52.468 52.468 0 0 0 15.358-37.087h-20.55a31.908 31.908 0 0 1-9.343 22.555 31.913 31.913 0 0 1-22.555 9.342l.003 20.551z"
          fill="#f8adbe"
          fillRule="nonzero"
        />
        <path
          fill="#f8adbe"
          fillRule="nonzero"
          d="M195.417 103.727h20.628v32.147h-20.628z"
        />
        <path
          d="M215.141 103.728a16.574 16.574 0 0 0 1.456-6.858c0-7.57-4.869-13.715-10.867-13.715-5.998 0-10.867 6.146-10.867 13.715a16.574 16.574 0 0 0 1.456 6.858h18.822z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          fill="#f8adbe"
          fillRule="nonzero"
          d="M145.733 165.903h27.754v18.763h-27.754z"
        />
        <path
          d="M119.262 166.213c0-1.189 2.017-2.154 4.5-2.154h16.07c2.485 0 4.5.965 4.5 2.154v9.516c-.035 3.779-25.1 3.447-25.072 0l.002-9.516z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M124.347 162.879c0-.979 1.2-1.773 2.676-1.773h9.55c1.477 0 2.676.794 2.676 1.773v7.834c-.021 3.111-14.92 2.838-14.9 0l-.002-7.834z"
          fill="#fbe3e8"
          fillRule="nonzero"
        />
        <path
          d="M98.294 186.825a52.465 52.465 0 0 1-37.086-15.361 52.468 52.468 0 0 1-15.362-37.087h20.551a31.915 31.915 0 0 0 9.34 22.555 31.913 31.913 0 0 0 22.555 9.342l.002 20.551z"
          fill="#f8adbe"
          fillRule="nonzero"
        />
        <path
          fill="#f8adbe"
          fillRule="nonzero"
          d="M45.846 106.762h20.628v29.113H45.846z"
        />
        <path
          d="M46.751 107.038a16.574 16.574 0 0 1-1.456-6.858c0-7.57 4.869-13.715 10.867-13.715 5.998 0 10.867 6.145 10.867 13.715a16.574 16.574 0 0 1-1.456 6.858H46.751z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          fill="#f8adbe"
          fillRule="nonzero"
          d="M90.87 166.125h27.916v18.545H90.87z"
        />
        <path
          d="M171.022 97.554c-.01-21.696-17.86-39.546-39.556-39.556-21.696.01-39.546 17.86-39.556 39.556V123.4c.01 21.696 17.86 39.546 39.556 39.556 21.696-.01 39.546-17.86 39.556-39.556V97.554z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M171.044 101.028c4.573.002 8.336 3.765 8.338 8.338-.003 4.573-3.765 8.335-8.338 8.338-4.602.001-4.602-16.676 0-16.676z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M116.734 94.508h55.3c0-25.292-18.163-45.8-40.568-45.8-22.405 0-40.567 20.5-40.567 45.8h20.124l3.413-19.438"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M171.022 104.742V92.57"
          fill="#757090"
          fillRule="nonzero"
          stroke="#e4778f"
        />
        <path
          d="M90.466 117.294c-4.573-.002-8.336-3.765-8.338-8.338.002-4.573 3.765-8.336 8.338-8.338 4.602-.001 4.602 16.676 0 16.676z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M91.91 102.922V85.294"
          fill="#757090"
          fillRule="nonzero"
          stroke="#e4778f"
        />
        <path
          d="M120.75 144.337c.551 4.22 5.116 7.43 10.565 7.43s10.015-3.21 10.566-7.43H120.75z"
          fill="#757090"
          fillRule="nonzero"
        />
        <path
          d="M141.881 144.194H120.75c.011.782.19 1.553.524 2.26h20.056a4.71 4.71 0 0 0 .551-2.26z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M125.888 150.655c.808-1.292 2.933-2.193 5.427-2.193 2.494 0 4.62.9 5.428 2.193a13.406 13.406 0 0 1-5.428 1.113c-1.866 0-3.712-.379-5.427-1.113z"
          fill="#f7ced7"
          fillRule="nonzero"
        />
        <path
          d="M131.233 144.072c.041-.192.062-.388.062-.585a2.795 2.795 0 0 0-2.78-2.78 2.795 2.795 0 0 0-2.78 2.78c0 .197.021.393.062.585h5.436zM136.793 144.072c.041-.192.062-.388.062-.585a2.795 2.795 0 0 0-2.78-2.78 2.795 2.795 0 0 0-2.779 2.78c0 .197.02.393.062.585h5.435zM128.565 151.395a2.801 2.801 0 0 0-.062.585 2.795 2.795 0 0 0 2.78 2.78 2.795 2.795 0 0 0 2.779-2.78 2.724 2.724 0 0 0 0-.585c-1.817.34-3.68.34-5.497 0z"
          fill="#f78ea5"
          fillRule="nonzero"
        />
        <path
          d="M128.255 131.682c-.046.217-.07.437-.07.659a3.146 3.146 0 0 0 3.13 3.13 3.147 3.147 0 0 0 3.131-3.13c0-.222-.024-.442-.07-.659h-6.121z"
          fill="#fddde4"
          fillRule="nonzero"
        />
        <path
          d="M108.672 124.524c2.855.001 5.204 2.35 5.206 5.205-.002 2.855-2.351 5.204-5.206 5.205-2.855-.001-5.204-2.35-5.206-5.205.002-2.855 2.351-5.204 5.206-5.205zM156.152 124.524c2.855.001 5.204 2.35 5.206 5.205-.002 2.855-2.351 5.204-5.206 5.205-2.855-.002-5.203-2.35-5.205-5.205.002-2.855 2.35-5.203 5.205-5.205z"
          fill="#f8a8b9"
          fillRule="nonzero"
        />
        <path
          d="M141.533 110.189c4.126-2.4 8.207-2.03 12.26 0M110.286 110.189c4.126-2.4 8.207-2.03 12.26 0"
          fill="none"
          stroke="#f67f99"
          strokeLinecap="round"
        />
        <path
          d="M60.262 94.414c2.98.002 5.431 2.453 5.433 5.433-.002 2.98-2.453 5.431-5.433 5.433-2.98-.002-5.431-2.453-5.433-5.433.002-2.98 2.453-5.431 5.433-5.433zM202.9 91.939c2.789.002 5.083 2.296 5.085 5.085-.002 2.789-2.296 5.083-5.085 5.085-2.789-.002-5.083-2.296-5.085-5.085.002-2.789 2.296-5.083 5.085-5.085z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M172.034 80.457a41.703 41.703 0 0 0-11.009-19.535c-16.18-16.164-42.791-16.164-58.971 0a41.702 41.702 0 0 0-11.008 19.535l2.116.52a39.52 39.52 0 0 1 10.434-18.514c15.335-15.319 40.554-15.319 55.889 0a39.516 39.516 0 0 1 10.434 18.514l2.115-.52z"
          fill="#cdc7ea"
          fillRule="nonzero"
        />
        <path
          d="M91.046 0l43.179 49.385L91.743 65.6 91.046 0z"
          fill="#56dabd"
          fillRule="nonzero"
        />
        <path
          d="M110.362 50.623c11.723-4.474 22.511-4.778 24.075-.679 1.564 4.099-6.683 11.058-18.407 15.532s-22.511 4.778-24.075.679c-1.564-4.099 6.684-11.059 18.407-15.532z"
          fill="#56dabd"
          fillRule="nonzero"
        />
        <path
          d="M98.653 27.263l.293 2.2 2.183.4-2 .959.293 2.2-1.53-1.608-2 .959 1.056-1.952-1.53-1.608 2.183.4 1.052-1.95zM123.261 48.796l1.646.865 1.331-1.3-.314 1.833 1.646.864-1.84.268-.314 1.833-.823-1.667-1.84.268 1.331-1.3-.823-1.664zM95.672 14.299l1.646.865 1.331-1.3-.314 1.833 1.646.865-1.839.268-.314 1.832-.823-1.667-1.84.268 1.331-1.3-.824-1.664zM106.499 38.168l2.66 1.138 1.9-2.179-.26 2.881 2.66 1.138-2.821.643-.26 2.882-1.483-2.484-2.821.643 1.9-2.178-1.475-2.484zM99.419 53.382l3.88 1.66 2.778-3.178-.38 4.2 3.88 1.66-4.114.938-.38 4.2-2.164-3.623-4.115.938L101.582 57l-2.163-3.618z"
          fill="#c7f3e9"
          fillRule="nonzero"
        />
        <path
          d="M238.937 72.116l-.008.028a3.565 3.565 0 0 0-.919-.13 3.643 3.643 0 0 0-3.666 3.58c-.003.311.033.62.108.922l-3.515.879a7.255 7.255 0 0 1-.216-1.844c.049-3.974 3.357-7.206 7.331-7.161.604.007 1.205.09 1.788.246l.008-.027c.3.082.608.126.919.13a3.642 3.642 0 0 0 3.665-3.58 3.578 3.578 0 0 0-.108-.922l3.515-.879c.151.603.223 1.223.216 1.844-.049 3.974-3.357 7.205-7.331 7.16a7.236 7.236 0 0 1-1.787-.246zM10.438 80.145l.025.013a3.643 3.643 0 0 0-.323.87 3.642 3.642 0 0 0 2.716 4.345c.303.07.613.1.924.091l.11 3.621a7.227 7.227 0 0 1-1.848-.182c-3.872-.895-6.323-4.816-5.432-8.689a7.265 7.265 0 0 1 .621-1.694l-.025-.013c.144-.275.253-.567.323-.87a3.641 3.641 0 0 0-2.716-4.344 3.59 3.59 0 0 0-.924-.091l-.11-3.622a7.227 7.227 0 0 1 1.848.182c3.872.895 6.323 4.816 5.432 8.689a7.265 7.265 0 0 1-.621 1.694zM8.295 176.09l-.008-.027a3.647 3.647 0 0 1-.916.15 3.642 3.642 0 0 1-3.745-3.5 3.59 3.59 0 0 1 .087-.924l-3.534-.8a7.266 7.266 0 0 0-.174 1.848c.138 3.972 3.518 7.128 7.49 6.994a7.27 7.27 0 0 0 1.782-.286l.008.027c.298-.089.606-.139.916-.15a3.642 3.642 0 0 1 3.745 3.5c.011.31-.019.621-.087.924l3.534.8a7.267 7.267 0 0 0 .175-1.848c-.138-3.972-3.518-7.129-7.491-6.994a7.27 7.27 0 0 0-1.782.286z"
          fill="#f8adbe"
          fillRule="nonzero"
        />
        <path
          d="M141.261 18.831l.022-.006a2.86 2.86 0 0 1 2.705-3.609c.244-.004.487.024.724.083l.684-2.764a5.705 5.705 0 0 0-1.449-.166c-3.122.049-5.652 2.654-5.61 5.776.008.473.075.944.2 1.4l-.021.006a2.862 2.862 0 0 1-2.705 3.61 2.812 2.812 0 0 1-.725-.083l-.683 2.764c.474.117.961.173 1.449.166 3.122-.048 5.653-2.654 5.611-5.776a5.671 5.671 0 0 0-.202-1.401zM48.951 28.225l.005-.021a2.86 2.86 0 0 1-2.261-3.542c.105-.392.293-.757.55-1.07l-2.199-1.807a5.686 5.686 0 0 0-1.1 2.139c-.809 3.017 1.008 6.166 4.026 6.975.148.04.299.074.45.101l-.006.022a2.862 2.862 0 0 1 2.26 3.542 2.844 2.844 0 0 1-.551 1.07l2.202 1.807a5.706 5.706 0 0 0 1.099-2.139c.809-3.018-1.008-6.167-4.025-6.976a6.349 6.349 0 0 0-.45-.101z"
          fill="#cdc7ea"
          fillRule="nonzero"
        />
        <path
          d="M26.472 101.978a2.403 2.403 0 0 1 2.391 2.392v.003a2.405 2.405 0 0 1-2.391 2.391 2.405 2.405 0 0 1-2.391-2.391v-.003a2.403 2.403 0 0 1 2.391-2.392zM67.782 57.936a2.403 2.403 0 0 1 2.39 2.39 2.404 2.404 0 0 1-2.39 2.391 2.405 2.405 0 0 1-2.391-2.391 2.404 2.404 0 0 1 2.391-2.39zM24.01 55.122a2.403 2.403 0 0 1 2.391 2.39 2.404 2.404 0 0 1-2.391 2.39 2.404 2.404 0 0 1-2.391-2.39 2.403 2.403 0 0 1 2.391-2.39zM60.312 191.498a2.403 2.403 0 0 1 2.391 2.39 2.404 2.404 0 0 1-2.391 2.39 2.404 2.404 0 0 1-2.391-2.39 2.403 2.403 0 0 1 2.391-2.39zM250.817 102.479a2.403 2.403 0 0 1 2.39 2.39 2.404 2.404 0 0 1-2.39 2.391 2.404 2.404 0 0 1-2.39-2.391 2.403 2.403 0 0 1 2.39-2.39zM168.329 28.797a2.404 2.404 0 0 1 2.391 2.39 2.403 2.403 0 0 1-2.391 2.39 2.403 2.403 0 0 1-2.39-2.39 2.403 2.403 0 0 1 2.39-2.39zM218.947 188.654a2.405 2.405 0 0 1 2.391 2.391 2.405 2.405 0 0 1-2.391 2.391 2.404 2.404 0 0 1-2.39-2.391 2.404 2.404 0 0 1 2.39-2.391zM36.765 163.969a2.404 2.404 0 0 1 2.391 2.39 2.405 2.405 0 0 1-2.391 2.391 2.405 2.405 0 0 1-2.391-2.391 2.404 2.404 0 0 1 2.391-2.39z"
          fill="#56d9be"
          fillRule="nonzero"
        />
        <circle cx="89.538" cy="116.696" r="2.643" fill="#cdc7eb" />
        <circle cx="172.538" cy="116.696" r="2.643" fill="#cdc7eb" />
        <path
          d="M121.443 116.011a2.47 2.47 0 0 0-2.468-2.468h-5.118a2.47 2.47 0 0 0-2.468 2.468 2.47 2.47 0 0 0 2.468 2.468h5.118a2.47 2.47 0 0 0 2.468-2.468zM152.69 116.011a2.47 2.47 0 0 0-2.468-2.468h-5.118a2.47 2.47 0 0 0-2.468 2.468 2.47 2.47 0 0 0 2.468 2.468h5.118a2.47 2.47 0 0 0 2.468-2.468z"
          fill="#fff"
        />
        <path
          d="M116.416 113.543a2.999 2.999 0 0 1 2.982 2.982 2.998 2.998 0 0 1-2.982 2.983 2.998 2.998 0 0 1-2.982-2.983 2.999 2.999 0 0 1 2.982-2.982zM147.663 113.543a2.999 2.999 0 0 1 2.982 2.982 2.998 2.998 0 0 1-2.982 2.983 2.998 2.998 0 0 1-2.982-2.983 2.999 2.999 0 0 1 2.982-2.982z"
          fill="#6a6488"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default partyChristin;
