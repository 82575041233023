import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Attachments from './AttachmentsPreview/Attachments';
import SliderContainer from './AttachmentsPreview/slider/SliderContainer';
import ModalWrapper from './ModalWrapper';

class AttachmentsPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPreview: false,
      clickedAttachment: 0
    };
  }

  togglePreview = clickedAttachment => {
    clickedAttachment && this.setState({ clickedAttachment });
    this.setState(state => ({
      openPreview: !state.openPreview
    }));
  };

  renderPreview() {
    const { openPreview, clickedAttachment } = this.state;
    const { attachments } = this.props;
    return (
      openPreview && (
        <ModalWrapper onClick={this.togglePreview}>
          <SliderContainer
            attachments={attachments}
            clickedAttachment={clickedAttachment}
            togglePreview={this.togglePreview}
          />
        </ModalWrapper>
      )
    );
  }

  render() {
    const { attachments, isLabel } = this.props;
    return (
      <>
        <Attachments
          attachments={attachments}
          togglePreview={clickedAttachment =>
            this.togglePreview(clickedAttachment)
          }
          isLabel={isLabel}
        />
        {this.renderPreview()}
      </>
    );
  }
}

AttachmentsPreview.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      mime_type: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  isLabel: PropTypes.bool
};

AttachmentsPreview.defaultProps = {
  isLabel: false
};

export default AttachmentsPreview;
