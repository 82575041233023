import React from 'react';

const costSingleIcon = ({
  fill = '#ffffff',
  width = '19.258',
  height = '19.258'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19.258 19.258"
  >
    <defs />
    <g
      id="Group_321"
      data-name="Group 321"
      transform="translate(-204.5 -537.758)"
    >
      <g
        id="Group_320"
        data-name="Group 320"
        transform="translate(204.5 557.016)"
      >
        <path
          id="Path_413"
          d="M9.629-18.758a9.133 9.133 0 0 1 9.129 9.129A9.133 9.133 0 0 1 9.629-.5 9.133 9.133 0 0 1 .5-9.629a9.133 9.133 0 0 1 9.129-9.129z"
          data-name="Path 413"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          id="Path_414"
          d="M9.629-7.315a.566.566 0 0 1 .41.159.539.539 0 0 1 .164.4.544.544 0 0 1-.164.4.556.556 0 0 1-.41.164H6.33a.519.519 0 0 1-.395-.169.524.524 0 0 1-.159-.364.9.9 0 0 1 .041-.292 1.08 1.08 0 0 1 .154-.272L8.41-10.44H6.422a.556.556 0 0 1-.41-.164.556.556 0 0 1-.164-.41.527.527 0 0 1 .164-.395.565.565 0 0 1 .41-.159h3.156a.523.523 0 0 1 .384.159.536.536 0 0 1 .159.395.961.961 0 0 1-.031.261.78.78 0 0 1-.143.251L7.488-7.315z"
          className="cls-2"
          data-name="Path 414"
          fill={fill}
        />
        <path
          id="Path_415"
          d="M12.98-11a.442.442 0 0 1 .364.174.56.56 0 0 1 .138.348.452.452 0 0 1-.087.266.613.613 0 0 1-.231.195l-.738.338v2.869a.605.605 0 0 1-.179.446.605.605 0 0 1-.446.179.576.576 0 0 1-.43-.179.614.614 0 0 1-.174-.446v-2.308l-.236.113a.521.521 0 0 1-.236.051.442.442 0 0 1-.364-.174.56.56 0 0 1-.138-.348.451.451 0 0 1 .087-.266.612.612 0 0 1 .23-.195l.656-.3v-2.91a.605.605 0 0 1 .179-.446.605.605 0 0 1 .446-.179.576.576 0 0 1 .43.179.615.615 0 0 1 .174.446v2.336l.318-.144A.667.667 0 0 1 12.98-11z"
          className="cls-2"
          data-name="Path 415"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default costSingleIcon;
