import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const hover = css`
  &:hover {
    background: ${({ theme }) => theme.backgroundColors.primary};
    color: ${({ theme }) => theme.buttonColors.primary};
    border: 2px solid ${({ theme }) => theme.buttonColors.primary};
  }
`;

const StyledButton = styled.button`
  background: ${({ theme, disabled }) =>
    disabled ? theme.buttonColors.inactive : theme.buttonColors.primary};
  color: white;
  border: 2px solid
    ${({ theme, disabled }) =>
      disabled ? theme.buttonColors.inactive : theme.buttonColors.primary};
  font-size: ${({ small }) => (small && '0.9em') || '1.1em'};
  padding: ${({ small }) => (small && '.8em 2em') || '1em 2.5em'};
  display: inline-block;
  border-radius: 25px;
  outline: 0;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  box-shadow: ${({ disabled, theme }) =>
    disabled ? 'none' : theme.boxShadow.button};
  ${({ disabled }) => !disabled && hover};
`;

const PrimaryButton = ({ onClick, children, type, disabled, small }) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      small={small}
    >
      {children}
    </StyledButton>
  );
};

PrimaryButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

PrimaryButton.defaultProps = {
  type: 'button',
  onClick: null,
  disabled: false
};

export default PrimaryButton;
