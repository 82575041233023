import React from 'react';
import styled from 'styled-components/macro';

import ChristinIcon from '../../../../assets/tutorial/christinTutor';

const ChristinWrapper = styled.div`
  width: 50%;
  min-width: 130px;
`;

const ChristinTutor = () => (
  <ChristinWrapper>
    <ChristinIcon />
  </ChristinWrapper>
);

export default ChristinTutor;
