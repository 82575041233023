import React, { Component } from 'react';
import { connect } from 'react-redux';
import { featureContent } from './components/Content';
import LevelPanel from './components/LevelPanel';
import LevelComponent from './LevelComponent';

class LevelContainer extends Component {
  renderPanels() {
    const level = this.props.match.params.name;

    return Object.values(featureContent).map(feature => {
      return (
        <LevelPanel
          key={feature.id}
          topic={feature.header}
          text={feature.description}
          active={feature.levels.includes(level)}
        >
          {feature.thumbnailImage}
        </LevelPanel>
      );
    });
  }
  render() {
    const level = this.props.match.params.name;
    const communityStatus = this.props.communityStatus;
    const premium = level === 'premium';

    return (
      <LevelComponent premium={premium} communityStatus={communityStatus}>
        {this.renderPanels()}
      </LevelComponent>
    );
  }
}

const mapStateToProps = state => ({
  communityStatus: state.user.currentDataResource.data
    ? state.user.currentDataResource.data.status
    : ''
});

export default connect(
  mapStateToProps,
  null
)(LevelContainer);
