import { combineReducers } from 'redux';
import * as ModalActions from './actionsTypes';

const modalReducer = (state = { open: false, content: null, isWide: false }, action) => {
  switch (action.type) {
    case ModalActions.SHOW_MODAL:
      return { open: true, content: action.payload, isWide: action.isWide };
    case ModalActions.HIDE_MODAL:
      return { open: false, content: null, isWide: false };
    default:
      return state;
  }
};

export const modal = combineReducers({
  modal: modalReducer,
});
