import React from 'react';

const emojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <g data-name="Group 521" transform="translate(-5696 -773)">
          <ellipse
            data-name="Ellipse 22"
            cx="10.876"
            cy="10.75"
            rx="10.876"
            ry="10.75"
            transform="translate(5697 774)"
            fill="#e2e2e2"
          />
          <ellipse
            data-name="Ellipse 23"
            cx="1.801"
            cy="1.801"
            rx="1.801"
            ry="1.801"
            transform="translate(5702.602 780.445)"
            fill="#cecece"
          />
          <ellipse
            data-name="Ellipse 24"
            cx="1.801"
            cy="1.801"
            rx="1.801"
            ry="1.801"
            transform="translate(5710.082 780.445)"
            fill="#cecece"
          />
          <path
            data-name="Subtraction 1"
            d="M5708.128 791.475a5.86 5.86 0 0 1-5.854-5.854h11.707a5.86 5.86 0 0 1-5.853 5.854z"
            fill="#cecece"
          />
        </g>
      </g>
    </svg>
  );
};

export default emojiIcon;
