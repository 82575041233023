import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SwitchWrapper = styled.div`
  position: relative;
  width: 34px;
`;
const SwitchLabel = styled.span`
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 14px;
  padding: 0;
  line-height: 14px;
  background-color: ${({ active, theme }) =>
    active ? theme.buttonColors.active : theme.buttonColors.inactive};
  border: 0px solid ${({ theme }) => theme.backgroundColors.primary};
  border-radius: 20px;
  transition: 0.4s;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ active }) => (active ? '0' : '22px')};
    width: 20px;
    margin: -3px;
    background: ${({ active, theme }) =>
      active
        ? theme.backgroundColors.quaternary
        : theme.backgroundColors.tertiary};
    border-radius: 20px;
    box-shadow: ${({ theme }) => theme.boxShadow.element};
    transition: 0.4s;
  }
`;

function Switch(props) {
  const { active, onChange } = props;

  return (
    <SwitchWrapper onClick={onChange}>
      <SwitchLabel active={active} />
    </SwitchWrapper>
  );
}

Switch.propTypes = {
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Switch;
