import React, { Component, Fragment } from 'react';
import styled from 'styled-components/macro';

import Modal from '../../../shared/components/Modal';
import AvatarEditorContainer from './AvatarEditorContainer';

import AddAvatarLayer from '../../../../assets/addAvatarLayer';
import AvatarDefault from '../../../shared/components/avatar/components/AvatarDefault';

const DefaultAvatarBox = styled.div`
  position: absolute;
  top: 4em;
  z-index: 5;
`;
const AvatarWrapper = styled.div`
  width: 14.5em;
  height: 14.5em;
  display: flex;
  position: relative;
  justify-self: center;
  justify-content: center;
  align-items: center;
  margin: 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.textColors.tertiary};
`;
const AddButton = styled.p`
  color: white;
  font-size: 1.1em;
  position: absolute;
  top: 10em;
  z-index: 14;
`;
const AddAvatarLayerBox = styled.div`
  position: absolute;
  top: 10em;
  opacity: 0.4;
  z-index: 5;
`;
const AvatarImageBox = styled.div`
  position: relative;
  width: 14.5em;
  height: 14.5em;
  border-radius: 100%;
  background: ${({ path }) => `url(${path}) no-repeat center`};
  background-size: cover;
  cursor: pointer;
`;

class AvatarBox extends Component {
  state = { openModal: false };

  toggleModal(toggle, event, callback) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ openModal: toggle }, () => {
      if (callback) {
        callback();
      }
    });
  }

  renderModal() {
    const { openModal } = this.state;

    if (openModal) {
      return (
        <Modal closeModal={e => this.toggleModal(false, e)} wide noBackgroundClosing>
          <AvatarEditorContainer toggleModal={e => this.toggleModal(false, e)} />
        </Modal>
      );
    }
    return null;
  }

  render() {
    const { avatarPath, userName } = this.props;
    const textButton = avatarPath ? 'Zmień' : 'Dodaj';

    return (
      <Fragment>
        <AvatarWrapper onClick={e => this.toggleModal(true, e)}>
          {avatarPath.length > 1 ? (
            <AvatarImageBox path={avatarPath} />
          ) : (
            <DefaultAvatarBox>
              <AvatarDefault name={userName} bigFont />
            </DefaultAvatarBox>
          )}
          <AddAvatarLayerBox>
            <AddAvatarLayer />
          </AddAvatarLayerBox>
          <AddButton>{textButton}</AddButton>
        </AvatarWrapper>
        {this.renderModal()}
      </Fragment>
    );
  }
}

export default AvatarBox;
