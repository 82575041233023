import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Arrow from '../components/Arrow';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';
import AddCaseIcon from './../../../../assets/addCaseIcon';
import casePanel from './../../../../assets/tutorial/casePanel.svg';
import listCases from './../../../../assets/tutorial/listCases.svg';

const arrowIcon = keyframes`
  0% {
    bottom: 20%;
    right: 10%;
    transform: translate(0) scale(1.2);
  }
  100% {
    transform: rotate(-180deg) scale(1.2);
    bottom: 40%;
    right: 40%;
  }
`;
const animationArrow = () =>
  css`
    ${arrowIcon} 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  `;

const ListCasesImage = styled.img`
  position: relative;
  width: 100%;
  opacity: 0.2;
`;
const WrapperArrow = styled.div`
  position: absolute;
  animation: ${({ isActive }) => isActive && animationArrow};
  animation-delay: 0.5s;
  animation-fill-mode: both;
  bottom: ${({ freezeArrow }) => freezeArrow && '40%'};
  right: ${({ freezeArrow }) => freezeArrow && '40%'};
  transform: ${({ freezeArrow }) => freezeArrow && 'rotate(-180deg)'};
`;
const AnimationBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 2em;
`;
const AddCaseBox = styled.div`
  position: absolute;
  width: 18%;
  bottom: 0;
  right: 2em;
  transform: ${({ isActive }) => (isActive ? 'scale(1)' : 'scale(1.2)')};
  opacity: ${({ isActive }) => (isActive ? '.2' : '1')};
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
  svg {
    width: 100%;
  }
`;
const CasePanelBox = styled.div`
  position: absolute;
  top: 21%;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.buttonColors.primary};
  width: 100%;
  border-radius: 10px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transform: ${({ isActive }) => (isActive ? 'scale(1.1)' : 'scale(0.9)')};
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
  z-index: ${({ isActive }) => (isActive ? '2' : '0')};
  img {
    width: 95%;
    margin: 0 auto;
  }
`;

const Step4 = ({ nextSlide, activeSlide }) => {
  const isActive = activeSlide === 3 ? 1 : 0;
  const freezeArrow = activeSlide === 2 || activeSlide === 4;
  return (
    <PageWrapper onButtonClick={nextSlide}>
      <TutorialHeader>
        Przejdź do sprawy, aby <b>poznać szczegóły, zagłosować, zostawić komentarz</b>
      </TutorialHeader>
      <AnimationBox>
        <CasePanelBox isActive={isActive}>
          <img alt="casePanel" src={casePanel} />
        </CasePanelBox>
        <ListCasesImage alt="listCases" src={listCases} isActive={isActive} />
        <WrapperArrow isActive={isActive} freezeArrow={freezeArrow ? 1 : 0}>
          <Arrow turn="down" />
        </WrapperArrow>
        <AddCaseBox isActive={isActive}>
          <AddCaseIcon />
        </AddCaseBox>
      </AnimationBox>
    </PageWrapper>
  );
};

Step4.propTypes = {
  nextSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.number.isRequired,
};

export default Step4;
