import React from 'react';

const ThumbYes = () => (
  <svg
    viewBox="0 0 21 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h20.904v25H0z" />
    <clipPath id="a">
      <path d="M0 0h20.904v25H0z" />
    </clipPath>
    <g>
      <path
        d="M11.28 8.668l.956-2.608c.659-1.8.232-4.55-1.598-5.527C10.043.214 9.21.054 8.539.223a1.517 1.517 0 0 0-.925.693c-.231.393-.207.851-.29 1.287-.209 1.104-.731 2.154-1.539 2.942C4.376 6.519 0 10.482 0 10.482V25h15.123c2.04.001 3.377-2.278 2.371-4.058 1.2-.768 1.61-2.386.907-3.629 1.2-.768 1.61-2.387.908-3.629 2.07-1.326 1.378-4.607-1.041-5.016-.146-.025-6.988 0-6.988 0z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ThumbYes;
