import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { areNotificationsActive } from '../../helpers/firebase';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import { Text } from '../theme/components';
import Megaphone from '../../../assets/megaphone';

const BannerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: -2em auto 2em;
  padding: 1em 1.5em;
  background: #ccf4eb;
  color: #514994;
`;
const BannerContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 440px;
  height: 100%;
`;
const IconWrapper = styled.span`
  width: 100px;
`;

const NotificationBanner = props => {
  const { pushDevices } = props;
  const notificationsActive = pushDevices
    ? areNotificationsActive(pushDevices)
    : false;

  if (notificationsActive) return null;

  return (
    <BannerWrapper>
      <BannerContent>
        <IconWrapper>
          <Megaphone />
        </IconWrapper>
        <Text small>
          <b>Włącz wyświetlanie powiadomień na tym urządzeniu</b> i nie pozwól,
          by cokolwiek Cię ominęło!
        </Text>
        <Link to="/profil">
          <PrimaryButton small>Włącz</PrimaryButton>
        </Link>
      </BannerContent>
    </BannerWrapper>
  );
};

NotificationBanner.Proptypes = {
  pushDevices: PropTypes.array
};

NotificationBanner.defaultProps = {
  pushDevices: null
};

const mapStateToProps = state => ({
  pushDevices: state.user.profile.data && state.user.profile.data.push_devices
});

export default connect(
  mapStateToProps,
  null
)(NotificationBanner);
