import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../../layout/theme/components';

const Styled = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  cursor: pointer;
  margin-bottom: 6%;
  input {
    position: absolute;
    opacity: 0;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;
const ImgAlt = styled.span`
  width: 2em;
  max-height: 2em;
  margin-right: 1em;
`;
const Label = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  min-height: ${({ description }) => (description ? '5.5em' : 'default')};
`;
const LabelText = styled.span`
  font-size: 1.6em;
  color: ${({ checked, theme }) =>
    checked ? theme.textColors.secondary : theme.textColors.tertiary};
  font-weight: ${({ checked }) => (checked ? '600' : '400')};
`;
const Description = styled.div`
  font-size: 1.1em;
  margin: 0.4em 0 1em;
  color: ${({ theme }) => theme.labels.negative};
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin-top: 1%;
  }
`;
const Checkmark = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.4em;
  width: 1.4em;
  background-color: ${({ theme, blocked }) =>
    blocked ? theme.buttonColors.inactive : theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 50%;
  &:after {
    content: '';
    width: ${({ blocked }) => (blocked ? '100%' : '.6em')};
    height: ${({ blocked }) => (blocked ? '100%' : '.6em')};
    border-radius: 50%;
    background: ${({ theme }) => theme.buttonColors.primary};
    display: ${({ checked }) => (checked ? 'flex' : 'none')};
  }
`;
const ColorIcon = styled.img`
  display: flex;
  align-self: flex-start;
  width: 2em;
  max-height: 2em;
  margin-right: 1em;
  filter: ${({ checked }) => (checked ? 'grayscale(0)' : 'grayscale(100%)')};
  opacity: ${({ checked }) => (checked ? '1' : '0.3')};
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.labels.negative};
`;

const RadioInput = ({
  name,
  checked,
  value,
  label,
  labelIconPath,
  onChange,
  description,
  blocked
}) => (
  <Styled>
    {label && (
      <LabelWrapper>
        {labelIconPath ? (
          <ColorIcon alt="" src={labelIconPath} checked={checked} />
        ) : (
          <ImgAlt />
        )}
        <Label description={description}>
          <LabelText checked={checked}>{label}</LabelText>
          {description && checked && <Description>{description}</Description>}
          {blocked && <StyledText small>Dostępne w wersji Premium</StyledText>}
        </Label>
      </LabelWrapper>
    )}
    {!blocked && (
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
    )}
    <Checkmark checked={checked} blocked={blocked} />
  </Styled>
);

RadioInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  labelIconPath: PropTypes.string,
  description: PropTypes.string,
  blocked: PropTypes.bool
};

RadioInput.defaultProps = {
  checked: false,
  label: null,
  labelIconPath: null,
  description: '',
  blocked: false
};

export default RadioInput;
