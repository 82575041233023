import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import {
  INVALID_FILE,
  INVALID_INPUT_TEXT,
  NETWORK_ERROR_TEXT
} from '../../helpers/constants';
import { Alert, InvalidText } from '../../layout/theme/components';
import FileInput from '../../shared/components/forms/FileInput';
import ThumbnailLabel from '../../shared/components/ThumbnailLabel';
import ResolutionWrapper from './components/ResolutionWrapper';

const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: ${({ withEditor }) => (withEditor ? '55vh' : '100%')};
  margin: 3% 0;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 75%;
  }
`;
const ThumbnailBox = styled.div`
  width: 100%;
  margin-top: 20%;
`;
const StyledAlert = styled(Alert)`
  margin-top: 2em;
`;

const PrepareResolutionComponent = ({
  withEditor,
  children,
  resolution,
  handleFileChange,
  attachment,
  handleRemoveFile,
  handleSubmit,
  attachSizeValid,
  isError
}) => {
  return (
    <ResolutionWrapper
      handleSubmit={e => handleSubmit(e)}
      title="Przygotuj uchwałę:"
    >
      <TemplatesWrapper withEditor={withEditor}>{children}</TemplatesWrapper>
      {isError['textError'] && (
        <StyledAlert negative>{INVALID_INPUT_TEXT}</StyledAlert>
      )}
      {isError['networkError'] && (
        <StyledAlert negative>{NETWORK_ERROR_TEXT}</StyledAlert>
      )}
      <ThumbnailBox>
        {resolution && (
          <ThumbnailLabel
            type={resolution.mime_type}
            attachment={resolution}
            path={resolution.path}
            noCancelButton
          />
        )}
        <FileInput
          title="Dodaj załącznik"
          changeHandler={(value, isFileValid) =>
            handleFileChange(value, isFileValid)
          }
          files={attachment}
          removeFile={(index, sizeValid) => handleRemoveFile(index, sizeValid)}
          multiple
        />
        {!attachSizeValid && <InvalidText>{INVALID_FILE.size}</InvalidText>}
      </ThumbnailBox>
    </ResolutionWrapper>
  );
};

PrepareResolutionComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isError: PropTypes.objectOf(
    PropTypes.shape({
      networkError: PropTypes.bool,
      textError: PropTypes.bool
    })
  ).isRequired,
  withEditor: PropTypes.bool,
  resolution: PropTypes.object,
  handleFileChange: PropTypes.func,
  attachment: PropTypes.array,
  handleRemoveFile: PropTypes.func,
  handleSubmit: PropTypes.func,
  attachSizeValid: PropTypes.bool
};

PrepareResolutionComponent.defaultProps = {
  withEditor: false,
  resolution: null,
  handleFileChange: null,
  attachment: [],
  handleRemoveFile: null,
  handleSubmit: null,
  attachSizeValid: false
};

export default PrepareResolutionComponent;
