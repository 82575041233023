import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import TutorialArrow from './../../../../assets/tutorial/tutorialArrow';

const getTurn = turn => {
  switch (turn) {
    case 'up':
      return -45;
    case 'down':
      return 135;
    case 'upLeft':
      return -90;
    case 'upRight':
      return 0;
    default:
      return 45;
  }
};

const StyledArrow = styled.div`
  width: 30px;
  height: 30px;
  margin: 5% 0;

  svg {
    fill: ${({ theme }) => theme.buttonColors.primary};
    transform: rotate(${({ turn }) => getTurn(turn)}deg);
  }
`;

const Arrow = ({ turn }) => {
  return (
    <StyledArrow turn={turn}>
      <TutorialArrow />
    </StyledArrow>
  );
};

Arrow.propTypes = {
  turn: PropTypes.oneOf(['up', 'down', 'upLeft', 'upRight'])
};

Arrow.defaultProps = {
  turn: 'up'
};

export default Arrow;
