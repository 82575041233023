import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { VOTES } from '../../../helpers/constants';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';

const Status = styled.div`
  color: ${({ color }) => color};
`;
const StatusLabel = styled.span`
  text-transform: uppercase;
`;

const VoteStatus = props => {
  const { vote, votedBy, canVoteByUser, onClick } = props;
  const voteProps = Object.values(VOTES).find(item => vote === item.value);

  return (
    <>
      {vote !== 0 && (
        <Status color={voteProps.color} small>
          <StatusLabel>{voteProps.label}</StatusLabel>
          {votedBy && ` (${votedBy})`}
        </Status>
      )}
      {canVoteByUser && (
        <TooltipWrapper
          tooltip="Tu możesz wprowadzić głosy oddane na papierowych listach do głosowania lub mailowo. Dzięki temu zostaną
        doliczone do wyników głosowania."
          displayOnMobile={false}
        >
          <SecondaryButton onClick={onClick} small>
            {vote === 0 ? 'Dodaj głos' : 'Zmień głos'}
          </SecondaryButton>
        </TooltipWrapper>
      )}
    </>
  );
};

VoteStatus.propTypes = {
  vote: PropTypes.number.isRequired,
  votedBy: PropTypes.string,
  canVoteByUser: PropTypes.bool,
  onClick: PropTypes.func,
};

VoteStatus.defaultProps = {
  votedBy: null,
  canVoteByUser: false,
  onClick: null,
};

export default VoteStatus;
