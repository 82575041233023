import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const loader10g = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 1);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const loader10m = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 1);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const loader10d = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const dotStyle = (animation, borderRadius = 1.2) => css`
  width: 1.2em;
  height: 1.2em;
  border-radius: ${borderRadius}em;
  animation: ${animation} 3s ease-in-out infinite;
`;

const LoaderContent = styled.div`
  ${dotStyle(loader10m)};

  &:before {
    ${dotStyle(loader10g)};
    content: '';
    position: absolute;
    top: 0px;
    left: -2.5em;
  }

  &:after {
    ${dotStyle(loader10d, 1)};
    content: '';
    position: absolute;
    top: 0px;
    left: 2.5em;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
`;

const Loader = () => (
  <LoaderWrapper>
    <LoaderContent />
  </LoaderWrapper>
);

export default Loader;
