import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import FileInput from '../../shared/components/forms/FileInput';
import ResolutionWrapper from './components/ResolutionWrapper';
import RadioInput from '../../shared/components/forms/RadioInput';
import { InvalidText } from '../../layout/theme/components';
import { NETWORK_ERROR_TEXT, INVALID_FILE } from '../../helpers/constants';
import pdfIcon from '../../../assets/pdfIcon.svg';

const TemplatesWrapper = styled.div`
  width: 100%;
  margin: 3% 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const ResolutionComponent = ({
  children,
  handleSubmit,
  userFile,
  attachment,
  handleRemoveFile,
  handleFileChange,
  chooseTemplate,
  isError
}) => (
  <ResolutionWrapper
    handleSubmit={e => handleSubmit(e)}
    title="Wybierz szablon uchwały:"
  >
    <TemplatesWrapper>
      <RadioInput
        name="resolution_file"
        value={null}
        checked={userFile}
        onChange={chooseTemplate}
        label="Dodaj własny plik PDF"
        labelIconPath={pdfIcon}
      />
      {userFile && (
        <FileInput
          title="Dodaj uchwałę"
          changeHandler={(value, oversized) =>
            handleFileChange(value, oversized)
          }
          files={attachment}
          maxFilesQuantity={1}
          removeFile={handleRemoveFile}
          bottomMargin
        />
      )}
      {children}
    </TemplatesWrapper>
    {isError['noFile'] && (
      <InvalidText negative>{'Nie dodano załącznika PDF.'}</InvalidText>
    )}
    {isError['networkError'] && (
      <InvalidText negative>{NETWORK_ERROR_TEXT}</InvalidText>
    )}
    {isError['invalidSize'] && <InvalidText>{INVALID_FILE.size}</InvalidText>}
  </ResolutionWrapper>
);

ResolutionComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  isError: PropTypes.object.isRequired,
  userFile: PropTypes.bool,
  attachment: PropTypes.arrayOf(PropTypes.object),
  chooseTemplate: PropTypes.func
};

ResolutionComponent.defaultProps = {
  userFile: false,
  files: [],
  chooseTemplate: null
};

export default ResolutionComponent;
