import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Anonym from '../../../../assets/anonym';

const Checkboxes = styled.label`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.textColors.tertiary};
  padding: 10px 0;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  svg {
    display: inline;
    margin-right: 5px;
    align-self: center;
  }
  input[type='checkbox'] {
    display: none;
  }
`;

const Checkbox = styled.span`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  margin: 0 5px;
  vertical-align: bottom;
  &:after {
    position: absolute;
    top: -5px;
    left: 0;
    content: ${({ isCheck }) => (isCheck ? "'\\2713'" : "''")};
    color: ${({ theme }) => theme.labels.positive};
    font-size: 1.6em;
  }
`;

class AnonymCheckbox extends Component {
  constructor() {
    super();
    this.state = {
      checked: false
    };
  }

  check(e) {
    const { changeHandler } = this.props;
    const { checked } = e.target;
    this.setState({ checked }, () => changeHandler(checked ? 1 : 0));
  }

  render() {
    return (
      <Checkboxes>
        <input
          type="checkbox"
          defaultChecked={this.state.checked}
          onChange={e => this.check(e)}
        />
        <Checkbox isCheck={this.state.checked} />
        <Anonym />
        Dodaj anonimowo
      </Checkboxes>
    );
  }
}

AnonymCheckbox.propTypes = {
  changeHandler: PropTypes.func.isRequired
};

export default AnonymCheckbox;
