import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clearQuery, queryCases, setPageYOffset } from '../../data/case/actions';
import { hideModal, showModal } from '../../data/modal/actions';
import { fetchCases, removeCase, searchCases } from '../../helpers/api/case';
import { userRoles, VOTES } from '../../helpers/constants';
import { getLastVisit } from '../../helpers/localStorage';
import { H2 } from '../../layout/theme/components';
import DenyBubble from '../../shared/components/DenyBubble';
import Loader from '../../shared/components/Loader';
import Modal from '../../shared/components/Modal';
import Voting from '../sounding/VotingContainer';
import ArchivesContainer from './ArchivesContainer';
import Case from './components/CaseItem';
import NoResultsCases from './components/NoResultsCases';
import NoSearchResults from './components/NoSearchResults';
import ResolutionItem from './components/ResolutionItem';

class ListingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      cases: [],
      currentPage: 1,
      itemsPerPage: 1,
      totalItems: 1,
      archivedItems: 0,
      openedSoundingId: null,
      caseToRemove: 0,
      openModal: false,
      eventType: 'case',
    };

    this.lastVisit = getLastVisit();
  }

  componentWillMount() {
    const { shouldClearQuery, clearQuery, queryCases } = this.props;
    if (shouldClearQuery) queryCases('') && clearQuery(false);
  }

  componentDidMount() {
    const { currentResource } = this.props;
    if (currentResource) {
      this.getCases(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { openedSoundingId } = this.state;
    const { windowYOffset, activeFilter, setPageYOffset, query, currentResource } = this.props;
    const spaceAfterQuery = query.substr(-1).indexOf(' ') === 0;
    const queryChanged = !spaceAfterQuery && prevProps.query !== query;
    const currentResourceChanged = prevProps.currentResource !== currentResource;
    const soundingClosed = prevState.openedSoundingId !== openedSoundingId && openedSoundingId === null;
    const filterChanged = activeFilter !== prevProps.activeFilter;
    if (queryChanged || currentResourceChanged || soundingClosed || filterChanged) {
      this.getCases(true);
    }
    if (prevProps.activeFilter !== activeFilter) setPageYOffset(0);
    windowYOffset !== 0 && window.scrollTo(0, windowYOffset);
  }

  handleResponse(data, page) {
    const cases = data.data ? data.data : [];

    return this.setState(state => ({
      isLoading: false,
      error: null,
      cases: page === 1 || !data.data ? cases : [...state.cases, ...data.data],
      currentPage: data.paging && data.paging.current_page,
      itemsPerPage: data.paging && data.paging.items_per_page,
      totalItems: data.paging && data.paging.total_items,
      archivedItems: data.paging && data.paging.archived_items,
    }));
  }

  showDeleteModal(event, caseId, eventType) {
    const { closeModal, openModal } = this.props;
    const typeCase = eventType === 'case';
    if (event) {
      event.stopPropagation();
    }
    openModal(
      <DenyBubble
        leftClick={closeModal}
        rightClick={e => this.handleDeleteDraft(e, caseId, closeModal)}
        leftButton="ANULUJ"
        rightButton="TAK"
      >
        <H2>Czy na pewno chcesz usunąć {typeCase ? 'sprawę' : 'uchwałę'}?</H2>
      </DenyBubble>
    );
  }

  getCases(resetPage) {
    const { query, activeFilter } = this.props;
    const page = resetPage ? 1 : this.state.currentPage + 1;
    if (resetPage) this.setState({ isLoading: true, error: null });
    if (!query)
      fetchCases(page, activeFilter)
        .then(data => this.handleResponse(data, page))
        .catch(error => this.setState({ error, isLoading: false }));
    else
      searchCases(page, query)
        .then(data => this.handleResponse(data, page))
        .catch(error => this.setState({ error, isLoading: false }));
  }

  openSounding(event, openedSoundingId, type) {
    event.stopPropagation();
    const {
      allowCommunityVote,
      history: { push },
    } = this.props;

    allowCommunityVote ? this.setState({ openedSoundingId, eventType: type }) : push('/funkcje/sounding');
  }

  formatCommentsCount = count => (count > 99 ? '+99' : count);

  getVoteLabel = voteValue =>
    voteValue !== null ? Object.values(VOTES).find(vote => voteValue === vote.value).label : '';

  redirectToCase = (event, { caseId, isDraft }) => {
    event.stopPropagation();
    const {
      setPageYOffset,
      history: { push },
    } = this.props;
    setPageYOffset(window.pageYOffset);

    const location = isDraft
      ? {
          pathname: 'sprawa/dodaj',
          state: { caseId, isDraft },
        }
      : {
          pathname: `/sprawa/${caseId}`,
          state: { goBack: true },
        };
    push(location);
  };

  handleDeleteDraft = (event, caseId, callback) => {
    event.stopPropagation();
    removeCase(caseId)
      .then(() => this.getCases(true))
      .then(callback)
      .catch(error => this.setState({ error }));
  };

  renderCase = (caseItem, index, archived = false) => {
    const { isKeeper, canArchive } = this.props;
    const { resolution, status, case_id: caseId } = caseItem;
    const isResolutionInProgress = resolution && status === 'resolution_in_progress';
    const isResolutionArchived = resolution && status === 'archived';
    const isDraft = caseItem.status === 'draft';

    if (caseItem.status !== 'unclaimed') {
      return resolution ? (
        <ResolutionItem
          key={caseId}
          caseId={caseId}
          caseItemId={index}
          topic={caseItem.topic}
          commentsCount={this.formatCommentsCount(caseItem.comments_count)}
          hasAttachment={caseItem.attachment}
          newCase={!isDraft && caseItem.new}
          newActivity={caseItem.new_activity}
          openSounding={e => this.openSounding(e, caseId, 'resolution')}
          userVote={caseItem.user_vote}
          userVoteLabel={this.getVoteLabel(caseItem.user_vote)}
          status={caseItem.status}
          clickHandler={e => this.redirectToCase(e, { caseId, isDraft })}
          isDraft={isDraft}
          showDeleteModal={e => this.showDeleteModal(e, caseId, 'resolution')}
          resolution={resolution}
          isKeeper={isKeeper}
          canVote={isResolutionInProgress && !isKeeper && !archived}
          canArchive={canArchive && !isResolutionInProgress && !isResolutionArchived && !isDraft}
          refreshListing={() => this.getCases(true)}
        />
      ) : (
        <Case
          key={caseId}
          caseId={caseId}
          caseItemId={index}
          topic={caseItem.topic}
          commentsCount={this.formatCommentsCount(caseItem.comments_count)}
          hasAttachment={caseItem.attachment}
          newCase={!isDraft && caseItem.new}
          newActivity={caseItem.new_activity}
          iconPath={caseItem.icons.category ? caseItem.icons.category.path : ''}
          categoryColor={caseItem.main_category_color}
          openSounding={e => this.openSounding(e, caseId, 'case')}
          soundingExist={caseItem.sounding_question && !archived}
          userVote={caseItem.user_vote}
          userVoteLabel={this.getVoteLabel(caseItem.user_vote)}
          status={caseItem.status}
          rangeSlug={caseItem.range_slug}
          clickHandler={e => this.redirectToCase(e, { caseId, isDraft })}
          isDraft={isDraft}
          showDeleteModal={e => this.showDeleteModal(e, caseId, 'case')}
          canArchive={canArchive && !isResolutionInProgress && !isResolutionArchived && !isDraft}
          refreshListing={() => this.getCases(true)}
        />
      );
    } else {
      return null;
    }
  };

  renderNoCases() {
    const { query, activeFilter } = this.props;

    if (query.length >= 1 && this.state.cases.length === 0) return <NoSearchResults query={query} />;

    return activeFilter ? <NoResultsCases type={activeFilter} /> : <NoResultsCases />;
  }

  renderSoundingModal() {
    const { eventType } = this.state;
    const caseItem = this.state.cases.find(caseItem => caseItem.case_id === this.state.openedSoundingId);
    const header = eventType === 'resolution' ? 'Tytuł uchwały:' : 'Pytanie sondażowe:';
    const question = eventType === 'resolution' ? caseItem.topic : caseItem.sounding_question;
    return (
      <Modal closeModal={e => this.openSounding(e, null)}>
        <Voting
          caseId={this.state.openedSoundingId}
          question={question}
          headerTitle={header}
          resolution={eventType === 'resolution'}
          inModal
          isSoundingClosed={false}
        />
      </Modal>
    );
  }

  shouldShowCases() {
    const publishedCases = this.state.cases.filter(caseItem => caseItem.status !== 'unclaimed');
    return publishedCases.length > 0;
  }

  render() {
    const {
      openedSoundingId,
      cases,
      currentPage,
      totalItems,
      itemsPerPage,
      archivedItems,
      isLoading,
      error,
    } = this.state;
    const hasMore = totalItems / itemsPerPage > currentPage;

    if (isLoading) return <Loader />;
    if (error)
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { error: true },
          }}
        />
      );
    return (
      <>
        {this.shouldShowCases() ? (
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => this.getCases(false)}
            hasMore={totalItems / itemsPerPage > currentPage}
          >
            {cases.map((caseItem, index) => this.renderCase(caseItem, index))}
          </InfiniteScroll>
        ) : (
          this.renderNoCases()
        )}
        {!hasMore &&
          archivedItems > 0 && <ArchivesContainer renderCase={this.renderCase} archivesCount={archivedItems} />}
        {openedSoundingId !== null && this.renderSoundingModal()}
      </>
    );
  }
}

ListingContainer.propTypes = {
  currentResource: PropTypes.number,
  activeFilter: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  queryCases: PropTypes.func.isRequired,
  clearQuery: PropTypes.func.isRequired,
  allowCommunityVote: PropTypes.bool,
  query: PropTypes.string,
  shouldClearQuery: PropTypes.bool,
  windowYOffset: PropTypes.number,
  isKeeper: PropTypes.bool,
};

ListingContainer.defaultProps = {
  currentResource: null,
  query: '',
  shouldClearQuery: false,
  allowCommunityVote: false,
  windowYOffset: 0,
  isKeeper: false,
};

const mapStateToProps = state => ({
  currentResource: state.user.currentResource,
  allowCommunityVote: state.user.accessControlList.data && state.user.accessControlList.data.community.sounding_vote,
  canArchive: state.user.accessControlList.data ? state.user.accessControlList.data.user.case_archive : false,
  activeFilter: state.case.activeFilter,
  query: state.case.query.query,
  shouldClearQuery: state.case.query.shouldClear,
  windowYOffset: state.case.windowYOffset,
  isKeeper:
    state.user.profile.data &&
    (state.user.profile.data.role === userRoles.ROLE_WARDEN || state.user.profile.data.role === userRoles.ROLE_KEEPER),
});

const mapDispatchToProps = dispatch => ({
  clearQuery: bool => dispatch(clearQuery(bool)),
  queryCases: value => dispatch(queryCases(value)),
  setPageYOffset: value => dispatch(setPageYOffset(value)),
  openModal: content => dispatch(showModal(content, false)),
  closeModal: () => dispatch(hideModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingContainer);
