import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { UAParser } from 'ua-parser-js';
import { addFirebaseDevice } from './api/firebase';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const initFirebase = () => {
  firebase.initializeApp(config);
};

export const isMessagingSupported = () => {
  return firebase.messaging.isSupported();
};

export const getFirebaseToken = () => {
  const messaging = firebase.messaging();
  return messaging.getToken();
};

export const getDeviceName = () => {
  var parser = new UAParser();
  const deviceID = `${parser.getOS().name} ${parser.getBrowser().name}`;

  return deviceID;
};

export const areNotificationsActive = savedDevices => {
  const oldDevice = navigator.userAgent;
  const device = getDeviceName();
  let isDeviceRegistered = false;

  if (savedDevices.includes(device)) {
    isDeviceRegistered = true;
  } else if (savedDevices.includes(oldDevice)) {
    registerNewDevice(device);
    isDeviceRegistered = true;
  }

  return isDeviceRegistered && Notification.permission === 'granted';
};

const registerNewDevice = device => {
  getFirebaseToken().then(currentToken => {
    if (currentToken) {
      addFirebaseDevice(currentToken, device);
    }
  });
};
