import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { formatCurrency, roundNumbers } from '../../helpers/tools';

const CostTileWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 65px;
  text-align: right;
  font-weight: 500;
  line-height: 1.5;
  margin: 5px 0 0 0;
  background-color: ${({ background, theme }) =>
    background ? background : theme.backgroundColors.secondary};
  padding: 1.2em;
`;
const CostDescriptionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;
`;
const CostText = styled.p`
  font-size: 13px;
  color: ${({ color, theme }) =>
    color ? color : theme.buttonColors.background};
  margin: 0;
`;
const CostValue = styled.p`
  color: ${({ color, theme }) =>
    color ? color : theme.buttonColors.background};
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;
const CostIcon = styled.div`
  display: flex;
  align-items: center;
  max-width: 65px;
`;

const CostTile = ({
  children,
  cost,
  costDescription,
  backgroundColor,
  textColor
}) => {
  const formatCost = cost => {
    const formattedNumber = Number(cost);
    return Number.isInteger(formattedNumber)
      ? formatCurrency(formattedNumber)
      : formatCurrency(roundNumbers(formattedNumber, 2));
  };

  return (
    <CostTileWrapper background={backgroundColor}>
      <CostIcon>{children}</CostIcon>
      <CostDescriptionWrapper>
        <CostText color={textColor}>{costDescription}</CostText>
        <CostValue color={textColor}>
          {cost ? formatCost(cost) : 'brak'}
        </CostValue>
      </CostDescriptionWrapper>
    </CostTileWrapper>
  );
};

CostTile.propTypes = {
  children: PropTypes.node,
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  costDescription: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};

CostTile.defaultProps = {
  cost: null,
  costDescription: null,
  backgroundColor: null,
  textColor: null
};

export default CostTile;
