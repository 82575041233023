import React from 'react';

const checkMark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.728" height="16.263">
      <path
        d="M2 9.142l4.293 4.293L17.728 2"
        fill="none"
        stroke="#57c543"
        strokeLinecap="round"
        strokeWidth="4"
      />
    </svg>
  );
};

export default checkMark;
