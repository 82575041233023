import React from 'react';
import styled from 'styled-components/macro';

import { Alert } from '../../../layout/theme/components';
import {
  NETWORK_ERROR_TEXT,
  FREQUENCY_ERROR
} from '../../../helpers/constants';

const ErrorAlert = styled(Alert)`
  margin: 2em 0 0;
`;

const ErrorComponent = ({ errorStatus }) =>
  errorStatus === 400 ? (
    <ErrorAlert negative>{FREQUENCY_ERROR}</ErrorAlert>
  ) : (
    <ErrorAlert negative>{NETWORK_ERROR_TEXT}</ErrorAlert>
  );

export default ErrorComponent;
