import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ArrowIcon from '../shared/components/ArrowIcon';
import Button from '../shared/components/buttons/PrimaryButton';
import Textarea from '../shared/components/forms/Textarea';
import FileInput from '../shared/components/forms/FileInput';
import Buttons from '../shared/components/forms/Buttons';
import Form from '../shared/components/forms/Form';
import { H2 } from '../layout/theme/components';
import Container from '../shared/components/Container';
import ThumbRadio from '../shared/components/forms/ThumbRadio';

const Header = styled(H2)`
  width: 100%;
  margin-bottom: 9%;
  word-break: break-word;
`;

const HeaderColor = styled.span`
  color: ${({ theme }) => theme.textColors.secondary};
  span {
    color: ${({ theme }) => theme.textColors.primary};
  }
`;

const EvaluationComponent = props => {
  return (
    <Container>
      <Link to="/">
        <ArrowIcon />
      </Link>
      <Header>
        Jak oceniasz aplikację
        <HeaderColor> Wspólnota.online</HeaderColor>?
      </Header>
      <Form submitHandler={props.handleSubmit}>
        <ThumbRadio
          changeHandler={value => props.handleChange(value, 'vote')}
          thumbs
        />
        <Textarea
          value={props.evaluation}
          placeholder="Dodaj opinię"
          changeHandler={value => props.handleChange(value, 'evaluation')}
        />
        <FileInput
          changeHandler={file => props.handleChange(file, 'attachment')}
          files={props.files}
          removeFile={props.removeFile}
        />
        <Buttons>
          <Button type="submit">WYŚLIJ WIADOMOŚĆ</Button>
        </Buttons>
      </Form>
    </Container>
  );
};

EvaluationComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeFile: PropTypes.func.isRequired,
  evaluation: PropTypes.string.isRequired
};

export default EvaluationComponent;
