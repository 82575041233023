import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import HighFive from '../../../assets/highFive';
import { EXCEEDED_MESSAGES_AMOUNT } from '../../helpers/constants';
import { Alert, LinkedItem, Text } from '../../layout/theme/components';
import ArrowIcon from '../../shared/components/ArrowIcon';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import Container from '../../shared/components/Container';
import InvitingData from './components/InvitingData';

const InvitingWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2em;
`;
const Headers = styled.div`
  color: ${({ theme }) => theme.textColors.secondary};
  word-break: break-word;
  width: 100%;
  margin-bottom: 10%;
`;
const StyledTextWrapper = styled.div`
  margin: 5% 0 0 0;
`;
const StyledText = styled(Text)`
  padding: 0;
  margin: 0;
`;
const HighFiveBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
`;
const FormInputs = styled.div`
  width: 100%;
  margin: 5% 0;
`;
const AddEmail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin-top: 25%;
`;

const InvitingComponent = ({
  inputs,
  inputValue,
  onChangeInput,
  onClickLink,
  removeInput,
  handleSubmit,
  valid,
  error,
  goBack,
  showAddButton,
  username,
}) => {
  return (
    <Container>
      <Link to="/">
        <ArrowIcon />
      </Link>
      <InvitingWrapper>
        <Headers>
          <Text defaultColor>
            Cześć <b>{username}</b>
          </Text>
          <StyledTextWrapper>
            <StyledText defaultColor>Zrób teraz pierwszy krok:</StyledText>
            <StyledText defaultColor bold>
              zaproś sąsiadów!
            </StyledText>
          </StyledTextWrapper>
        </Headers>
        <HighFiveBox>
          <HighFive width="200px" />
        </HighFiveBox>
        <FormInputs>
          <InvitingData
            inputs={inputs}
            inputValue={inputValue}
            removeInput={inputName => removeInput(inputName)}
            onChangeInput={e => onChangeInput(e)}
            valid={valid}
          />
          {error && <Alert negative>{EXCEEDED_MESSAGES_AMOUNT}</Alert>}
        </FormInputs>
        <AddEmail>
          {showAddButton && <LinkedItem onClick={e => onClickLink(e, 'email')}>Dodaj e-mail +</LinkedItem>}
        </AddEmail>
        <ButtonsWrapper>
          <TextButton onClick={goBack}>ZAMKNIJ</TextButton>
          <PrimaryButton onClick={handleSubmit}>ZAPROŚ</PrimaryButton>
        </ButtonsWrapper>
      </InvitingWrapper>
    </Container>
  );
};

InvitingComponent.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  removeInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  valid: PropTypes.bool,
  error: PropTypes.object,
  showAddButton: PropTypes.bool,
  username: PropTypes.string,
};

InvitingComponent.defaultProps = {
  inputValue: '',
  valid: true,
  error: null,
  showAddButton: true,
  username: '',
};

export default InvitingComponent;
