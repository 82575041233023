import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Dot = styled.div`
  position: absolute;
  left: -3px;
  top: 25px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ theme, newEvent }) =>
    newEvent ? theme.buttonColors.primary : theme.textColors.secondary};
`;

const DotDate = ({ newEvent }) => <Dot newEvent={newEvent} />;

DotDate.propTypes = {
  newEvent: PropTypes.bool
};
DotDate.defaultProps = {
  newEvent: false
};

export default DotDate;
