import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import ArrowIcon from '../../shared/components/ArrowIcon';
import { Text } from '../../layout/theme/components';

const CategoriesWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  min-height: 5em;
  width: 100%;
  background: ${({ background }) => background};
  padding: 0 0 0 1.5em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 2em 0 2em 1.5em;
  }
`;
const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  padding: 0 1em 0.5em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
    flex: none;
  }
`;
const StyledArrowIcon = styled(ArrowIcon).attrs({
  color: ({ theme }) => theme.buttonColors.primary
})``;
const StyledText = styled(Text)`
  margin-left: 1em;
`;

const CategoriesComponent = props => (
  <CategoriesWrapper>
    <Header>
      <Link to={props.goBackTo}>
        <StyledArrowIcon flex />
      </Link>
      <StyledText bold>{props.header}</StyledText>
    </Header>
    <CategoriesList>{props.children}</CategoriesList>
  </CategoriesWrapper>
);

CategoriesComponent.propTypes = {
  header: PropTypes.string.isRequired,
  goBackTo: PropTypes.string
};

CategoriesComponent.defaultProps = {
  goBackTo: '/'
};

export default CategoriesComponent;
