import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArrowIcon from '../../shared/components/ArrowIcon';
import Container from '../../shared/components/Container';
import Loader from '../../shared/components/Loader';
import SentPage from '../../shared/SentPage';
import MortgageContainer from './MortgageContainer';
import VotePremiumContainer from './VotePremiumContainer';

class UpgradeContainer extends Component {
  state = {
    sent: false,
  };

  saveHandler = () => {
    this.setState({ sent: true });
  };

  renderStep() {
    const { resourceData } = this.props;
    const level = resourceData.status;
    const votingCase = resourceData.voting_case;
    const isKeeper = resourceData.keeper_exists || resourceData.keepers.length > 0;

    switch (level) {
      case 'basic':
        return <MortgageContainer isKeeper={isKeeper} saveHandler={this.saveHandler} />;
      case 'trial':
        return <VotePremiumContainer caseId={votingCase} />;
      default:
        return null;
    }
  }

  renderSentPage() {
    const level = this.props.resourceData.status;

    switch (level) {
      case 'basic':
        return (
          <SentPage
            header="Gratulacje! Zgłoszenie przyjęto."
            text="Daj nam chwilę na sprawdzenie danych lokali i wspólnoty. Wprowadzimy je do bazy i odblokujemy Premium na okres próbny."
          />
        );
      case 'trial':
        return (
          <SentPage
            header="Dodaj do wspólnoty swoich sąsiadów!"
            text="Żeby móc decydować, potrzeba powyżej 50% głosów na tak"
            closeButton="Zrobię to później"
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { resourceData, isLoading } = this.props;
    const { sent } = this.state;

    if (isLoading) return <Loader />;
    if (sent) return this.renderSentPage();

    const premium = resourceData.status !== 'premium';
    return (
      <Container premiumColor={premium} wide>
        <ArrowIcon onClick={this.props.history.goBack} />
        {this.renderStep()}
      </Container>
    );
  }
}

UpgradeContainer.propTypes = {
  resourceData: PropTypes.object,
  isLoading: PropTypes.bool,
};

UpgradeContainer.defaultProps = {
  resourceData: null,
  isLoading: true,
};

const mapStateToProps = state => ({
  resourceData: state.user.currentDataResource ? state.user.currentDataResource.data : '',
  isLoading: state.user.currentDataResource ? state.user.currentDataResource.isLoading : '',
});

export default connect(
  mapStateToProps,
  null
)(UpgradeContainer);
