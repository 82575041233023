import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { INVALID_FILE } from '../../../helpers/constants';
import { resizeFile, sendFilesSize } from '../../../helpers/tools';
import { LinkedItem } from '../../../layout/theme/components';
import Loader from '../Loader';
import ThumbnailLabel from './../ThumbnailLabel';

const FilesNode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ bottomMargin }) => (bottomMargin ? '0 0 2em' : '4em 0 0')};
`;
const LoaderWrapper = styled.div`
  position: relative;
  height: 4em;
  font-size: 0.7em;
`;
const InputWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 1em 0;
  color: ${({ theme }) => theme.textColors.secondary};
  &:after {
    content: '+';
    display: inline-block;
    margin-left: 0.5em;
    font-size: 2.2em;
    font-weight: 700;
  }
  input[type='file'] {
    display: none;
  }
`;
const Error = styled.div`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.labels.negative};
  margin: 1em 0;
`;

class FileInput extends Component {
  state = {
    sizeValid: true,
    extensionValid: true,
    filesQuantityValid: true,
    files: [],
    isLoading: false
  };

  addFiles = event => {
    const filesState = this.state.files;
    const files = Array.from(event.target.files);
    this.setState({ isLoading: true, extensionValid: true });

    const sumFiles = filesState.length + files.length;
    const filesQuantityValid = sumFiles - 1 <= this.props.maxFilesQuantity;

    if (filesQuantityValid && files.length > 0) {
      this.handleFilesValidation(files).then(validatedFiles => {
        validatedFiles.forEach(file => this.state.files.push(file));

        const wrongFiles = files.filter(file => file.oversized);
        wrongFiles.length !== 0 && this.setState({ sizeValid: false });

        this.setState({
          filesQuantityValid: filesQuantityValid,
          isLoading: false
        });
        this.props.changeHandler(validatedFiles, this.state.sizeValid);
      });
    } else {
      this.setState({ filesQuantityValid: false, isLoading: false });
    }
  };

  handleFilesValidation = files => {
    const { maxFileSize } = this.props;
    const extensions = ['video/mp4', 'video/ogg', 'video/MPV', 'video/webm'];

    const promises = files.map(file => {
      if (file.size <= maxFileSize) {
        file['oversized'] = false;
      } else if (file.type !== 'application/pdf') {
        return resizeFile(file).then(resizedFile => {
          resizedFile['oversized'] = resizedFile.size > maxFileSize;
          resizedFile['filePath'] = this.getUrl(file);
          if (resizedFile.size > maxFileSize) sendFilesSize(file);
          return resizedFile;
        });
      } else {
        file['oversized'] = true;
        sendFilesSize(file);
      }
      if (extensions.includes(file.type)) {
        this.setState({ extensionValid: false, isLoading: false });
        return Promise.reject(this.setState({ extensionValid: false }));
      }
      file['filePath'] = this.getUrl(file);
      return file;
    });
    return Promise.all(promises);
  };

  handleRemoveFile = index => {
    let { sizeValid } = this.state;
    const filesState = this.state.files;
    const { files, maxFilesQuantity } = this.props;
    const oversizedFiles = [];
    this.state.files.forEach(file => {
      if (file.oversized) oversizedFiles.push(file);
    });
    if (
      (oversizedFiles.length === 1 && files[index] === oversizedFiles[0]) ||
      oversizedFiles.length === 0
    ) {
      sizeValid = true;
    } else {
      sizeValid = false;
    }
    this.setState({
      sizeValid: sizeValid
    });
    const newFileState = filesState.filter(file => !filesState[index]);
    this.setState({ files: newFileState });
    filesState.length <= maxFilesQuantity &&
      this.setState({ filesQuantityValid: true });
    this.props.removeFile(index, sizeValid);
  };

  getUrl = file => URL.createObjectURL(file);

  render() {
    const {
      sizeValid,
      extensionValid,
      isLoading,
      filesQuantityValid
    } = this.state;
    const {
      files,
      title,
      multiple,
      bottomMargin,
      maxFilesQuantity,
      removeFile
    } = this.props;
    const showInput = maxFilesQuantity > files.length;
    return (
      <FilesNode bottomMargin={bottomMargin}>
        {files.map((file, index) => (
          <ThumbnailLabel
            key={index}
            id={index}
            attachment={file}
            type={file.mime_type || file.type}
            path={file.path || file.filePath}
            handleRemoveFile={index => this.handleRemoveFile(index)}
            noCancelButton={removeFile ? false : true}
            oversized={file.oversized}
          />
        ))}
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!sizeValid && <Error>{INVALID_FILE.size}</Error>}
        {!extensionValid && <Error>{INVALID_FILE.extension}</Error>}
        {!filesQuantityValid && (
          <Error>{`${INVALID_FILE.quantity}${maxFilesQuantity}.`}</Error>
        )}
        {showInput && (
          <InputWrapper>
            <LinkedItem>{title}</LinkedItem>
            <input
              type="file"
              accept=".pdf, .png, .jpg, .jpeg"
              onChange={e => {
                this.addFiles(e);
                e.target.value = null;
              }}
              multiple={multiple}
            />
          </InputWrapper>
        )}
      </FilesNode>
    );
  }
}

FileInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  removeFile: PropTypes.func,
  maxFileSize: PropTypes.number,
  multiple: PropTypes.bool,
  bottomMargin: PropTypes.bool,
  maxFilesQuantity: PropTypes.number
};

FileInput.defaultProps = {
  title: 'Jeśli chcesz, dodaj załącznik',
  files: [],
  maxFileSize: 10 * 1024 * 1000,
  multiple: false,
  removeFile: null,
  bottomMargin: false,
  maxFilesQuantity: 10
};

export default FileInput;
