import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import PrimaryButton from './buttons/PrimaryButton';
import TextButton from './buttons/TextButton';
import Loader from './Loader';

const BubbleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Text = styled.div`
  padding: 2% 0;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1% 0;
  margin: auto;
  flex: 1;
`;
const LoaderWrapper = styled.div`
  margin: 0.5em;
  position: relative;
  width: 100%;
  display: flex;
  padding: 1% 0;
`;

const DenyBubble = ({ children, leftClick, rightClick, leftButton, rightButton, isLoading }) => {
  return (
    <BubbleWrapper>
      <Text>{children}</Text>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <ButtonsWrapper>
          <TextButton onClick={leftClick} small>
            {leftButton}
          </TextButton>
          <PrimaryButton onClick={rightClick} small>
            {rightButton}
          </PrimaryButton>
        </ButtonsWrapper>
      )}
    </BubbleWrapper>
  );
};

DenyBubble.propTypes = {
  children: PropTypes.node.isRequired,
  leftClick: PropTypes.func.isRequired,
  rightClick: PropTypes.func.isRequired,
  leftButton: PropTypes.string.isRequired,
  rightButton: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
DenyBubble.defaultProps = {
  isLoading: false,
};

export default DenyBubble;
