import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { getDay, getMonthName, getYear } from '../../../helpers/date';
import ActivityDate from './ActivityDate';
import DotDate from './DotDate';

const ActivityContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  margin-left: 0.5em;
  background: ${({ newEvent }) => (newEvent ? '#fffde8' : 'transparent')};
  border-left: 1px solid ${({ theme }) => theme.borderColor};
`;
const Activity = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: ${({ type }) => {
    switch (type) {
      case 'sounding':
      case 'resolution': {
        return '#d1cde4';
      }
      case 'community_cost': {
        return 'transparent';
      }
      default: {
        return 'transparent';
      }
    }
  }};
`;

const ActivityWrapper = ({ newEvent, type, date, children }) => {
  const day = getDay(date);
  const month = getMonthName(date);
  const year = getYear(date);
  const displayYear = new Date().getFullYear() !== Number(year);

  return (
    <ActivityContainer newEvent={newEvent}>
      <Activity type={type}>
        <DotDate newEvent={newEvent} />
        <ActivityDate day={day} month={month} year={displayYear ? year : null} />
        {children}
      </Activity>
    </ActivityContainer>
  );
};

ActivityWrapper.propTypes = {
  date: PropTypes.string,
  newEvent: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
};

ActivityWrapper.defaultProps = {
  date: new Date().toISOString(),
  newEvent: false,
  type: '',
  children: null,
};

export default ActivityWrapper;
