import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '../../shared/components/buttons/PrimaryButton';
import TextInput from '../../shared/components/forms/TextInput';
import FileInput from '../../shared/components/forms/FileInput';

import { INVALID_INPUT_FORMAT } from '../../helpers/constants';
import { H2 } from '../../layout/theme/components';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled(H2)`
  ${sectionStyle};
`;
const FieldsWrapper = styled.div`
  ${sectionStyle};
  justify-content: flex-start;
  margin-bottom: 5%;
  flex: 1;
`;
const FormSection = styled.form`
  ${sectionStyle};
  flex: 1;
`;
const MediaWrapper = styled.div`
  width: 100%;
  margin: 3% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const MediaComponent = ({
  handleSubmit,
  children,
  multipleSelection,
  handleInputChange,
  meterValue,
  isValid,
  handleAttachment,
  files,
  removeFile
}) => (
  <Fragment>
    <Header>Wybierz media:</Header>
    <FormSection onSubmit={e => handleSubmit(e)}>
      <FieldsWrapper>
        <MediaWrapper>{children}</MediaWrapper>
        {!multipleSelection && (
          <React.Fragment>
            <TextInput
              name="status"
              type="text"
              title="liczba"
              placeholder="Podaj stan licznika"
              onChange={handleInputChange}
              value={meterValue}
              valid={isValid}
              invalidText={INVALID_INPUT_FORMAT}
              autoFocus
            />
            <FileInput
              changeHandler={handleAttachment}
              files={files}
              removeFile={removeFile}
            />
          </React.Fragment>
        )}
      </FieldsWrapper>
      <Button type="submit" disabled={!isValid}>
        DALEJ
      </Button>
    </FormSection>
  </Fragment>
);

MediaComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleAttachment: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  meterValue: PropTypes.string.isRequired,
  isValid: PropTypes.array.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  removeFile: PropTypes.func
};

MediaComponent.defaultProps = {
  removeFile: null
};

export default MediaComponent;
