import React from 'react';

const SearchIcon = () => (
  <svg
    viewBox="0 0 26 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h25.047v25H0z" />
    <clipPath id="a">
      <path d="M0 0h25.047v25H0z" />
    </clipPath>
    <g>
      <path d="M24.539 24.538a1.575 1.575 0 0 1-2.229 0l-4.832-4.832a10.873 10.873 0 0 1-6.535 2.18C4.9 21.886 0 16.987 0 10.943S4.9 0 10.943 0c6.044 0 10.944 4.899 10.944 10.943 0 2.453-.817 4.71-2.18 6.535l4.832 4.833a1.575 1.575 0 0 1 0 2.227zM10.943 3.127a7.817 7.817 0 1 0 .002 15.634 7.817 7.817 0 0 0-.002-15.634z" />
    </g>
  </svg>
);

export default SearchIcon;
