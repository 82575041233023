import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import plusIcon from './../../../../assets/plusIcon.svg';

const MAX_DISPLAY_CATEGORIES = 5;
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  height: 12vh;
  padding: 1em;
  border-radius: 10px;
  margin: 0.5em;
  background: ${({ background }) => (background ? background : '#8e8e8e')};
`;
const ImageWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 40%;
  height: 100%;
  overflow: hidden;
  img {
    height: 80%;
    width: 100%;
    margin-right: 6%;
  }
`;
const CategoryTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  width: 60%;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.2em;
  line-height: 125%;
  text-align: right;
  color: #ffffff;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    font-size: 1.4em;
  }
`;
const PlusIconWrapper = styled.div`
  width: 5%;
  margin-left: 7%;
  img {
    width: 100%;
  }
`;

const CategoryPanel = ({ categories }) => {
  const displayingCategories = categories.slice(0, MAX_DISPLAY_CATEGORIES);
  return displayingCategories.map(category => {
    return (
      <CategoryWrapper background={category.color} key={category.id}>
        <ImageWrapper>
          <img alt="" src={category.picture.path} />
        </ImageWrapper>
        <CategoryTitle>{category.name}</CategoryTitle>
        <PlusIconWrapper>
          <img alt="plusIcon" src={plusIcon} />
        </PlusIconWrapper>
      </CategoryWrapper>
    );
  });
};

CategoryPanel.propTypes = {
  title: PropTypes.string,
  iconPath: PropTypes.string,
  background: PropTypes.string
};

CategoryPanel.defaultProps = {
  iconPath: null,
  background: '#8e8e8e'
};

export default CategoryPanel;
