export const FETCH_USER_STARTED = 'FETCH_USER_STARTED';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const SET_RESOURCE = 'SET_RESOURCE';
export const SET_DATA_RESOURCE_STARTED = 'SET_DATA_RESOURCE_STARTED';
export const SET_DATA_RESOURCE_SUCCEED = 'SET_DATA_RESOURCE_SUCCEED';
export const SAVE_COMMUNITY_STATUS = 'SAVE_COMMUNITY_STATUS';

export const SET_USERNAME_TYPE = 'SET_USERNAME_TYPE';

export const SET_PERMISSION_LIST_STARTED = 'SET_PERMISSION_LIST_STARTED';
export const SET_PERMISSION_LIST_SUCCEED = 'SET_PERMISSION_LIST_SUCCEED';

export const SET_USER_LABEL = 'SET_USER_LABEL';