import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../shared/components/buttons/PrimaryButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 1em;
  max-width: 400px;
`;
const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1;
  padding: 0 1em;
  width: 100%;
  color: ${({ theme }) => theme.textColors.secondary};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 0;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  z-index: 3;
`;

const PageWrapper = ({ closeTutorial, children, onButtonClick }) => {
  return (
    <Wrapper>
      <Page>{children}</Page>
      {onButtonClick ? (
        <ButtonWrapper>
          <Button onClick={onButtonClick}>DALEJ</Button>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <Button onClick={closeTutorial}>PRZEJDŹ DO STRONY GŁÓWNEJ</Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onButtonClick: PropTypes.func,
  closeTutorial: PropTypes.func,
  centerElements: PropTypes.bool,
};

PageWrapper.defaultProps = {
  centerElements: false,
};

export default PageWrapper;
