import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import ThumbYesIcon from './../../../assets/thumbYes';

const Thumb = css`
  position: relative;
  height: 100%;
  width: 2.5em;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  svg {
    position: absolute;
    top: 25%;
    left: 30%;
    width: 60%;
    z-index: 2;
  }
`;

const ThumbYes = styled.div`
  fill: ${({ highlighted, theme }) =>
    highlighted === 1 ? theme.labels.positive : theme.buttonColors.inactive};
  ${Thumb};
`;

const ThumbBackground = css`
  position: relative;
  width: 2em;
  height: 2em;
  z-index: 1;
  border-radius: 4px;
  background: lightgray;
`;

const BackgroundYes = styled.div`
  ${ThumbBackground};
  background: ${({ highlighted, theme }) =>
    highlighted === 1 && theme.labelsBackground.positive};
`;

const ThumbUp = memo(({ onClick, highlighted }) => (
  <ThumbYes onClick={onClick} highlighted={highlighted}>
    <BackgroundYes highlighted={highlighted} />
    <ThumbYesIcon />
    {/* TODO add label */}
  </ThumbYes>
));

ThumbUp.propTypes = {
  onClick: PropTypes.func,
  highlighted: PropTypes.number
};

ThumbUp.defaultProps = {
  onClick: null,
  highlighted: null
};

export default ThumbUp;
