import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Place from './PlaceComponent';
import RadioInput from '../../shared/components/forms/RadioInput';

import { savePlaceStep } from '../../helpers/api/case';

class PlaceContainer extends Component {
  constructor(props) {
    super(props);

    const selectedPlace = props.places.find(place => place.selected);

    this.state = {
      placeId: selectedPlace ? selectedPlace.id : props.places[0].id
    };

    this.selectPlace = this.selectPlace.bind(this);
    this.saveStep = this.saveStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  saveStep(placeId) {
    const { caseId } = this.props;
    savePlaceStep(caseId, placeId).then(() => this.props.handleNextStep());
  }

  handleSubmit(e) {
    e.preventDefault();
    const { placeId } = this.state;
    this.saveStep(placeId);
  }

  selectPlace(placeId) {
    this.setState({ placeId: placeId });
  }

  renderPlaces() {
    const { places } = this.props;
    const { placeId } = this.state;
    return places.map(place => {
      return (
        <RadioInput
          key={place.id}
          name="place"
          value={place.id}
          checked={place.id === placeId}
          onChange={this.selectPlace}
          label={place.name}
          labelIconPath={place.media ? place.media.path : null}
        />
      );
    });
  }

  render() {
    return (
      <Fragment>
        <Place handleSubmit={this.handleSubmit}>{this.renderPlaces()}</Place>
      </Fragment>
    );
  }
}

PlaceContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  places: PropTypes.array.isRequired,
  handleNextStep: PropTypes.func.isRequired
};

export default PlaceContainer;
