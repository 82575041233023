import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import AvatarBadge from './components/AvatarBadge';
import AvatarImage from './components/AvatarImage';

const AvatarWrapper = styled.div`
  position: relative;
  width: 3.5em;
`;

const AvatarIcon = ({ userRole, userName, path, width, height, borderWidth, hideBorder, showBadge }) => {
  return (
    <AvatarWrapper>
      <AvatarImage
        path={path}
        userRole={userRole}
        userName={userName}
        width={width}
        height={height}
        borderWidth={borderWidth}
        hideBorder={hideBorder}
      />
      {showBadge && <AvatarBadge userRole={userRole} width={width} />}
    </AvatarWrapper>
  );
};

AvatarIcon.propTypes = {
  userRole: PropTypes.string,
  userName: PropTypes.string,
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
  hideBorder: PropTypes.bool,
  showBadge: PropTypes.bool,
};

AvatarIcon.defaultProps = {
  userRole: null,
  userName: null,
  path: '',
  width: null,
  height: null,
  hideBorder: false,
  borderWidth: null,
  showBadge: true,
};

export default AvatarIcon;
