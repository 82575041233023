import React, { Component } from 'react';

import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import Category from './components/CategoryItem';
import CategoriesList from './components/CategoriesList';

import { fetchCategories } from '../helpers/api/categories';

class CategoriesContainer extends Component {
  state = {
    categories: [],
    isLoading: false,
    error: null,
    selected: null
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    this.setState({ isLoading: true });

    fetchCategories()
      .then(data => this.setState({ categories: data.data, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderCategories() {
    return this.state.categories.map(category => {
      return (
        <Category
          key={category.id}
          background={category.color}
          title={category.name}
          iconPath={category.picture ? category.picture.path : 'default icon'}
          onClick={() =>
            this.props.history.push(`/podkategorie/${category.id}`)
          }
        />
      );
    });
  }

  render() {
    const { categories, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <CategoriesList header="Wybierz kategorię">
        {categories.length > 0
          ? this.renderCategories()
          : 'Brak kategorii do wybrania'}
      </CategoriesList>
    );
  }
}

export default CategoriesContainer;
