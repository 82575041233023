import styled from 'styled-components/macro';

const PageBreak = styled.div`
  @media all {
    display: none;
  }

  @media print {
    display: block;
    page-break-after: always;
  }
`;

export default PageBreak;
