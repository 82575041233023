import React from 'react';

const logoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.525" height="31.698">
    <path
      data-name="Path 138"
      d="M20.277 24.912a1.987 1.987 0 0 0 .208.859c1.739 3.18 5.782 4.735 11.039 5.927a156.106 156.106 0 0 1-16.977-2.028 27.58 27.58 0 0 1-6.426-2.086 14.634 14.634 0 0 1-5.613-4.879A12.924 12.924 0 0 1 0 14.91v-.185-.183A14.554 14.554 0 0 1 14.547 0a14.554 14.554 0 0 1 14.547 14.547v.368c0 2.423-1.03 6.967-2.985 8.457q-.069.09-.144.17v-8.29a.4.4 0 0 0-.178-.387l-6.921-5.959c-.113-.087-.068-.077-.252-.117a.432.432 0 0 0-.419.121l-6.761 5.96a.4.4 0 0 0-.173.383v9.66a.534.534 0 0 0 .511.511h4.335a.535.535 0 0 0 .511-.511V20.69a.474.474 0 0 1 .4-.5q1.427-.021 2.854 0a.471.471 0 0 1 .4.487c.022 1.408.005 2.822.005 4.235z"
      fill="#56dabe"
      fillRule="evenodd"
    />
  </svg>
);

export default logoIcon;
