import React from 'react';

const Anonym = () => (
  <svg
    viewBox="0 0 24 9"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeMiterlimit="1.5"
    width="24px"
    height="9px"
  >
    <path fill="none" d="M0 0h23.347v8.356H0z" />
    <clipPath id="a">
      <path d="M0 0h23.347v8.356H0z" />
    </clipPath>
    <g>
      <path
        d="M.563 1.347a5.234 5.234 0 0 0 4.919 7.009 5.234 5.234 0 0 0 4.919-7.009c-3.285-1.5-6.564-1.533-9.838 0zM12.933 1.347a5.234 5.234 0 0 0 4.919 7.009 5.234 5.234 0 0 0 4.919-7.009c-3.285-1.5-6.564-1.533-9.838 0z"
        fill="#d1cde4"
      />
      <path
        d="M10.262 2.323a1.426 1.426 0 0 1 2.851 0"
        fill="none"
        stroke="#d1cde4"
      />
    </g>
  </svg>
);

export default Anonym;
