import React, { Component } from 'react';
import { activeUser } from '../../helpers/api/user';
import Loader from '../../shared/components/Loader';
import ActivationFailedComponent from './ActivationFailedComponent';
import UserActivationComponent from './UserActivationComponent';

class UserActivationContainer extends Component {
  state = {
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const token = this.props.match.params.token;

    activeUser(token)
      .then(() => this.setState({ isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ActivationFailedComponent />;

    return <UserActivationComponent />;
  }
}

export default UserActivationContainer;
