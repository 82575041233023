import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import BasicActiveIcon from '../../../assets/features/basicActiveIcon';
import PremiumActiveIcon from '../../../assets/features/premiumActiveIcon';
import { H0, H1, Text } from '../../layout/theme/components';
import ArrowIcon from '../../shared/components/ArrowIcon';
import Container from '../../shared/components/Container';
import TrialButton from './components/TrialButton';

const StyledLevel = styled(H0)`
  color: ${({ theme, premiumColor }) => (premiumColor ? theme.textColors.quaternary : theme.textColors.secondary)};
  margin: 0.5em 0;
`;
const StyledWhiteHeader = styled(H0)`
  color: ${({ theme }) => theme.backgroundColors.primary};
`;
const StyledHeader = styled(H1)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  margin-bottom: 2em;
`;
const StyledText = styled(Text)`
  color: ${({ theme, premiumColor, defaultColor }) => (premiumColor ? theme.backgroundColors.primary : defaultColor)};
  width: 100%;
  text-align: left;
  margin: 2em 0 0.5em 0.5em;
`;
const PictureBox = styled.div`
  max-width: 250px;
  width: 40%;
  max-height: 300px;
  margin: 2em 0;
`;
const PanelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding-bottom: 5em;
`;
const ProfitsBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
`;
const Footer = styled.div`
  background: ${({ theme }) => theme.backgroundColors.premium};
  width: 100vw;
`;
const FooterHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
`;
const FooterBox = styled.div`
  padding: 1em 0 2em;
`;

const LevelComponent = ({ children, premium, communityStatus }) => {
  return (
    <Container noBotttomPadding premiumColor={premium}>
      <Link to="/wspolnota">
        <ArrowIcon />
      </Link>
      {premium ? (
        <Fragment>
          <StyledLevel premiumColor={premium}>Premium</StyledLevel>
          <PictureBox>
            <PremiumActiveIcon />
          </PictureBox>
          <StyledHeader>
            od 0,01 zł za m<sup>2</sup> / miesiąc
          </StyledHeader>
          {communityStatus !== 'premium' && <TrialButton />}
        </Fragment>
      ) : (
        <Fragment>
          <StyledLevel>Basic</StyledLevel>
          <PictureBox>
            <BasicActiveIcon />
          </PictureBox>
          <H1 defaultColor>
            0,00 zł za m<sup>2</sup> / miesiąc
          </H1>
        </Fragment>
      )}
      <ProfitsBox>
        <StyledText premiumColor={premium} defaultColor bold big>
          Poznaj możliwości!
        </StyledText>
        <PanelsWrapper>{children}</PanelsWrapper>
      </ProfitsBox>
      {communityStatus !== 'premium' &&
        !premium && (
          <Footer>
            <FooterBox>
              <FooterHeader>
                <StyledWhiteHeader>Wypróbuj&nbsp;</StyledWhiteHeader>
                <StyledLevel premiumColor>Premium</StyledLevel>
              </FooterHeader>
              <TrialButton />
            </FooterBox>
          </Footer>
        )}
    </Container>
  );
};

LevelComponent.propTypes = {
  children: PropTypes.node.isRequired,
  premium: PropTypes.bool,
};

LevelComponent.defaultProps = {
  premium: false,
};

export default LevelComponent;
