import React, { memo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import BackArrowIcon from '../../../assets/backArrow';

const ArrowBox = styled.div`
  width: 16px;
  height: 25px;
  position: ${({ flex }) => (flex ? 'static' : 'absolute')};
  transform: ${({ transform }) => (transform ? 'rotateY(180deg)' : 'none')};
  top: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 100;

  svg {
    width: 100%;
    fill: ${({ theme, color }) => (color ? color : theme.buttonColors.primary)};
    display: block;
  }
`;

const ArrowIcon = memo(({ onClick, flex, transform, color, className }) => (
  <ArrowBox
    onClick={onClick}
    flex={flex}
    transform={transform ? 1 : 0}
    color={color}
    className={className}
  >
    <BackArrowIcon />
  </ArrowBox>
));

ArrowIcon.propTypes = {
  onClick: PropTypes.func,
  flex: PropTypes.bool,
  color: PropTypes.string,
  transform: PropTypes.bool,
  className: PropTypes.string
};

ArrowIcon.defaultProps = {
  onClick: null,
  flex: false,
  transform: false,
  className: ''
};

export default ArrowIcon;
