import PropTypes from 'prop-types';
import React from 'react';
import Autocomplete from 'react-autocomplete';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { termsUrl } from '../../helpers/constants';
import { Alert, H0, Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import Checkbox from '../../shared/components/forms/Checkbox';
import TextInput from '../../shared/components/forms/TextInput';

const RegistrationHeader = styled.div`
  margin: 3% 0 8%;
  color: #585858;
`;
const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  > div {
    flex: 1 1 auto;
    margin: 1em;
    z-index: 1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
  color: #585858;
`;
const InputLabel = styled(Text)`
  text-align: left;
  margin-top: 1em;
`;
const SelectItem = styled.div`
  background: ${({ theme, highlighted }) =>
    highlighted ? theme.backgroundColors.secondary : theme.backgroundColors.primary};
  width: 100%;
  padding: 1em 0.5em;
  text-align: left;
  font-size: 1.6em;
`;

const AutocompleteInput = styled.input`
  display: block;
  width: 100%;
  border-width: 0 0 1px 0;
  padding: 0.4em;
  z-index: 1;
  text-align: left;
  font-size: 1.6em;
  border-color: ${({ theme }) => theme.borderColor};
  outline: 0;
  ::placeholder {
    color: ${({ theme }) => theme.textColors.tertiary};
  }
  &&:focus {
    border-color: ${({ theme }) => theme.backgroundColors.tertiary};
  }
`;

const RegistrationComponent = props => {
  const { formData, cities, handleInputChange, handleCityChange, handleSubmit, error } = props;

  return (
    <>
      <RegistrationHeader>
        <H0 defaultColor>Dołącz do swojej wspólnoty!</H0>
        <Text>Decyzje już zapadają - znajdź swoją wspólnotę lub dodaj nową.</Text>
      </RegistrationHeader>
      {error && <Alert negative>{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <InputLabel small>Znajdź swoją wspólnotę:</InputLabel>
          <InlineInputs>
            <Autocomplete
              getItemValue={item => item.value}
              shouldItemRender={(item, value) => item.value.toLowerCase().indexOf(value.toLowerCase()) > -1}
              items={cities}
              renderItem={(item, isHighlighted) => <SelectItem highlighted={isHighlighted}>{item.label}</SelectItem>}
              renderInput={props => <AutocompleteInput {...props} required />}
              inputProps={{
                placeholder: 'Miejscowość',
              }}
              value={formData.town}
              onChange={e => handleCityChange(e.target.value)}
              onSelect={val => handleCityChange(val)}
            />
            <TextInput
              name="address"
              value={formData.address}
              placeholder="Dokładny adres lokalu"
              onChange={handleInputChange}
              inline
              isRequired
            />
          </InlineInputs>
        </InputGroup>
        <InputGroup>
          <InputLabel small>Wpisz swoje dane:</InputLabel>
          <TextInput
            name="name"
            value={formData.name}
            placeholder="Imię i Nazwisko"
            onChange={handleInputChange}
            isRequired
          />
          <TextInput
            name="email"
            value={formData.email}
            placeholder="Adres email"
            onChange={handleInputChange}
            isRequired
          />
        </InputGroup>
        <Checkbox
          name="termsAccepted"
          type="checkbox"
          onChange={handleInputChange}
          checked={formData.termsAccepted}
          label={`Zgoda na <a href="${termsUrl}" target="_blank">komunikację handlową</a> oraz na treści marketingowe <small>(zgoda wymagana)</small>`}
          labelRight
        />
        <PrimaryButton type="submit">Dołącz</PrimaryButton>
      </form>
      <TextButton label="MASZ JUŻ KONTO?" primary>
        <Link to="/logowanie">Zaloguj się</Link>
      </TextButton>
    </>
  );
};

RegistrationComponent.propTypes = {
  formData: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      town: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      termsAccepted: PropTypes.bool.isRequired,
    })
  ).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  handleInputChange: PropTypes.func.isRequired,
  handleCityChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
};

RegistrationComponent.defaultProps = {
  error: null,
};

export default RegistrationComponent;
