import React, { Component } from 'react';
import { connect } from 'react-redux';

import LogoutComponent from './LogoutComponent';
import { logout } from '../data/user/actions';
import Loader from '../shared/components/Loader';

class LogoutContainer extends Component {
  componentDidMount() {
    const { isLogged, logout } = this.props;
    isLogged && logout();
  }

  render() {
    const { isLogged } = this.props;

    if (isLogged) return <Loader />;

    return <LogoutComponent />;
  }
}

const mapStateToProps = state => ({
  isLogged: state.user.auth.loggedIn
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutContainer);
