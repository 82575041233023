import React, { Component } from 'react';
import { postEvaluation } from '../helpers/api/community';
import { postMedia } from '../helpers/api/media';
import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import SentPage from '../shared/SentPage';
import EvaluationComponent from './EvaluationComponent';

class EvaluationContainer extends Component {
  state = {
    form: {
      evaluation: '',
      vote: 0,
    },
    attachment: [],
    isLoading: false,
    error: null,
  };

  handleChange(value, field) {
    if (field !== 'attachment') {
      const temp = { ...this.state.form };
      temp[field] = value;
      this.setState({ form: temp });
    } else {
      this.setState({ attachment: value });
    }
  }

  handleRemoveFile = fileIndex => {
    const attachment = this.state.attachment.filter((_, index) => fileIndex !== index);
    this.setState({ attachment });
  };

  postData({ attachment, vote, evaluation }) {
    this.setState({ isLoading: true });

    postEvaluation(attachment, vote, evaluation)
      .then(() => this.setState({ sent: true, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleSubmit(e) {
    const { attachment, form } = this.state;
    e.preventDefault();
    if (attachment.length > 0) {
      postMedia(attachment[0]).then(resp => {
        form['attachment'] = resp.data.id;
        this.postData(form);
      });
    } else {
      this.postData(form);
    }
  }

  render() {
    const { sent, form, attachment, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <Error />;
    if (sent) return <SentPage header="Dziękujemy!" text="Twoje zdanie jest dla nas bardzo ważne." />;

    return (
      <EvaluationComponent
        handleChange={(value, field) => this.handleChange(value, field)}
        handleSubmit={e => this.handleSubmit(e)}
        files={attachment}
        removeFile={this.handleRemoveFile}
        evaluation={form.evaluation}
      />
    );
  }
}

export default EvaluationContainer;
