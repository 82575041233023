import React from 'react';

const Cancel = () => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="357"
    height="357"
    viewBox="0 0 357 357"
  >
    <polygon
      points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 214.2,178.5"
      id="close"
    />
  </svg>
);

export default Cancel;
