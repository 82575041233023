import React from 'react';

const AnonymMale = () => (
  <svg
    viewBox="0 0 161 112"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <rect id="ilu02" width="160.5" height="111.5" fill="none" />
    <clipPath id="_clip1">
      <rect width="160.5" height="111.5" />
    </clipPath>
    <g>
      <path
        d="M100.831,111.5c-7.068,-7.067 -16.653,-11.038 -26.648,-11.038c-9.995,0 -19.58,3.971 -26.647,11.038l53.295,0Z"
        fill="#f8cfd8"
      />
      <path
        d="M74.43,98.463c10.239,0.095 20.324,4.279 27.64,11.449c0,0 0.79,0.73 0.759,1.653c-0.033,1.036 -0.949,1.881 -1.998,1.935l-53.295,0c-1.816,-0.093 -2.623,-2.248 -0.887,-3.932c7.231,-6.921 17.078,-10.947 27.041,-11.103c0.247,-0.002 0.493,-0.003 0.74,-0.002Zm-0.469,4c-7.522,0.07 -14.949,2.541 -21.008,7.002c0,0 42.508,0.035 42.508,0.035c-6.071,-4.526 -13.853,-7.062 -21.5,-7.037Z"
        fill="#f8cfd8"
        fillRule="nonzero"
      />
      <path
        d="M52.462,1c-10.512,0 -19.047,8.535 -19.047,19.047c0,3.365 0.875,6.527 2.408,9.271l-16.224,0c-10.512,0 -19.046,8.535 -19.046,19.047c0,10.512 8.534,19.047 19.046,19.047l87.659,0c10.512,0 19.047,-8.535 19.047,-19.047c0,-3.365 -0.874,-6.527 -2.408,-9.271l16.224,0c10.512,0 19.047,-8.535 19.047,-19.047c0,-10.512 -8.535,-19.047 -19.047,-19.047l-87.659,0Z"
        fill="#f7f7f7"
      />
      <path
        d="M83.136,89.949c0,-3.402 -2.762,-6.165 -6.165,-6.165l-5.254,0c-3.402,0 -6.165,2.763 -6.165,6.165l0,7.934c0,3.402 2.763,6.165 6.165,6.165l5.254,0c3.403,0 6.165,-2.763 6.165,-6.165l0,-7.934Z"
        fill="#fadee4"
      />
      <path
        d="M103.346,49.309c0,-16.007 -12.995,-29.002 -29.002,-29.002c-16.007,0 -29.002,12.995 -29.002,29.002l0,17.551c0,16.007 12.995,29.002 29.002,29.002c16.007,0 29.002,-12.995 29.002,-29.002l0,-17.551Z"
        fill="#fcecf0"
      />
      <path
        d="M103.362,51.857c3.374,0 6.114,2.74 6.114,6.114c0,3.374 -2.74,6.114 -6.114,6.114c-3.374,0 -3.374,-12.228 0,-12.228Z"
        fill="#fcecf0"
      />
      <path
        d="M44.283,63.783c-3.374,0 -6.114,-2.739 -6.114,-6.114c0,-3.374 2.74,-6.113 6.114,-6.113c3.374,0 3.374,12.227 0,12.227Z"
        fill="#fcecf0"
      />
      <path
        d="M65.552,81.389c0.502,4.491 4.667,7.909 9.639,7.909c4.971,0 9.136,-3.418 9.638,-7.909l-19.277,0Z"
        fill="#616161"
      />
      <path d="M100.471,104.21l0,0l0,0l0,0Z" fill="#767191" />
      <path
        d="M84.829,81.389l-19.277,0c0.001,0.651 0.15,1.336 0.478,2.062l18.296,0c0.311,-0.522 0.475,-1.215 0.503,-2.062Z"
        fill="#fff"
      />
      <path
        d="M85.825,81.513c-0.07,1.411 -0.923,2.938 -0.923,2.938l-19.522,0c-0.817,-1.839 -0.836,-4.062 -0.836,-4.062l21.326,0c-0.015,0.375 -0.03,0.749 -0.045,1.124Zm-19.125,0.876c0.009,0.031 0.009,0.031 0.018,0.062l16.955,0c0.009,-0.029 0.017,-0.062 0.017,-0.062c-5.663,0 -11.327,0 -16.99,0Z"
        fill="none"
      />
      <path
        d="M61.59,66.97c0,-0.905 -0.735,-1.64 -1.64,-1.64l-6.24,0c-0.905,0 -1.64,0.735 -1.64,1.64c0,0.905 0.735,1.64 1.64,1.64l6.24,0c0.905,0 1.64,-0.735 1.64,-1.64Z"
        fill="#fadee4"
      />
      <path
        d="M98.55,66.97c0,-0.905 -0.735,-1.64 -1.64,-1.64l-6.24,0c-0.905,0 -1.64,0.735 -1.64,1.64c0,0.905 0.735,1.64 1.64,1.64l6.24,0c0.905,0 1.64,-0.735 1.64,-1.64Z"
        fill="#fadee4"
      />
      <path
        d="M70.239,88.048c0.737,-1.451 2.676,-2.462 4.952,-2.462c2.275,0 4.214,1.011 4.951,2.462c-1.425,0.787 -3.098,1.237 -4.951,1.25l0,0c-1.802,-0.015 -3.488,-0.463 -4.952,-1.25Z"
        fill="#fdd5de"
      />
      <path
        d="M86.627,52.849c2.568,0.047 5.072,1.098 6.9,2.903c0,0 0.396,0.464 0.265,0.964c-0.453,1.733 -3.122,-1.547 -6.618,-1.84c-2.291,-0.192 -4.627,0.666 -6.281,2.299c0,0 -3.06,0.163 -1.226,-1.598c1.871,-1.756 4.422,-2.743 6.96,-2.728Z"
        fill="#8781a8"
        fillRule="nonzero"
      />
      <path
        d="M64.627,52.849c2.568,0.047 5.072,1.098 6.9,2.903c0,0 0.396,0.464 0.265,0.964c-0.458,1.752 -3.153,-1.636 -6.808,-1.853c-2.235,-0.133 -4.483,0.724 -6.091,2.312c0,0 -3.105,0.206 -1.226,-1.598c1.871,-1.756 4.422,-2.743 6.96,-2.728Z"
        fill="#8781a8"
        fillRule="nonzero"
      />
      <path
        d="M72.215,69.412c-0.049,0.227 -0.074,0.458 -0.074,0.69c0,1.808 1.468,3.276 3.277,3.276c1.808,0 3.277,-1.468 3.277,-3.276c0,-0.232 -0.025,-0.463 -0.074,-0.69l-6.406,0Z"
        fill="#fdd5de"
      />
      <path
        d="M66.536,57.579c1.983,0.084 2.573,3.889 0,3.998c-2.871,0.041 -6.802,0.529 -6.065,-2.476c0.461,-1.879 3.532,-1.558 6.065,-1.522Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle cx="64.446" cy="60.512" r="2.406" fill="#616161" />
      <path
        d="M87.536,57.579c1.908,0.081 2.845,3.877 0,3.998c-2.11,0.03 -4.483,0.484 -5.547,-0.595c-1.063,-1.078 -0.306,-3.332 1.368,-3.403c1.393,-0.02 2.786,-0.02 4.179,0Z"
        fill="#fff"
        fillRule="nonzero"
      />
      <circle cx="85.446" cy="60.512" r="2.406" fill="#616161" />
      <path
        d="M48.02,42.044l0,2.415c0.023,0.085 0.038,0.174 0.046,0.264c0.112,3.989 0.002,7.98 0.002,11.97c-0.042,1.491 -2.823,2.153 -2.997,0.084c-0.102,-3.63 -0.021,-7.263 -0.005,-10.895l-0.046,0l0,-12.442c2.559,-11.941 14.972,-20.699 29.324,-20.699c0.743,0 1.48,0.023 2.212,0.069l20.653,0l-3.714,4.715l6.855,0l-3.269,4.149c3.297,3.283 5.631,7.302 6.587,11.766l0,12.442l-0.024,0c0.065,3.603 -0.024,7.208 -0.024,10.811c-0.042,1.491 -2.823,2.153 -2.998,0.084c-0.101,-3.63 -0.02,-7.263 -0.004,-10.895l-0.046,0l0,-1.433c-6.413,7.479 -19.834,8.449 -31.663,1.812c-6.035,-3.385 -10.064,-7.48 -12.11,-11.861l-8.779,7.644Z"
        fill="#8781a8"
      />
      <path
        d="M74.622,12.742c0.646,0.007 1.289,0.03 1.934,0.068l20.653,0l-3.714,4.715l6.855,0l-3.269,4.149c3.211,3.224 5.608,7.304 6.587,11.766c0,0 -0.024,12.442 -0.024,12.442c0.061,3.614 0.127,7.261 -0.025,10.846c-0.107,1.495 -2.816,1.95 -2.997,0.049c-0.09,-3.412 -0.032,-6.81 -0.008,-10.214c0,0 0.837,-2.994 -0.663,-1.432c-6.831,7.006 -18.676,6.438 -26.832,3.152c-6.825,-2.751 -13.201,-7.313 -16.32,-13.883l-8.779,7.644l0,2.415c0.206,0.852 0.068,1.171 0.078,1.76c0.049,3.505 0.116,7.032 -0.031,10.509c-0.107,1.495 -2.816,1.95 -2.996,0.049c-0.091,-3.412 -0.033,-6.81 -0.009,-10.214c0,0 -0.042,-0.681 -0.042,-0.681l0,-12.442c2.726,-12.52 16.566,-20.75 29.602,-20.698Zm-0.436,1.999c-11.891,0.084 -24.373,6.861 -27.154,18.864c0,0 -0.012,6.658 -0.012,6.658l8.466,-7.371c1.06,-0.832 2.588,-0.451 3.212,0.845c0.231,0.48 0.168,0.338 0.276,0.547c7.431,13.856 30.309,20.175 40.085,8.857c0,0 1.411,-1.126 2.609,-0.362l0,-9.122c-0.922,-4.012 -3.099,-7.657 -6.003,-10.57c0,0 -0.986,-1.475 -0.155,-2.65l0.718,-0.912c0,0 -2.946,-0.011 -2.946,-0.011c-1.445,-0.233 -2.248,-1.965 -1.358,-3.227l1.163,-1.477l-16.59,0c-1.284,-0.076 -0.674,-0.073 -2.311,-0.069Z"
        fill="none"
      />
    </g>
  </svg>
);

export default AnonymMale;
