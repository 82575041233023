import React, { memo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import NoActivities from '../../../../assets/activities/noActivities';
import CheckedActivities from '../../../../assets/activities/checkedActivities';
import NewActivities from '../../../../assets/activities/newActivities';
import PublicActivities from '../../../../assets/activities/publicActivities';

const StatusWrapper = styled.div`
  margin: 1.5em 0;
  text-align: center;

  svg {
    height: 100px;
  }
`;
const Status = styled.div`
  font-size: 1.3em;
  margin: 0.5em 0;
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.textColors.highlighted : theme.textColors.secondary};
`;

const renderActivityPicture = status => {
  switch (status) {
    case 'new':
      return <NewActivities />;
    case 'checked':
      return <CheckedActivities />;
    case 'empty':
      return <NoActivities />;
    default:
      return <PublicActivities />;
  }
};

const ActivitiesStatus = memo(({ status, newActivitiesCount }) => {
  return (
    <StatusWrapper>
      {renderActivityPicture(status)}
      {status === 'new' && (
        <Status highlighted>
          Dodano nowe zdarzenia ({newActivitiesCount})
        </Status>
      )}
      {status === 'checked' && <Status>Jesteś na bieżąco</Status>}
      {status === 'empty' && <Status>Dodaj pierwszą opinię</Status>}
    </StatusWrapper>
  );
});

ActivitiesStatus.propTypes = {
  status: PropTypes.string.isRequired,
  newActivitiesCount: PropTypes.number.isRequired
};

export default ActivitiesStatus;
