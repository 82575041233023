import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Label } from '../../../layout/theme/components';

const LabelWrapper = styled(Label)`
  display: block;
  width: 100%;
  margin: 1em 0;
`;

const SoundingLabel = ({ children }) => <LabelWrapper>{children}</LabelWrapper>;

SoundingLabel.propTypes = {
  children: PropTypes.node.isRequired
};

export default SoundingLabel;
