import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import BasicActiveIcon from '../../../../assets/features/basicActiveIcon';
import PremiumActiveIcon from '../../../../assets/features/premiumActiveIcon';

const LevelImage = ({ level }) => {
  const premium = level === 'premium';
  const basic = level === 'basic';

  return (
    <Fragment>
      {premium && <PremiumActiveIcon />}
      {basic && <BasicActiveIcon />}
    </Fragment>
  );
};

LevelImage.propTypes = {
  level: PropTypes.string.isRequired
};

export default LevelImage;
