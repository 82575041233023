import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';
import AddCaseIcon from './../../../../assets/addCaseIcon';
import listCases from './../../../../assets/tutorial/listCases.svg';

const ListCasesImage = styled.img`
  position: relative;
  height: 100%;
`;
const AnimationBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 2em;
`;
const AddCaseBox = styled.div`
  position: absolute;
  width: 18%;
  bottom: 0;
  right: 2em;
  svg {
    width: 100%;
  }
`;

const Step2 = ({ nextSlide }) => (
  <PageWrapper onButtonClick={nextSlide}>
    <TutorialHeader>
      Oto lista wszystkich spraw Twojej wspólnoty, zakładanych przez mieszkańców i zarządcę. Czerwoną ramką oznaczamy
      nowe sprawy, które pojawiły się od czasu twojej ostatniej wizyty. Jeżeli sprawa ma nowy komentarz zostanie
      oznaczona czerwoną kropką.
    </TutorialHeader>
    <AnimationBox>
      <ListCasesImage alt="listCases" src={listCases} />
      <AddCaseBox>
        <AddCaseIcon />
      </AddCaseBox>
    </AnimationBox>
  </PageWrapper>
);

Step2.propTypes = {
  nextSlide: PropTypes.func.isRequired,
};

export default Step2;
