import React, { useReducer } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { Caption } from '../../layout/theme/components';
import DropdownArrow from './DropdownArrow';

import { toggler } from './../../data/hooksReducers';

const List = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.5em 0 1em;
  border-bottom: 1px solid ${({ theme }) => theme.buttonColors.inactive};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 3em;
  padding-bottom: 0.5em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  cursor: pointer;
`;
const Line = styled.span`
  display: flex;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.buttonColors.inactive};
  background: ${({ theme }) => theme.backgroundColors.primary};
`;
const ArrowWrapper = styled.span`
  background: ${({ theme }) => theme.buttonColors.secondary};
  padding: 2px;
  border-radius: 50%;
`;

const DropdownList = ({ header, open, children }) => {
  const [state, dispatch] = useReducer(toggler, { show: open });
  return (
    <>
      <Header
        onClick={() => dispatch({ type: 'toggleState' })}
        hide={state.show ? 1 : 0}
      >
        <Caption>{header}</Caption>
        <ArrowWrapper>
          <DropdownArrow rotate={state.show} inactive />
        </ArrowWrapper>
      </Header>
      <Line />
      {state.show && <List>{children}</List>}
    </>
  );
};

DropdownList.propTypes = {
  header: PropTypes.string.isRequired,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired
};

DropdownList.defaultProps = {
  open: false
};

export default DropdownList;
