import React from 'react';
import AddingCasesIcon from '../../../../assets/features/addingCasesIcon';
import AnonymIcon from '../../../../assets/features/anonymIcon';
import BasicActiveIcon from '../../../../assets/features/basicActiveIcon';
import BasicInactiveIcon from '../../../../assets/features/basicInactiveIcon';
import CommentCasesIcon from '../../../../assets/features/commentCasesIcon';
import ContactNumbersIcon from '../../../../assets/features/contactNumbersIcon';
import FinancesIcon from '../../../../assets/features/financesIcon';
import LayerCakeIcon from '../../../../assets/features/layerCake';
import PremiumActiveIcon from '../../../../assets/features/premiumActiveIcon';
import PremiumInactiveIcon from '../../../../assets/features/premiumInactiveIcon';
import RangesIcon from '../../../../assets/features/rangesIcon';
import SoundingIcon from '../../../../assets/features/soundingIcon';
import VotingResolutionsIcon from '../../../../assets/features/votingResolutions';

export const levelIcon = {
  basic: {
    active: <BasicActiveIcon />,
    inactive: <BasicInactiveIcon />,
  },
  premium: {
    active: <PremiumActiveIcon />,
    inactive: <PremiumInactiveIcon />,
  },
};

export const featureContent = {
  addCase: {
    header: 'Dodawanie spraw',
    description: 'Twórz własne sprawy, korzystając z listy gotowych kategorii',
    additionalDescription: '',
    thumbnailImage: <AddingCasesIcon />,
    largeImage: false,
    levels: ['basic', 'premium'],
    showBasicPremium: true,
  },
  comment: {
    header: 'Komentowanie',
    description: 'Podejmuj dyskusje z sąsiadami, aby szybciej rozwiązywać spory i podejmować uchwały',
    additionalDescription: '',
    thumbnailImage: <CommentCasesIcon />,
    largeImage: false,
    levels: ['basic', 'premium'],
    showBasicPremium: true,
  },
  anonymity: {
    header: 'Anonimowość',
    description: 'Nowe sprawy możesz dodawać zarówno imiennie, jak i anonimowo',
    additionalDescription: '',
    thumbnailImage: <AnonymIcon />,
    largeImage: false,
    levels: ['basic', 'premium'],
    showBasicPremium: true,
  },
  sounding: {
    header: 'Głosowanie sondażowe',
    description: 'Bez pisania. Jednym kliknięciem poinformuj wszystkich czy jesteś za, czy przeciw',
    additionalDescription: '',
    thumbnailImage: <SoundingIcon />,
    largeImage: false,
    levels: ['premium'],
    showBasicPremium: true,
  },
  caseRanges: {
    header: 'Zasięgi spraw',
    description:
      'Jeśli nie chcesz wiedzieć, że w budynku obok kolejny raz zepsuła się winda, dzięki zasięgom spraw zobaczysz tylko te sprawy, które dotyczą Twojego mieszkania!',
    additionalDescription: '',
    thumbnailImage: <RangesIcon />,
    largeImage: false,
    levels: ['premium'],
    showBasicPremium: true,
  },
  votePower: {
    header: 'Kalkulator siły głosu',
    description:
      'Sprawdź jaki jest rozkład sił głosów w Twojej wspólnocie. Dowiedz się z kim zagadać, żeby szybciej przegłosowywać uchwały!',
    additionalDescription: '',
    thumbnailImage: <LayerCakeIcon />,
    largeImage: false,
    levels: ['premium'],
    showBasicPremium: true,
  },
  voteResolution: {
    header: 'Głosowanie uchwał',
    description: 'Decyzyjność na jeden klik. Głosuj uchwały w kilka sekund, bez wychodzenia z domu',
    additionalDescription: '',
    thumbnailImage: <VotingResolutionsIcon />,
    largeImage: false,
    levels: ['premium'],
    showBasicPremium: true,
  },
  finances: {
    header: 'Finanse',
    description: 'W celu aktywacji modułu w Twojej wspólnocie skontaktuj się z Twoim zarządcą.',
    keeperDescription: (
      <>
        W celu aktywacji modułu w twojej wspólnocie skontaktuj się z nami na{' '}
        <a href="mailto:pomoc@wspolnota.online">pomoc@wspolnota.online</a>
      </>
    ),
    additionalDescription:
      'Pełen wgląd w bilans Twoich wpłat, a także kosztów ponoszonych przez wspólnotę. Możliwość płatności bezpośrednio przez aplikację!',
    thumbnailImage: <FinancesIcon />,
    largeImage: true,
    levels: ['premium'],
    showBasicPremium: false,
  },
  contactNumbers: {
    header: 'Numery kontaktowe',
    description: 'Cała baza numerów kontaktowych do Zarządcy i usługodawców w jednym miejscu.',
    additionalDescription: '',
    thumbnailImage: <ContactNumbersIcon />,
    largeImage: false,
    levels: ['premium'],
    showBasicPremium: true,
  },
};
