import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from './../shared/components/buttons/PrimaryButton';

import PageNotFoundIcon from './../../assets/pageNotFoundIcon';
import { H0, H2, Text } from '../layout/theme/components';

const PageNotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 2em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
    justify-content: center;
  }
`;
const ImageWrapper = styled.div`
  min-width: 250px;
  order: 0;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    order: -1;
    min-width: 400px;
  }
`;
const Headers = styled.div`
  line-height: 1em;
  margin-bottom: 27%;
`;
const TextWrapper = styled.div`
  text-align: center;
  line-height: 1em;
  position: static;
  color: ${({ theme }) => theme.textColors.secondary};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    position: relative;
    top: -10em;
    left: 18em;
  }
`;
const ButtonWrapper = styled.div`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    position: relative;
    top: 9.5em;
    right: 0.5em;
  }
`;

const PageNotFound = () => (
  <PageNotFoundWrapper>
    <TextWrapper>
      <Headers>
        <H0>Przepraszamy!</H0>
        <H2>nie możesz tu wejść.</H2>
      </Headers>
      <Text>Brak uprawnień</Text>
    </TextWrapper>
    <ImageWrapper>
      <PageNotFoundIcon />
    </ImageWrapper>
    <ButtonWrapper>
      <Link to="/">
        <Button small>WRÓĆ DO STRONY GŁÓWNEJ</Button>
      </Link>
    </ButtonWrapper>
  </PageNotFoundWrapper>
);

export default PageNotFound;
