import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  color: ${({ theme }) => theme.textColors.secondary};
  font-weight: 600;
  width: 3.5em;
  height: 4.7em;
  background: inherit;
  background: inherit;
`;

const ActivityDate = ({ day, month, year }) => (
  <DateWrapper>
    <span>{day}</span>
    <span>{month}</span>
    {year && <span>{year}</span>}
  </DateWrapper>
);

ActivityDate.propTypes = {
  day: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string,
};
ActivityDate.defaultProps = {
  year: null,
};

export default ActivityDate;
