import React from 'react';

const ThumbNo = () => (
  <svg
    viewBox="0 0 21 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h20.904v25H0z" />
    <path
      d="M9.439 16.487l-.956 2.608c-.659 1.8-.232 4.55 1.597 5.527.596.319 1.429.479 2.1.31.384-.097.723-.352.924-.693.232-.393.208-.851.291-1.287.209-1.104.731-2.154 1.539-2.942 1.409-1.374 5.785-5.337 5.785-5.337V.155H5.596C3.556.154 2.218 2.433 3.225 4.212c-1.2.769-1.61 2.387-.908 3.63-1.199.768-1.61 2.387-.907 3.629-2.07 1.326-1.378 4.607 1.041 5.016.146.025 6.988 0 6.988 0z"
      fillRule="nonzero"
    />
  </svg>
);

export default ThumbNo;
