import {
  differenceInMilliseconds,
  format,
  formatDistance,
  formatDistanceToNow
} from 'date-fns';
import { pl } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

export const daysPassed = created => {
  return formatDistanceToNow(parseISO(created), { locale: pl });
};

export const daysBetweenDates = (date1, date2) => {
  return formatDistance(parseISO(date1), parseISO(date2), { locale: pl });
};

export const formatDateMonthTranslated = date => {
  return format(parseISO(date), '	MMMM yyyy', { locale: pl });
};

export const formatFullDateMonthTranslated = date => {
  return format(parseISO(date), 'dd	MMMM yyyy', { locale: pl });
};

export const formatDate = date => {
  return format(parseISO(date), 'dd.MM.yyyy');
};

export const formatTime = date => {
  return format(parseISO(date), 'H:mm');
};

export const formatDateTime = date => {
  return format(parseISO(date), 'dd.MM.yyyy, H:mm');
};

export const getDistanceWithMilliseconds = date => {
  const dateNow = new Date();
  return differenceInMilliseconds(dateNow, parseISO(date));
};

export const getDay = date => {
  return format(parseISO(date), 'dd');
};

export const getMonth = date => {
  return format(parseISO(date), 'MM');
};

export const getYear = date => {
  return format(parseISO(date), 'yyyy');
};

export const getMonthName = date => {
  return format(parseISO(date), 'MMM', { locale: pl });
};

export const getDate = date => {
  return format(date, 'yyyy-MM-dd');
};

export const getTime = date => {
  return format(date, 'HH:mm:ss');
};
