import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import NotificationIcon from '../../../../assets/NotificationIcon';
import { Alert, Text } from '../../../layout/theme/components';
import Switch from '../../../shared/components/Switch';

const NotificationsWrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 2em;
  color: #514994;
  text-align: left;
  word-break: break-word;
  line-height: 1.2;
`;
const StyledText = styled(Text)`
  flex: 1;
  margin-left: 1em;
`;
const Toggler = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 20px;
  }
`;

function NotificationSection(props) {
  const { enabled, toggleNotifications, error, notificationsSupported } = props;

  return (
    <NotificationsWrapper>
      <Toggler>
        <NotificationIcon />
        <StyledText bold>Powiadomienia</StyledText>
        {notificationsSupported && (
          <Switch onChange={toggleNotifications} active={enabled} />
        )}
      </Toggler>
      {!enabled &&
        notificationsSupported && (
          <Text small>
            Włącz wyświetlanie powiadomień i nigdy nie pozwól, by cokolwiek Cię
            ominęło!
          </Text>
        )}
      {error && <Alert negative>{error}</Alert>}
      {!notificationsSupported && (
        <Alert negative>
          Twoja przeglądarka nie obsługuje powiadomień.
          <br />
          Powiadomienia Web Push nie są obecnie dostępne: na urządzeniach
          mobilnych iOS, przeglądarce Safari/Mac i IE/Windows.
        </Alert>
      )}
    </NotificationsWrapper>
  );
}

NotificationSection.propTypes = {
  enabled: PropTypes.bool.isRequired,
  toggleNotifications: PropTypes.func.isRequired,
  notificationsSupported: PropTypes.bool.isRequired,
  error: PropTypes.string
};

NotificationSection.defaultProps = {
  error: null
};

export default NotificationSection;
