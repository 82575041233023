import 'rc-tooltip/assets/bootstrap.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createGlobalStyle } from 'styled-components';

export const theme = {
  backgroundColors: {
    primary: '#FFFFFF',
    secondary: '#F1F1F1',
    tertiary: '#B5B5B5',
    quaternary: '#56DABE',
    premium: '#675E97',
  },
  textColors: {
    primary: '#000000',
    secondary: '#675E97',
    tertiary: '#B5B5B5',
    quaternary: '#56DABE',
    annotation: '#E5E5E5',
    highlighted: '#F63D66',
    background: '#ffffff',
  },
  userColors: {
    warden: '#F63D66',
    keeper: '#F63D66',
    company: '#56DABE',
    user: '#675E97',
    anonymous: '#E5E5E5',
  },
  linkColors: {
    primary: '#675E97',
    secondary: '#56DABE',
    inactive: '#B5B5B5',
  },
  borderColor: '#EAEAEA',
  buttonColors: {
    primary: '#F63D66',
    secondary: '#675E97',
    tertiary: '#B5B5B5',
    quaternary: '#56DABE',
    active: '#BBF0E5',
    inactive: '#E5E5E5',
    voting: '#3DABDB',
    background: '#FFFFFF',
  },
  labels: {
    positive: '#57C543',
    negative: '#F96060',
    neutral: '#675E97',
  },
  labelsBackground: {
    positive: 'rgba(87,197,66,0.4117647058823529)',
    negative: 'rgba(250,95,95,0.4117647058823529)',
    neutral: 'rgba(117,107,163,0.4117647058823529)',
  },
  boxShadow: {
    element: '2px 2px 25px -6px rgba(0,0,0,0.2)',
    button: '2px 2px 19px -1px rgba(0,0,0,0.2)',
    modal: '0px 0px 15px 1px rgba(0,0,0,0.07)',
  },
  mobileBreakPoint: '960px',
};

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');
  @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600');
  * {
    box-sizing: border-box;
  }
  b {
    font-weight: 600;
  }
  body {
    padding: 0;
    margin: 0;
    background: ${theme.backgroundColors.primary};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 10px;
    color: ${theme.textColors.primary}
  }
  p {
    font-size: 1.4em;
    margin: .5em 0;
    font-weight: 400;
    color: ${theme.textColors.primary}
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  button {
    font-family: 'Montserrat';
    letter-spacing: 0.05em;
    font-weight: 500;
  }
  input,
  textarea {
    color: ${theme.textColors.primary},
  }
`;
