import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Cancel from '../../../../assets/tutorial/cancelTutorial';

const ButtonIcon = styled.button`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  svg {
    height: 15px;
    width: 15px;
    & path {
      fill: ${({ fill, theme }) => (fill ? fill : theme.buttonColors.primary)};
    }
  }
`;

const ExitIcon = ({ onClick, fill }) => (
  <ButtonIcon onClick={onClick} fill={fill}>
    <Cancel />
  </ButtonIcon>
);

ExitIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ExitIcon;
