import React from 'react';

const votingResolutions = () => {
  return (
    <svg
      viewBox="0 0 64 55"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h63.944v54.326H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h63.944v54.326H0z" />
      </clipPath>
      <g fillRule="nonzero" clipPath="url(#a)">
        <path
          d="M55.425 9.485C55.422 4.283 51.142.003 45.94 0H9.485C4.283.002.002 4.283 0 9.485v35.359c.002 5.202 4.283 9.483 9.485 9.485H45.94c5.202-.002 9.483-4.283 9.485-9.485V9.485z"
          fill="#d7d4e7"
        />
        <path
          d="M55.425 9.485C55.422 4.283 51.142.003 45.94 0H9.485C4.283.002.002 4.283 0 9.485v35.359c.002 5.202 4.283 9.483 9.485 9.485H45.94c5.202-.002 9.483-4.283 9.485-9.485V9.485z"
          fill="#bcb6da"
        />
        <path
          d="M1.904 3.787l49.084 49.084a9.44 9.44 0 0 1-5.047 1.454H9.485C4.283 54.323.002 50.042 0 44.84V9.484a9.445 9.445 0 0 1 1.904-5.697z"
          fill="#9d93cd"
        />
        <path
          d="M38.46 43.211c-4.372.444-18.519 0-18.519 0-2.876 0-5.457-3.209-4.039-5.718a3.887 3.887 0 0 1-1.278-5.114c-1.69-1.083-1.571-3.363-.581-5.114-2.917-1.868-2.639-6.492.769-7.068.206-.035 7.754 0 7.754 0l-1.346-3.674c-.929-2.537-.328-6.412 2.25-7.789a4.416 4.416 0 0 1 2.958-.436 2.134 2.134 0 0 1 1.3.977c.242.575.38 1.189.409 1.812a7.836 7.836 0 0 0 2.169 4.145c1.985 1.936 5.972 5.776 8.151 7.521 1.494 1.2 1.7 3.622 4.924 2.953v14.841c-2.688-.323-3.064 2.475-4.921 2.664z"
          fill="#fcecef"
        />
        <path
          d="M57.634 21.255a1.602 1.602 0 0 0-1.593-1.593H44.978a1.602 1.602 0 0 0-1.593 1.593v23.85a1.602 1.602 0 0 0 1.593 1.593h11.063a1.602 1.602 0 0 0 1.593-1.593v-23.85z"
          fill="#71dfd3"
        />
        <path
          d="M50.595 24.493a2.683 2.683 0 0 1 2.669 2.669 2.683 2.683 0 0 1-2.669 2.669 2.683 2.683 0 0 1-2.669-2.669 2.683 2.683 0 0 1 2.669-2.669z"
          fill="#fff"
        />
        <path fill="#00c5b1" d="M57.634 22.146h6.31v20.43h-6.31z" />
      </g>
    </svg>
  );
};

export default votingResolutions;
