import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1, H2, Text } from '../../layout/theme/components';
import TextButton from '../../shared/components/buttons/TextButton';
import SecondaryButton from '../../shared/components/buttons/SecondaryButton';
import ExternalLinkIcon from '../../../assets/externalLinkIcon';
import ExclamationIcon from '../../../assets/exclamationIcon';
import CorrectIcon from '../../../assets/correctIcon';
import { financesPropertyNames } from '../../helpers/constants';
import { formatDateMonthTranslated, formatDateTime } from '../../helpers/date';
import {
  financesBalanceStatus,
  financesBalanceText,
  financesDescriptionText
} from '../../helpers/constants';
import { theme } from '../../layout/theme/globalStyle';

const FinancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 65vh;
`;

const StyledH1 = styled(H1)`
  margin-bottom: 0;
`;

const FinancesBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10%;
`;

const FinancesWrapperText = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledBalanceWrapper = styled(FinancesWrapperText)`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  align-items: flex-start;
`;

const StyledHeaderWrapper = styled.div`
  margin-bottom: 10%;
`;

const StyledDueText = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.labels.negative};
`;

const StyledBalancePrice = styled(Text)`
  margin-top: 0;
  font-size: 34px;
  color: ${({ surplus, theme }) =>
    surplus ? theme.labels.positive : theme.labels.negative};
`;

const FinansesButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25%;
  justify-content: space-between;
  min-height: 120px;
`;

const DateButtonWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.span`
  margin-right: 5px;
`;

const FinancesBalanceComponent = ({
  getProperty,
  refreshable,
  logoutFromFinances,
  renderModal,
  setOpenModal,
  formatPrice,
  status
}) => {
  const [fill, setFill] = useState(theme.backgroundColors.premium);

  return (
    <FinancesContainer>
      <FinancesBalanceWrapper>
        <StyledHeaderWrapper>
          <StyledH1>Twoje finanse</StyledH1>
          <Text>
            {status !== financesBalanceStatus.PENDING
              ? `${formatDateMonthTranslated(
                  getProperty(financesPropertyNames.UPDATED_AT)
                )} r.`
              : '–'}
          </Text>
        </StyledHeaderWrapper>
        <StyledBalanceWrapper>
          <H2>{financesBalanceText[status]}</H2>
          {status !== financesBalanceStatus.PENDING && (
            <StyledBalancePrice surplus={status !== financesBalanceStatus.DUE}>
              {formatPrice(getProperty(financesPropertyNames.HAVE))}
            </StyledBalancePrice>
          )}
          <Text small>
            {financesDescriptionText[status]}
            {status === financesBalanceStatus.DUE && (
              <StyledDueText>
                {formatPrice(getProperty(financesPropertyNames.HAVE))}
              </StyledDueText>
            )}
          </Text>
        </StyledBalanceWrapper>
      </FinancesBalanceWrapper>
      <FinansesButtonsWrapper>
        <DateButtonWrapper small>
          <ButtonText>
            {status !== financesBalanceStatus.PENDING
              ? `Data pobrania danych
              ${formatDateTime(getProperty(financesPropertyNames.UPDATED_AT))}`
              : '–'}
          </ButtonText>
          {status !== financesBalanceStatus.PENDING && (
            <span>{refreshable ? <ExclamationIcon /> : <CorrectIcon />}</span>
          )}
        </DateButtonWrapper>
        {refreshable && (
          <SecondaryButton small onClick={() => setOpenModal(true)}>
            Pobierz aktualne dane
          </SecondaryButton>
        )}
        <a
          href={getProperty(financesPropertyNames.LINK)}
          target="_blank"
          rel="noopener noreferrer"
          disabled={status === financesBalanceStatus.PENDING}
        >
          <SecondaryButton
            onMouseEnter={() =>
              status === financesBalanceStatus.PENDING
                ? setFill(theme.backgroundColors.premium)
                : setFill(theme.backgroundColors.primary)
            }
            onMouseLeave={() => setFill(theme.backgroundColors.premium)}
            disabled={status === financesBalanceStatus.PENDING}
            small
            inverted
          >
            Zobacz szczegółowe zestawienie <ExternalLinkIcon fill={fill} />
          </SecondaryButton>
        </a>
      </FinansesButtonsWrapper>
      <TextButton onClick={() => logoutFromFinances()}>
        rozłącz konto weles
      </TextButton>
      {renderModal()}
    </FinancesContainer>
  );
};

FinancesBalanceComponent.propTypes = {
  getProperty: PropTypes.func.isRequired,
  refreshable: PropTypes.bool,
  logoutFromFinances: PropTypes.func.isRequired,
  renderModal: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  formatPrice: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

FinancesBalanceComponent.defaultProps = {
  refreshable: false
};

export default FinancesBalanceComponent;
