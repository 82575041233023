import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Arrow from '../components/Arrow';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';
import AddCaseIcon from './../../../../assets/addCaseIcon';
import listCases from './../../../../assets/tutorial/listCases.svg';

const ListCasesImage = styled.img`
  position: relative;
  height: 100%;
  opacity: ${({ isActive }) => (isActive ? '.2' : '1')};
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
`;
const AnimationBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 2em;
`;
const WrapperArrow = styled.div`
  position: absolute;
  bottom: 20%;
  right: 10%;
  transform: ${({ isActive }) => (isActive ? 'scale(1.2)' : 'none')};
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
`;
const AddCaseBox = styled.div`
  width: 18%;
  position: absolute;
  bottom: 0;
  right: 2em;
  transform: ${({ isActive }) => (isActive ? 'scale(1.2)' : 'none')};
  transition: all 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;

  svg {
    width: 100%;
  }
`;

const Step3 = ({ nextSlide, activeSlide }) => {
  const isActive = activeSlide === 2;
  return (
    <PageWrapper onButtonClick={nextSlide}>
      <TutorialHeader>
        Kliknij, aby <b>dodać własną, nową sprawę</b>
      </TutorialHeader>
      <AnimationBox>
        <ListCasesImage alt="listCases" src={listCases} isActive={isActive} />
        <WrapperArrow isActive={isActive}>
          <Arrow turn="down" />
        </WrapperArrow>
        <AddCaseBox isActive={isActive}>
          <AddCaseIcon />
        </AddCaseBox>
      </AnimationBox>
    </PageWrapper>
  );
};

Step3.propTypes = {
  nextSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.number.isRequired,
};

export default Step3;
