import React, { Component } from 'react';
import { fetchDataError } from '../../../helpers/api/user';
import { EXCEEDED_MESSAGES_AMOUNT, INVALID_INPUT_TEXT } from '../../../helpers/constants';
import { valueLength } from '../../../helpers/validation';
import { InvalidText } from '../../../layout/theme/components';
import TextButton from '../../../shared/components/buttons/TextButton';
import Textarea from '../../../shared/components/forms/Textarea';
import FullModalContainer from '../../../shared/components/FullModalContainer';
import Modal from '../../../shared/components/Modal';
import SentDataError from './SentDataError';

class DataError extends Component {
  state = {
    openModal: false,
    errorDesc: '',
    errorDescValid: true,
    loading: false,
    sent: false,
    error: null,
    invalidText: '',
  };

  toggleModal(toggle, event, callback) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ openModal: toggle }, () => {
      if (callback) {
        callback();
      }
    });
  }

  postDataError = () => {
    this.setState({ loading: true });
    fetchDataError(this.state.errorDesc)
      .then(() => this.setState({ sent: true, loading: false }))
      .catch(error => this.setState({ error }));
  };

  handleChange = value => {
    this.setState({ errorDesc: value }, () => this.setDescValidation(value));
  };

  setDescValidation = value => {
    const errorDescValid = valueLength(value, 1200, 3);
    this.setState({ errorDescValid });
  };

  sentDataError = () => {
    this.setState({
      sent: false,
      errorDesc: '',
      openModal: false,
      error: null,
    });
  };

  renderModal() {
    const { openModal, errorDesc, errorDescValid, sent, error, loading } = this.state;

    if (openModal) {
      return (
        <Modal closeModal={e => this.toggleModal(false, e)} isWide>
          {!sent ? (
            <FullModalContainer
              onClick={this.postDataError}
              buttonText="WYŚLIJ WIADOMOŚĆ"
              header="Czy coś się nie zgadza?"
              isLoading={loading && !error}
            >
              <Textarea
                placeholder="Opis"
                value={errorDesc}
                valid={errorDescValid}
                changeHandler={value => this.handleChange(value)}
                invalidText={INVALID_INPUT_TEXT}
              />
              {error && <InvalidText>{EXCEEDED_MESSAGES_AMOUNT}</InvalidText>}
            </FullModalContainer>
          ) : (
            <SentDataError sentDataError={this.sentDataError} />
          )}
        </Modal>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <TextButton onClick={e => this.toggleModal(true, e)} small>
          ZGŁOŚ BŁĄD DANYCH
        </TextButton>
        {this.renderModal()}
      </>
    );
  }
}

export default DataError;
