import React from 'react';

const highFive = ({ width = '100%', height = '100%' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 178"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M175.058 11.994l-.015-.005a2.1 2.1 0 0 0-2.396-2.715l-.399-2.056a4.194 4.194 0 0 1 4.991 4.377 4.171 4.171 0 0 1-.2 1.026l.016.005a2.1 2.1 0 0 0 2.396 2.716l.399 2.056a4.194 4.194 0 0 1-4.99-4.377c.022-.349.089-.694.198-1.027z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M231.726 39.735h-.017a2.1 2.1 0 0 0-3.337-1.402l-1.259-1.675a4.193 4.193 0 0 1 6.67 2.778h.016a2.098 2.098 0 0 0 3.337 1.402l1.26 1.675a4.193 4.193 0 0 1-6.67-2.778z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F68EA5"
        d="M150.776 57.343c.64-19.026 16.249-34.119 35.286-34.119s34.646 15.093 35.286 34.119h.02v64.136h-70.612V57.345l.02-.002z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#E4778F"
        d="M156.96 68.583c.528-15.69 13.4-28.138 29.101-28.139 15.696 0 28.57 12.446 29.1 28.137l.016.002v52.894h-58.231V68.583h.014z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M160.211 130.685c.51-10.1 12.616-18.188 27.477-18.188 14.859 0 26.966 8.084 27.476 18.188h.017v32.047h-54.985v-32.051l.015.004z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M83.73 131.063c-.519-10.1-12.854-18.188-28-18.188s-27.476 8.084-28 18.188h-.017v32.047h56.034v-32.05l-.017.003z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F68EA5"
        d="M177.074 114.082c0-.875 1.484-1.585 3.313-1.585h11.828c1.829 0 3.314.71 3.314 1.585v7.005c-.025 2.781-18.479 2.538-18.456 0v-7.005z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#3BC2A5"
        d="M66.864 113.461c0-.875-1.485-1.585-3.314-1.585H51.722c-1.829 0-3.314.711-3.314 1.585v7.005c.025 2.782 18.478 2.538 18.456 0v-7.005z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FBE3E8"
        d="M180.821 111.628c0-.72.883-1.305 1.97-1.305h7.03c1.087 0 1.97.585 1.97 1.305v5.768c-.015 2.29-10.982 2.089-10.97 0v-5.768zM50.276 107.995a2.405 2.405 0 0 1 2.643-2.054h9.434a2.405 2.405 0 0 1 2.643 2.054v9.075c-.02 3.6-14.738 3.287-14.72 0v-9.075z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M161.645 129.256a38.606 38.606 0 0 1-38.609-38.608h15.128a23.48 23.48 0 0 0 23.477 23.477l.004 15.131z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M82.299 128.634a38.606 38.606 0 0 0 38.608-38.608H105.78a23.48 23.48 0 0 1-23.477 23.477l-.004 15.131z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCECEF"
        d="M120.882 75.776a29.822 29.822 0 0 0 1.042-7.973c0-8.8-3.485-15.947-7.777-15.947s-7.777 7.145-7.777 15.947a29.817 29.817 0 0 0 1.042 7.973h13.47zM135.192 75.678c.577-2.124.863-4.316.852-6.517 0-7.194-2.848-13.034-6.356-13.034-3.509 0-6.357 5.841-6.357 13.034a24.392 24.392 0 0 0 .852 6.517h11.009z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M156.178 114.019h20.55v13.652h-20.55z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M67.215 113.396h20.55v13.652h-20.55z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCECEF"
        d="M215.181 63.539c-.007-16.078-13.039-29.11-29.117-29.118-16.079.007-29.111 13.04-29.119 29.118v19.027c.008 16.079 13.04 29.111 29.119 29.118 16.078-.007 29.11-13.039 29.118-29.118l-.001-19.027z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCECEF"
        d="M215.196 66.099a6.142 6.142 0 0 1 6.138 6.138 6.142 6.142 0 0 1-6.138 6.138c-3.388 0-3.388-12.276 0-12.276z"
      />
      <path
        fill="none"
        stroke="#AFA9CA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M217.812 75.447v2.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F68EA5"
        d="M175.217 61.298h40.708c0-18.619-13.37-33.712-29.862-33.712-16.493 0-29.863 15.093-29.863 33.712h14.815l2.513-14.309"
      />
      <path
        fill="none"
        stroke="#E4778F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M215.181 68.831V59.87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCECEF"
        d="M155.881 78.072a6.14 6.14 0 0 1-6.138-6.138 6.14 6.14 0 0 1 6.138-6.138c3.388-.001 3.388 12.276 0 12.276z"
      />
      <path
        fill="none"
        stroke="#AFA9CA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M154.56 75.447v2.2"
      />
      <path
        fill="none"
        stroke="#E4778F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M156.944 67.494V54.518"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#757090"
        d="M180.038 97.979c.405 3.105 3.766 5.469 7.778 5.469s7.372-2.363 7.777-5.469h-15.555z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M195.594 97.874h-15.556c.009.575.14 1.143.386 1.663h14.765a3.48 3.48 0 0 0 .405-1.663z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F7CED7"
        d="M183.82 102.63c.595-.951 2.158-1.614 4-1.614 1.841 0 3.399.663 4 1.614a9.867 9.867 0 0 1-4 .819c-1.375 0-2.736-.278-4-.819z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F78EA5"
        d="M187.755 97.784a2.047 2.047 0 0 0-2-2.478 2.047 2.047 0 0 0-2 2.478h4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F78EA5"
        d="M191.849 97.784a2.048 2.048 0 1 0-4.048-.431c0 .145.016.289.047.431h4.001zM185.789 103.175a2.046 2.046 0 1 0 4.046.431 1.941 1.941 0 0 0 0-.431c-1.337.25-2.709.25-4.046 0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FDDDE4"
        d="M175.307 88.664a2.306 2.306 0 0 0 2.248 2.785 2.305 2.305 0 0 0 2.248-2.785h-4.496z"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8A8B9"
        cx="160.891"
        cy="87.226"
        r="3.832"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8A8B9"
        cx="195.841"
        cy="87.226"
        r="3.832"
      />
      <path
        fill="none"
        stroke="#FFF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M187.395 76.421h4.39"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#6A6488"
        cx="189.594"
        cy="77.511"
        r="2.2"
      />
      <path
        fill="none"
        stroke="#F67F99"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M185.081 72.842c3.037-1.765 6.041-1.495 9.024 0"
      />
      <path
        fill="none"
        stroke="#FFF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M164.396 76.421h4.391"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#6A6488"
        cx="166.591"
        cy="77.511"
        r="2.2"
      />
      <path
        fill="none"
        stroke="#F67F99"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M162.079 72.842c3.037-1.765 6.041-1.495 9.024 0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M6.049 121.813l-.006.02a2.67 2.67 0 0 0-.677-.1 2.667 2.667 0 0 0-2.62 3.315l-2.587.647a5.338 5.338 0 0 1 6.557-6.447l.006-.02c.22.063.448.096.677.1a2.668 2.668 0 0 0 2.62-3.314l2.587-.647a5.337 5.337 0 0 1-6.557 6.446z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M5.796 65.856l.018.01a2.668 2.668 0 0 0 2.442 3.908l.081 2.666a5.336 5.336 0 0 1-4.903-7.781l-.018-.01a2.668 2.668 0 0 0-2.442-3.908l-.081-2.666a5.336 5.336 0 0 1 4.903 7.781zM116.857 139.961l-.006-.021a2.669 2.669 0 0 1-3.367-3.143l-2.6-.589a5.337 5.337 0 0 0 6.698 6.297l.006.02a2.667 2.667 0 0 1 3.367 3.144l2.6.589a5.336 5.336 0 0 0-6.698-6.297zM108.318 26.088h.016a2.1 2.1 0 0 1 2.522-2.596l.5-2.034a4.194 4.194 0 0 0-5.052 5.164h-.016a2.1 2.1 0 0 1-2.518 2.594l-.5 2.035a4.194 4.194 0 0 0 5.197-4.13 4.198 4.198 0 0 0-.149-1.033z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M56.033 4.376h.016a2.1 2.1 0 0 1 2.953-2.093l.865-1.909a4.194 4.194 0 0 0-5.909 4.157h-.016a2.1 2.1 0 0 1-2.953 2.094l-.865 1.909a4.194 4.194 0 0 0 5.909-4.158z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M103.497 50.095a1.76 1.76 0 1 1 0 3.52 1.76 1.76 0 0 1 0-3.52zM38.425 28.726a1.76 1.76 0 0 1 0 3.519 1.76 1.76 0 0 1 0-3.519z"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        cx="23.251"
        cy="22.353"
        r="1.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M201.225 19.831a1.76 1.76 0 1 1 .002 3.52 1.76 1.76 0 0 1-.002-3.52zM128.967 37.035a1.76 1.76 0 1 1 0 3.522 1.76 1.76 0 0 1 0-3.522z"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        cx="252.833"
        cy="58.076"
        r="1.759"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        cx="120.683"
        cy="119.247"
        r="1.76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M123.02 73.361h15.138v17.64H123.02z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M105.629 73.051h15.3v17.64h-15.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FAEBED"
        d="M27.723 59.29c.008-17.147 13.906-31.045 31.053-31.053 17.147.008 31.045 13.906 31.053 31.053v18.792c-.008 17.146-13.906 31.045-31.053 31.053-17.147-.008-31.045-13.906-31.053-31.053V59.29z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCECEF"
        d="M27.706 63.992a6.549 6.549 0 0 0-6.546 6.546 6.55 6.55 0 0 0 6.546 6.546c3.613.001 3.613-13.092 0-13.092z"
      />
      <path
        fill="#FFF"
        d="M73.719 71.012c-2.123.09-2.755 4.164 0 4.281 3.074.043 7.283.566 6.494-2.651-.489-2.012-3.777-1.668-6.494-1.63z"
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#616161"
        cx="75.962"
        cy="74.152"
        r="2.576"
      />
      <path
        fill="#FFF"
        d="M51.237 71.012c-2.043.087-3.046 4.152 0 4.281 2.259.032 4.8.518 5.939-.637a2.23 2.23 0 0 0-1.464-3.644c-1.488-.021-2.98-.021-4.475 0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#616161"
        d="M53.474 71.576a2.577 2.577 0 1 0 2.576 2.576 2.577 2.577 0 0 0-2.576-2.576z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M45.838 45.328c0-1.108-8.515-2.008-10.007-2.008h-5.405c-1.492 0-2.7.9-2.7 2.008v23.357c1.492 0 10.811-5.71 10.811-6.818l7.301-16.539z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M27.723 45.421c0-12.488 13.9-22.611 31.053-22.611s31.053 10.123 31.053 22.611H27.723zM70.503 66.088c0-.392.317-.709.709-.709h9.435c.392 0 .709.317.709.709v1.418a.71.71 0 0 1-.709.709h-9.435a.71.71 0 0 1-.709-.709v-1.418zM48.08 66.088a.71.71 0 0 1 .709-.709h9.435c.392 0 .709.317.709.709v1.418a.71.71 0 0 1-.709.709h-9.435a.71.71 0 0 1-.709-.709v-1.418z"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#87A7EC"
        d="M67.524 72.233a2.16 2.16 0 0 1 2.16-2.16h12.344a2.16 2.16 0 0 1 2.16 2.16v4.321a2.16 2.16 0 0 1-2.16 2.161H69.684a2.16 2.16 0 0 1-2.16-2.161v-4.321z"
      />
      <path
        fill="none"
        stroke="#A1A3C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M67.524 72.233a2.16 2.16 0 0 1 2.16-2.16h12.344a2.16 2.16 0 0 1 2.16 2.16v4.321a2.16 2.16 0 0 1-2.16 2.161H69.684a2.16 2.16 0 0 1-2.16-2.161v-4.321z"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#87A7EC"
        d="M45.32 72.233a2.16 2.16 0 0 1 2.16-2.16h12.348c1.192 0 2.159.967 2.16 2.16v4.321a2.16 2.16 0 0 1-2.16 2.161H47.48a2.16 2.16 0 0 1-2.16-2.161v-4.321z"
      />
      <path
        fill="none"
        stroke="#A1A3C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M45.32 72.233a2.16 2.16 0 0 1 2.16-2.16h12.348c1.192 0 2.159.967 2.16 2.16v4.321a2.16 2.16 0 0 1-2.16 2.161H47.48a2.16 2.16 0 0 1-2.16-2.161v-4.321z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#A1A3C0"
        d="M61.985 71.507h5.539v2.22h-5.539z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M68.189 104.25c0 5.4-4.7 9.769-10.508 9.769s-10.508-4.373-10.508-9.769h21.016zM39.146 96.302c0-6.081 8.229-11.011 18.381-11.011s18.381 4.93 18.381 11.011H39.146z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#616161"
        d="M65.571 94.741c-.418 3.733-3.881 6.576-8.014 6.576-4.133 0-7.6-2.842-8.014-6.576h16.028z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M49.543 94.741h16.028a4.152 4.152 0 0 1-.4 1.714H49.963a3.578 3.578 0 0 1-.42-1.714z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FCD5DD"
        d="M61.675 100.277a4.548 4.548 0 0 0-4.117-2.047 4.547 4.547 0 0 0-4.117 2.047 8.556 8.556 0 0 0 4.117 1.039 8.8 8.8 0 0 0 4.117-1.039zM70.383 82.027a5.391 5.391 0 0 1-5.268 6.523 5.391 5.391 0 0 1-5.268-6.523h10.536z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFC864"
        d="M84.189 43.32l5.64 19.057V45.421l-5.64-3.829"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#56D9BE"
        d="M212.889 136.797h-.016a2.1 2.1 0 0 1-2.895 2.172l-.813 1.931a4.194 4.194 0 0 0 5.794-4.315h.016a2.098 2.098 0 0 1 2.896-2.172l.813-1.932a4.192 4.192 0 0 0-5.795 4.316z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8ADBE"
        d="M249.299 101.878l-.004.016a2.102 2.102 0 0 1 1.259 3.396l1.619 1.33a4.193 4.193 0 0 0-2.485-6.785l.004-.016a2.102 2.102 0 0 1-1.259-3.396l-1.62-1.33a4.193 4.193 0 0 0 2.486 6.785z"
      />
      <path
        fill="#FFF"
        d="M9.058 170.286c0-.994.19-1.951.571-2.87a7.46 7.46 0 0 1 1.626-2.434 7.416 7.416 0 0 1 2.433-1.625 7.42 7.42 0 0 1 2.87-.571h211c.994 0 1.951.19 2.869.571a7.43 7.43 0 0 1 2.434 1.625c.703.704 1.245 1.515 1.626 2.434s.57 1.876.571 2.87c-.001.994-.19 1.951-.571 2.87s-.923 1.729-1.626 2.434a7.42 7.42 0 0 1-2.434 1.625 7.415 7.415 0 0 1-2.869.571h-211a7.42 7.42 0 0 1-2.87-.571 7.416 7.416 0 0 1-2.433-1.625 7.45 7.45 0 0 1-1.626-2.434 7.442 7.442 0 0 1-.571-2.87z"
      />
    </svg>
  );
};

export default highFive;
