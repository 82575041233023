import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import SearchIcon from '../../../assets/searchIcon';
import { queryCases } from '../../data/case/actions';
import TextInput from '../../shared/components/forms/TextInput';

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-right: 1.5em;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
`;
const Button = styled.button`
  border-radius: 50%;
  border: none;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.linkColors.primary};
  padding: 0.8em;
  fill: ${({ theme }) => theme.backgroundColors.primary};
  margin-left: 1em;
  flex: 1 0 auto;
  cursor: pointer;
`;

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: ''
    };

    this.inputRef = React.createRef();
  }

  componentWillMount() {
    const { query } = this.props;
    query.length >= 1 && this.setState({ query: query });
  }

  componentDidMount = () => {
    const { focus } = this.props;
    if (focus) this.inputRef.current.focus();
  };

  handleInputChange = e => {
    const trimQuery = e.target.value.trimStart();
    this.setState({ query: trimQuery });
    this.props.queryCases(trimQuery);
  };

  render() {
    const { query } = this.state;

    return (
      <SearchWrapper>
        <Form>
          <TextInput
            name="query"
            value={query}
            placeholder="Szukaj"
            isRequired
            inputRef={this.inputRef}
            onChange={this.handleInputChange}
          />
          <Button>
            <SearchIcon />
          </Button>
        </Form>
      </SearchWrapper>
    );
  }
}

SearchInput.propTypes = {
  queryCases: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  query: PropTypes.string
};

SearchInput.defaultProps = {
  focus: false,
  query: ''
};

const mapStateToProps = state => ({
  query: state.case.query.query
});

const mapDispatchToProps = dispatch => ({
  queryCases: value => {
    dispatch(queryCases(value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInput);
