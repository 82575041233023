import React from 'react';

const financesIcon = () => {
  return (
    <svg
      viewBox="0 0 9 6"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
      width="9"
      height="9"
    >
      <path fill="none" d="M0 0h9v6H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h9v6H0z" />
      </clipPath>
      <g>
        <path d="M4.5 6L0 2.212 1.719 0h5.562L9 2.212 4.5 6z" fill="#fff" />
      </g>
    </svg>
  );
};

export default financesIcon;
