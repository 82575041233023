import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { H0, Text } from '../../layout/theme/components';
import TextButton from '../../shared/components/buttons/TextButton';
import SentCaseAeroplane from './../../../assets/sentCaseAeroplane';
import Button from './../../shared/components/buttons/PrimaryButton';

const PublishedWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 5em;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-wrap: wrap;
    justify-content: center;
    position: static;
  }
`;

const SentCaseWrapper = styled.div`
  position: absolute;
  width: 70%;
  height: 50%;
  z-index: 2;
  top: 4%;
  svg {
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    position: relative;
    right: -7%;
    width: 35%;
  }
`;

const RightSideWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: 60%;
  z-index: 1;
  margin-top: 18%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    position: relative;
    left: -7%;
    width: 35%;
    margin-top: 0;
  }
`;

const HeadersBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 55%;
  color: ${({ theme }) => theme.textColors.secondary};
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60%;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  margin: 3% 0;
  + button {
    margin-bottom: 0;
  }
`;

const TextBox = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 1%;
`;

const CloseWrapper = styled.div`
  margin: 1%;
`;

const PublishedComponent = ({ caseId, caseNumber }) => (
  <PublishedWrapper>
    <SentCaseWrapper>
      <SentCaseAeroplane />
    </SentCaseWrapper>
    <RightSideWrapper>
      <HeadersBox>
        <H0>Wysłano</H0>
        <Text>Numer Twojej sprawy:</Text>
        <H0>{caseNumber}</H0>
        <TextBox>
          <strong>Koniecznie go zapisz,</strong>
          aby sprawdzić postęp
          <br />
          oraz opinię innych współwłaścicieli!
        </TextBox>
      </HeadersBox>
      <ButtonBox>
        <Link
          to={{
            pathname: `/sprawa/${caseId}`,
            state: { goBack: false }
          }}
        >
          <Button>PRZEJDŹ DO SPRAWY</Button>
        </Link>
      </ButtonBox>
      <CloseWrapper>
        <Link to="/">
          <TextButton>zamknij</TextButton>
        </Link>
      </CloseWrapper>
    </RightSideWrapper>
  </PublishedWrapper>
);

PublishedComponent.propTypes = {
  caseId: PropTypes.number.isRequired,
  caseNumber: PropTypes.string.isRequired
};

export default PublishedComponent;
