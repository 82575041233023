import React, { Component } from 'react';

import NewPassword from './NewPasswordComponent';

import { changePassword } from './../helpers/api/user';
import {
  getActivationCode,
  resetActivationCode
} from '../helpers/localStorage';
import { isPasswordValid } from '../helpers/validation';

export default class NewPasswordContainer extends Component {
  state = {
    passwords: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    },
    isValid: true,
    invalidText: '',
    sent: false
  };

  componentDidMount() {
    this.setState({ passwords: { oldPassword: getActivationCode() } });
  }

  getValidationText() {
    const { oldPassword, newPassword, repeatPassword } = this.state.passwords;

    if (!isPasswordValid(newPassword)) {
      return 'Minimalna długość hasła to 6 znaków. Może składać się tylko z liter (dowolnej wielkości) oraz cyfr (minimum jedna cyfra).';
    }
    if (newPassword !== repeatPassword) {
      return 'Hasło w obu polach musi być takie same';
    }
    if (oldPassword === newPassword) {
      return 'Nowe hasło musi się różnić od hasła sms.';
    }
    return '';
  }

  handleChange = (value, field) => {
    const temp = { ...this.state.passwords };
    temp[field] = value.trim();

    this.setState({
      passwords: temp,
      isValid: true
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { oldPassword, newPassword, repeatPassword } = this.state.passwords;
    const invalidText = this.getValidationText();

    if (invalidText === '') {
      changePassword(oldPassword, newPassword, repeatPassword)
        .then(() => {
          this.setState({ sent: true });
          resetActivationCode();
        })
        .catch(() =>
          this.setState({
            isValid: false,
            invalidText: 'Wystąpił błąd.'
          })
        );
    } else {
      this.setState({
        isValid: false,
        invalidText: invalidText
      });
    }
  };

  render() {
    const { passwords, isValid, sent, invalidText } = this.state;
    return (
      <NewPassword
        handleSubmit={this.handleSubmit}
        handleChange={(value, field) => this.handleChange(value, field)}
        newPassword={passwords.newPassword}
        repeatPassword={passwords.repeatPassword}
        isValid={isValid}
        invalidText={invalidText}
        sent={sent}
      />
    );
  }
}
