import React from 'react';

function PrinterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.195" height="17.667">
      <path
        d="M12.514 5.889H2.208A2.205 2.205 0 000 8.097v4.417h2.944v2.944h8.833v-2.944h2.944V8.097a2.205 2.205 0 00-2.207-2.208zm-2.208 8.1H4.417v-3.681h5.889zm2.208-5.153a.736.736 0 11.736-.736.738.738 0 01-.736.733zm-.736-6.628H2.944v2.944h8.833z"
        className="a"
      />
    </svg>
  );
}

export default PrinterIcon;
