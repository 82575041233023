import { getRequestHeaders, handleResponse } from '../request';
const apiUrl = process.env.REACT_APP_API_URL;

/**
 * It gets activities relative to selected apartment and case;
 * @param {number} communityCase
 * @param {number} page
 * @param {number} limit
 */
export const fetchActivities = (communityCase, page = 1, limit = 10) => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };

  return fetch(
    `${apiUrl}/case/${communityCase}/activities?page=${page}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
};

export const putComment = (communityCase, comment) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({ comment })
  };
  return fetch(`${apiUrl}/case/${communityCase}/comment`, requestOptions).then(
    handleResponse
  );
};

export const removeComment = (caseId, commentId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: getRequestHeaders()
  };
  return fetch(
    `${apiUrl}/case/${caseId}/comment/${commentId}`,
    requestOptions
  ).then(handleResponse);
};
