export const hasHtml = value => value.search(/[&<>$]/g) !== -1;

export const valueLength = (value, max, min = 0) => value.length <= max && value.length >= min;

export const isPhoneNumber = (dialling, number) => number.length >= 9 && /^[\d+]+$/.test(dialling.concat(number));

export const isEmailValid = email =>
  // eslint-disable-next-line
  /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z0-9_\-\.]+)$/.test(email);

export const isPasswordValid = value => /^.*(?=.{6,20})(?=.*\d)(?=.*[a-zA-Z]).*$/.test(value);

export const maxChars = {
  topic: 150,
  description: 2560,
};
