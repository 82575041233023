import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Anonymous from './AnonymousComponent';
import Button from '../../shared/components/buttons/PrimaryButton';

import { saveAnonymousStep } from '../../helpers/api/case';
import Loader from '../../shared/components/Loader';

class AnonymousContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anonymous: false
    };

    this.toggleAnonymous = this.toggleAnonymous.bind(this);
    this.saveStep = this.saveStep.bind(this);
  }

  saveStep() {
    const { anonymous } = this.state;
    const { caseId } = this.props;
    saveAnonymousStep(caseId, anonymous).then(() =>
      this.props.handleNextStep()
    );
  }

  toggleAnonymous(show) {
    this.setState({ anonymous: show });
  }

  render() {
    const { anonymous } = this.state;
    const { user, addAsAnonymous, isLoading } = this.props;
    if (isLoading) return <Loader />;
    return (
      <Fragment>
        <Anonymous
          name={user.display_name}
          isAnonymous={anonymous}
          isFemale={user.gender === 'F'}
          clickHandler={this.toggleAnonymous}
          addAsAnonymous={addAsAnonymous}
        />
        <Button onClick={this.saveStep}>DALEJ</Button>
      </Fragment>
    );
  }
}

AnonymousContainer.propTypes = {
  user: PropTypes.object.isRequired,
  caseId: PropTypes.number.isRequired,
  addAsAnonymous: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  user: state.user.profile.data,
  isLoading:
    state.user.accessControlList && state.user.accessControlList.isLoading,
  addAsAnonymous: state.user.accessControlList.data.user.add_as_anonymous
});

export default connect(mapStateToProps)(AnonymousContainer);
