import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LiveSearchIcon from '../../../../assets/liveSearch';
import { Label, LabelColored } from '../../../layout/theme/components';

import { clickedAddCaseSearchBar } from '../../../helpers/tools';

const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 14vh;
  margin-top: 5%;
`;
const SearchIconWrapper = styled.div`
  height: 40px;
  width: 40px;
`;
const AddCaseWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textColors.secondary};
`;
const Plus = styled.span`
  font-size: 1.6em;
  font-weight: bold;
  margin-left: 5%;
`;

const NoSearchResult = ({ query }) => (
  <NoResultsWrapper>
    <Label>Brak wyników wyszukiwania</Label>
    <SearchIconWrapper>
      <LiveSearchIcon />
    </SearchIconWrapper>
    <Link to="/kategorie">
      <AddCaseWrapper>
        <LabelColored onClick={clickedAddCaseSearchBar()}>
          Dodaj sprawę dla&nbsp;
        </LabelColored>
        <LabelColored bold>{query}</LabelColored>
        <Plus>+</Plus>
      </AddCaseWrapper>
    </Link>
  </NoResultsWrapper>
);

NoSearchResult.propTypes = {
  query: PropTypes.string.isRequired
};

export default NoSearchResult;
