import React from 'react';

const createCases = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="64.186">
    <g data-name="Group 3180" transform="translate(978 -671)">
      <path
        data-name="Path 14013"
        d="M-935.884 675.446a1.719 1.719 0 0 0-1.708-1.728l-28.092-.2a1.722 1.722 0 0 0-1.73 1.709l-.3 42.758a1.721 1.721 0 0 0 1.709 1.733l28.091.2a1.721 1.721 0 0 0 1.733-1.709z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14014"
        d="M-959.966 678.234a.192.192 0 0 1 .151.063.218.218 0 0 1 .059.159l-.01 1.306a1.088 1.088 0 0 1-.13.529.909.909 0 0 1-.356.357 1.015 1.015 0 0 1-.514.126 1.042 1.042 0 0 1-.52-.133.9.9 0 0 1-.353-.364 1.08 1.08 0 0 1-.127-.531l.009-1.304a.206.206 0 0 1 .066-.156.226.226 0 0 1 .167-.063.208.208 0 0 1 .151.066.207.207 0 0 1 .063.155l-.009 1.307a.615.615 0 0 0 .073.307.541.541 0 0 0 .2.21.584.584 0 0 0 .28.075.615.615 0 0 0 .291-.07.551.551 0 0 0 .218-.208.581.581 0 0 0 .083-.307l.008-1.305a.229.229 0 0 1 .055-.158.188.188 0 0 1 .145-.061z"
        fill="#fff"
      />
      <path
        data-name="Path 14015"
        d="M-957.012 678.382a.191.191 0 0 1 .111.185.243.243 0 0 1-.055.147.18.18 0 0 1-.148.075.251.251 0 0 1-.123-.034.9.9 0 0 0-.385-.086.889.889 0 0 0-.441.1.719.719 0 0 0-.29.3.928.928 0 0 0-.1.441.855.855 0 0 0 .224.646.836.836 0 0 0 .6.224.818.818 0 0 0 .383-.079.274.274 0 0 1 .115-.029.2.2 0 0 1 .158.081.23.23 0 0 1 .051.148.2.2 0 0 1-.029.106.19.19 0 0 1-.084.07 1.337 1.337 0 0 1-.6.138 1.418 1.418 0 0 1-.648-.156 1.143 1.143 0 0 1-.467-.442 1.348 1.348 0 0 1-.172-.71 1.3 1.3 0 0 1 .172-.657 1.194 1.194 0 0 1 .467-.455 1.364 1.364 0 0 1 .668-.158 1.294 1.294 0 0 1 .593.145z"
        fill="#fff"
      />
      <path
        data-name="Path 14016"
        d="M-954.252 678.277a.22.22 0 0 1 .162.063.214.214 0 0 1 .06.158l-.014 2.084a.214.214 0 0 1-.066.158.234.234 0 0 1-.166.061.219.219 0 0 1-.155-.063.212.212 0 0 1-.065-.158l.006-.841-1.149-.006-.006.838a.208.208 0 0 1-.069.158.233.233 0 0 1-.159.061.221.221 0 0 1-.16-.063.215.215 0 0 1-.063-.161l.014-2.081a.207.207 0 0 1 .066-.156.226.226 0 0 1 .167-.063.212.212 0 0 1 .153.066.207.207 0 0 1 .065.155l-.006.829 1.15.009.006-.829a.2.2 0 0 1 .07-.156.225.225 0 0 1 .159-.063z"
        fill="#fff"
      />
      <path
        data-name="Path 14017"
        d="M-941.871 680.607a.237.237 0 0 1 .017.086.207.207 0 0 1-.062.154.2.2 0 0 1-.141.059.216.216 0 0 1-.117-.041.265.265 0 0 1-.08-.109l-.184-.438-1.076-.007-.191.438a.236.236 0 0 1-.081.107.191.191 0 0 1-.114.036.187.187 0 0 1-.138-.051.189.189 0 0 1-.048-.139.246.246 0 0 1 .011-.068l.913-2.149a.233.233 0 0 1 .087-.11.177.177 0 0 1 .13-.034.2.2 0 0 1 .12.038.212.212 0 0 1 .08.106zm-1.463-.709h.721l-.358-.843z"
        fill="#fff"
      />
      <path
        data-name="Path 14018"
        d="M-940.188 686.038l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14019"
        d="M-940.187 712.316l-22.5.007v.662l22.5-.007z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14020"
        d="M-940.186 713.889l-22.5.007v.661l22.5-.006z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14021"
        d="M-940.185 715.46l-22.5.007v.661l22.5-.006z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14022"
        d="M-940.188 688.994l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14023"
        d="M-940.188 692.064l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14024"
        d="M-940.188 695.019l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14025"
        d="M-940.188 697.975l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14026"
        d="M-940.188 701.045l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14027"
        d="M-940.188 704.001l-22.459.02v1.98l22.46-.028z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Rectangle 1639"
        fill="#fff"
        d="M-951.272 696.952h11.483v9.664h-11.483z"
      />
      <path
        data-name="Path 14028"
        d="M-951.272 707.07l-11.375.02v1.98l11.375-.024z"
        fill="#e9e9e9"
        fillRule="evenodd"
      />
      <path
        data-name="Path 14029"
        d="M-940.188 677.212l-22.459.061v6.019l22.46-.073z"
        fill="#aed6dc"
        fillRule="evenodd"
      />
      <path
        data-name="Rectangle 1640"
        fill="#f5f5f5"
        d="M-949.957 698.011h9.209v11.937h-9.209z"
      />
      <path
        data-name="Path 14030"
        d="M-955.796 706.935a1.742 1.742 0 0 1 2.457.173l6.094 7.012a1.742 1.742 0 0 1-2.63 2.285l-6.093-7.012a1.742 1.742 0 0 1 .172-2.458z"
        fill="#56d9be"
      />
      <path
        data-name="Path 14031"
        d="M-949.41 716.442c-.632-.728-.915-2.637-.19-3.268 3.355-2.914 5.14-4.564 7.48-6.46 9.552-7.735 15.37-8.994 15.37-8.994s-5.27 3.766-12.11 9.773a82.677 82.677 0 0 0-8.525 9.092c.012-.143-1.394.583-2.025-.143z"
        fill="#56d9be"
      />
      <rect
        data-name="Rectangle 1641"
        width="69"
        height="64.186"
        rx="9"
        transform="translate(-978 671)"
        fill="#f2f2f2"
      />
      <rect
        data-name="Rectangle 1642"
        width="32"
        height="41"
        rx="3"
        transform="translate(-959 682.186)"
        fill="#fff"
      />
      <path
        data-name="Rectangle 1643"
        fill="#e1e1e1"
        d="M-954 690.186h22v2h-22z"
      />
      <path
        data-name="Rectangle 1644"
        fill="#e1e1e1"
        d="M-954 698.186h22v2h-22z"
      />
      <path
        data-name="Rectangle 1645"
        fill="#e1e1e1"
        d="M-954 705.186h22v2h-22z"
      />
      <path
        data-name="Rectangle 1646"
        fill="#e1e1e1"
        d="M-954 713.186h13v2h-13z"
      />
      <path
        data-name="Path 14032"
        d="M-933.806 713.006l-5.392 2.446 2.119-5.498 18.3-19.625a1.988 1.988 0 0 1 2.81-.098l.366.341a1.988 1.988 0 0 1 .098 2.81z"
        fill="#aed6dc"
      />
      <path
        data-name="Path 14033"
        d="M-939.508 715.843l1.463-3.391 1.817 1.694z"
        fill="#6b6b6b"
      />
      <path
        data-name="Rectangle 1647"
        fill="#fff"
        d="M-934.534 712.329l-.727-.678 17.623-18.898.727.678z"
      />
      <path
        data-name="Path 14034"
        d="M-958.533 704.186a10.532 10.532 0 1 1-10.532 10.532 10.532 10.532 0 0 1 10.532-10.532z"
        fill="#f53d65"
      />
      <path
        data-name="Line 22"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        d="M-958.5 710.686v9"
      />
      <path
        data-name="Line 23"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        d="M-954 715.186h-9"
      />
    </g>
  </svg>
);

export default createCases;
