import React, { Component } from 'react';

import { saveKeeper } from '../../helpers/api/community';
import { hasHtml, valueLength } from '../../helpers/validation';
import Loader from '../../shared/components/Loader';
import SentPage from '../../shared/SentPage';
import { notifyKeeper } from '../../helpers/api/user';
import KeeperComponent from './KeeperComponent';

export default class KeeperContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        keeper: ''
      },
      isLoading: false,
      sent: false,
      notified: false,
      valid: true,
      error: null
    };
  }

  componentDidMount() {
    const notifyKeeper = this.props.location.state
      ? this.props.location.state.notifyKeeper
      : false;
    notifyKeeper && this.handleNotifyKeeper();
  }

  handleSaveKeeper() {
    const { keeper } = this.state.form;
    this.setState({ isLoading: true });

    saveKeeper(keeper)
      .then(() => this.setState({ sent: true, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  handleNotifyKeeper() {
    this.setState({ isLoading: true });

    notifyKeeper()
      .then(() => this.setState({ notified: true, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  submitForm(e) {
    e.preventDefault();
    const { keeper } = this.state.form;
    const isValid = !hasHtml(keeper) && valueLength(keeper, 150, 1);
    if (isValid) {
      this.handleSaveKeeper();
    } else {
      this.setState({ valid: false });
    }
  }

  updateForm(value, field) {
    this.setState({ form: { ...this.state.form, [field]: value } });
  }

  render() {
    const { form, sent, valid, isLoading, error, notified } = this.state;

    if (isLoading) return <Loader />;
    if (sent)
      return (
        <SentPage
          header="Gratulacje! Zgłoszenie przyjęto."
          text="Skontaktujemy się z Twoim zarządcą!"
        />
      );
    if (notified)
      return (
        <SentPage
          header="Gratulacje! Zgłoszenie przyjęto."
          text="Wyślemy wiadomość do Twojego zarządcy z zapytaniem o numer Waszej księgi wieczystej!"
        />
      );

    return (
      <KeeperComponent
        goBack={this.props.history.goBack}
        keeper={form.keeper}
        updateForm={(value, field) => this.updateForm(value, field)}
        valid={valid}
        submitForm={e => this.submitForm(e)}
        error={error}
        wide
      />
    );
  }
}
