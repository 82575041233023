import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Alert, LinkedItem } from '../layout/theme/components';
import Button from '../shared/components/buttons/PrimaryButton';
import Captcha from '../shared/components/Captcha';
import WelcomeContainer from './components/WelcomeContainer';
import UsernameInputToggler from './UsernameInputToggler';

const SentBox = styled.div`
  width: 100%;
  padding-top: 16%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const PasswordRemindComponent = ({
  handleSubmit,
  dialling,
  username,
  handleChange,
  valid,
  sent,
  error,
  loading,
  sentText
}) => (
  <WelcomeContainer>
    {!sent ? (
      <Fragment>
        <form onSubmit={e => handleSubmit(e)}>
          <UsernameInputToggler
            dialling={dialling}
            username={username}
            handleChange={handleChange}
            valid={valid}
          />
          {error && <Alert negative>Wystąpił błąd, spróbuj jeszcze raz.</Alert>}
          <Link to="/logowanie">
            <LinkedItem>Wróć do logowania</LinkedItem>
          </Link>
          <Captcha loading={loading}>RESETUJ HASŁO</Captcha>
        </form>
      </Fragment>
    ) : (
      <SentBox>
        <Alert>{sentText}</Alert>
        <Link to="/logowanie">
          <Button>ZALOGUJ</Button>
        </Link>
      </SentBox>
    )}
  </WelcomeContainer>
);

PasswordRemindComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  dialling: PropTypes.string,
  username: PropTypes.string,
  sentText: PropTypes.string,
  valid: PropTypes.bool
};

PasswordRemindComponent.defaultProps = {
  dialling: '',
  username: '',
  sentText: 'Wysłano hasło na podany adres e-mail',
  valid: ''
};

export default PasswordRemindComponent;
