import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setActiveFilter, setUnvotedResolutionsNumber } from '../data/case/actions';
import {
  createCase,
  fetchCurrentStep,
  fetchLastStep,
  fetchNextStep,
  fetchPreviewStep,
  fetchPreviousStep,
  removeCase,
} from '../helpers/api/case';
import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import AnonymousContainer from './step/AnonymousContainer';
import CulpritContainer from './step/CulpritContainer';
import MediaContainer from './step/MediaContainer';
import MeetingContainer from './step/MeetingContainer';
import PlaceContainer from './step/PlaceContainer';
import PrepareResolutionContainer from './step/PrepareResolutionContainer';
import PreviewContainer from './step/PreviewContainer';
import PublishedComponent from './step/PublishedComponent';
import RangeContainer from './step/RangeContainer';
import ResolutionContainer from './step/ResolutionContainer';
import SoundingContainer from './step/SoundingContainer';
import TopicContainer from './step/TopicContainer';
import StepComponent from './StepComponent';

class StepContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: false,
      category: {},
      step: null,
      published: false,
      prompt: null,
      caseId: null,
      selectionType: '',
      data: {},
    };

    this.getNextStep = this.getNextStep.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.getPreviousStep = this.getPreviousStep.bind(this);
    this.setPublishedStatus = this.setPublishedStatus.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    if (location.state.categoryId)
      createCase(location.state.categoryId)
        .then(response => this.getStep(response.data.case_id))
        .catch(error => this.setState({ error, isLoading: false }));
    else if (location.state.caseId) this.getStep(location.state.caseId);
  }

  setLoading(isLoading) {
    this.setState({ isLoading });
  }

  getStep(caseId) {
    fetchCurrentStep(caseId)
      .then(response =>
        this.setState({
          isLoading: false,
          step: response.data.type,
          data: response.data,
          prompt: response.data.display_prompt ? response.data.prompt_text : null,
          caseId,
          selectionType: response.data.selection_type,
          category: response.data.category,
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getNextStep() {
    const { caseId, step } = this.state;
    this.setState({ isLoading: true });
    fetchNextStep(caseId, step)
      .then(response => {
        if (response.data) {
          this.setState({
            isLoading: false,
            step: response.data.type,
            data: response.data,
            prompt: response.data.display_prompt ? response.data.prompt_text : null,
            selectionType: response.data.selection_type,
          });
        } else {
          this.getPreviewStep();
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getPreviousStep() {
    const { caseId, step, data } = this.state;
    const { location, history } = this.props;
    if (location.state.isDraft && data.current_step === 1) return history.push('/');
    this.setState({
      isLoading: true,
    });
    if (step === 'preview') {
      fetchLastStep(caseId)
        .then(response => {
          if (response.data) {
            this.setState({
              isLoading: false,
              step: response.data.type,
              data: response.data,
              prompt: response.data.display_prompt ? response.data.prompt_text : null,
              selectionType: response.data.selection_type,
            });
          } else {
            this.removeCaseHandler(`/podkategorie/${data.category.parent_id}`);
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    } else {
      fetchPreviousStep(caseId, step)
        .then(response => {
          if (response.data) {
            this.setState({
              isLoading: false,
              step: response.data.type,
              data: response.data,
              prompt: response.data.display_prompt ? response.data.prompt_text : null,
              selectionType: response.data.selection_type,
            });
          } else {
            this.removeCaseHandler(`/podkategorie/${data.category.parent_id}`);
          }
        })
        .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  getPreviewStep() {
    const { caseId } = this.state;
    fetchPreviewStep(caseId)
      .then(response =>
        this.setState({
          isLoading: false,
          step: 'preview',
          data: response.data,
          selectionType: response.data.selection_type,
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  setPublishedStatus() {
    const { setUnvotedResolutionsNumber, setActiveFilter } = this.props;
    this.state.data.resolution && setUnvotedResolutionsNumber();
    setActiveFilter('myCases');
    this.setState({
      isLoading: false,
      published: true,
    });
  }

  removeCaseHandler(redirect) {
    const { caseId } = this.state;
    const location = {
      pathname: redirect ? redirect : '/',
      state: { goBack: true },
    };
    removeCase(caseId)
      .then(() => this.props.history.push(location))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderStep() {
    const { step, caseId, data, selectionType, category } = this.state;
    switch (step) {
      case 'anonymous':
        return <AnonymousContainer caseId={caseId} handleNextStep={this.getNextStep} anonymous={data.anonymous} />;
      case 'topic':
        return (
          <TopicContainer
            caseId={caseId}
            handleNextStep={this.getNextStep}
            setLoading={this.setLoading}
            topic={data.topic}
            description={data.description}
            attachments={data.attachments}
            communityCost={data.community_cost}
          />
        );
      case 'sounding':
        return (
          <SoundingContainer
            caseId={caseId}
            handleNextStep={this.getNextStep}
            sounding={data.sounding}
            question={data.question}
          />
        );
      case 'place':
        return <PlaceContainer caseId={caseId} places={data.places} handleNextStep={this.getNextStep} />;
      case 'range':
        return <RangeContainer caseId={caseId} ranges={data.ranges} handleNextStep={this.getNextStep} />;
      case 'media':
        return (
          <MediaContainer
            caseId={caseId}
            medias={data.medias}
            meterValue={data.meter_value}
            multipleChoice={false}
            handleNextStep={this.getNextStep}
            selectionType={selectionType}
          />
        );
      case 'culprit': {
        return (
          <CulpritContainer
            caseId={caseId}
            address={data.address}
            description={data.description}
            handleNextStep={this.getNextStep}
          />
        );
      }
      case 'preview':
        return (
          <PreviewContainer
            caseId={caseId}
            caseDetails={data}
            categoryName={category.name}
            handleAfterPublish={this.setPublishedStatus}
            removeCase={() => this.removeCaseHandler()}
          />
        );
      case 'choice_resolution_template':
        return (
          <ResolutionContainer
            caseId={caseId}
            templates={data.templates}
            file={data.file}
            handleNextStep={this.getNextStep}
            subtype={data.subtype}
          />
        );
      case 'prepare_resolution':
        return (
          <PrepareResolutionContainer
            caseId={caseId}
            handleNextStep={this.getNextStep}
            file={data.file}
            templates={data.templates}
            subtype={data.subtype}
            title={data.title}
            content={data.content}
            attachments={data.attachments}
          />
        );
      case 'meeting':
        return (
          <MeetingContainer caseId={caseId} datetime={data.time} place={data.place} handleNextStep={this.getNextStep} />
        );
      default:
        return null;
    }
  }

  render() {
    const { isLoading, error, category, prompt, caseId, published, data, step } = this.state;
    const { location } = this.props;
    if (!location.state.categoryId && !location.state.caseId) return <Redirect to="/kategorie" />;
    if (isLoading) return <Loader />;
    if (error) return <Error />;
    if (published) return <PublishedComponent caseId={caseId} caseNumber={data.case_number} />;
    return (
      <StepComponent
        showCategoryPic={step === 'topic'}
        prompt={prompt}
        categoryName={category.name}
        categoryColor={category.parent_color}
        categoryIcon={category.parent_icon && category.parent_icon.path}
        rangeIcon={step === 'preview' && data.icons.range.path}
        picturePath={category.picture && category.picture.path}
        goBackHandler={this.getPreviousStep}
        allSteps={category.steps_count}
        currentStep={data.current_step}
      >
        {this.renderStep()}
      </StepComponent>
    );
  }
}

StepContainer.propTypes = {
  setActiveFilter: PropTypes.func.isRequired,
  setUnvotedResolutionsNumber: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setActiveFilter: filter => dispatch(setActiveFilter(filter)),
  setUnvotedResolutionsNumber: () => dispatch(setUnvotedResolutionsNumber()),
});

export default connect(
  null,
  mapDispatchToProps
)(StepContainer);
