import { getAccessControlList } from '../../helpers/api/community';
import { fetchLogin, fetchLogout, fetchProfile } from '../../helpers/api/user';
import { setUnvotedDisplayed } from '../../helpers/localStorage';
import { setUnvotedResolutionsNumber } from '../case/actions';
import * as UserActions from './actionTypes';

const loginStarted = () => ({
  type: UserActions.LOGIN_STARTED,
});
const loginSucceeded = () => ({
  type: UserActions.LOGIN_SUCCESS,
});
const loginErrored = error => ({
  type: UserActions.LOGIN_ERROR,
  payload: error,
});
const logoutSucceeded = () => ({
  type: UserActions.LOGOUT,
});
const userFetchStarted = () => ({
  type: UserActions.FETCH_USER_STARTED,
});
const userFetchSucceeded = data => ({
  type: UserActions.FETCH_USER_SUCCESS,
  payload: data,
});
const userFetchErrored = error => ({
  type: UserActions.FETCH_USER_ERROR,
  payload: error,
});
const setResource = resoruceId => ({
  type: UserActions.SET_RESOURCE,
  payload: resoruceId,
});
const setDataResourceStarted = () => ({
  type: UserActions.SET_DATA_RESOURCE_STARTED,
});
const setDataResourceSucceed = resoruce => ({
  type: UserActions.SET_DATA_RESOURCE_SUCCEED,
  payload: resoruce,
});

export const saveCommunityStatus = value => {
  return {
    type: UserActions.SAVE_COMMUNITY_STATUS,
    payload: value,
  };
};

export const setPermissionsStarted = value => {
  return {
    type: UserActions.SET_PERMISSION_LIST_STARTED,
    value,
  };
};
export const setPermissionsSucceed = value => {
  return {
    type: UserActions.SET_PERMISSION_LIST_SUCCEED,
    value,
  };
};

export const setUsernameType = value => {
  return {
    type: UserActions.SET_USERNAME_TYPE,
    value,
  };
};

export const setUserLabel = value => {
  return {
    type: UserActions.SET_USER_LABEL,
    value,
  };
};

export const pickUsername = (firstName, lastName, username) => dispatch => {
  const userFirstName = firstName ? firstName : '';
  const userLastName = lastName ? lastName : '';
  const userName = userFirstName.length < 1 && userLastName.length < 1 ? username : `${userFirstName} ${userLastName}`;

  dispatch(setUserLabel(userName));
};

export const login = (username, password) => dispatch => {
  dispatch(loginStarted());
  fetchLogin(username, password)
    .then(() => setUnvotedDisplayed(false))
    .then(() => dispatch(loginSucceeded()), error => dispatch(loginErrored(error)));
};

export const logout = () => dispatch => {
  fetchLogout()
    .then(() => dispatch(logoutSucceeded()))
    .then(() => setUnvotedDisplayed(true));
};

export const fetchUser = (resourceId = null) => dispatch => {
  if (resourceId) dispatch(setResource(resourceId));
  dispatch(userFetchStarted());
  fetchProfile().then(
    data => {
      dispatch(userFetchSucceeded(data.data));

      const lastResource = data.data.last_resource_id ? Number(data.data.last_resource_id) : data.data.resources[0].id;
      dispatch(setResourceAndAccess(lastResource, data.data.resources));

      const firstName = data.data.first_name;
      const lastName = data.data.last_name;
      const username = data.data.username;
      dispatch(pickUsername(firstName, lastName, username));
    },
    error => dispatch(userFetchErrored(error.toString()))
  );
};

export const setResourceAndAccess = (resoruceId, resources) => dispatch => {
  dispatch(setResource(resoruceId));
  dispatch(setDataResourceStarted());
  resources.filter(resource => {
    if (Number(resource.id) === resoruceId) {
      return dispatch(setDataResourceSucceed(resource));
    } else return null;
  });
  dispatch(setUnvotedResolutionsNumber());
  getAccessControlList().then(data => dispatch(setPermissionsSucceed(data.data)));
};

export const setCurrentResource = resoruceId => dispatch => {
  dispatch(setResource(resoruceId));
};
