import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Cancel from '../../../../assets/cancel';

const Button = styled.button`
  position: ${({ position }) => position};
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  width: 35px;
  height: 35px;
  border: 2px solid ${({ theme }) => theme.buttonColors.primary};
  border-radius: 100%;
  background: ${({ theme }) => theme.buttonColors.primary};
  padding: 0;
  cursor: pointer;
  z-index: 1000;
  svg {
    fill: ${({ theme }) => theme.backgroundColors.primary};
    width: 45%;
    height: 45%;
  }
  &:hover {
    background: ${({ theme }) => theme.backgroundColors.primary};
    svg {
      fill: ${({ theme }) => theme.buttonColors.primary};
    }
  }
`;

const ExitButton = ({ onClick, top, left, right, position }) => {
  return (
    <Button
      onClick={onClick}
      top={top}
      left={left}
      right={right}
      position={position}
    >
      <Cancel />
    </Button>
  );
};

ExitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  position: PropTypes.string
};

ExitButton.defaultProps = {
  top: '-1em',
  left: 'auto',
  right: '-1em',
  position: 'absolute'
};

export default ExitButton;
