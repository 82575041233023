import React from 'react';

const communityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.999" height="8.719">
      <path
        d="M1.999 8.718h-2v-6L3.482 0l3.516 2.718v6h-2v-3h-3v3z"
        fill="#fff"
      />
    </svg>
  );
};

export default communityIcon;
