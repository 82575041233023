import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  width: 600px;
  margin: 0 auto;
  font-size: 10px;
  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`;

const ReportWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

ReportWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReportWrapper;
