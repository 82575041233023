import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { H2, Text } from '../../layout/theme/components';
import Container from '../../shared/components/Container';
import ArrowIcon from '../../shared/components/ArrowIcon';

import TutorialScreen from './../../../assets/img/finances-tutorial.png';
import TutorialScreen2 from './../../../assets/img/finances-tutorial2.png';
import TutorialScreen3 from './../../../assets/img/finances-tutorial3.png';

const FinancesWrapper = styled.div`
  text-align: left;
  margin: 0 10px;
  color: #000000;
  word-break: normal;
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledText = styled(Text)`
  margin: 10px 0 0 15px;
`;

const StyledImage = styled.img`
  width: 100%;
  margin: 15px 0;
`;

const FinancesTutorialComponent = () => {
  return (
    <Container>
      <Link to="/finanse">
        <ArrowIcon />
      </Link>
      <FinancesWrapper>
        <StyledH2>Jak znaleźć dane logowania?</StyledH2>
        <Text small>
          1. Zaloguj się do panelu <strong>Weles3</strong>
        </Text>
        <Text small>
          2. Kliknij w zakładkę <strong>Moje dane</strong>
        </Text>
        <Text small>
          3. <strong>Nazwa użytkownika</strong>
        </Text>
        <StyledText small>
          Znajdziesz ją po zalogowaniu do Weles3 w zakładce "Moje dane". Jest to
          ciąg cyfr znajdujący się pod polem "Użytkownik".
        </StyledText>
        <StyledImage
          src={TutorialScreen}
          alt="Samouczek z instrukcją jak znaleźć dane logowania do Weles3 obrazek 1"
        />
        <StyledText small>
          Możesz także poprosić Twojego zarządcę o wydruk dokumentu "Dane
          logowania do e-kartoteki" - tam w tabeli znajdziesz nazwę użytkownika.
        </StyledText>
        <StyledImage
          src={TutorialScreen2}
          alt="Samouczek z instrukcją jak znaleźć dane logowania do Weles3  obrazek 2"
        />
        <Text small>
          4. <strong>PIN</strong>
        </Text>
        <StyledText small>
          Krótki kod PIN, który ustawiłeś w Weles3. Opcję tę znajdziesz na
          ekranie głównym, zaraz po zalogowaniu do Weles3:
        </StyledText>
        <Text small>
          5. <strong>Kod PIN</strong>
        </Text>
        <StyledText small>
          Po ustawieniu krótkiego kodu PIN system Weles3 przydzieli Tobie
          unikalny "Kod PIN", który będzie dostępny w sekcji "Logowanie przez
          PIN".
        </StyledText>
        <StyledImage
          src={TutorialScreen3}
          alt="Samouczek z instrukcją jak znaleźć dane logowania do Weles3  obrazek 3"
        />
      </FinancesWrapper>
    </Container>
  );
};

export default FinancesTutorialComponent;
