import React from 'react';

const AnonymFemale = () => (
  <svg
    viewBox="0 0 149 101"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <rect id="ilu04" width="149" height="100.5" fill="none" />
    <path
      d="M49.127,15.589c-9.736,0.001 -17.639,7.904 -17.639,17.64c0,3.116 0.809,6.044 2.23,8.586l-15.025,0c-9.736,0 -17.639,7.903 -17.639,17.639c0,9.735 7.903,17.639 17.639,17.639l81.18,0c9.735,0 17.639,-7.904 17.639,-17.639c0,-3.116 -0.809,-6.045 -2.23,-8.586l15.025,0c9.736,0 17.639,-7.904 17.639,-17.639c0,-9.736 -7.903,-17.639 -17.639,-17.64l-81.18,0Z"
      fill="#f7f7f7"
    />
    <path
      d="M41.03,34.331c0.577,-17.48 14.929,-31.472 32.549,-31.472c17.619,0 31.971,13.992 32.548,31.472l0.018,0l0,59.157l-65.133,0l0,-59.157l0.018,0Z"
      fill="#f78fa6"
    />
    <path
      d="M46.735,44.699c0.476,-14.416 12.312,-25.956 26.844,-25.956c14.531,0 26.367,11.54 26.843,25.956l0.015,0l0,48.789l-53.717,0l0,-48.789l0.015,0Z"
      fill="#e57790"
    />
    <path d="M97.774,90.889l0,0l0,0l0,0Z" fill="#767191" />
    <path
      d="M79.22,81.553c0,-1.118 -0.907,-2.026 -2.026,-2.026l-7.231,0c-1.119,0 -2.026,0.908 -2.026,2.026l0,8.951c0.015,3.554 11.297,3.243 11.283,0l0,-8.951Z"
      fill="#fbe3e9"
    />
    <path
      d="M100.437,40.047c0,-14.824 -12.035,-26.859 -26.858,-26.859c-14.824,0 -26.859,12.035 -26.859,26.859l0,17.55c0,14.824 12.035,26.859 26.859,26.859c14.823,0 26.858,-12.035 26.858,-26.859l0,-17.55Z"
      fill="#fcecf0"
    />
    <path
      d="M100.452,42.406c3.125,0 5.662,2.537 5.662,5.662c0,3.125 -2.537,5.662 -5.662,5.662c-3.125,0 -3.125,-11.324 0,-11.324Z"
      fill="#fcecf0"
    />
    <path
      d="M45.739,53.451c-3.125,0 -5.662,-2.537 -5.662,-5.662c0,-3.125 2.537,-5.662 5.662,-5.662c3.125,0 3.125,11.324 0,11.324Z"
      fill="#fcecf0"
    />
    <path
      d="M66.376,69.988c0.376,2.876 3.488,5.065 7.203,5.065c3.714,0 6.827,-2.189 7.202,-5.065l-14.405,0Z"
      fill="#767191"
    />
    <path
      d="M80.781,69.89l-14.405,0c0.001,0.487 0.112,0.999 0.357,1.541l13.672,0c0.233,-0.39 0.355,-0.908 0.376,-1.541Z"
      fill="#fff"
    />
    <path
      d="M69.879,74.294c0.55,-0.881 1.999,-1.495 3.7,-1.495c1.7,0 3.149,0.614 3.699,1.495c-1.065,0.478 -2.314,0.751 -3.699,0.759l0,0c-1.347,-0.009 -2.607,-0.281 -3.7,-0.759Z"
      fill="#f8cfd8"
    />
    <path
      d="M73.522,69.807c0.028,-0.131 0.043,-0.265 0.043,-0.399c0,-1.046 -0.849,-1.895 -1.895,-1.895c-1.046,0 -1.895,0.849 -1.895,1.895c0,0.134 0.014,0.268 0.042,0.399l3.705,0Z"
      fill="#f78ea5"
    />
    <path
      d="M77.312,69.807c0.028,-0.131 0.042,-0.265 0.042,-0.399c0,-1.046 -0.849,-1.895 -1.894,-1.895c-1.046,0 -1.895,0.849 -1.895,1.895c0,0.134 0.014,0.268 0.042,0.399l3.705,0Z"
      fill="#f78ea5"
    />
    <path
      d="M71.701,74.799c-0.028,0.131 -0.042,0.265 -0.042,0.399c0,1.046 0.849,1.895 1.895,1.895c1.046,0 1.895,-0.849 1.895,-1.895c0,-0.134 0.028,-0.268 0,-0.399c-1.232,0.211 -2.509,0.252 -3.748,0Z"
      fill="#f78ea5"
    />
    <path
      d="M71.493,61.361c-0.032,0.148 -0.048,0.299 -0.048,0.45c0,1.177 0.956,2.133 2.134,2.133c1.177,0 2.133,-0.956 2.133,-2.133c0,-0.151 -0.016,-0.302 -0.047,-0.45l-4.172,0Z"
      fill="#fedee5"
    />
    <circle cx="58.143" cy="60.03" r="3.549" fill="#f9a8ba" />
    <circle cx="90.509" cy="60.03" r="3.549" fill="#f9a8ba" />
    <path
      d="M82.689,50.026l4.066,0"
      fill="none"
      stroke="#fff"
      strokeWidth="4"
    />
    <circle cx="84.722" cy="51.03" r="2.033" fill="#6a6488" />
    <path
      d="M80.544,46.711c2.812,-1.635 5.594,-1.384 8.357,0"
      fill="none"
      stroke="#f67f9a"
      strokeWidth="2"
    />
    <path
      d="M61.389,50.026l4.066,0"
      fill="none"
      stroke="#fff"
      strokeWidth="4"
    />
    <circle cx="63.422" cy="51.03" r="2.033" fill="#6a6488" />
    <path
      d="M59.244,46.711c2.812,-1.635 5.594,-1.384 8.357,0"
      fill="none"
      stroke="#f67f9a"
      strokeWidth="2"
    />
    <path
      d="M44.52,51.03l0,2.033"
      fill="none"
      stroke="#afaacb"
      strokeWidth="2"
    />
    <path
      d="M102.864,51.03l0,2.033"
      fill="none"
      stroke="#afaacb"
      strokeWidth="2"
    />
    <path
      d="M63.575,37.982l37.549,0c0,-17.174 -12.332,-31.096 -27.545,-31.096c-15.214,0 -27.546,13.922 -27.546,31.096l13.666,0l2.317,-13.199"
      fill="#f78fa6"
    />
    <path
      d="M100.437,44.927l0,-8.265"
      fill="none"
      stroke="#e57790"
      strokeWidth="2"
    />
    <path
      d="M46.72,43.693l0,-11.97"
      fill="none"
      stroke="#e57790"
      strokeWidth="2"
    />
    <path
      d="M98.107,97.641c-6.545,-6.545 -15.421,-10.222 -24.678,-10.222c-9.256,0 -18.132,3.677 -24.678,10.222l49.356,0Z"
      fill="#f8cfd8"
      stroke="#f8cfd8"
      strokeWidth="4"
    />
  </svg>
);

export default AnonymFemale;
