import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 3em;
  }
`;

const SliderWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

SliderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default SliderWrapper;
