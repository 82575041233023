export const getAccessToken = () => {
  return localStorage.getItem('user');
};
export const saveAccessToken = token => {
  localStorage.setItem('user', token);
};
export const resetLocalStorage = () => {
  localStorage.removeItem('user');
};

export const getLastVisit = () => {
  return localStorage.getItem('lastVisit');
};
export const saveLastVisit = date => {
  localStorage.setItem('lastVisit', date);
};

export const saveActivationCode = code => {
  localStorage.setItem('activationCode', code);
};

export const getActivationCode = () => {
  return localStorage.getItem('activationCode');
};

export const resetActivationCode = code => {
  localStorage.setItem('activationCode', code);
};

export const setUnvotedDisplayed = code => {
  localStorage.setItem('unvotedDisplayed', code);
};

export const getUnvotedDisplayed = () => {
  return localStorage.getItem('unvotedDisplayed');
};
