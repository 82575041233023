import React from 'react';

function PrivateRangeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.468"
      height="17.468"
      fill="#c4c4c4"
    >
      <path fill="none" d="M0 0h17.468v17.468H0z" />
      <path d="M13.101 5.823h-.728V4.367a3.639 3.639 0 10-7.278 0v1.456h-.728a1.46 1.46 0 00-1.456 1.455v7.278a1.46 1.46 0 001.456 1.456h8.734a1.46 1.46 0 001.456-1.456V7.278a1.46 1.46 0 00-1.456-1.455zm-4.367 6.55a1.456 1.456 0 111.456-1.456 1.46 1.46 0 01-1.456 1.456zm2.256-6.55H6.477V4.367a2.256 2.256 0 014.513 0z" />
    </svg>
  );
}

export default PrivateRangeIcon;
