import React from 'react';

const TutorialArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.135" height="31.164">
    <g data-name="Group 174">
      <path
        data-name="Path 314"
        d="M5.18 30.509l19.512-19.513.014 12.282a2.257 2.257 0 0 0 .668 1.6 2.252 2.252 0 0 0 1.6.666h1.91a2.238 2.238 0 0 0 1.593-.66 2.237 2.237 0 0 0 .662-1.592V2.262a2.235 2.235 0 0 0-.665-1.595 2.235 2.235 0 0 0-1.6-.667H7.844a2.238 2.238 0 0 0-1.592.662 2.239 2.239 0 0 0-.662 1.591v1.909a2.234 2.234 0 0 0 .662 1.592 2.166 2.166 0 0 0 1.571.64l12.385-.021L.65 25.936a2.337 2.337 0 0 0 .019 3.253l1.35 1.347a2.292 2.292 0 0 0 3.216-.063"
        fill="#f53d65"
      />
    </g>
  </svg>
);

export default TutorialArrow;
