import React from 'react';

const SoundingPicture = () => (
  <svg
    viewBox="0 0 278 171"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h277.75v170.167H0z" />
    <path
      d="M147.95 39.258c14.413 0 26.114 11.701 26.114 26.114 0 4.613-1.199 8.948-3.301 12.711h22.243c14.414 0 26.115 11.702 26.115 26.114 0 14.413-11.702 26.114-26.115 26.114H72.822c-14.413 0-26.115-11.701-26.115-26.114 0-4.613 1.199-8.948 3.302-12.711H27.765c-14.413 0-26.114-11.701-26.114-26.114s11.701-26.114 26.114-26.114H147.95z"
      fill="#fdf4f6"
    />
    <path
      d="M204.899 61.977c14.413 0 26.114 11.701 26.114 26.114 0 4.613-1.199 8.949-3.301 12.711h22.243c14.413.001 26.115 11.702 26.115 26.115 0 14.412-11.702 26.114-26.115 26.114H129.771c-14.413 0-26.115-11.702-26.115-26.114 0-4.614 1.199-8.949 3.302-12.712H84.714c-14.413 0-26.114-11.701-26.114-26.114s11.701-26.114 26.114-26.114h120.185z"
      fill="#fdf4f6"
    />
    <path fill="#aedcf0" d="M84.887 67.693h82.448v100.429H84.887z" />
    <path
      d="M187.508 53.66l-20.173 14.033v100.429l20.173-14.034V53.66z"
      fill="#3dabdb"
    />
    <path
      d="M187.947 53.545l-20.174 14.034H84.887l20.173-14.034h82.887z"
      fill="#e2f1f8"
    />
    <path
      d="M166.673 57.395l-9.105 6.334h-51.407l9.104-6.334h51.408z"
      fill="#fff"
    />
    <path fill="#ebebeb" d="M84.887 99.481h82.448v39H84.887z" />
    <path
      d="M111.517 112.07l1.068-2.912c.736-2.011.259-5.082-1.784-6.174-.666-.355-1.595-.534-2.345-.345-.43.108-.807.393-1.033.774-.258.438-.232.95-.324 1.437-.234 1.233-.817 2.405-1.719 3.285-1.573 1.535-6.461 5.962-6.461 5.962v16.214h16.89c2.279.002 3.773-2.543 2.649-4.531 1.34-.858 1.798-2.666 1.013-4.054 1.34-.858 1.798-2.666 1.014-4.054 2.312-1.48 1.539-5.145-1.163-5.602-.163-.028-7.805 0-7.805 0z"
      fill="#57c543"
      fillRule="nonzero"
      stroke="#fff"
    />
    <path
      d="M140.704 125.745l-1.067 2.912c-.737 2.011-.26 5.082 1.784 6.174.665.356 1.595.534 2.344.345.43-.108.808-.392 1.033-.774.259-.438.232-.95.324-1.436.234-1.233.817-2.406 1.719-3.286 1.574-1.535 6.461-5.962 6.461-5.962v-16.214h-16.89c-2.279-.001-3.772 2.544-2.648 4.532-1.34.858-1.798 2.665-1.014 4.053-1.34.858-1.798 2.666-1.013 4.054-2.312 1.481-1.539 5.145 1.162 5.602.163.028 7.805 0 7.805 0z"
      fill="#f96060"
      fillRule="nonzero"
      stroke="#fff"
    />
    <path
      d="M94.639 22.489a1.65 1.65 0 0 0-2.253-.603l-12.338 7.123a1.65 1.65 0 0 0-.603 2.253L89.397 48.5a1.65 1.65 0 0 0 2.253.603l12.338-7.123a1.65 1.65 0 0 0 .603-2.252l-9.952-17.239z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M92.472 37.136l.201 1.154c.139.796.875 1.71 1.749 1.681.284-.009.622-.126.832-.329a.64.64 0 0 0 .191-.448c.002-.193-.103-.355-.165-.531a2.345 2.345 0 0 1-.058-1.399c.225-.799.987-3.169.987-3.169l-3.061-5.301-5.522 3.188c-.746.43-.754 1.544-.011 1.982a1.157 1.157 0 0 0 .434 1.516 1.158 1.158 0 0 0 .434 1.517c-.477.921.468 1.973 1.437 1.612.059-.021 2.552-1.473 2.552-1.473z"
      fill="#f96060"
      fillRule="nonzero"
    />
    <path
      d="M63.344 38.569a1.65 1.65 0 0 0-1.086-2.064L48.65 32.286a1.65 1.65 0 0 0-2.063 1.087l-5.895 19.012a1.649 1.649 0 0 0 1.086 2.063l13.608 4.22a1.65 1.65 0 0 0 2.063-1.087l5.895-19.012z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M51.122 46.925l-.711.931c-.49.643-.662 1.803-.047 2.426.2.202.516.371.807.386a.64.64 0 0 0 .459-.163c.142-.13.19-.317.278-.482a2.34 2.34 0 0 1 .987-.993c.739-.377 2.996-1.427 2.996-1.427l1.813-5.847-6.09-1.889c-.822-.255-1.645.496-1.462 1.338a1.16 1.16 0 0 0-.819 1.349c-.579.159-.946.76-.819 1.348-.999.276-1.13 1.684-.207 2.15.056.029 2.815.873 2.815.873z"
      fill="#f96060"
      fillRule="nonzero"
    />
    <path
      d="M92.723 90.573a1.65 1.65 0 0 0-.918-2.144L78.581 83.13a1.65 1.65 0 0 0-2.144.917l-7.404 18.477a1.649 1.649 0 0 0 .917 2.144l13.224 5.299a1.649 1.649 0 0 0 2.144-.917l7.405-18.477z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M79.868 97.92l-.783.871c-.541.601-.805 1.744-.242 2.414.183.218.484.411.773.45a.637.637 0 0 0 .471-.126c.152-.117.215-.3.315-.458a2.345 2.345 0 0 1 1.064-.91c.767-.317 3.102-1.182 3.102-1.182l2.277-5.682-5.919-2.372c-.799-.321-1.68.361-1.565 1.216a1.159 1.159 0 0 0-.924 1.278 1.16 1.16 0 0 0-.925 1.279c-1.018.194-1.262 1.587-.379 2.126.053.033 2.735 1.096 2.735 1.096z"
      fill="#f96060"
      fillRule="nonzero"
    />
    <path
      d="M205.007 56.002a1.65 1.65 0 0 0-.917-2.145l-13.224-5.299a1.65 1.65 0 0 0-2.144.917l-7.404 18.477a1.649 1.649 0 0 0 .917 2.144l13.224 5.3a1.65 1.65 0 0 0 2.144-.918l7.404-18.476z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M192.153 63.348l-.783.871c-.541.601-.805 1.744-.242 2.414.183.218.484.411.773.45a.637.637 0 0 0 .471-.126c.152-.117.214-.3.315-.458a2.345 2.345 0 0 1 1.064-.91c.767-.317 3.101-1.182 3.101-1.182l2.278-5.682-5.92-2.372c-.798-.321-1.679.362-1.564 1.216a1.16 1.16 0 0 0-.925 1.278 1.159 1.159 0 0 0-.924 1.279c-1.018.194-1.262 1.587-.38 2.126.054.033 2.736 1.096 2.736 1.096z"
      fill="#f96060"
      fillRule="nonzero"
    />
    <path
      d="M216.487 128.041a1.65 1.65 0 0 0-2.02-1.167l-13.761 3.688a1.65 1.65 0 0 0-1.166 2.019l5.152 19.227a1.65 1.65 0 0 0 2.02 1.166l13.761-3.687a1.65 1.65 0 0 0 1.166-2.02l-5.152-19.226z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M210.604 141.627l-.105 1.167c-.072.805.402 1.878 1.254 2.077.277.064.634.039.888-.103a.633.633 0 0 0 .301-.384c.052-.185-.008-.369-.022-.555a2.343 2.343 0 0 1 .306-1.366c.424-.714 1.774-2.806 1.774-2.806l-1.585-5.913-6.159 1.651c-.831.222-1.127 1.296-.523 1.911a1.159 1.159 0 0 0 .026 1.577 1.16 1.16 0 0 0 .027 1.578c-.698.766-.058 2.026.971 1.929.063-.006 2.847-.763 2.847-.763z"
      fill="#f96060"
      fillRule="nonzero"
    />
    <path
      d="M110.386 10.658a1.65 1.65 0 0 0-1.649-1.649H94.49a1.65 1.65 0 0 0-1.649 1.649v19.905c0 .91.739 1.649 1.649 1.649h14.247a1.65 1.65 0 0 0 1.649-1.649V10.658z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M102.031 18.926l.394-1.075c.272-.743.096-1.876-.658-2.279-.246-.132-.589-.197-.866-.128a.625.625 0 0 0-.381.286c-.096.162-.086.351-.12.53a2.28 2.28 0 0 1-.635 1.213c-.58.567-2.385 2.201-2.385 2.201v5.987h6.236c.841 0 1.393-.94.978-1.674.494-.316.663-.984.374-1.496.494-.317.664-.984.374-1.497.853-.546.568-1.899-.429-2.068-.06-.01-2.882 0-2.882 0z"
      fill="#57c543"
      fillRule="nonzero"
    />
    <path
      d="M190.779 30.028a1.649 1.649 0 0 0-2.332 0l-10.074 10.074a1.65 1.65 0 0 0 0 2.332l14.075 14.075a1.65 1.65 0 0 0 2.332 0l10.074-10.074a1.649 1.649 0 0 0 0-2.332l-14.075-14.075z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M190.718 41.782l-.482-1.039c-.332-.717-1.258-1.394-2.077-1.146-.266.081-.556.277-.702.522a.628.628 0 0 0-.068.472c.047.182.188.308.291.459.261.383.415.842.409 1.307-.01.811-.131 3.243-.131 3.243l4.233 4.233 4.409-4.409c.596-.595.321-1.649-.491-1.875a1.133 1.133 0 0 0-.794-1.323 1.13 1.13 0 0 0-.794-1.322c.218-.991-.941-1.745-1.765-1.16a279.64 279.64 0 0 0-2.038 2.038z"
      fill="#57c543"
      fillRule="nonzero"
    />
    <path
      d="M73.143 97.568a1.651 1.651 0 0 0-2.155-.894l-13.164 5.447a1.65 1.65 0 0 0-.893 2.154l7.609 18.393a1.65 1.65 0 0 0 2.155.893l13.164-5.446a1.65 1.65 0 0 0 .893-2.154l-7.609-18.393z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M68.584 108.402l-.047-1.144c-.033-.79-.629-1.771-1.48-1.855-.277-.027-.62.043-.849.213a.627.627 0 0 0-.243.41c-.026.186.055.357.092.536.095.454.061.936-.122 1.364-.321.745-1.363 2.945-1.363 2.945l2.288 5.532 5.762-2.384c.778-.321.928-1.4.264-1.92a1.132 1.132 0 0 0-.226-1.526 1.133 1.133 0 0 0-.226-1.526c.579-.831-.202-1.972-1.188-1.747-.059.014-2.662 1.102-2.662 1.102z"
      fill="#57c543"
      fillRule="nonzero"
    />
    <path
      d="M177.834 9.096a1.65 1.65 0 0 0-1.166-2.02l-13.761-3.687a1.65 1.65 0 0 0-2.02 1.166l-5.152 19.227a1.65 1.65 0 0 0 1.167 2.019l13.76 3.688a1.65 1.65 0 0 0 2.02-1.166l5.152-19.227z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M167.624 14.92l.659-.936c.455-.647.578-1.788-.046-2.372-.204-.191-.518-.343-.803-.348a.626.626 0 0 0-.443.178c-.134.131-.173.316-.253.481-.201.417-.521.78-.926 1.008-.708.396-2.874 1.508-2.874 1.508l-1.55 5.782 6.024 1.614c.812.218 1.588-.546 1.377-1.363.56-.178.896-.779.749-1.348.56-.178.896-.779.749-1.349.966-.307 1.04-1.688.12-2.109-.055-.026-2.783-.746-2.783-.746z"
      fill="#57c543"
      fillRule="nonzero"
    />
    <path
      d="M123.833 60.562H149"
      fill="none"
      stroke="#8781a8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M152.454 50.206a1.651 1.651 0 0 0-.373-2.303l-11.554-8.334a1.65 1.65 0 0 0-2.302.373L126.58 56.085a1.648 1.648 0 0 0 .373 2.302l2.547 1.838 15.772-.064 7.182-9.955z"
      fill="#fff"
      stroke="#e6e6e6"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
    <path
      d="M140.841 52.024l.948-.642c.655-.443 1.176-1.465.8-2.233-.123-.251-.363-.505-.628-.61a.619.619 0 0 0-.476.009c-.172.075-.275.234-.408.36a2.29 2.29 0 0 1-1.224.612c-.803.12-3.222.39-3.222.39l-3.502 4.855 5.057 3.648c.682.492 1.679.053 1.772-.785a1.134 1.134 0 0 0 1.179-.995 1.134 1.134 0 0 0 1.179-.995c1.012.056 1.572-1.208.862-1.928a262.933 262.933 0 0 0-2.337-1.686z"
      fill="#57c543"
      fillRule="nonzero"
    />
    <path
      d="M123.833 60.562H149"
      fill="none"
      stroke="#8781a8"
      strokeLinecap="round"
      strokeMiterlimit="1.5"
    />
  </svg>
);

export default SoundingPicture;
