import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Text, LinkedItem, Alert } from '../../../layout/theme/components';
import TextButton from '../../../shared/components/buttons/TextButton';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import Loader from '../../../shared/components/Loader';

import { INVALID_FILE, NETWORK_ERROR_TEXT } from './../../../helpers/constants';

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  margin: auto;
`;

const StyledRangeThumb = props => `
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  top: -0.7em;
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
  border-radius: 50%;
  border: none;
  background: ${props.theme.buttonColors.primary};
  box-shadow: ${props.theme.boxShadow.button};
`;

const StyledRangeTrack = props => `
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props.theme.textColors.tertiary};
`;

const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  margin: 10% 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    ${StyledRangeThumb};
  }
  &::-webkit-slider-runnable-track {
    ${StyledRangeTrack};
  }
  /* Microsoft Edge */
  &::-ms-track {
    ${StyledRangeTrack};
  }
  /* Firefox */
  @-moz-document url-prefix() {
    border: 1px solid #e5e5e5;
    height: 1px;
  }
  &::-moz-range-thumb {
    ${StyledRangeThumb};
  }
`;
const RangeInputBox = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.textColors.tertiary};
`;
const StyledText = styled(Text)`
  white-space: nowrap;
  margin-right: 0.5em;
`;
const InputWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textColors.secondary};
  cursor: pointer;
  &:after {
    content: '+';
    display: inline-block;
    margin-left: 0.5em;
    font-size: 2.2em;
    font-weight: 700;
  }
  input[type='file'] {
    display: none;
  }
`;
const ButtonsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2%;
`;
const AvatarEditorWrapper = styled.div`
  height: 250px;
  width: 250px;
`;
const BottomButtonsBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledAlert = styled(Alert)`
  margin-top: 3%;
`;
const LoaderBox = styled.div`
  position: absolute;
  top: 11%;
  width: 100%;
  height: 65%;
  z-index: 1000;
  background: white;
`;

const AvatarEditorComponent = ({
  toggleModal,
  handleSaveAvatar,
  handleNewImage,
  handleRemoveFile,
  image,
  handleScale,
  scale,
  children,
  valid,
  isLoading,
  error
}) => {
  return (
    <AvatarWrapper>
      <ButtonsBox>
        <InputWrapper>
          <LinkedItem>Dodaj zdjęcie</LinkedItem>
          <input
            type="file"
            onChange={handleNewImage}
            name="newImage"
            accept=".png, .jpg, .jpeg"
          />
        </InputWrapper>
        {image && (
          <TextButton onClick={handleRemoveFile} small>
            Usuń
          </TextButton>
        )}
      </ButtonsBox>
      <AvatarEditorWrapper>{children}</AvatarEditorWrapper>

      {!valid && <StyledAlert negative>{INVALID_FILE['size']}</StyledAlert>}
      {error && <StyledAlert negative>{NETWORK_ERROR_TEXT}</StyledAlert>}
      {isLoading && (
        <LoaderBox>
          <Loader />
        </LoaderBox>
      )}
      <RangeInputBox>
        <StyledText>Zoom:</StyledText>
        <StyledInput
          name="scale"
          type="range"
          onChange={handleScale}
          min="1"
          max="8"
          step="0.01"
          value={scale}
        />
      </RangeInputBox>
      <BottomButtonsBox>
        <TextButton onClick={() => toggleModal(false)} small>
          ODRZUĆ
        </TextButton>
        <PrimaryButton disabled={!image} onClick={handleSaveAvatar} small>
          ZAPISZ
        </PrimaryButton>
      </BottomButtonsBox>
    </AvatarWrapper>
  );
};

AvatarEditorComponent.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  handleSaveAvatar: PropTypes.func.isRequired,
  handleNewImage: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  handleScale: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  valid: PropTypes.bool
};

AvatarEditorComponent.defaultProps = {
  valid: true
};

export default AvatarEditorComponent;
