import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { fetchTutorial } from '../../helpers/api/case';
import Loader from '../../shared/components/Loader';
import Error from '../../shared/Error';
import Step1 from './steps/Step1';
import Step10 from './steps/Step10';
import Step11 from './steps/Step11';
import Step12 from './steps/Step12';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import Step7 from './steps/Step7';
import Step8 from './steps/Step8';
import Step9 from './steps/Step9';

class TutorialContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      ranges: [],
      activeSlide: 0,
      isLoading: false,
      error: null,
    };

    this.slider = createRef();
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    this.setState({ isLoading: true });

    fetchTutorial()
      .then(data =>
        this.setState({
          categories: data.data.all_categories,
          ranges: data.data.all_ranges,
          isLoading: false,
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  nextSlide = () => this.slider.current.slickNext();

  prevSlide = () => this.slider.current.slickPrev();

  options = {
    accessibility: true,
    infinite: false,
    arrows: false,
    beforeChange: (current, next) => this.setState({ activeSlide: next }),
  };

  getAddress = () => {
    const { currentResourceStreet } = this.props;
    return currentResourceStreet ? currentResourceStreet : '';
  };

  renderTutorial() {
    const { closeTutorial, userLabel, gender } = this.props;
    const { categories, ranges, activeSlide } = this.state;

    return (
      <Slider ref={this.slider} {...this.options}>
        <Step1 name={userLabel} address={this.getAddress()} isFemale={gender === 'F'} nextSlide={this.nextSlide} />
        <Step2 nextSlide={this.nextSlide} />
        <Step3 nextSlide={this.nextSlide} activeSlide={activeSlide} />
        <Step4 nextSlide={this.nextSlide} activeSlide={activeSlide} />
        <Step5 nextSlide={this.nextSlide} activeSlide={activeSlide} />
        <Step6 nextSlide={this.nextSlide} categories={categories} />
        <Step7 nextSlide={this.nextSlide} />
        <Step8 nextSlide={this.nextSlide} />
        <Step9 nextSlide={this.nextSlide} ranges={ranges} />
        <Step10 nextSlide={this.nextSlide} ranges={ranges} />
        <Step11 nextSlide={this.nextSlide} ranges={ranges} />
        <Step12 closeTutorial={() => closeTutorial()} />
      </Slider>
    );
  }

  render() {
    const { categories, ranges, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <Error />;
    return <>{categories.length > 0 && ranges.length > 0 ? this.renderTutorial() : 'Brak dostępnych danych'}</>;
  }
}

TutorialContainer.propTypes = {
  currentResourceStreet: PropTypes.string,
  userLabel: PropTypes.string.isRequired,
};

TutorialContainer.defaultProps = {
  currentApartmentStreet: '',
};

const mapStateToProps = state => ({
  currentResourceStreet: state.user.currentDataResource.data ? state.user.currentDataResource.data.display_name : '',
  gender: state.user.profile.data ? state.user.profile.data.gender : '',
  userLabel: state.user ? state.user.userLabel : '',
});

export default connect(mapStateToProps)(TutorialContainer);
