import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const FormNode = styled.form`
  width: 100%;
`;

class Form extends Component {
  render() {
    const { submitHandler, children } = this.props;
    return <FormNode onSubmit={e => submitHandler(e)}>{children}</FormNode>;
  }
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  submitHandler: PropTypes.func.isRequired
};

export default Form;
