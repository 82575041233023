import PropTypes from 'prop-types';
import React from 'react';
import SelectSearch from 'react-select-search';
import styled from 'styled-components';
import { NavItem } from '../../layout/theme/components';
import DropdownArrow from './DropdownArrow';

const SelectWrapper = styled.div`
  margin-top: 2em;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid ${({ theme }) => theme.buttonColors.inactive};
    position: absolute;
    z-index: 5;
    width: 100%;
  }
`;
const ListItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.buttonColors.inactive};
  width: 100%;
  padding: 0.5em 1em;
  font-weight: 600;
  cursor: pointer;
  height: 4em;
  z-index: 2;
`;
const StyledNavItem = styled(NavItem)`
  flex: 1;
  word-break: break-word;
  color: ${({ active, theme }) => (active ? theme.textColors.secondary : 'inherit')};
`;
const Drop = styled.div`
  position: relative;
`;
const DropdownInput = styled.input`
  background: ${({ theme }) => theme.backgroundColors.primary};
  color: ${({ theme }) => theme.textColors.secondary};
  border: 1px solid ${({ theme }) => theme.buttonColors.inactive};
  width: 100%;
  padding: 1em 2em 1em 1em;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.2;
  cursor: pointer;
  height: auto;
  z-index: 2;
`;
const ArrowWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

function SearchDropdown({ options, selected, placeholder, clickHandler }) {
  const handleFilter = items => {
    return searchValue => {
      if (searchValue.length === 0) {
        return options;
      }
      const updatedItems = items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      return updatedItems;
    };
  };

  const renderOption = ({ value }) => {
    const isActive = selected === value ? true : false;
    const name = options.find(item => item.value === value).name;
    return (
      <ListItem onClick={() => clickHandler(value)}>
        <StyledNavItem active={isActive}>{name}</StyledNavItem>
      </ListItem>
    );
  };

  const renderValue = props => {
    return (
      <Drop>
        <DropdownInput {...props} />
        {options.length > 1 && (
          <ArrowWrapper>
            <DropdownArrow />
          </ArrowWrapper>
        )}
      </Drop>
    );
  };

  return (
    <SelectWrapper>
      <SelectSearch
        options={options}
        value={selected}
        placeholder={placeholder}
        filterOptions={handleFilter}
        renderOption={renderOption}
        renderValue={renderValue}
        search={options.length > 6}
        closeOnSelect={false}
      />
    </SelectWrapper>
  );
}

SearchDropdown.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchDropdown;
