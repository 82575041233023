import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import CaseList from './case/listing/ListingContainer';
import CasePreviewContainer from './case/preview/CasePreviewContainer';
import CasePublicContainer from './case/preview/CasePublicContainer';
import ReportContainer from './case/report/ReportContainer';
import Step from './case/StepContainer';
import CategoriesList from './categories/CategoriesContainer';
import SubcategoriesList from './categories/SubcategoriesContainer';
import Contacts from './community/ContactsContainer';
import Evaluation from './community/EvaluationContainer';
import ResolutionComponent from './community/ResolutionComponent';
import CommunityStatusContainer from './community/status/CommunityStatusContainer';
import FeatureContainer from './community/status/FeatureContainer';
import InvitingContainer from './community/status/InvitingContainer';
import Keeper from './community/status/KeeperContainer';
import LevelContainer from './community/status/LevelContainer';
import Upgrade from './community/status/UpgradeContainer';
import FinancesTutorialComponent from './finances/components/FinancesTutorialComponent';
import FinancesContainer from './finances/FinancesContainer';
import { getAccessToken } from './helpers/localStorage';
import { initializeReactGA } from './helpers/tools';
import Layout from './layout/Layout';
import GlobalStyle, { theme } from './layout/theme/globalStyle';
import Loader from './shared/components/Loader';
import PageNotFound from './shared/PageNotFound';
import { store } from './store';
import Login from './user/LoginContainer';
import Logout from './user/LogoutContainer';
import NewPassword from './user/NewPasswordContainer';
import NotificationsContainer from './user/notifications/NotificationsContainer';
import PasswordReminder from './user/PasswordRemindContainer';
import { RegistrationContainer } from './user/registration/RegistrationContainer';
import UserActivation from './user/registration/UserActivationContainer';
import UserProfile from './user/userProfile/UserProfileContainer';

const LazyVotePower = lazy(() => import('./community/votePower/VotePowerContainer'));

const PrivateRoute = ({
  component: Component,
  menu,
  addCaseButton,
  search,
  apartmentLabel,
  redirect = true,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      getAccessToken() || !redirect ? (
        <Layout
          menu={menu}
          addCaseButton={addCaseButton}
          apartmentLabel={apartmentLabel}
          search={search}
          history={props.history}
        >
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/logowanie',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PrivateRouteNoLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      getAccessToken() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/logowanie',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const WaitingComponent = Component => {
  return props => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

const App = () => {
  initializeReactGA();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <>
          <Normalize />
          <GlobalStyle />
          <Router>
            <Switch>
              <Route path="/rejestracja" component={RegistrationContainer} />
              <Route path="/logowanie" component={Login} />
              <Route path="/wylogowanie" component={Logout} />
              <Route path="/nowykod" component={PasswordReminder} />
              <Route path="/aktywacja/:token" component={UserActivation} />
              <Route path="/zaproszenie" component={InvitingContainer} />
              <Route path="/zaproszenie/:apartment" component={InvitingContainer} />
              <Route path="/public/sprawa/:id" component={CasePublicContainer} />
              <PrivateRoute exact path="/" component={CaseList} menu addCaseButton search apartmentLabel />
              <PrivateRoute path="/sprawa/dodaj" component={Step} />
              <PrivateRoute path="/sprawa/:id" component={CasePreviewContainer} redirect={false} />
              <PrivateRoute path="/kategorie" component={CategoriesList} />
              <PrivateRoute path="/podkategorie/:id" component={SubcategoriesList} />
              <PrivateRoute path="/wspolnota" component={CommunityStatusContainer} />
              <PrivateRoute path="/kontakty" component={Contacts} />
              <PrivateRoute path="/powiadomienia" component={NotificationsContainer} />
              <PrivateRoute path="/ocena" component={Evaluation} />
              <PrivateRoute path="/kalkulator" component={WaitingComponent(LazyVotePower)} />
              <PrivateRoute path="/odblokuj" component={Upgrade} />
              <PrivateRoute path="/zarzadca" component={Keeper} />
              <PrivateRoute path="/poziomy/:name" component={LevelContainer} />
              <PrivateRoute path="/funkcje/:feature" component={FeatureContainer} />
              <PrivateRoute path="/finanse" component={FinancesContainer} />
              <PrivateRoute path="/jak-znalezc-dane-logowania-weles3" component={FinancesTutorialComponent} />
              <PrivateRoute path="/uchwala" component={ResolutionComponent} />
              <PrivateRouteNoLayout path="/raport/:id" component={ReportContainer} />
              <PrivateRouteNoLayout path="/nowehaslo" component={NewPassword} />
              <PrivateRouteNoLayout path="/profil" component={UserProfile} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
