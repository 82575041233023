import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import TooltipWrapper from '../../shared/components/TooltipWrapper';

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: #454545;

  th,
  td {
    padding: 1em 0.2em;
    font-weight: 400;
    vertical-align: top;
    word-break: keep-all;
    font-size: 1.4em;
  }
  th {
    padding: 0.5em 0.2em;
    word-break: keep-all;
  }
`;
const TableHeader = styled.tr`
  color: #756ba4;
`;
const GroupHeader = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.borderColor};

  &:first-child {
    border-color: #675e97;
  }
`;

const Table = props => {
  const { columns, rows } = props;

  return (
    <StyledTable>
      <thead>
        <TableHeader>
          {columns.map((column, index) => (
            <th key={index}>
              <TooltipWrapper tooltip={column.tooltip}>
                {column.label}
              </TooltipWrapper>
            </th>
          ))}
        </TableHeader>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <Fragment key={`row-${index}`}>
            <GroupHeader key={row.id}>
              {columns.map((column, index) => (
                <td key={index}>{row[column.id]}</td>
              ))}
            </GroupHeader>
            {row.subrows &&
              row.subrows.map((subrow, index) => (
                <tr key={`subrow-${index}`}>
                  {columns.map((column, index) => (
                    <td key={index}>{subrow[column.id]}</td>
                  ))}
                </tr>
              ))}
          </Fragment>
        ))}
      </tbody>
    </StyledTable>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.array
};

Table.defaultProps = {
  rows: []
};

export default Table;
