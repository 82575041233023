import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const CasePreviewWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  padding: 0 6%;
  padding-bottom: ${({ isStickyLabel }) => (isStickyLabel ? '100px' : '3%')};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    width: 70%;
    max-width: 900px;
    word-break: break-word;
  }
`;

const CasePreviewComponent = ({ children, isStickyLabel }) => {
  return (
    <CasePreviewWrapper isStickyLabel={isStickyLabel}>
      {children}
    </CasePreviewWrapper>
  );
};

CasePreviewComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isStickyLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

CasePreviewComponent.defaultProps = {
  isStickyLabel: null
};

export default CasePreviewComponent;
