import React from 'react';

const Welcome = () => (
  <svg
    viewBox="0 0 360 264"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <path fill="none" d="M0 0h359v263H0z" />
    <path
      d="M188.537 194.486c-15.411-.001-27.922-12.512-27.922-27.923 0-4.933 1.282-9.568 3.53-13.591h-23.784c-15.411-.001-27.923-12.512-27.923-27.923 0-15.411 12.512-27.922 27.923-27.922h128.507c15.411 0 27.922 12.511 27.922 27.922 0 4.933-1.281 9.568-3.53 13.591h23.784c15.411 0 27.923 12.512 27.923 27.923 0 15.411-12.512 27.922-27.923 27.923H188.537z"
      fill="#ffe4ea"
    />
    <path
      d="M303.021 164.77c-4.654 0-8.432-3.778-8.432-8.431 0-1.49.388-2.89 1.066-4.104h-7.182c-4.653 0-8.431-3.779-8.431-8.432 0-4.654 3.778-8.432 8.431-8.432h38.805a8.436 8.436 0 0 1 7.366 12.536h7.182c4.653 0 8.432 3.778 8.432 8.432 0 4.653-3.779 8.431-8.432 8.431h-38.805z"
      fill="#d8d4ec"
    />
    <circle cx="244.655" cy="70.657" r="62.782" fill="#fbb2c2" />
    <path
      d="M186.783 44.182c15.411 0 27.923 12.512 27.923 27.923a27.78 27.78 0 0 1-3.53 13.591h23.784c15.411 0 27.922 12.512 27.922 27.922 0 15.411-12.511 27.923-27.922 27.923H106.453c-15.411 0-27.923-12.512-27.923-27.923a27.78 27.78 0 0 1 3.53-13.591H58.276c-15.41 0-27.922-12.512-27.922-27.922 0-15.411 12.511-27.923 27.922-27.923h128.507z"
      fill="#ffe4ea"
    />
    <path
      d="M134.201 246.322h68.805V83.135l-34.404-17.766-34.401 17.766v64.042"
      fill="#9e97c0"
      fillRule="nonzero"
    />
    <path
      d="M139.823 54.983c5.542 0 10.042-4.499 10.042-10.041a9.979 9.979 0 0 0-1.27-4.887h8.553c5.542-.001 10.041-4.5 10.041-10.042s-4.499-10.041-10.041-10.041h-46.212c-5.542 0-10.041 4.499-10.041 10.041 0 1.774.461 3.441 1.269 4.888h-8.553c-5.542 0-10.041 4.499-10.041 10.041 0 5.542 4.499 10.041 10.041 10.041h46.212z"
      fill="#c1bcd9"
    />
    <path
      d="M44.156 239.887h179.74M244.655 239.887h25.316"
      fill="none"
      stroke="#675e97"
      strokeWidth="4"
    />
    <path
      d="M244.655 242.749h25.316"
      fill="none"
      stroke="#56dbbe"
      strokeWidth="10"
    />
    <path
      d="M160.88 102.575a2.867 2.867 0 0 0-2.865-2.865h-5.73a2.867 2.867 0 0 0-2.865 2.865v16.462a2.866 2.866 0 0 0 2.865 2.864h5.73a2.866 2.866 0 0 0 2.865-2.864v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M162.942 102.294h-15.584l1.773-5.167h12.038l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M187.592 102.575a2.867 2.867 0 0 0-2.865-2.865h-5.729a2.866 2.866 0 0 0-2.865 2.865v16.462a2.865 2.865 0 0 0 2.865 2.864h5.729a2.866 2.866 0 0 0 2.865-2.864v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M189.655 102.294H174.07l1.773-5.167h12.039l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M161.925 119.849c2.063.154 2.758 4.036-.103 4.108h-13.094c-2.05-.051-3.121-4.033 0-4.111 4.399 0 8.799-.107 13.197.003zM188.637 119.849c2.063.154 2.758 4.036-.102 4.108H175.44c-2.049-.051-3.12-4.033 0-4.111 4.399 0 8.799-.107 13.197.003z"
      fill="#7571a2"
      fillRule="nonzero"
    />
    <path
      d="M160.88 140.736a2.866 2.866 0 0 0-2.865-2.865h-5.73a2.866 2.866 0 0 0-2.865 2.865v16.461a2.867 2.867 0 0 0 2.865 2.865h5.73a2.867 2.867 0 0 0 2.865-2.865v-16.461z"
      fill="#fef9df"
    />
    <path
      d="M162.942 140.454h-15.584l1.773-5.167h12.038l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M187.592 140.736a2.866 2.866 0 0 0-2.865-2.865h-5.729a2.865 2.865 0 0 0-2.865 2.865v16.461a2.866 2.866 0 0 0 2.865 2.865h5.729a2.867 2.867 0 0 0 2.865-2.865v-16.461z"
      fill="#fef9df"
    />
    <path
      d="M189.655 140.454H174.07l1.773-5.167h12.039l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M161.925 158.009c2.063.155 2.758 4.037-.103 4.108h-13.094c-2.05-.051-3.121-4.032 0-4.11 4.399 0 8.799-.107 13.197.002zM188.637 158.009c2.063.155 2.758 4.037-.102 4.108H175.44c-2.049-.051-3.12-4.032 0-4.11 4.399 0 8.799-.107 13.197.002z"
      fill="#7571a2"
      fillRule="nonzero"
    />
    <path
      d="M160.88 178.896a2.867 2.867 0 0 0-2.865-2.865h-5.73a2.867 2.867 0 0 0-2.865 2.865v16.462a2.866 2.866 0 0 0 2.865 2.864h5.73a2.866 2.866 0 0 0 2.865-2.864v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M162.942 178.615h-15.584l1.773-5.167h12.038l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M187.592 178.896a2.867 2.867 0 0 0-2.865-2.865h-5.729a2.866 2.866 0 0 0-2.865 2.865v16.462a2.865 2.865 0 0 0 2.865 2.864h5.729a2.866 2.866 0 0 0 2.865-2.864v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M189.655 178.615H174.07l1.773-5.167h12.039l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M161.925 196.17c2.063.154 2.758 4.036-.103 4.108h-13.094c-2.05-.051-3.121-4.033 0-4.111 4.399 0 8.799-.107 13.197.003zM188.637 196.17c2.063.154 2.758 4.036-.102 4.108H175.44c-2.049-.051-3.12-4.033 0-4.111 4.399 0 8.799-.107 13.197.003z"
      fill="#7571a2"
      fillRule="nonzero"
    />
    <path
      d="M40.008 125.554c-7.136 0-12.929 5.793-12.929 12.929 0 2.283.593 4.43 1.634 6.293H17.701c-7.136 0-12.929 5.793-12.929 12.928 0 7.136 5.793 12.929 12.929 12.929h59.502c7.135 0 12.928-5.793 12.928-12.929 0-2.283-.593-4.43-1.634-6.293H99.51c7.135 0 12.928-5.793 12.928-12.928 0-7.136-5.793-12.929-12.928-12.929H40.008z"
      fill="#d8d4ec"
    />
    <circle cx="311.535" cy="240.591" r="10.057" fill="#43d0b1" />
    <circle cx="291.013" cy="237.053" r="14.177" fill="#48c5aa" />
    <circle cx="301.478" cy="232.352" r="8.239" fill="#6ee3c9" />
    <circle cx="273.911" cy="240.201" r="11.029" fill="#56dbbe" />
    <circle cx="296.983" cy="248.812" r="7.088" fill="#7ee2cc" />
    <path
      d="M116.76 135.371l-37.315 19.272v89.469h74.63v-89.469l-37.315-19.272z"
      fill="#bfbad9"
      fillRule="nonzero"
    />
    <path
      d="M109.036 168.36a2.867 2.867 0 0 0-2.865-2.865h-5.73a2.866 2.866 0 0 0-2.864 2.865v16.462a2.866 2.866 0 0 0 2.864 2.865h5.73a2.867 2.867 0 0 0 2.865-2.865V168.36z"
      fill="#fef9df"
    />
    <path
      d="M111.099 168.079H95.514l1.773-5.167h12.038l1.774 5.167z"
      fill="#756ca4"
    />
    <path
      d="M135.748 168.36a2.866 2.866 0 0 0-2.865-2.865h-5.729a2.867 2.867 0 0 0-2.865 2.865v16.462a2.867 2.867 0 0 0 2.865 2.865h5.729a2.866 2.866 0 0 0 2.865-2.865V168.36z"
      fill="#fef9df"
    />
    <path
      d="M137.811 168.079h-15.585l1.773-5.167h12.039l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M110.081 185.634c2.063.154 2.758 4.036-.102 4.108H96.884c-2.049-.051-3.12-4.033 0-4.111 4.399 0 8.799-.107 13.197.003zM136.793 185.634c2.063.154 2.758 4.036-.102 4.108h-13.095c-2.049-.051-3.12-4.033 0-4.111 4.399 0 8.8-.107 13.197.003z"
      fill="#7571a2"
      fillRule="nonzero"
    />
    <path
      d="M109.036 204.972a2.866 2.866 0 0 0-2.865-2.864h-5.73a2.865 2.865 0 0 0-2.864 2.864v16.462a2.866 2.866 0 0 0 2.864 2.865h5.73a2.867 2.867 0 0 0 2.865-2.865v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M111.099 204.691H95.514l1.773-5.167h12.038l1.774 5.167z"
      fill="#756ca4"
    />
    <path
      d="M135.748 204.972a2.865 2.865 0 0 0-2.865-2.864h-5.729a2.866 2.866 0 0 0-2.865 2.864v16.462a2.867 2.867 0 0 0 2.865 2.865h5.729a2.866 2.866 0 0 0 2.865-2.865v-16.462z"
      fill="#fef9df"
    />
    <path
      d="M137.811 204.691h-15.585l1.773-5.167h12.039l1.773 5.167z"
      fill="#756ca4"
    />
    <path
      d="M110.081 222.246c2.063.155 2.758 4.037-.102 4.108H96.884c-2.049-.051-3.12-4.033 0-4.11 4.399 0 8.799-.108 13.197.002zM136.793 222.246c2.063.155 2.758 4.037-.102 4.108h-13.095c-2.049-.051-3.12-4.033 0-4.11 4.399 0 8.8-.108 13.197.002z"
      fill="#7571a2"
      fillRule="nonzero"
    />
    <path
      d="M157.891 154.579H75.39l9.387-19.208h63.728l9.386 19.208zM209.854 84.577h-82.501l9.387-19.208h63.728l9.386 19.208z"
      fill="#8178ad"
    />
    <path
      d="M44.156 242.749h179.74"
      fill="none"
      stroke="#56dbbe"
      strokeWidth="10"
    />
    <circle cx="77.022" cy="238.862" r="14.177" fill="#43d0b1" />
    <circle cx="62.845" cy="224.685" r="14.177" fill="#56dbbe" />
    <circle cx="71.084" cy="234.26" r="8.239" fill="#48c5aa" />
    <circle cx="55.56" cy="245.289" r="11.029" fill="#56dbbe" />
    <circle cx="44.531" cy="234.26" r="14.177" fill="#7ee2cc" />
    <circle cx="66.589" cy="245.95" r="7.088" fill="#7ee2cc" />
  </svg>
);

export default Welcome;
