import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Alert, H2, Text } from '../../../layout/theme/components';
import TextButton from '../../../shared/components/buttons/TextButton';
import PreviewSection from './PreviewSection';

const StyledH2 = styled(H2)`
  margin: 0;
`;
const TextInputWrapper = styled.div`
  display: flex;
  align-items: baseline;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 1em;
  margin-top: 0.5em;

  input {
    border: none;
    outline: 0;
    font-size: 1.6em;
    flex: 1;
    margin-right: 0.5em;
    text-overflow: ellipsis;
  }
`;
const StyledTextButton = styled(TextButton)`
  color: #f53d65;
  margin: 0;
`;

const ShareSection = () => {
  const urlToShare = window.location.href;
  const [copySuccess, setCopySuccess] = useState(null);
  const inputRef = useRef(null);

  const copyToClipboard = e => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Skopiowano!');
  };

  const isCopyCommandSupported = () => {
    return document.queryCommandSupported('copy');
  };

  return (
    <PreviewSection>
      <StyledH2>Udostępnij sprawę</StyledH2>
      <Text small>
        Skopiuj link do sprawy i udostępnij w swoim ulubionym serwisie
        społecznościowym
      </Text>
      <TextInputWrapper>
        <input
          type="text"
          ref={inputRef}
          name="urlToShare"
          value={urlToShare}
          readOnly
        />
        {isCopyCommandSupported &&
          (copySuccess ? (
            <Alert>{copySuccess}</Alert>
          ) : (
            <StyledTextButton onClick={copyToClipboard}>
              Kopiuj
            </StyledTextButton>
          ))}
      </TextInputWrapper>
    </PreviewSection>
  );
};

export default ShareSection;
