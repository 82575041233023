import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import {
  H1,
  Text,
  LinkedItem,
  InvalidText
} from '../../layout/theme/components';
import TextInput from '../../shared/components/forms/TextInput';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import WelesLogo from '../../../assets/img/weles3.png';

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;
const StyledInputWrapper = styled.div`
  margin: 15% 0;
`;
const StyledLink = styled(Link)`
  margin-top: 10px;
`;
const StyledLinkedItem = styled(LinkedItem)`
  position: relative;
  margin-top: 20px;
  &::before {
    content: '?';
    position: absolute;
    left: -18px;
    top: 1px;
    font-size: 10px;
    color: ${({ theme }) => theme.backgroundColors.quaternary};
    background-color: ${({ theme }) => theme.backgroundColors.quaternary};
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;
const StyledInvalidText = styled(InvalidText)`
  text-align: center;
  margin-top: 20px;
`;

const FinancesLoginComponent = ({
  handleSubmit,
  handleInputChange,
  error,
  isLoading
}) => {
  return (
    <StyledForm onSubmit={e => handleSubmit(e)}>
      <img src={WelesLogo} alt="Weles3 Logo" />
      <H1>Połącz się z Weles3</H1>
      <Text>
        żeby pobrać dane o Twoich finansach i uzyskać szybki dostęp do płatności
        bezpośrednio z aplikacji wymagane jest jednorazowe uwierzytelnienie
        aplikacji w Weles3
      </Text>
      <StyledLink to="/jak-znalezc-dane-logowania-weles3">
        <StyledLinkedItem secondary>
          Nie pamiętasz danych logowania?
        </StyledLinkedItem>
      </StyledLink>
      <StyledInputWrapper>
        <TextInput
          name="user"
          onChange={e => handleInputChange(e.target)}
          type="text"
          placeholder="Nazwa użytkownika"
          isRequired
          autoFocus
        />
        <TextInput
          name="code"
          onChange={e => handleInputChange(e.target)}
          type="text"
          placeholder="Kod PIN"
          isRequired
        />
        <TextInput
          name="pin"
          onChange={e => handleInputChange(e.target)}
          type="text"
          placeholder="PIN"
          isRequired
        />
      </StyledInputWrapper>
      <PrimaryButton type="submit" disabled={isLoading}>
        POŁĄCZ Z WELES3
      </PrimaryButton>
      {error && <StyledInvalidText>{error}</StyledInvalidText>}
    </StyledForm>
  );
};

FinancesLoginComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FinancesLoginComponent;
