import { ellipsis, lighten } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import Cancel from '../../../assets/cancel';
import Thumbnail from './Thumbnail';

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5%;
  margin: 0.5em 0;
  background: ${({ highlighted, theme }) =>
    highlighted
      ? lighten(0.3, theme.labels.negative)
      : 'rgba(240,240,240,0.82)'};

  img {
    width: 30px;
    height: 30px;
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 0.5em;
  }
`;
const Text = styled.p`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  color: ${({ highlighted, theme }) =>
    highlighted ? theme.textColors.highlighted : theme.textColors.tertiary};
  ${({ highlighted }) => (highlighted ? ellipsis('100%') : ellipsis('84%'))};
  width: 75%;
  margin-left: 1%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 85%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-self: flex-end;
  right: 0;
  width: 10px;
  height: 10px;
  margin: 0 2%;
  cursor: pointer;
  svg {
    fill: ${({ highlighted, theme }) =>
      highlighted ? theme.textColors.highlighted : theme.textColors.secondary};
    width: 100%;
    height: 100%;
  }
`;

class ThumbnailLabel extends Component {
  shouldComponentUpdate(nextProps) {
    return !(this.props.attachment === nextProps.attachment);
  }

  render() {
    const {
      attachment,
      handleRemoveFile,
      path,
      id,
      oversized,
      type,
      noCancelButton
    } = this.props;
    return (
      <LabelWrapper>
        {!oversized && <Thumbnail path={path} type={type} small />}
        <Text highlighted={oversized}>{attachment.name}</Text>
        {!noCancelButton ? (
          <ImageWrapper
            onClick={() => handleRemoveFile(id)}
            highlighted={oversized}
          >
            <Cancel />
          </ImageWrapper>
        ) : (
          <ImageWrapper />
        )}
      </LabelWrapper>
    );
  }
}

ThumbnailLabel.propTypes = {
  attachment: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  handleRemoveFile: PropTypes.func,
  id: PropTypes.number,
  oversized: PropTypes.bool,
  type: PropTypes.string,
  noCancelButton: PropTypes.bool
};

ThumbnailLabel.defaultProps = {
  oversized: false,
  handleRemoveFile: null,
  id: 0,
  type: '',
  noCancelButton: false
};

export default ThumbnailLabel;
