import React from 'react';

const megaphone = () => {
  return (
    <svg
      viewBox="0 0 79 54"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h78.25v53.119H0z" />
      <path
        d="M28.614 29.821c1.979.037 3.733 1.717 3.782 3.774.036 3.109.551 6.541-1.5 8.116a3.894 3.894 0 0 1-2.281.79c-3.42.02-6.841.02-10.262 0-1.977-.04-3.732-1.742-3.78-3.782v-5.116c.046-1.986 1.725-3.743 3.781-3.782 3.42-.02 6.84-.02 10.26 0zm-10.207 2.64c-.626.004-1.179.549-1.193 1.156-.04 1.704-.079 3.411.001 5.113a1.23 1.23 0 0 0 1.173 1.131c3.408.019 6.818.109 10.224-.001.605-.027 1.129-.572 1.143-1.18.02-1.693.039-3.387-.001-5.079-.028-.585-.559-1.128-1.172-1.14-3.392-.019-6.783 0-10.175 0z"
        fill="#948db6"
        fillRule="nonzero"
      />
      <circle cx="7.233" cy="26.569" r="4.867" fill="#454545" />
      <path
        d="M7.31 20.603c3.59.066 6.696 3.759 5.684 7.525-.983 3.661-5.981 5.67-9.274 3.264-2.697-1.971-3.205-6.367-.853-8.888a6.079 6.079 0 0 1 4.443-1.901zm-.126 2.201c-2.9.053-4.954 4.201-2.549 6.491 1.312 1.248 3.567 1.353 4.994.179 2.246-1.846 1.282-6.554-2.298-6.67h-.147z"
        fill="none"
      />
      <path
        d="M31.076 22.705a3.979 3.979 0 0 0-3.977-3.977H11.21a3.979 3.979 0 0 0-3.977 3.977v7.954a3.979 3.979 0 0 0 3.977 3.977h15.889a3.979 3.979 0 0 0 3.977-3.977v-7.954z"
        fill="#948db6"
      />
      <path
        d="M27.161 17.848c2.512.051 4.749 2.208 4.795 4.795.015 2.693.015 5.385 0 8.077-.044 2.498-2.181 4.746-4.795 4.796-5.337.038-10.676.038-16.014 0-2.494-.047-4.748-2.172-4.794-4.796a724.871 724.871 0 0 1 0-8.077c.044-2.495 2.181-4.742 4.794-4.795 5.338-.031 10.677-.031 16.014 0zm-15.948 1.76c-1.622.01-3.071 1.435-3.099 3.056a672.686 672.686 0 0 0 0 8.036c.028 1.599 1.438 3.025 3.056 3.055 5.323.039 10.646.039 15.968 0 1.6-.03 3.029-1.438 3.057-3.055.016-2.679.016-5.357 0-8.036-.028-1.594-1.405-3.022-3.057-3.055-5.308-.031-10.617-.001-15.925-.001z"
        fill="none"
      />
      <path
        d="M23.934 30.384c0-.666-.541-1.207-1.207-1.207H12.376a1.207 1.207 0 0 0 0 2.414h10.351c.666 0 1.207-.541 1.207-1.207z"
        fill="#fff"
      />
      <path
        d="M22.787 28.078c1.959.075 3.161 3.313.942 4.384a2.356 2.356 0 0 1-.942.228c-3.472.045-6.945.001-10.418.001-1.955-.025-3.272-3.288-.993-4.386.292-.141.614-.215.941-.227 3.49-.045 6.98-.045 10.47 0zm-10.404 2.2c-.119.001-.129.212 0 .212H22.72c.123 0 .126-.208.01-.212-3.448-.044-6.898 0-10.347 0z"
        fill="none"
      />
      <circle cx="56.436" cy="26.561" r="5.03" fill="#e24f70" />
      <path
        d="M56.515 20.431c4.06.079 7.342 4.873 5.445 8.79-1.665 3.437-6.885 4.508-9.752 1.777-3.361-3.2-1.562-10.456 4.149-10.567h.158zm-.13 2.201c-3.005.058-5.188 4.367-2.66 6.772 2.155 2.05 6.682.705 6.64-2.895-.023-1.983-1.723-3.809-3.828-3.876l-.152-.001z"
        fill="none"
      />
      <path
        d="M56.211 10.637v31.94c-9.75-4.344-19.532-7.125-29.353-7.985v-15.97c9.776-.923 19.562-3.848 29.353-7.985z"
        fill="#f8cfd8"
      />
      <path
        d="M56.267 9.759a.928.928 0 0 1 .824.878v31.94a.915.915 0 0 1-1.238.804c-9.309-4.138-19.051-7.02-29.073-7.912 0 0-.765-.223-.802-.877v-15.97c.065-1.147 1.941-.977 3.515-1.185 9.059-1.196 17.893-4.031 26.376-7.611 0 0 .12-.069.398-.067zm-1.098 2.266c-8.754 3.585-17.92 6.361-27.3 7.38l-.131.014V33.79c9.402.926 18.672 3.615 27.45 7.378l.143.061V11.958l-.162.067z"
        fill="none"
      />
      <path
        d="M52.997 30.075a.898.898 0 0 0-1.795 0v7.357a.899.899 0 0 0 1.795 0v-7.357z"
        fill="#fff"
      />
      <path
        d="M52.15 28.077c1.023.038 1.906.909 1.948 1.953v7.447c-.069 1.733-2.964 2.813-3.84.731a1.996 1.996 0 0 1-.156-.731 188.65 188.65 0 0 1 0-7.447c.021-1.044.95-1.966 2.048-1.953z"
        fill="none"
      />
      <path
        d="M76.272 25.908c.489.048.481.2.567.406.164.397-.137.898-.61.913h-7.143c-.687-.022-.936-1.29 0-1.32 2.395 0 4.792-.077 7.186.001zM66.829 35.087c.059.005.082.001.205.047l6.9 2.796c.466.206.404.354.412.591.014.436-.463.797-.908.633l-6.9-2.797c-.588-.26-.612-1.3.291-1.27zM61.125 44.36c.147.012.239.03.399.162l6.186 5.402c.362.336.263.475.198.69-.127.417-.697.608-1.066.305l-6.186-5.402c-.87-.807-.013-1.168.469-1.157zM73.728 13.933c.327.032.356.116.446.215.285.313.175.872-.24 1.056L67.034 18c-.335.124-.399.048-.534-.017-.448-.215-.489-.973.038-1.207l6.9-2.796c.207-.076.185-.05.29-.047zM67.32 2.054c.317.031.363.121.453.224.233.266.236.655-.063.932l-6.186 5.402c-.475.39-.624.219-.875-.006-.28-.251-.282-.721.007-.989l6.186-5.402c.128-.106.134-.174.478-.161z"
        fill="#fae5e9"
        fillRule="nonzero"
      />
      <path
        d="M56.326 10.196c.547.086.956.53.985 1.094v30.559c-.06 1.149-2.117 1.595-2.2 0V11.29a1.136 1.136 0 0 1 1.215-1.094z"
        fill="#948db6"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default megaphone;
