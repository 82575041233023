import React from 'react';

const addingCasesIcon = () => {
  return (
    <svg
      viewBox="0 0 81 73"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path fill="none" d="M0 0h80.13v72.989H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h80.13v72.989H0z" />
      </clipPath>
      <g clipPath="url(#a)">
        <g fill="#756ba4" stroke="#f1deba">
          <g fillRule="nonzero">
            <path d="M48.826 54.773H27.291a6.226 6.226 0 0 1 1.205-3.788l6.652-8.659a3.682 3.682 0 0 1 2.91-1.569 3.685 3.685 0 0 1 2.91 1.569l6.652 8.659a6.226 6.226 0 0 1 1.206 3.788z" />
            <path d="M69.986 54.773H37.955a9.262 9.262 0 0 1 1.793-5.634l9.894-12.879a5.48 5.48 0 0 1 4.328-2.334 5.483 5.483 0 0 1 4.329 2.334l9.894 12.879a9.262 9.262 0 0 1 1.793 5.634z" />
          </g>
          <circle cx="32.209" cy="29.9" r="4.371" />
        </g>
        <circle
          cx="36.209"
          cy="25.9"
          r="4.371"
          fill="#e8e8e8"
          stroke="#f1deba"
        />
        <path
          d="M29.094 40.844H45.9M37.5 32.439v16.809"
          fill="#f53d65"
          fillRule="nonzero"
          stroke="#fff"
          strokeWidth="3"
        />
        <path
          d="M54 23c0-4.415-3.585-8-8-8H16c-4.415 0-8 3.585-8 8v34c0 4.415 3.585 8 8 8h30c4.415 0 8-3.585 8-8V23z"
          fill="#d1cde4"
        />
        <path
          d="M22.797 0l32.439 11.295c-3.447 24.571-9.649 40.324-25.057 47.725L0 46.085C19.509 30.137 21.376 19.825 22.797 0z"
          fill="#faf6ef"
          fillRule="nonzero"
        />
        <path
          d="M66.937 22.081l-30.318 5.091c-.646 22.172 2.454 36.985 14.94 45.817l28.571-6.875c-14.818-16.98-14.917-26.352-13.193-44.033z"
          fill="#faefd9"
          fillRule="nonzero"
        />
        <path
          d="M43.728 35.554a77.624 77.624 0 0 0 16.734-3.332M43.931 42.647a63.196 63.196 0 0 0 16.734-3.332M46.764 48.673c6.5-.5 10.559-.788 15.189-2.531M48.356 56.35a38.945 38.945 0 0 0 16.734-3.332"
          fill="none"
          stroke="#fff"
        />
        <path
          d="M54.606 24.942l-3.412 2.051"
          fillRule="nonzero"
          stroke="#b5b5b5"
        />
        <path
          d="M56.171 29.353l-4.722-7.858 7.647-1.921 2.361 3.929-5.286 5.85z"
          fill="#56dabd"
          fillRule="nonzero"
        />
        <path
          d="M62.787 25.377a.637.637 0 0 0 .218-.876l-4.065-6.764a.637.637 0 1 0-1.094.658l4.065 6.764a.637.637 0 0 0 .876.218z"
          fill="#56dabd"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default addingCasesIcon;
