import React from 'react';

const Comment = () => (
  <svg
    viewBox="0 0 10 8"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h9.792v7.449H0z" />
    <path
      d="M6.626.129A3.076 3.076 0 0 1 9.7 3.204a3.076 3.076 0 0 1-3.074 3.074h-2.9A19.03 19.03 0 0 1 .413 7.449c.625-.49 1.106-.965 1.437-1.425A3.077 3.077 0 0 1 0 3.204 3.076 3.076 0 0 1 3.074.129h3.552z"
      fill="#d1cde5"
    />
  </svg>
);

export default Comment;
