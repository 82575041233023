import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import ArrowIcon from '../../shared/components/ArrowIcon';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 5em;
  width: 100%;
  padding: 0 0 0 1.5em;
  background: ${({ background }) => background};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 2em 0 2em 1.5em;
    width: 30%;
    justify-content: start;
  }
`;
const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';
  min-height: 4em;
  color: ${({ theme }) => theme.backgroundColors.primary};
  font-size: 1.3em;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 3em;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ isRangeIcon }) => (isRangeIcon ? '0 1.5em 0 1.4em' : '0 1em')};

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: ${({ isRangeIcon }) => (isRangeIcon ? '0 0.5em 0 .7em' : '0 1em')};
  }
`;
const CategoryIcon = styled.img`
  display: flex;
  align-self: flex-start;
  height: 29px;
  margin-right: 0.5em;
`;
const RangeIcon = styled.img`
  height: 29px;
`;
const CategoryPicture = styled.img`
  display: ${({ showCategoryPic }) => (showCategoryPic ? 'flex' : 'none')};
  margin-top: 1em;
  height: 100px;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    display: flex;
    flex: 1;
    width: 50%;
  }
`;

const CaseHeader = ({
  categoryColor,
  goBackHandler,
  rangeIcon,
  categoryIcon,
  children,
  picturePath,
  showCategoryPic
}) => {
  return (
    <Header background={categoryColor}>
      <Title>
        <ArrowIcon color="#fff" onClick={goBackHandler} flex />
        <IconWrapper isRangeIcon={rangeIcon}>
          <CategoryIcon src={categoryIcon} />
          {rangeIcon && <RangeIcon src={rangeIcon} />}
        </IconWrapper>
        {children}
      </Title>
      {picturePath && (
        <CategoryPicture showCategoryPic={showCategoryPic} src={picturePath} />
      )}
    </Header>
  );
};

CaseHeader.propTypes = {
  children: PropTypes.string.isRequired,
  goBackHandler: PropTypes.func.isRequired,
  categoryIcon: PropTypes.string,
  rangeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  picturePath: PropTypes.string,
  categoryColor: PropTypes.string,
  showCategoryPic: PropTypes.bool
};

CaseHeader.defaultProps = {
  categoryIcon: null,
  rangeIcon: null,
  picturePath: null,
  categoryColor: '#8e8e8e',
  showCategoryPic: false
};

export default CaseHeader;
