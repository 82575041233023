import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { hideModal, showModal } from '../../../data/modal/actions';
import { Text } from '../../../layout/theme/components';
import CommentForm from '../CommentForm';
import ActivityWrapper from './ActivityWrapper';

const AddCommentContainer = styled.div`
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 6px;
  background: ${({ theme }) => theme.backgroundColors.secondary};
  color: ${({ theme }) => theme.textColors.tertiary};
  text-align: left;
  cursor: pointer;
  margin-left: 3em;
`;

class AddComment extends Component {
  constructor() {
    super();

    this.renderModal = this.renderModal.bind(this);
    this.addResponse = this.addResponse.bind(this);
  }

  addResponse() {
    const { handleResponse, closeModal } = this.props;
    handleResponse();
    closeModal();
  }

  renderModal(event) {
    if (event) {
      event.stopPropagation();
    }
    const { communityCase, addAsAnonymous, openModal } = this.props;

    openModal(
      <CommentForm communityCase={communityCase} addAsAnonymous={addAsAnonymous} handleResponse={this.addResponse} />,
      true
    );
  }

  render() {
    return (
      <ActivityWrapper>
        <AddCommentContainer onClick={this.renderModal}>
          <Text small>Dodaj komentarz</Text>
        </AddCommentContainer>
      </ActivityWrapper>
    );
  }
}

AddComment.propTypes = {
  communityCase: PropTypes.number.isRequired,
  handleResponse: PropTypes.func.isRequired,
  addAsAnonymous: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openModal: (content, isWide) => dispatch(showModal(content, isWide)),
  closeModal: () => dispatch(hideModal()),
});

export default connect(
  null,
  mapDispatchToProps
)(AddComment);
