import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { LabelColored, Text } from '../../../layout/theme/components';

import CheckMarkIcon from '../../../../assets/features/checkMark';

const PanelBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.textColors.primary};
  background: ${({ theme }) => theme.backgroundColors.primary};
  border-radius: 10px;
  padding: 1.5em;
  margin: 0.5em 0;
  box-shadow: ${({ theme }) => theme.boxShadow.element};
  opacity: ${({ active }) => (active ? '1' : '.4')};
`;
const PictureBox = styled.div`
  width: 17%;
  min-width: 6em;
  margin-right: 2em;
  img {
    width: 100%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 75%;
  word-break: break-word;
  text-align: left;
`;
const MarkBox = styled.div`
  position: relative;
  height: 100%;
  width: 2em;
  svg {
    position: absolute;
    top: -3em;
    right: 0em;
  }
`;

const LevelPanel = ({ children, topic, text, active }) => {
  return (
    <PanelBox active={active}>
      <PictureBox>{children}</PictureBox>
      <TextBox>
        <LabelColored big bold>
          {topic}
        </LabelColored>
        <Text>{text}</Text>
      </TextBox>
      <MarkBox>{active && <CheckMarkIcon />}</MarkBox>
    </PanelBox>
  );
};

LevelPanel.propTypes = {
  children: PropTypes.node.isRequired,
  topic: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool
};

LevelPanel.defaultProps = {
  active: false
};

export default LevelPanel;
