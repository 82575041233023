import React from 'react';

const ErrorIcon = () => (
  <svg
    viewBox="0 0 271 264"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="1.5"
  >
    <path fill="none" d="M0 0h270v263.333H0z" />
    <path
      d="M268.784 255.622a7.715 7.715 0 0 0-7.712-7.712H8.833a7.715 7.715 0 0 0-7.712 7.712 7.715 7.715 0 0 0 7.712 7.711h252.239a7.715 7.715 0 0 0 7.712-7.711z"
      fill="#a3a6d0"
    />
    <path
      d="M49.654 2.077a7.93 7.93 0 0 0-7.927 7.927c0 1.62.487 3.127 1.323 4.383H19.589c-8.92 0-16.161 7.242-16.161 16.162 0 8.919 7.241 16.16 16.161 16.161h10.327c.37-.028.744-.043 1.122-.043h42.569c.377 0 .751.015 1.122.043h.621l-.101.049c7.237.817 12.87 6.966 12.87 14.42 0 7.576-5.817 13.803-13.224 14.456.15.078-41.545.228-41.545.228-8.92 0-16.162 7.242-16.162 16.161 0 7.654 5.333 14.073 12.483 15.74h30.078a7.93 7.93 0 0 1 7.926 7.927 7.93 7.93 0 0 1-7.926 7.926H44.644c.445 8.523 7.506 15.306 16.139 15.307h74.313c8.92 0 16.162-7.242 16.162-16.162 0-7.393-4.975-13.633-11.757-15.553h-22.719c-8.432 0-15.277-6.845-15.277-15.277 0-8.431 6.845-15.277 15.277-15.277h44.875c6.471-2.11 11.152-8.197 11.152-15.369 0-8.919-7.241-16.161-16.161-16.161h-55.761l.013-.006h-.729a8.013 8.013 0 0 1-8.009-8.009 8.012 8.012 0 0 1 8.004-8.009h36.49a7.93 7.93 0 0 0 7.927-7.927 7.93 7.93 0 0 0-7.927-7.926h-31.375a7.93 7.93 0 0 0-7.233-11.171H49.654z"
      fill="#56dbbe"
      fillOpacity="0.216"
    />
    <path
      d="M131.064 137.318c25.087 17.671 46.716 33.311 72.446 6.805-10.551 18.578-4.563 32.206-50.477 24.164l-21.969-30.969z"
      fill="url(#_Linear1)"
    />
    <path
      d="M130.002 117.012c30.562 2.761 57.112 5.491 66.143-30.329.151 21.365 12.151 30.173-31.633 46.166l-34.51-15.837z"
      fill="url(#_Linear2)"
    />
    <path
      d="M146.549 117.012c-30.562 2.761-57.112 5.491-66.143-30.329-.151 21.365-12.15 30.173 31.633 46.166l34.51-15.837z"
      fill="url(#_Linear3)"
    />
    <path
      d="M141.981 137.318c-25.087 17.671-46.715 33.311-72.446 6.805 10.551 18.578 4.564 32.206 50.477 24.164l21.969-30.969z"
      fill="url(#_Linear4)"
    />
    <path
      d="M138.09 140.496c-30.241 5.208-56.486 10.063-68.366-24.915 1.566 21.307-9.687 31.052 35.24 43.474l33.126-18.559z"
      fill="url(#_Linear5)"
    />
    <path
      d="M134.955 140.496c30.241 5.208 56.486 10.063 68.366-24.915-1.566 21.307 9.687 31.052-35.24 43.474l-33.126-18.559z"
      fill="url(#_Linear6)"
    />
    <path
      d="M134.961 117.82c28.794-10.611 53.95-19.529 46.749-55.761 9.297 19.236 23.915 22.049-8.782 55.27l-37.967.491z"
      fill="url(#_Linear7)"
    />
    <path
      d="M141.59 117.82c-28.793-10.611-53.949-19.529-46.749-55.761-9.297 19.236-23.915 22.049 8.782 55.27l37.967.491z"
      fill="url(#_Linear8)"
    />
    <path
      d="M138.09 145.732c-29.232-9.334-54.756-17.137-49.156-53.651-8.442 19.626-22.922 23.079 11.204 54.83l37.952-1.179z"
      fill="url(#_Linear9)"
    />
    <path
      d="M134.955 145.732c29.232-9.334 54.756-17.137 49.156-53.651 8.442 19.626 22.922 23.079-11.204 54.83l-37.952-1.179z"
      fill="url(#_Linear10)"
    />
    <path
      d="M137.579 122.354c20.649-22.699 38.851-42.219 15.744-71.041 17.125 12.776 31.391 8.526 17.713 53.086l-33.457 17.955z"
      fill="url(#_Linear11)"
    />
    <path
      d="M138.972 122.354c-20.649-22.699-38.851-42.219-15.744-71.041-17.125 12.776-31.391 8.526-17.713 53.086l33.457 17.955z"
      fill="url(#_Linear12)"
    />
    <path
      d="M102.63 197.757c-16.137-10.789 9.691-18.073 39.715-17.545 21.464.377 38.979 7.798 39.716 17.545h.024v50.153h-79.48v-50.153h.025z"
      fill="#d5d5d5"
    />
    <g fill="#d5d5d5">
      <path d="M170.646 197.757c16.063-10.789-9.647-18.073-39.536-17.545-21.366.377-38.802 7.798-39.535 17.545h-.025v50.153h79.121v-50.153h-.025z" />
      <path d="M60.638 240.477c-3.824 5.09-2.796 12.327 2.294 16.151s12.327 2.797 16.151-2.293l35.199-46.853c3.824-5.09 2.796-12.327-2.294-16.151s-14.077-3.046-17.901 2.044l-33.449 47.102z" />
    </g>
    <path
      d="M128.005 179.722l-16.507.463-6.796 9.478h12.3l-10.619 7.444c3.471 14.242 6.681 25.888 16.312 50.803h5.31v-68.188z"
      fill="url(#_Linear13)"
    />
    <path
      d="M144.83 179.722l16.506.463 6.797 9.478h-12.301l10.619 7.444c-3.47 14.242-6.68 25.888-16.312 50.803h-5.309v-68.188z"
      fill="url(#_Linear14)"
    />
    <path
      d="M145.722 179.722c-7.393-7.715-13.956-6.256-20.123-.425v68.613h21.238l-1.115-68.188z"
      fill="#333"
    />
    <path
      d="M128.005 173.179a2.8 2.8 0 0 1 2.798-2.799h9.988a2.8 2.8 0 0 1 2.799 2.799v12.363c-.022 4.909-15.604 4.479-15.585 0v-12.363z"
      fill="#fbe3e9"
    />
    <path
      d="M98.7 115.851c0-20.474 16.623-37.097 37.097-37.097 20.475 0 37.097 16.623 37.097 37.097v24.24c0 20.474-16.622 37.097-37.097 37.097-20.474 0-37.097-16.623-37.097-37.097v-24.24z"
      fill="#fcecf0"
    />
    <path
      d="M98.68 119.11a7.824 7.824 0 0 0-7.82 7.82 7.824 7.824 0 0 0 7.82 7.82c4.316 0 4.316-15.64 0-15.64zm75.568 15.255a7.825 7.825 0 0 0 7.82-7.821 7.824 7.824 0 0 0-7.82-7.82c-4.316 0-4.316 15.641 0 15.641z"
      fill="#fcecf0"
    />
    <path
      d="M139.379 144.604a3.666 3.666 0 0 1-3.582 4.435 3.666 3.666 0 0 1-3.582-4.435h7.164z"
      fill="#fedee5"
    />
    <circle cx="155.971" cy="143.452" r="4.901" fill="#f9a8ba" />
    <circle cx="115.496" cy="143.452" r="4.901" fill="#f9a8ba" />
    <path
      d="M125.872 130.308h-5.616"
      fill="none"
      stroke="#fff"
      strokeWidth="4"
    />
    <circle cx="123.064" cy="131.695" r="2.808" fill="#6a6488" />
    <path
      d="M151.338 130.308h-5.616"
      fill="none"
      stroke="#fff"
      strokeWidth="4"
    />
    <circle cx="148.53" cy="131.695" r="2.808" fill="#6a6488" />
    <path
      d="M152.593 113.661H95.754c0-23.837 18.668-43.161 41.696-43.161 23.029 0 41.696 19.324 41.696 43.161H158.46l-3.508-18.319"
      fill="url(#_Linear15)"
    />
    <path
      d="M122.936 113.305c1.985.032 3.938.587 5.685 1.548.273.15 1.019.736.798 1.452-.393 1.277-2.565-.064-4.41-.517-2.557-.627-5.299-.011-7.698 1.184 0 0-.546.206-.962.012-.828-.386-.826-1.619.35-2.181 1.953-.921 4.069-1.513 6.237-1.498zm26.484.051c2.152.034 4.379.487 6.094 1.792.057.043.109.091.157.144.706.792-.138 2.371-1.719 1.509-3.107-1.664-6.949-1.375-10.158.221 0 0-1.08.258-1.47-.467-1.02-1.895 4.166-3.22 7.096-3.199z"
      fill="#595959"
      fillRule="nonzero"
    />
    <path
      d="M175.991 129.636c.702.04 1.297.638 1.327 1.345.028 1.983.366 4.704-1.715 4.193-1.3-.318-1.08-2.44-1.055-4.193a1.385 1.385 0 0 1 1.443-1.345z"
      fill="#afaacb"
      fillRule="nonzero"
    />
    <path
      d="M171.673 116.686a4.966 4.966 0 0 1 3.509 6.078l-2.569 9.587a5.236 5.236 0 0 1-.096.315 18.05 18.05 0 0 1-.152.609c-2.61 9.741-12.637 15.53-22.378 12.92-8.412-2.254-13.877-10.039-13.531-18.395l-.852-.228c-3.878 7.409-12.504 11.419-20.916 9.165-9.741-2.61-15.53-12.637-12.92-22.378.054-.202.112-.404.173-.604.021-.107.046-.214.075-.32l2.569-9.587a4.964 4.964 0 0 1 6.078-3.509l61.01 16.347z"
      fill="#a3a6d0"
      fillOpacity="0.384"
    />
    <path
      d="M109.684 96.352a10.01 10.01 0 0 1 1.751.24c20.487 5.198 40.833 10.938 61.248 16.409 4.393 1.231 7.308 5.959 6.244 10.541a438.567 438.567 0 0 1-2.663 9.942c-.218.744.135-.524-.207.78-2.696 9.859-12.701 17.063-22.922 16.322-8.502-.616-16.327-6.506-19.261-14.713 0 0-7.212 5.32-14.469 5.312-13.518-.015-25.266-14.217-21.174-28.364l.06-.248c.863-3.246 1.738-6.488 2.607-9.732 0 0 .467-1.529 1.173-2.586 1.681-2.517 4.164-3.982 7.613-3.903zm-.324 7.639a1.18 1.18 0 0 0-1.075.816c-.893 3.208-1.73 6.431-2.586 9.649 0 0-1.664 7.429 1.567 12.446 5.355 8.311 19.703 8.785 24.962-1.122 0 0 1.655-3.363 5.82-1.454 2.779 1.274 1.829 5.698 3.086 9.15 4.026 11.055 24.172 13.037 27.664-1.692.872-3.338 1.793-6.655 2.685-9.984.157-.588-.189-1.236-.764-1.412-20.276-5.684-40.681-10.901-61.021-16.351-.111-.03-.158-.047-.338-.046z"
      fill="url(#_Linear16)"
      fillRule="nonzero"
    />
    <path
      d="M117.586 130.915c-7.431-2.069-10.45-6.973-9.259-14.581m44.427 24.004c-7.431-2.069-10.45-6.973-9.26-14.581"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
    <path
      d="M141.406 164.444a5.573 5.573 0 0 0 .725-2.97 5.893 5.893 0 0 0-6.072-5.704 5.894 5.894 0 0 0-5.704 6.073 5.576 5.576 0 0 0 .909 2.918 5.827 5.827 0 0 1 4.979-3.103 5.826 5.826 0 0 1 5.163 2.786z"
      fill="#3f425d"
    />
    <path
      d="M141.406 164.444a5.826 5.826 0 0 0-5.163-2.786 5.827 5.827 0 0 0-4.979 3.103 5.827 5.827 0 0 0 5.163 2.786 5.826 5.826 0 0 0 4.979-3.103z"
      fill="#f96678"
    />
    <path
      d="M154.531 225.409l-5.768 5.009-5.976 1.225.025 1.034s-9.055 1.819-11.32 1.241c-.932-.238-2.203-1.866-2.203-1.866l-3.457 1.236-3.694-9.478 4.727-1.884 13.113-6.371 6.546-2.891c.719-.194 1.657.044 2.387.377l12.473 5.695a3.316 3.316 0 0 1 1.639 4.391 3.316 3.316 0 0 1-4.392 1.638l-4.1.644z"
      fill="#fcecf0"
    />
    <path
      d="M138.116 221.767a5.971 5.971 0 0 0-3.518 7.672 5.97 5.97 0 0 0 7.672 3.519 5.973 5.973 0 0 0 3.519-7.673 5.972 5.972 0 0 0-7.673-3.518z"
      fill="#fcecf0"
    />
    <path
      d="M151.331 219.794c-.164-.341 1.452-9.696 1.452-9.696l-1.724 5.689-1.856-7.979-2.784 11.926.242-10.793-3.243 3.406-2.336-3.923-2.078 7.86-1.973-19.693-.155 59.515a7.23 7.23 0 0 0 7.227 7.227 7.23 7.23 0 0 0 7.228-7.227v-36.312z"
      fill="#f2f2f2"
    />
    <path
      d="M149.203 221.203c-.155.345 1.029-3.604 1.029-3.604l-1.029-5.304-3.739 14.885c.282-.001.022-10.925.166-15.021l-2.383 2.315-1.75-2.401s-1.774 3.836-2.493 6.028v37.783a5.102 5.102 0 0 0 5.099 5.099c2.815 0 5.1-2.285 5.1-5.099v-34.681z"
      fill="#fff"
    />
    <path
      d="M149.203 230.977h-10.199v24.907a5.102 5.102 0 0 0 5.099 5.099c2.815 0 5.1-2.285 5.1-5.099v-24.907z"
      fill="#56dbbe"
    />
    <path
      d="M116.149 247.004c6.678-1.79 10.647-8.664 8.858-15.342-1.79-6.678-8.664-10.647-15.342-8.858l-46.861 12.557c-6.678 1.789-10.646 8.663-8.857 15.341 1.789 6.678 8.664 10.647 15.342 8.858l46.86-12.556z"
      fill="url(#_Linear17)"
    />
    <path
      d="M128.249 243.762l-6.484-24.2-23.639 6.334 6.484 24.2 23.639-6.334z"
      fill="url(#_Linear18)"
    />
    <path
      d="M120.17 250.096l-7.93-29.593-14.114 3.782 7.929 29.593 14.115-3.782z"
      fill="url(#_Linear19)"
    />
    <path
      d="M106.891 227.775a1.594 1.594 0 1 1-3.08.825 1.594 1.594 0 0 1 3.08-.825z"
      fill="#fff"
    />
    <path
      d="M128.832 227.362a3.315 3.315 0 0 0-.647 6.597l18.79 1.843a3.317 3.317 0 0 0 3.622-2.975 3.317 3.317 0 0 0-2.975-3.622l-18.79-1.843z"
      fill="#fcecf0"
    />
    <path
      d="M211.958 116.798a5.002 5.002 0 0 1 4.166 7.765h14.799c5.626 0 10.194 4.568 10.194 10.195 0 5.626-4.567 10.194-10.194 10.194h-6.514a9.209 9.209 0 0 0-.708-.027h-26.852c-.238 0-.474.009-.708.027h-.392l.064.031c-4.565.516-8.118 4.394-8.118 9.096 0 4.779 3.669 8.707 8.341 9.119-.094.049 26.207.144 26.207.144 5.627 0 10.194 4.568 10.194 10.194 0 4.828-3.363 8.877-7.874 9.929h-18.972c-2.76 0-5 2.24-5 5 0 2.759 2.24 5 5 5h9.528c-.281 5.376-4.735 9.655-10.181 9.655h-46.876c-5.627 0-10.195-4.568-10.195-10.195 0-4.663 3.138-8.599 7.416-9.81h14.331c5.319 0 9.637-4.318 9.637-9.637 0-5.318-4.318-9.636-9.637-9.636h-28.307c-4.081-1.332-7.034-5.171-7.034-9.695 0-5.627 4.568-10.195 10.194-10.195h35.174l-.008-.004h.46a5.054 5.054 0 0 0 5.052-5.052 5.056 5.056 0 0 0-5.05-5.052h-23.017c-2.76 0-5-2.24-5-5 0-2.759 2.24-5 5-5h19.791a5.002 5.002 0 0 1 4.563-7.046h30.526z"
      fill="#56dbbe"
      fillOpacity="0.239"
    />
    <path
      d="M171.779 170.844a2.11 2.11 0 0 1 1.757 3.276h6.244a4.303 4.303 0 0 1 4.301 4.301 4.303 4.303 0 0 1-4.301 4.301h-2.749a3.675 3.675 0 0 0-.298-.011h-11.329c-.1 0-.2.003-.298.011h-.166l.027.013a3.863 3.863 0 0 0 .094 7.684c-.04.021 11.057.061 11.057.061a4.303 4.303 0 0 1 4.301 4.301 4.306 4.306 0 0 1-3.322 4.189h-8.005a2.11 2.11 0 0 0 0 4.219h4.02a4.304 4.304 0 0 1-4.295 4.073H149.04a4.303 4.303 0 0 1-1.172-8.44h6.046a4.067 4.067 0 0 0 4.066-4.065 4.068 4.068 0 0 0-4.066-4.066h-11.942a4.305 4.305 0 0 1-2.968-4.09 4.303 4.303 0 0 1 4.301-4.301h14.839l-.003-.002h.194a2.132 2.132 0 0 0 .001-4.262h-9.711a2.11 2.11 0 0 1 0-4.219h8.35a2.111 2.111 0 0 1 1.925-2.973h12.879z"
      fill="#56dbbe"
      fillOpacity="0.855"
    />
    <path
      d="M240.711 207.569a2.25 2.25 0 0 1 2.25 2.25v22.738l11.391 20.982h-31.398l11.007-20.274v-23.446a2.25 2.25 0 0 1 2.25-2.25h4.5z"
      fill="none"
      stroke="#f2f2f2"
      strokeWidth="2"
    />
    <path fill="#fff" d="M235.104 204.855h6.857v11.429h-6.857z" />
    <path
      d="M224.701 252.495l5.734-10.445h16.538l5.837 10.445h-28.109"
      fill="#8eaae3"
    />
    <path
      d="M219.96 210.741c1.127 0 2.042.915 2.042 2.041v13.883c5.922 1.76 10.247 7.249 10.247 13.739 0 7.909-6.422 14.33-14.331 14.33s-14.33-6.421-14.33-14.33c0-6.49 4.325-11.979 10.247-13.739v-13.883c0-1.126.915-2.041 2.042-2.041h4.083z"
      fill="#fff"
    />
    <path
      d="M220.038 209.742c1.547.057 2.895 1.336 2.963 2.961l.001 13.236c7.933 2.762 12.577 13.122 8.734 21.109-3.387 7.039-12.739 10.57-19.955 7.407-7.792-3.416-11.542-14.034-7.174-21.657 1.811-3.16 4.765-5.619 8.208-6.853l.02-.006c0-4.488-.276-8.903.009-13.388.127-1.492 1.44-2.754 2.955-2.809 1.413-.019 2.826-.019 4.239 0zm-4.155 1.999c-.688.009-1.048 1.047-1.048 1.047l-.008 14.005c-.201 1.034-1.06.891-2.092 1.328-7.874 3.33-10.731 15.367-4.127 21.82 6.949 6.789 21.463 2.684 22.572-8.174.621-6.087-3.431-12.318-9.468-14.145 0 0-.664-.228-.71-.957 0-4.645.194-9.294-.002-13.935-.089-1.405-2.911-.989-5.117-.989z"
      fill="#f2f2f2"
      fillRule="nonzero"
    />
    <path fill="#fff" d="M214.767 208.126h6.375v7.375h-6.375z" />
    <path
      d="M206.558 234.435a12.366 12.366 0 0 0-1.775 6.368c0 7.029 5.937 12.736 13.248 12.736 7.312 0 13.248-5.707 13.248-12.736 0-2.236-.612-4.432-1.774-6.368h-22.947z"
      fill="#e8ee86"
    />
    <circle cx="211.609" cy="244.858" r="2.058" fill="none" stroke="#fff" />
    <circle cx="215.825" cy="248.873" r="1.058" fill="none" stroke="#fff" />
    <defs>
      <linearGradient
        id="_Linear1"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(36.948 -3.625 -3.525)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear2"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(66.948 -1.783 -1.54)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(66.948 5.222 94.22) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear4"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(36.948 -133.907 145.694) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear5"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(36.948 -133.907 145.694) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear6"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(36.948 -3.625 -3.525)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear7"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(66.948 -1.783 -1.54)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear8"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(66.948 5.222 94.22) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear9"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(36.948 -133.907 145.694) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear10"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(36.948 -3.625 -3.525)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear11"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(-74.6001 74.6001) rotate(66.948 -1.783 -1.54)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear12"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(66.948 5.222 94.22) scale(74.6001)"
      >
        <stop offset="0" stopColor="#333" />
        <stop offset="1" stopColor="#e57790" />
      </linearGradient>
      <linearGradient
        id="_Linear13"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-144.891 107.49 87.986) scale(46.8469)"
      >
        <stop offset="0" stopColor="#f2f2f2" />
        <stop offset="1" stopColor="#333" />
      </linearGradient>
      <linearGradient
        id="_Linear14"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(46.847 -46.847) rotate(35.109 8.85 1.952)"
      >
        <stop offset="0" stopColor="#f2f2f2" />
        <stop offset="1" stopColor="#333" />
      </linearGradient>
      <linearGradient
        id="_Linear15"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 25.9 109.896) scale(33.8245)"
      >
        <stop offset="0" stopColor="#e57790" />
        <stop offset="1" stopColor="#404040" />
      </linearGradient>
      <linearGradient
        id="_Linear16"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(109.392 32.291 100.993) scale(45.3262)"
      >
        <stop offset="0" stopColor="#a3a6d0" />
        <stop offset="0.43" stopColor="#878aa7" />
        <stop offset="1" stopColor="#4a4a4a" />
      </linearGradient>
      <linearGradient
        id="_Linear17"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(64.5363 -17.2924 24.0647 89.8107 62.295 249.558)"
      >
        <stop offset="0" stopColor="#f2f2f2" />
        <stop offset="0.49" stopColor="#c4c4c4" />
        <stop offset="1" stopColor="#404040" />
      </linearGradient>
      <linearGradient
        id="_Linear18"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(55.8509 -14.9652 24.0647 89.8107 70.772 247.286)"
      >
        <stop offset="0" stopColor="#f2f2f2" />
        <stop offset="0.49" stopColor="#c4c4c4" />
        <stop offset="1" stopColor="#404040" />
      </linearGradient>
      <linearGradient
        id="_Linear19"
        x2="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-15 968.844 -269.476) scale(14.61261)"
      >
        <stop offset="0" stopColor="#d5d5d5" />
        <stop offset="1" stopColor="#919191" />
      </linearGradient>
    </defs>
  </svg>
);

export default ErrorIcon;
