import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { H2, LabelColored, LinkedItem } from '../../layout/theme/components';
import ArrowIcon from '../../shared/components/ArrowIcon';
import StatusPanel from './components/StatusPanel';

import Container from '../../shared/components/Container';
import StatusFooter from './components/StatusFooter';

const Headers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 0.7em;
  margin-bottom: 2%;
`;
const PanelsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 6%;
`;
const StyledLabelColored = styled(LabelColored)`
  margin-bottom: 5%;
  word-break: break-word;
`;
const VerificationBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: no-wrap;
`;
const VerificationLabel = styled(LabelColored)`
  color: #fabb4a;
`;

const CommnityStatusComponent = ({
  keepers,
  name,
  residentsNumber,
  residentsPercent,
  levelStatuses,
  keeperExists,
  isRegularUser,
}) => (
  <Container noBotttomPadding>
    <Link to="/">
      <ArrowIcon />
    </Link>
    <Headers>
      <LabelColored>Wspólnota</LabelColored>
      <H2>{name}</H2>
    </Headers>
    {keepers.length > 0 ? (
      <StyledLabelColored bold>
        {keepers.length > 1 ? 'Zarządcy' : 'Zarządca'}: {keepers.join(', ')}
      </StyledLabelColored>
    ) : !keeperExists ? (
      <Link to="/zarzadca">
        <LinkedItem>Dodaj Zarządcę +</LinkedItem>
      </Link>
    ) : (
      <VerificationBox>
        <StyledLabelColored bold>Zarządca:&nbsp;</StyledLabelColored>
        <VerificationLabel bold>Weryfikacja</VerificationLabel>
      </VerificationBox>
    )}
    <PanelsBox>
      <StatusPanel level="basic" status={levelStatuses['basic']} />
      <StatusPanel level="premium" premium status={levelStatuses['premium']} />
    </PanelsBox>
    <StatusFooter residentsNumber={residentsNumber} residentsPercent={residentsPercent} isRegularUser={isRegularUser} />
  </Container>
);

CommnityStatusComponent.propTypes = {
  name: PropTypes.string.isRequired,
  keepers: PropTypes.array.isRequired,
  residentsNumber: PropTypes.number.isRequired,
  residentsPercent: PropTypes.number.isRequired,
  levelStatuses: PropTypes.object,
  keeperExists: PropTypes.bool,
  isRegularUser: PropTypes.bool,
};

CommnityStatusComponent.defaultProps = {
  residentsPercent: null,
  keeperExists: false,
  isRegularUser: false,
};

export default CommnityStatusComponent;
