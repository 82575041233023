import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import NotificationIcon from '../../../assets/NotificationIcon';

const Button = styled(Link)`
  display: flex;
  position: relative;

  svg {
    height: 26px;
  }
`;
const NewActivityMark = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
  height: 8px;
  width: 8px;
  background-color: #f53d65;
  border-radius: 50%;
`;

const NotificationsButton = props => {
  const { newActivity } = props;

  return (
    <Button to="/powiadomienia">
      {newActivity && <NewActivityMark />}
      <NotificationIcon />
    </Button>
  );
};

NotificationsButton.propTypes = {
  newActivity: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  newActivity: state.user.profile.data
    ? state.user.profile.data.new_notifications
    : false
});

export default connect(mapStateToProps)(NotificationsButton);
