import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Styled = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  margin-bottom: 6%;
  input {
    position: absolute;
    opacity: 0;
  }
`;
const Checkmark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 1.4em;
  width: 1.4em;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 50%;
  &:after {
    content: '';
    display: ${({ checked }) => (checked ? 'flex' : 'none')};
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background: ${({ theme }) => theme.buttonColors.primary};
  }
`;

const RadioInput = ({ name, checked }) => (
  <Styled>
    <input type="radio" name={name} checked={checked} readOnly />
    <Checkmark checked={checked} />
  </Styled>
);

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired
};

RadioInput.defaultProps = {
  checked: false
};

export default RadioInput;
