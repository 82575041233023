import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import ChristinToggler from '../shared/components/ChristinToggler';
import ExitButton from '../shared/components/buttons/ExitButton';

const BubbleBox = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 93%;
  top: 6em;
  left: 4.5%;
  color: ${({ theme }) => theme.textColors.secondary};
  font-size: 1.8em;
  z-index: 5;
  background: ${({ theme }) => theme.backgroundColors.primary};
  padding: 10% 10%;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow.element};
  transform: ${({ toggleNotification }) =>
    toggleNotification ? 'translateX(0)' : 'translateX(110%)'};
  transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;
const NotificationsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 10%;
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
`;

class NotificationComponent extends React.Component {
  state = { toggleNotification: false };

  handleChristin = () => {
    this.setState({ toggleNotification: !this.state.toggleNotification });
  };

  render() {
    const { toggleNotification } = this.state;

    return (
      <>
        <NotificationsWrapper>
          <ChristinToggler
            onClick={this.handleChristin}
            toggleNotification={this.state.toggleNotification}
          />
          <BubbleBox
            onClick={this.handleChristin}
            toggleNotification={this.state.toggleNotification}
          >
            <ExitButton
              onClick={this.handleChristin}
              top="-.4em"
              right="-.3em"
            />
            {this.props.children}
          </BubbleBox>
        </NotificationsWrapper>
        {toggleNotification && <Backdrop onClick={this.handleChristin} />}
      </>
    );
  }
}

NotificationComponent.propTypes = {
  toggleNotification: PropTypes.bool.isRequired
};

NotificationComponent.defaultProps = {
  toggleNotification: false
};

export default NotificationComponent;
