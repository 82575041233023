import React from 'react';

const anonymIcon = () => {
  return (
    <svg
      viewBox="0 0 77 56"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h76.145v55.114H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h76.145v55.114H0z" />
      </clipPath>
      <g fillRule="nonzero" clipPath="url(#a)">
        <path
          d="M24.919 11.481c-5.015.003-9.141 4.129-9.143 9.144a9.101 9.101 0 0 0 1.156 4.451H9.143C4.128 25.079.003 29.204 0 34.219c.002 5.015 4.128 9.141 9.143 9.144h42.082c5.015-.003 9.141-4.129 9.144-9.144a9.097 9.097 0 0 0-1.156-4.45h7.788c5.015-.002 9.142-4.129 9.144-9.144-.003-5.015-4.129-9.141-9.144-9.144H24.919z"
          fill="#f7f7f7"
        />
        <path
          d="M20.722 21.196c.305-9.042 7.826-16.314 16.873-16.314 9.045.002 16.564 7.274 16.869 16.314h.009v30.666h-33.76V21.196h.009z"
          fill="#f68ea5"
        />
        <path
          d="M23.679 26.571c.251-7.457 6.454-13.455 13.916-13.455 7.461.001 13.664 5.998 13.915 13.455h.007v25.291H23.672V26.571h.007z"
          fill="#e57790"
        />
        <path
          d="M50.31 54.015a18.096 18.096 0 0 0-12.793-5.3 18.095 18.095 0 0 0-12.792 5.3H50.31z"
          fill="#fbe3e8"
        />
        <path
          d="M37.637 47.617a19.54 19.54 0 0 1 13.366 5.536c.228.219.37.512.4.826a1.154 1.154 0 0 1-1.1 1.135H24.725c-.972-.046-1.434-1.243-.522-2.127a19.531 19.531 0 0 1 13.076-5.369l.358-.001zm-.225 2.2a17.273 17.273 0 0 0-9.665 3.089l19.555.01a17.127 17.127 0 0 0-9.89-3.1v.001z"
          fill="#fbe3e8"
        />
        <path
          d="M40.519 45.675c0-.576-.474-1.05-1.05-1.05H35.72a1.056 1.056 0 0 0-1.05 1.05v4.64c.008 1.842 5.856 1.681 5.849 0v-4.64z"
          fill="#fcecf0"
        />
        <path
          d="M51.517 24.159c-.003-7.636-6.286-13.919-13.922-13.923-7.637.003-13.92 6.286-13.923 13.923v9.1c.003 7.637 6.286 13.92 13.923 13.923 7.636-.004 13.919-6.287 13.922-13.923v-9.1z"
          fill="#fcecef"
        />
        <path
          d="M51.525 25.383a2.95 2.95 0 0 1 2.935 2.934 2.95 2.95 0 0 1-2.935 2.935c-1.62 0-1.62-5.869 0-5.869zM23.164 31.108a2.95 2.95 0 0 1-2.935-2.935 2.95 2.95 0 0 1 2.935-2.935c1.619 0 1.619 5.87 0 5.87z"
          fill="#fcecef"
        />
        <path
          d="M37.564 41.432a1.183 1.183 0 0 0-1.149-1.424 1.183 1.183 0 0 0-1.15 1.424h2.299z"
          fill="#f78ea5"
        />
        <path
          d="M39.92 41.432a1.183 1.183 0 0 0-1.149-1.424 1.182 1.182 0 0 0-1.15 1.424h2.299zM36.445 41.432a1.182 1.182 0 0 0 1.15 1.424 1.183 1.183 0 0 0 1.149-1.424h-2.299z"
          fill="#f78ea5"
        />
        <path
          d="M36.513 35.208a1.112 1.112 0 0 0 1.082 1.339 1.11 1.11 0 0 0 1.081-1.339h-2.163z"
          fill="#fddde4"
        />
        <path
          d="M29.593 32.199a1.85 1.85 0 0 1 1.84 1.839 1.85 1.85 0 0 1-1.84 1.84 1.85 1.85 0 0 1-1.839-1.84 1.849 1.849 0 0 1 1.839-1.839zM46.371 32.199a1.849 1.849 0 0 1 1.839 1.839 1.85 1.85 0 0 1-1.839 1.84 1.85 1.85 0 0 1-1.84-1.84 1.85 1.85 0 0 1 1.84-1.839z"
          fill="#f8a8b9"
        />
        <path
          d="M22.546 29.303c.977.041.692 2.9-.364 2.028-.562-.463-.437-2.041.364-2.028zM52.79 29.303c.977.041.692 2.9-.364 2.028-.562-.463-.437-2.041.364-2.028z"
          fill="#fbe3e8"
        />
        <path
          d="M32.409 23.09h19.464c0-8.9-6.392-16.119-14.278-16.119S23.316 14.19 23.316 23.09H30.4l1.2-6.842"
          fill="#f68ea5"
        />
        <path
          d="M32.409 16.119h19.464C51.873 7.219 45.481 0 37.595 0S23.316 7.217 23.316 16.119h9.093z"
          fill="#8780a7"
        />
        <path
          d="M51.564 21.857a.57.57 0 0 1 .5.518c.04 1.437 0 2.876 0 4.314-.015.55-1.036.785-1.1.031-.039-1.438 0-2.877 0-4.315l-.001-.025a.53.53 0 0 1 .601-.523zM23.727 19.298a.57.57 0 0 1 .493.51c.07 2.079 0 4.161 0 6.241-.018.544-1.02.809-1.1.037-.069-2.079 0-4.161 0-6.241l-.001-.033a.523.523 0 0 1 .608-.514z"
          fill="#fbe3e8"
        />
        <path
          d="M15.29 23.09s8.912 13.009 14.6 13.009c3.564-.004 6.496-2.936 6.5-6.5-.002-3.566-2.934-6.501-6.5-6.505l-14.6-.004z"
          fill="#6a6488"
        />
        <path
          d="M29.984 22.265c4.941.093 8.943 6.053 6.349 10.822-2.143 3.94-7.4 5.082-12.333 1.081a50.852 50.852 0 0 1-9.362-10.571c-2.614-3.942-.318-1.285.652-1.333 4.898 0 9.796-.03 14.694.001zM17.103 24.19c2.941 3.925 6.182 7.989 10.111 10.212 3.929 2.223 10.074-1.905 7.91-7.019a5.818 5.818 0 0 0-5.237-3.471h-12.99c.067.094.137.187.206.278z"
          fill="#d8d4ec"
        />
        <path
          d="M60.137 23.255s-8.913 12.843-14.6 12.843c-3.564-.004-6.496-2.936-6.5-6.5.002-3.566 2.934-6.501 6.5-6.505 5.687-.003 14.6.162 14.6.162z"
          fill="#6a6488"
        />
        <path
          d="M46.081 22.265c4.559.011 9.118.08 13.676.159 0 0 1.876.165.745 1.741-3.423 4.726-7.06 9.714-12.289 12.1-3.967 1.809-9.3-1.1-9.934-5.646-.592-4.229 2.968-8.358 7.802-8.354zm-.618 1.649c-3.837.073-6.913 4.722-4.921 8.385a5.607 5.607 0 0 0 4.884 2.86 5.605 5.605 0 0 0 2.861-.786c4.131-2.345 7.221-6.3 10.189-10.238l.062-.083c-4.36-.073-8.734-.162-13.074-.138h-.001z"
          fill="#d8d4ec"
        />
        <path
          d="M37.658 24.651a2.497 2.497 0 0 1 2.4 2.559c-.085.79-1.415 1.027-1.661-.255-.173-.8-1.469-.778-1.639.31-.172.873-2.106 1.164-1.542-.82a2.44 2.44 0 0 1 2.442-1.794z"
          fill="#d8d4ec"
        />
        <path
          d="M33.21 28.77c1.453.088.321 3.294-2.137 4.111-1.332.443-3.05.315-3.157-.629-.065-.574.5-.994 1.208-.848a3.128 3.128 0 0 0 3.3-2.137.769.769 0 0 1 .786-.497zM48.764 28.77c1.442.087.194 3.337-2.137 4.111-1.438.479-3.391.265-3.144-.894.161-.755 1.264-.445 2.1-.55 1.67-.212 2.052-2.684 3.181-2.667z"
          fill="#fff"
        />
        <path
          d="M20.696 20.501c-7.82-.863-11.252-4.286-11.252-5.927 0-2.655 11.717-2.837 28.232-2.837s29.4.182 29.4 2.837c0 1.641-4.6 5.064-12.424 5.927-.466-1.944-7.9-3.486-16.98-3.486s-16.508 1.542-16.976 3.486z"
          fill="#fbe3e8"
        />
        <path
          d="M20.696 20.501c-7.82-.863-11.252-4.286-11.252-5.927 0-2.655 11.717-2.837 28.232-2.837s29.4.182 29.4 2.837c0 1.641-4.6 5.064-12.424 5.927-.466-1.944-7.9-3.486-16.98-3.486s-16.508 1.542-16.976 3.486z"
          fill="#d8d4ec"
        />
        <path
          d="M54.476 20.501c-.426-3.012-7.844-5.457-16.881-5.457-9.037 0-16.456 2.445-16.882 5.457 2.814-1.629 9.409-2.952 16.882-2.952 7.473 0 14.069 1.323 16.881 2.952z"
          fill="#8780a7"
        />
      </g>
    </svg>
  );
};

export default anonymIcon;
