import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Caption } from '../../layout/theme/components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  width: 100%;
  margin-top: 2em;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    justify-content: space-around;
  }
`;

const LabelWrapper = styled(Caption)`
  margin-top: 1em;
`;

const ButtonsWrapper = ({ children, buttonText }) => (
  <Wrapper>
    <Buttons>{children}</Buttons>
    {buttonText && <LabelWrapper>{buttonText}</LabelWrapper>}
  </Wrapper>
);

ButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

ButtonsWrapper.defaultProps = {
  buttonText: null
};

export default ButtonsWrapper;
