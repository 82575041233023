import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import pdfIcon from '../../../../../../assets/pdfIcon.svg';

const Slide = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 1%;
  background: ${({ path }) => `url(${path}) no-repeat center`};
  background-size: cover;
  cursor: pointer;
  border: ${({ theme, isActive }) =>
    isActive && `2px solid ${theme.textColors.highlighted}`};
`;

const SlideBottom = ({ onClick, path, isPDF, activeSlide }) => (
  <Slide
    onClick={onClick}
    path={isPDF ? pdfIcon : path}
    isActive={activeSlide}
  />
);

SlideBottom.propTypes = {
  path: PropTypes.string.isRequired,
  isPDF: PropTypes.bool,
  onClick: PropTypes.func,
  activeSlide: PropTypes.bool
};

SlideBottom.defaultProps = {
  isPDF: false,
  onClick: null,
  activeSlide: false
};

export default SlideBottom;
