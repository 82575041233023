import React from 'react';

const premiumActiveIcon = () => {
  return (
    <svg
      viewBox="0 0 195 129"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h195v128.844H0z" />
      <clipPath id="a">
        <path d="M0 0h195v128.844H0z" />
      </clipPath>
      <g>
        <path
          d="M15.331 88.037c1.522-28.986 24.393-52.03 52.343-52.03 27.95 0 50.82 23.044 52.343 52.03h.081v32.708H15.25V88.037h.081z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <path
          fill="#cce1eb"
          fillRule="nonzero"
          d="M78.497 47.518h41.6v72.725h-41.6z"
        />
        <path
          fill="#514b81"
          fillRule="nonzero"
          d="M59.377 97.42h81.213v28.5H59.377z"
        />
        <path
          fill="#514b81"
          fillRule="nonzero"
          d="M74.185 77.269h50.225v48.651H74.185z"
        />
        <path
          d="M74.185 83.175h4.425V72.033h-4.427l.002 11.142zM83.344 72.033h4.427v11.142h-4.427zM92.504 72.033h4.427v11.142h-4.427z"
          fill="#68629b"
          fillRule="nonzero"
        />
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M47.5 53.422h13.889v72.725H47.5zM138.95 53.422h13.892v72.725H138.95z"
        />
        <path
          d="M143.494 65.32a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8V65.32zM143.494 81.786a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8v-5.575z"
          fill="#514b81"
          fillRule="nonzero"
        />
        <path
          d="M149.718 70.699a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516zM149.718 87.052a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          fill="#514b81"
          d="M145.508 19.35h.9v12.675h-.9zM99.242 3.45h.766v12.675h-.766z"
        />
        <path
          d="M155.491 19.4h-10.08v4.541h10.079l-2.053-2.347 2.054-2.194z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M109.188 0h-10.08v4.541h10.08l-2.054-2.346L109.188 0z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <path
          fill="#68629b"
          fillRule="nonzero"
          d="M101.663 72.033h4.427v11.142h-4.427zM110.825 72.033h4.427v11.142h-4.427zM119.982 72.033h4.427v11.142h-4.427z"
        />
        <path
          d="M99.302 90.998l12.463 14.505-4.152 20.417H90.982l-4.152-20.417 12.472-14.505z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M99.17 94.3l10.381 12.078-3.966 19.543H92.754l-3.965-19.542 2.7-3.142L99.17 94.3z"
          fill="#cce1eb"
          fillRule="nonzero"
        />
        <path
          d="M99.17 94.3l.127 31.62h-6.543l-3.965-19.542L99.17 94.3z"
          fill="#aecedc"
          fillRule="nonzero"
        />
        <path
          d="M96.72 110.838c.62 0 1.13.51 1.13 1.13 0 .62-.51 1.13-1.13 1.13-.62 0-1.13-.51-1.13-1.13 0-.62.51-1.13 1.13-1.13zM102.154 110.838c.62 0 1.13.51 1.13 1.13 0 .62-.51 1.13-1.13 1.13-.62 0-1.13-.51-1.13-1.13 0-.62.51-1.13 1.13-1.13z"
          fill="#6e67a8"
          fillRule="nonzero"
        />
        <path
          d="M52.041 65.32a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8V65.32zM85.999 59.114a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8v-5.575zM107.197 59.114a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8v-5.575zM52.041 81.786a2.41 2.41 0 0 1 2.4-2.4 2.41 2.41 0 0 1 2.4 2.4v5.575h-4.8v-5.575z"
          fill="#514b81"
          fillRule="nonzero"
        />
        <path
          d="M58.265 70.699a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516zM92.223 64.489a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516zM113.42 64.489a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516zM58.265 87.052a.259.259 0 0 0-.258-.258h-7.282a.259.259 0 0 0-.258.258v.516c0 .142.116.258.258.258h7.282a.259.259 0 0 0 .258-.258v-.516z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M99.208 14.114l12.793 17.229H86.415l12.793-17.229z"
          fill="#fabb4a"
          fillRule="nonzero"
        />
        <path
          d="M99.208 14.114l-2.941 17.229h-9.852l12.793-17.229z"
          fill="#fcd794"
          fillRule="nonzero"
        />
        <path
          d="M125.628 51.417H72.783l13.211-20.074h26.421l13.213 20.074z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M91.621 51.417H72.783l13.211-20.074h10.069l-4.442 20.074z"
          fill="#f8b2c1"
          fillRule="nonzero"
        />
        <path
          d="M177.334 76.384l2.013 14.428c.045.335.194.648.425.894.225.24.523.398.848.45l13.67 2.124-13.67 2.123c-.325.052-.623.21-.848.449-.231.247-.38.56-.425.895l-2.013 14.427-2.01-14.426a1.623 1.623 0 0 0-.426-.895 1.475 1.475 0 0 0-.848-.449l-13.67-2.123 13.67-2.124c.325-.052.623-.21.848-.45.232-.246.38-.559.426-.894l2.01-14.429zM24.365 86.384l1.783 12.783c.04.297.173.574.378.793.199.213.463.354.751.4l12.114 1.88-12.112 1.882a1.31 1.31 0 0 0-.751.4 1.444 1.444 0 0 0-.378.792l-1.783 12.784-1.785-12.784a1.436 1.436 0 0 0-.377-.792 1.31 1.31 0 0 0-.751-.4L9.341 102.24l12.112-1.881a1.31 1.31 0 0 0 .751-.4c.205-.219.337-.496.377-.793l1.784-12.782zM21.157 57.457l.811 5.817a.66.66 0 0 0 .172.36.599.599 0 0 0 .342.182l5.511.856-5.511.856a.599.599 0 0 0-.342.182.66.66 0 0 0-.172.36l-.811 5.817-.811-5.817a.66.66 0 0 0-.172-.36.599.599 0 0 0-.342-.182l-5.511-.856 5.511-.856a.599.599 0 0 0 .342-.182.66.66 0 0 0 .172-.36l.811-5.817zM81.987 10.785l1.486 10.66c.033.248.143.479.314.661.167.177.387.294.627.332l10.1 1.569-10.1 1.569c-.24.038-.46.155-.627.332a1.196 1.196 0 0 0-.314.661l-1.486 10.66-1.487-10.66a1.196 1.196 0 0 0-.314-.661 1.098 1.098 0 0 0-.627-.332l-10.1-1.569 10.1-1.569c.24-.038.46-.155.627-.332.171-.182.281-.413.314-.661l1.487-10.66z"
          fill="#ffd329"
          fillRule="nonzero"
        />
        <path
          d="M195 122.098a2.33 2.33 0 0 0-2.381-2.248H2.381A2.33 2.33 0 0 0 0 122.098v4.5a2.33 2.33 0 0 0 2.381 2.245h190.238a2.33 2.33 0 0 0 2.381-2.248v-4.497z"
          fill="#59e9c9"
          fillRule="nonzero"
        />
        <path fill="#514b81" d="M54.508 19.35h.9v12.675h-.9z" />
        <path
          d="M64.491 19.4h-10.08v4.541H64.49l-2.053-2.347 2.054-2.194z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M54.443 27.283l9.159 28.435H45.283l9.16-28.435z"
          fill="#fabb4a"
          fillRule="nonzero"
        />
        <path
          d="M54.443 27.283l-2.106 28.435h-7.054l9.16-28.435z"
          fill="#fcd794"
          fillRule="nonzero"
        />
        <path
          d="M65.798 55.719c0-.954-.785-1.739-1.739-1.739H44.826c-.954 0-1.739.785-1.739 1.739a1.749 1.749 0 0 0 1.739 1.739H64.06a1.749 1.749 0 0 0 1.738-1.739z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M145.895 27.283l9.159 28.435h-18.318l9.159-28.435z"
          fill="#fabb4a"
          fillRule="nonzero"
        />
        <path
          d="M145.895 27.283l-2.106 28.435h-7.053l9.159-28.435z"
          fill="#fcd794"
          fillRule="nonzero"
        />
        <path
          d="M157.252 55.719c0-.954-.785-1.739-1.739-1.739h-19.234c-.954 0-1.739.785-1.739 1.739a1.749 1.749 0 0 0 1.739 1.739h19.234a1.749 1.749 0 0 0 1.739-1.739z"
          fill="#f68ea5"
          fillRule="nonzero"
        />
        <path
          d="M157.04 31.007l1.136 8.152c.026.189.11.366.24.505a.84.84 0 0 0 .479.254l7.724 1.2-7.724 1.2a.84.84 0 0 0-.479.254.925.925 0 0 0-.24.505l-1.136 8.152-1.138-8.147a.925.925 0 0 0-.24-.505.84.84 0 0 0-.479-.254l-7.723-1.2 7.723-1.2a.84.84 0 0 0 .479-.254.925.925 0 0 0 .24-.505l1.138-8.157z"
          fill="#ffd329"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default premiumActiveIcon;
