import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { userRoles } from '../../helpers/constants';
import Container from '../components/Container';
import ModalWrapper from '../components/ModalWrapper';
import ManagerWelcomePage from './component/ManagerWelcomePage';

const WelcomePageContainer = ({ closeWelcomePage, role }) => (
  <ModalWrapper premium>
    <Container>
      <ManagerWelcomePage
        closeWelcomePage={closeWelcomePage}
        role={userRoles[role]}
      />
    </Container>
  </ModalWrapper>
);

WelcomePageContainer.propTypes = {
  closeWelcomePage: PropTypes.func,
  role: PropTypes.string.isRequired
};

WelcomePageContainer.defaultProps = {
  closeWelcomePage: null
};

const mapStateToProps = state => ({
  role: state.user.profile.data.role
});

export default connect(mapStateToProps)(WelcomePageContainer);
