import React from 'react';

const percentIcon = () => {
  return (
    <svg
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path
        d="M1.67 3.84c-.5 0-.903-.177-1.21-.53C.153 2.957 0 2.493 0 1.92S.153.883.46.53C.767.177 1.17 0 1.67 0s.905.175 1.215.525c.31.35.465.815.465 1.395 0 .58-.155 1.045-.465 1.395-.31.35-.715.525-1.215.525zM5.87.07h.98l-4.78 7h-.98l4.78-7zm-4.2 3.07a.732.732 0 0 0 .635-.32c.15-.213.225-.513.225-.9s-.075-.687-.225-.9A.732.732 0 0 0 1.67.7a.734.734 0 0 0-.625.325C.888 1.242.81 1.54.81 1.92s.078.678.235.895a.734.734 0 0 0 .625.325zm4.59 4c-.5 0-.903-.177-1.21-.53-.307-.353-.46-.817-.46-1.39s.153-1.037.46-1.39c.307-.353.71-.53 1.21-.53s.905.177 1.215.53c.31.353.465.817.465 1.39s-.155 1.037-.465 1.39c-.31.353-.715.53-1.215.53zm0-.7a.73.73 0 0 0 .63-.325c.153-.217.23-.515.23-.895s-.077-.678-.23-.895A.73.73 0 0 0 6.26 4a.734.734 0 0 0-.63.32c-.153.213-.23.513-.23.9s.077.687.23.9c.153.213.363.32.63.32z"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default percentIcon;
