import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../../layout/theme/components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4%;
`;
const BoxIcon = styled.div`
  margin-right: 10%;
  width: 35%;
  display: flex;
  justify-content: flex-end;
`;
const StyledText = styled(Text)`
  width: 65%;
  text-align: left;
`;

const OptionLabel = ({ icon, option }) => (
  <Wrapper>
    <BoxIcon>{icon}</BoxIcon>
    <StyledText bold>{option}</StyledText>
  </Wrapper>
);

export default OptionLabel;
