import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import TextInput from '../shared/components/forms/TextInput';
import { NavItem } from '../layout/theme/components';

import { setUsernameType } from '../data/user/actions';

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 4%;
`;
const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DiallingCodeWrapper = styled.div`
  width: 4em;
  margin-right: 3%;
  text-align: center;
`;
const PhoneNumberWrapper = styled.div`
  width: 88%;
`;

class UsernameInputToggler extends Component {
  render() {
    const { dialling, username, handleChange, valid, usernameType, setUsernameType } = this.props;
    const isUsernameTypeEmail = usernameType === 'email';

    return (
      <Fragment>
        <ButtonWrapper>
          <NavItem active={isUsernameTypeEmail} onClick={() => setUsernameType('email')}>
            Email
          </NavItem>
          <NavItem active={!isUsernameTypeEmail} onClick={() => setUsernameType('sms')}>
            Telefon
          </NavItem>
        </ButtonWrapper>
        {isUsernameTypeEmail ? (
          <InputsWrapper>
            <TextInput
              name="username"
              type="text"
              value={username}
              placeholder="Adres email"
              onChange={handleChange}
              isRequired
              valid={valid}
              invalidText="Podany adres e-mail jest nieprawidłowy."
              autofocus
            />
          </InputsWrapper>
        ) : (
          <Fragment>
            <InputsWrapper>
              <DiallingCodeWrapper>
                <TextInput
                  name="dialling"
                  type="text"
                  value={dialling}
                  onChange={handleChange}
                  isRequired
                  valid={valid}
                />
              </DiallingCodeWrapper>
              <PhoneNumberWrapper>
                <TextInput
                  name="username"
                  type="text"
                  value={username}
                  placeholder="Numer telefonu"
                  onChange={handleChange}
                  isRequired
                  valid={valid}
                  invalidText="Podany numer telefonu jest nieprawidłowy."
                  autofocus
                />
              </PhoneNumberWrapper>
            </InputsWrapper>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

UsernameInputToggler.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setUsernameType: PropTypes.func.isRequired,
  dialling: PropTypes.string,
  username: PropTypes.string,
  valid: PropTypes.bool,
  usernameType: PropTypes.string,
};

UsernameInputToggler.defaultProps = {
  dialling: '',
  username: '',
  valid: true,
  usernameType: 'email',
};

const mapStateToProps = state => ({
  usernameType: state.user.usernameType,
});

const mapDispatchToProps = dispatch => ({
  setUsernameType: value => {
    dispatch(setUsernameType(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsernameInputToggler);
