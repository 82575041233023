import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { H2 } from '../../../layout/theme/components';

const Question = styled(H2)`
  text-align: left;
  margin: 0.5em 0;
  word-break: break-word;
`;

const SoundingQuestion = memo(({ question }) => (
  <Question>{question}</Question>
));

SoundingQuestion.propTypes = {
  question: PropTypes.string.isRequired
};
export default SoundingQuestion;
