import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const hover = css`
  &:hover {
    background: ${({ theme, inverted }) =>
      inverted ? theme.buttonColors.secondary : theme.buttonColors.background};
    color: ${({ theme, inverted }) =>
      inverted ? theme.buttonColors.background : theme.buttonColors.secondary};
    border: 2px solid ${({ theme }) => theme.buttonColors.secondary};

    svg {
      fill: ${({ theme, inverted }) =>
        inverted
          ? theme.buttonColors.background
          : theme.buttonColors.secondary};
    }
  }
`;

const StyledButton = styled.button`
  background: ${({ theme, disabled, inverted }) =>
    disabled
      ? theme.buttonColors.inactive
      : inverted
        ? theme.buttonColors.background
        : theme.buttonColors.secondary};
  color: ${({ theme, inverted }) =>
    inverted ? theme.buttonColors.secondary : theme.buttonColors.background};
  border: 2px solid
    ${({ theme, disabled }) =>
      disabled ? theme.buttonColors.inactive : theme.buttonColors.secondary};
  font-size: 0.9em;
  padding: ${({ small }) => (small && '0.5em 1em') || '0.9em 1.7em'};
  display: inline-block;
  border-radius: 25px;
  outline: 0;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  box-shadow: ${({ disabled, theme }) =>
    disabled ? 'none' : theme.boxShadow.button};
  text-transform: uppercase;
  ${({ disabled }) => !disabled && hover};

  svg {
    fill: ${({ theme }) => theme.buttonColors.secondary};
  }
`;

const SecondaryButton = memo(
  ({
    onClick,
    children,
    type,
    disabled,
    inverted,
    small,
    onMouseEnter,
    onMouseLeave
  }) => (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      inverted={inverted}
      small={small}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </StyledButton>
  )
);

SecondaryButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  small: PropTypes.bool
};

SecondaryButton.defaultProps = {
  type: 'button',
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  disabled: false,
  inverted: false,
  small: false
};

export default SecondaryButton;
