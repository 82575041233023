import React from 'react';

const RangeIconTutorial = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.468"
    height="17.468"
    viewBox="0 0 17 17"
    fill="#30ab91"
  >
    <path fill="none" d="M0 0h17.468v17.468H0z" />
    <path d="M8.734 12.373a1.456 1.456 0 10-1.456-1.455 1.46 1.46 0 001.456 1.455zm4.367-6.55h-.728V4.367a3.639 3.639 0 10-7.278 0h1.382a2.256 2.256 0 014.513 0v1.456H4.367a1.46 1.46 0 00-1.456 1.455v7.278a1.46 1.46 0 001.456 1.456h8.734a1.46 1.46 0 001.456-1.456V7.278a1.46 1.46 0 00-1.456-1.455zm0 8.734H4.367V7.278h8.734z" />
  </svg>
);

export default RangeIconTutorial;
