import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  word-break: break-word;
`;

export const PreviewWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

PreviewWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
