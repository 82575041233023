import React, { memo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Hamburger = styled.div`
  width: 24px;
  height: 20px;
  position: ${({ active }) => (active ? 'fixed' : 'absolute')};
  top: ${({ top }) => (top ? top : '20px')};
  left: ${({ left }) => (left ? left : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 15;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
const Line = styled.div`
  background: ${({ theme, color }) =>
    color ? color : theme.buttonColors.primary};
  width: 90%;
  height: 4px;
  display: block;
  border-radius: 15px;
  transition: ${({ active }) =>
    active ? 'all 0.7s ease-in-out' : 'all 0.7s ease-in-out'};
`;
const LineTop = styled(Line)`
  transform: ${({ active }) =>
    active
      ? 'translateY(7.2px) rotate(-45deg);'
      : 'translateY(0) rotate(0deg)'};
`;
const LineMiddle = styled(Line)`
  opacity: ${({ active }) => (active ? '0' : '1')};
`;
const LineBottom = styled(Line)`
  transform: ${({ active }) =>
    active ? 'translateY(-7px) rotate(45deg)' : 'translateY(0) rotate(0deg)'};
`;

const HamburgerIcon = memo(
  ({ onClick, color, top, left, active, disabled }) => (
    <Hamburger
      top={top}
      left={left}
      onClick={onClick}
      active={active}
      disabled={disabled}
    >
      <LineTop color={color} active={active} />
      <LineMiddle color={color} active={active} />
      <LineBottom color={color} active={active} />
    </Hamburger>
  )
);

HamburgerIcon.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

HamburgerIcon.defaultProps = {
  onClick: null,
  color: '',
  active: false,
  disabled: false
};

export default HamburgerIcon;
