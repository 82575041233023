import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RadioInput from '../../shared/components/forms/RadioInput';
import ResolutionComponent from './ResolutionComponent';

import { saveResolutionTemplate } from '../../helpers/api/case';
import { postMedia } from '../../helpers/api/media';

import templateResolutionIcon from '../../../assets/resolution/templateResolution.svg';

const ResolutionContainer = ({
  caseId,
  templates,
  handleNextStep,
  file,
  subtype
}) => {
  const [templateId, setTemplate] = useState(null);
  const [attachment, addAttachment] = useState([]);
  const [error, setError] = useState(null);
  const [attachSizeValid, setAttachSizeValid] = useState(true);

  useEffect(() => {
    const selected = templates.filter(el => el.selected === true);
    if (!file || subtype === 'template') {
      if (selected.length > 0) setTemplate(selected[0].id);
      else setTemplate(null);
    } else {
      setTemplate(null);
      handleFileChange([file]);
    }
  }, []);
  useEffect(() => attachment && clearError(), [attachment]);

  const handleFileChange = (value, sizeValid) => {
    addAttachment([value]);
    !file && setAttachSizeValid(sizeValid);
  };
  const handleRemoveFile = (fileIndex, sizeValid) => {
    setAttachSizeValid(sizeValid);
    const filteredAttachments = attachment.filter(
      (_, index) => fileIndex !== index
    );
    addAttachment(filteredAttachments);
  };

  const chooseTemplate = id => {
    if (id !== null && attachment) {
      addAttachment([]);
      setTemplate(id);
    } else setTemplate(id);
  };

  const clearError = () => setError(null);

  const saveStep = (template, subtype, file) => {
    saveResolutionTemplate(caseId, template, subtype, file)
      .then(() => handleNextStep())
      .catch(error => setError(error));
  };
  const handleSubmit = e => {
    e.preventDefault();
    const isAttachment = !!attachment[0];
    const attachmentId = isAttachment ? attachment[0][0].id : null;
    if (isAttachment) {
      if (attachmentId) {
        return saveStep(null, 'file', attachmentId);
      } else {
        postMedia(...attachment[0])
          .then(({ data }) => saveStep(null, 'file', data.id))
          .catch(error => setError(error));
      }
    } else saveStep(templateId, 'template');
  };

  const isError = {
    networkError: error && error.status !== 400,
    noFile: error && error.status === 400,
    invalidSize: !attachSizeValid
  };
  return (
    <ResolutionComponent
      handleSubmit={handleSubmit}
      userFile={templateId === null}
      attachment={attachment[0]}
      handleFileChange={(value, sizeValid) =>
        handleFileChange(value, sizeValid)
      }
      handleRemoveFile={(fileIndex, sizeValid) =>
        handleRemoveFile(fileIndex, sizeValid)
      }
      templateId={templateId}
      chooseTemplate={id => chooseTemplate(id)}
      isError={isError}
    >
      {templates.map(template => (
        <RadioInput
          key={template.id}
          name={template.id}
          value={template.id}
          checked={template.id === templateId}
          onChange={chooseTemplate}
          label={template.name}
          labelIconPath={templateResolutionIcon}
        />
      ))}
    </ResolutionComponent>
  );
};

ResolutionContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  subtype: PropTypes.string,
  file: PropTypes.object
};
ResolutionContainer.defaultProps = {
  file: null,
  subtype: 'template'
};

export default ResolutionContainer;
