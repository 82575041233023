import { combineReducers } from 'redux';

import * as UserActions from './actionTypes';
import { getAccessToken } from '../../helpers/localStorage';

let loggedIn = getAccessToken() ? true : false;

const auth = (state = { loggedIn: loggedIn, isLoading: false, error: null }, action) => {
  switch (action.type) {
    case UserActions.LOGIN_STARTED:
      return { loggedIn: false, isLoading: true, error: null };
    case UserActions.LOGIN_SUCCESS:
      return { loggedIn: true, isLoading: false, error: null };
    case UserActions.LOGIN_ERROR:
      return {
        loggedIn: false,
        isLoading: false,
        error: action.payload.detail,
      };
    case UserActions.LOGOUT:
      return { loggedIn: false, isLoading: false, error: null };
    default:
      return state;
  }
};

const profile = (state = { data: null, isLoading: true, error: null }, action) => {
  switch (action.type) {
    case UserActions.FETCH_USER_STARTED:
      return { data: null, isLoading: true, error: null };
    case UserActions.FETCH_USER_SUCCESS:
      return { data: action.payload, isLoading: false, error: null };
    case UserActions.FETCH_USER_ERROR:
      return { data: null, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

const currentResource = (state = null, action) => {
  switch (action.type) {
    case UserActions.SET_RESOURCE:
      return action.payload;
    default:
      return state;
  }
};

const currentDataResource = (state = { data: null, isLoading: true }, action) => {
  switch (action.type) {
    case UserActions.SET_DATA_RESOURCE_STARTED:
      return { data: null, isLoading: true };
    case UserActions.SET_DATA_RESOURCE_SUCCEED:
      return { data: action.payload, isLoading: false };
    default:
      return state;
  }
};

const communityStatus = (state = null, action) => {
  switch (action.type) {
    case UserActions.SAVE_COMMUNITY_STATUS:
      return action.payload;
    default:
      return state;
  }
};

const accessControlList = (state = { data: null, isLoading: true }, action) => {
  switch (action.type) {
    case UserActions.SET_PERMISSION_LIST_STARTED:
      return { data: null, isLoading: true };
    case UserActions.SET_PERMISSION_LIST_SUCCEED:
      return { data: action.value, isLoading: false };
    default:
      return state;
  }
};

const usernameType = (state = 'email', action) => {
  switch (action.type) {
    case UserActions.SET_USERNAME_TYPE:
      return action.value;
    default:
      return state;
  }
};

const userLabel = (state = null, action) => {
  switch (action.type) {
    case UserActions.SET_USER_LABEL:
      return action.value;
    default:
      return state;
  }
};

export const user = combineReducers({
  auth,
  profile,
  currentResource,
  usernameType,
  currentDataResource,
  communityStatus,
  accessControlList,
  userLabel,
});
