import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { formatDate, formatTime } from '../../../helpers/date';
import { Label, Text } from '../../../layout/theme/components';
import PreviewSection from './PreviewSection';

const MeetingDescription = styled.div`
  margin-top: 5px;
`;
const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.textColors.primary};
`;
const MeetingText = styled(Text)`
  display: inline-block;
  padding: 0;
  margin: 0 0 0 10px;
`;

const PreviewMeeting = ({ meeting }) => {
  const { time, place } = meeting;
  return (
    <PreviewSection>
      {time && (
        <>
          <MeetingDescription>
            <StyledLabel big>Data:</StyledLabel>
            <MeetingText bold>{formatDate(time.date)}</MeetingText>
          </MeetingDescription>
          <MeetingDescription>
            <StyledLabel big>Godzina:</StyledLabel>
            <MeetingText bold>{formatTime(time.date)}</MeetingText>
          </MeetingDescription>
        </>
      )}
      {place && (
        <MeetingDescription>
          <StyledLabel big>Miejsce:</StyledLabel>
          <MeetingText bold>{place}</MeetingText>
        </MeetingDescription>
      )}
    </PreviewSection>
  );
};

PreviewMeeting.propTypes = {
  meeting: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      place: PropTypes.string,
      time: PropTypes.shape({
        date: PropTypes.string,
      }),
    }),
  ]),
};

PreviewMeeting.defaultProps = {
  meeting: [],
};

export default PreviewMeeting;
