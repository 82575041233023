import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ModalWrapper from '../components/ModalWrapper';
import Container from '../components/Container';
import { H0, Text } from '../../layout/theme/components';
import PrimaryButton from '../components/buttons/PrimaryButton';

import UnvotedIcon from '../../../assets/resolution/unvoted';

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  svg {
    width: 50%;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 15vh;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 10vh;
  }
`;

const UnvotedResolutions = ({ unvotedResolutionsNumber, closeUnvoted }) => (
  <ModalWrapper>
    <Container>
      <H0>Witaj!</H0>
      <Text>
        Tyle uchwał czeka na Twoje głosowanie: <b>{unvotedResolutionsNumber}</b>
      </Text>
      <ImageBox>
        <UnvotedIcon />
      </ImageBox>
      <ButtonsWrapper>
        <PrimaryButton onClick={closeUnvoted}>Głosuj teraz</PrimaryButton>
      </ButtonsWrapper>
    </Container>
  </ModalWrapper>
);

UnvotedResolutions.propTypes = {
  unvotedResolutionsNumber: PropTypes.number,
  closeUnvoted: PropTypes.func
};
UnvotedResolutions.defaultProps = {
  unvotedResolutionsNumber: 0,
  closeUnvoted: null
};

export default UnvotedResolutions;
