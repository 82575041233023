import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import RangeIconTutorial from '../../../../assets/tutorial/rangeIconTutorial';
import { Caption, Text } from '../../theme/components';
import ExampleCaseItem from '../components/ExampleCaseItem';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';

const ExampleWrapper = styled.div`
  width: 100%;
  margin-top: 15%;
`;
const StyledCaption = styled(Caption)`
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 1em;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.textColors.secondary};
  margin-top: 15%;
`;
const IconWrapper = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  margin: 0.5em;
  align-items: center;
  justify-content: center;

  svg {
    width: 50%;
    height: 50%;
  }
`;

const Step8 = ({ nextSlide }) => (
  <PageWrapper onButtonClick={nextSlide}>
    <TutorialHeader>
      Widoczność sprawy poznasz, patrząc na <b>ikonę z prawej strony tematu sprawy</b>
    </TutorialHeader>
    <ExampleWrapper>
      <StyledCaption>Przykład</StyledCaption>
      <ExampleCaseItem />
    </ExampleWrapper>
    <TextWrapper>
      <IconWrapper>
        <RangeIconTutorial />
      </IconWrapper>
      <Text>
        Widoczność sprawy:
        <br />
        <b>Publiczna</b>
      </Text>
    </TextWrapper>
  </PageWrapper>
);

Step8.propTypes = {
  nextSlide: PropTypes.func.isRequired,
};

export default Step8;
