import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import DefaultAvatarIcon from '../../../../../assets/defaultAvatar';

const Name = styled.div`
  color: #fff;
  font-size: ${({ bigFont }) => (bigFont ? '3em' : '1.6em')};
`;

const AvatarDefault = ({ name, bigFont }) => {
  return name ? <Name bigFont={bigFont ? true : false}>{name[0]}</Name> : <DefaultAvatarIcon />;
};

AvatarDefault.propTypes = {
  name: PropTypes.string,
  bigFont: PropTypes.bool,
};
AvatarDefault.defaultProps = {
  name: null,
  bigFont: false,
};

export default AvatarDefault;
