import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { putComment } from '../../helpers/api/activities';
import { postMedia } from '../../helpers/api/media';
import { tagUser } from '../../helpers/api/user';
import {
  INVALID_INPUT_TEXT,
  mentionAlert,
  NETWORK_ERROR_TEXT
} from '../../helpers/constants';
import { maxChars } from '../../helpers/validation';
import AnonymCheckbox from '../../shared/components/forms/AnonymCheckbox';
import FileInput from '../../shared/components/forms/FileInput';
import Textarea from '../../shared/components/forms/Textarea';
import FullModalContainer from '../../shared/components/FullModalContainer';
import { ResolutionContext } from '../preview/CasePreviewContainer';
import { Alert, InvalidText } from './../../layout/theme/components';

class CommentForm extends Component {
  constructor() {
    super();

    this.state = {
      form: {
        vote: 0,
        anonymous: 0,
        description: ''
      },
      attachments: [],
      usersToMention: [],
      descriptionValid: true,
      attachSizeValid: true,
      isLoading: false,
      error: false
    };

    this.submitForm = this.submitForm.bind(this);
  }

  changeFormValue(value, field) {
    value.length <= maxChars[field]
      ? this.setState({
          form: { ...this.state.form, [field]: value },
          descriptionValid: true
        })
      : this.setState({ descriptionValid: false });
  }

  handleCheckbox(value, field) {
    this.setState({ form: { ...this.state.form, [field]: value } });
  }

  updateMentions = mentions => {
    this.setState({ usersToMention: mentions });
  };

  handleFileChange(value, sizeValid) {
    this.setState({
      attachments: [...this.state.attachments, ...value],
      attachSizeValid: sizeValid
    });
  }

  handleRemoveFile = (fileIndex, sizeValid) => {
    this.setState({ attachSizeValid: sizeValid });

    const attachments = this.state.attachments.filter(
      (_, index) => fileIndex !== index
    );
    this.setState({ attachments });
  };

  handleFilesUpload = files => {
    const promises = files.map(file =>
      postMedia(file).then(({ data }) => data.id)
    );

    return Promise.all(promises);
  };

  submitComment(form) {
    const { communityCase, handleResponse } = this.props;
    const { usersToMention } = this.state;
    const mentions = usersToMention.map(user => user.id);

    putComment(communityCase, form)
      .then(resp => {
        this.setState({ isLoading: false });
        tagUser('comment', mentions, communityCase, resp.data.id).then(resp =>
          handleResponse('success', resp)
        );
      })
      .catch(() => this.setState({ isLoading: false, error: true }));
  }

  submitForm(e) {
    const { attachments, form } = this.state;
    const descLength = form.description.length;
    e.preventDefault();

    this.setState({ isLoading: true });

    if (attachments.length > 0 && descLength > 0) {
      this.handleFilesUpload(attachments).then(data => {
        form.attachments = data;
        this.submitComment(form);
      });
    } else if (descLength > 0) this.submitComment(form);
    else this.setState({ descriptionValid: false, isLoading: false });
  }

  getMentionMessage() {
    const { usersToMention } = this.state;
    const usersNames = usersToMention.map(user => user.display);
    let usersToDisplay = `${usersNames.join(', ')}`;

    return mentionAlert(usersToDisplay, usersToMention.length > 1);
  }

  render() {
    const { Consumer } = ResolutionContext;
    const {
      form,
      descriptionValid,
      attachments,
      attachSizeValid,
      isLoading,
      error,
      usersToMention
    } = this.state;

    return (
      <FullModalContainer
        buttonType="submit"
        isLoading={isLoading}
        buttonText="DODAJ KOMENTARZ"
        disabled={!descriptionValid || !attachSizeValid}
        onClick={this.submitForm}
      >
        <form>
          <Consumer>
            {isResolution => (
              <Textarea
                changeHandler={value =>
                  this.changeFormValue(value, 'description')
                }
                value={form.description}
                valid={descriptionValid}
                invalidText={INVALID_INPUT_TEXT}
                isEmoji
                isMention={!isResolution}
                updateMentions={this.updateMentions}
                counter
              />
            )}
          </Consumer>
          {error && <InvalidText>{NETWORK_ERROR_TEXT}</InvalidText>}
          <FileInput
            changeHandler={(value, isFileValid) =>
              this.handleFileChange(value, isFileValid)
            }
            files={attachments}
            multiple
            removeFile={(index, sizeValid) =>
              this.handleRemoveFile(index, sizeValid)
            }
          />
          {this.props.addAsAnonymous && (
            <AnonymCheckbox
              changeHandler={value => this.handleCheckbox(value, 'anonymous')}
            />
          )}
          {usersToMention.length > 0 && (
            <Alert negative>{this.getMentionMessage()}</Alert>
          )}
        </form>
      </FullModalContainer>
    );
  }
}

CommentForm.propTypes = {
  communityCase: PropTypes.number.isRequired,
  handleResponse: PropTypes.func.isRequired,
  addAsAnonymous: PropTypes.bool.isRequired
};

export default CommentForm;
