import React from 'react';

const giveKeeper = () => {
  return (
    <svg
      viewBox="0 0 196 133"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path fill="none" d="M0 0h195.65v132.667H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h195.65v132.667H0z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="M151.051 54.111a13.073 13.073 0 0 0 1.148-5.408c0-5.969-3.84-10.816-8.569-10.816-4.729 0-8.569 4.846-8.569 10.816a13.073 13.073 0 0 0 1.148 5.408h14.842z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M114.87 132.667h-68.3v-13.089c.004-9.763 8.037-17.796 17.8-17.8h32.7c9.763.004 17.796 8.037 17.8 17.8v13.089z"
          fill="#56ddbf"
          fillRule="nonzero"
        />
        <path
          d="M90.329 94.563c-.002-3.432-2.825-6.255-6.257-6.257H78.74c-3.432.001-6.256 2.825-6.257 6.257v8.018c0 3.453 4.435 8.105 7.888 8.105l2.069.217c3.453 0 7.888-4.87 7.888-8.323l.001-8.017z"
          fill="#f9dde3"
          fillRule="nonzero"
        />
        <path
          d="M110.838 51.581c-.007-16.143-13.289-29.425-29.432-29.432-16.143.007-29.425 13.289-29.432 29.432v17.81c.007 16.143 13.289 29.425 29.432 29.432 16.143-.007 29.425-13.289 29.432-29.432v-17.81z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M109.984 58.516c3.4.004 6.196 2.8 6.2 6.2-.004 3.4-2.8 6.196-6.2 6.2-3.422.008-3.422-12.4 0-12.4zM51.771 70.619c-3.4-.004-6.196-2.8-6.2-6.2.004-3.4 2.8-6.196 6.2-6.2 3.424-.009 3.424 12.4 0 12.4z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M80.059 74.666a2.505 2.505 0 0 0-.057.532 2.543 2.543 0 0 0 2.529 2.529 2.543 2.543 0 0 0 2.529-2.529c0-.179-.019-.357-.057-.532h-4.944z"
          fill="#fcd5dd"
          fillRule="nonzero"
        />
        <path
          d="M94.977 64.056c1.936.082 2.887 3.935 0 4.057-2.141.03-4.549.491-5.629-.6a2.114 2.114 0 0 1 1.388-3.454c1.412-.022 2.826-.023 4.241-.003z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M92.854 63.72a2.454 2.454 0 0 1 2.441 2.441 2.454 2.454 0 0 1-2.441 2.441 2.454 2.454 0 0 1-2.441-2.441 2.454 2.454 0 0 1 2.441-2.441z"
          fill="#616161"
          fillRule="nonzero"
        />
        <path
          d="M90.329 102.663a1.374 1.374 0 0 0-1.366-1.366H73.851a1.374 1.374 0 0 0-1.366 1.366v1.165a1.374 1.374 0 0 0 1.366 1.366h15.112a1.374 1.374 0 0 0 1.366-1.366v-1.165z"
          fill="#f9dde3"
          fillRule="nonzero"
        />
        <path
          d="M64.326 101.496c-2.446 4.425-1.312 9.829.272 16.685l7.887-8.893a15.222 15.222 0 0 0 8.922 1.594c-1.989-2.733-9.743-4.648-8.922-9.385-3.822-1.244-6.433-.21-8.159-.001z"
          fill="#04ab9a"
          fillRule="nonzero"
        />
        <path
          d="M92.529 57.825a10.679 10.679 0 0 1 5.624 1.508c.315.24.501.614.501 1.01 0 .697-.574 1.27-1.27 1.27-.242 0-.478-.068-.682-.198a8.784 8.784 0 0 0-8.245.067 1.661 1.661 0 0 1-.664.167 1.304 1.304 0 0 1-1.141-1.29c0-.524.316-.998.799-1.2a10.56 10.56 0 0 1 4.906-1.335l.172.001z"
          fill="#746aa4"
          fillRule="nonzero"
        />
        <path
          d="M73.226 64.056c1.936.082 2.887 3.935 0 4.057-2.141.03-4.549.491-5.629-.6a2.114 2.114 0 0 1 1.388-3.454c1.412-.022 2.826-.023 4.241-.003z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M71.103 63.72a2.454 2.454 0 0 1 2.441 2.441 2.454 2.454 0 0 1-2.441 2.441 2.454 2.454 0 0 1-2.441-2.441 2.454 2.454 0 0 1 2.441-2.441z"
          fill="#616161"
          fillRule="nonzero"
        />
        <path
          d="M70.778 57.825a10.679 10.679 0 0 1 5.624 1.508c.315.24.501.614.501 1.01 0 .697-.574 1.27-1.27 1.27-.242 0-.478-.068-.682-.198a8.784 8.784 0 0 0-8.245.067 1.661 1.661 0 0 1-.664.167 1.304 1.304 0 0 1-1.141-1.29c0-.524.316-.998.799-1.2a10.56 10.56 0 0 1 4.906-1.335l.172.001z"
          fill="#746aa4"
          fillRule="nonzero"
        />
        <path
          d="M96.997 73.646a.684.684 0 0 0-.681.681c0 .374.307.681.681.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681zM94.68 72.261a.685.685 0 0 0-.68.681c0 .373.307.68.68.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681zM98.85 71.793a.684.684 0 0 0-.681.681c0 .374.307.681.681.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681zM67.415 73.646a.684.684 0 0 0-.681.681c0 .374.307.681.681.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681zM65.098 72.261a.685.685 0 0 0-.68.681c0 .373.307.68.68.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681zM69.268 71.793a.684.684 0 0 0-.681.681c0 .374.307.681.681.681a.684.684 0 0 0 .681-.681.684.684 0 0 0-.681-.681z"
          fill="#eec0c6"
          fillRule="nonzero"
        />
        <path
          d="M91.722 84.288c-.512 4.581-4.76 8.066-9.831 8.066s-9.319-3.485-9.831-8.066h19.662z"
          fill="#616161"
          fillRule="nonzero"
        />
        <path
          d="M72.06 84.288h19.662a5.087 5.087 0 0 1-.488 2.1H72.573a4.378 4.378 0 0 1-.513-2.1z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M86.941 91.076c-.752-1.48-2.729-2.512-5.05-2.512-2.321 0-4.3 1.032-5.05 2.512a10.528 10.528 0 0 0 5.05 1.274 10.81 10.81 0 0 0 5.05-1.274z"
          fill="#fcd5dd"
          fillRule="nonzero"
        />
        <path
          d="M107.827 45.691v2.424a1.598 1.598 0 0 0-.046.265c-.112 4 0 8.009 0 12.014.042 1.5 2.833 2.161 3.009.084.1-3.643.02-7.29 0-10.935h.046V37.057c-2.568-11.984-15.028-20.775-29.432-20.775-.745 0-1.485.023-2.219.07h-20.73l3.729 4.732h-6.881l3.281 4.165a23.721 23.721 0 0 0-6.611 11.808v12.488h.024c-.065 3.616.024 7.234.024 10.85.042 1.5 2.833 2.161 3.009.084.1-3.643.02-7.29 0-10.935h.046v-1.439c6.437 7.507 19.907 8.48 31.78 1.819 6.056-3.4 10.1-7.508 12.154-11.9l8.817 7.667z"
          fill="#746aa4"
          fillRule="nonzero"
        />
        <path
          d="M110.404 117.951a41.374 41.374 0 0 0 29.245-12.114 41.374 41.374 0 0 0 12.114-29.245h-16.206a25.164 25.164 0 0 1-7.367 17.786 25.164 25.164 0 0 1-17.786 7.367v16.206z"
          fill="#56ddbf"
          fillRule="nonzero"
        />
        <path
          fill="#56ddbf"
          fillRule="nonzero"
          d="M135.495 52.805h16.267v25.35h-16.267z"
        />
        <path
          fill="#04ab9a"
          fillRule="nonzero"
          d="M135.495 52.806h16.267v8.555h-16.267z"
        />
        <path
          d="M139.905 55.781a1.748 1.748 0 0 1 1.737 1.737 1.746 1.746 0 0 1-1.737 1.737 1.746 1.746 0 0 1-1.737-1.737 1.746 1.746 0 0 1 1.737-1.737z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          fill="#56ddbf"
          fillRule="nonzero"
          d="M98.56 101.78h12.181v12.214H98.56z"
        />
        <path
          d="M138.316 28.523a5.044 5.044 0 0 0-3.154-2.039c-2.715-.528-5.382 1.271-5.91 3.986a5.045 5.045 0 0 0 .831 3.869l36.401 51.523a5.04 5.04 0 0 0 3.155 2.04c2.714.527 5.381-1.272 5.909-3.986a5.043 5.043 0 0 0-.831-3.87l-36.401-51.523z"
          fill="#ada5d6"
          fillRule="nonzero"
        />
        <path
          d="M123.058 19.893l-7.809-6.553.059-.042a3.705 3.705 0 0 1-.201-.16c.523-2.48-.624-7.136-2.079-11.253l6.168 8.731.399-.282-6.168-8.732c3.394 2.748 7.399 5.385 9.913 5.72.03.081.058.162.083.243l.059-.042 3.566 9.55-3.99 2.82z"
          fill="#ccc"
          fillRule="nonzero"
        />
        <path
          d="M137.879 27.902l-8.233 5.816-6.741-13.908 4.116-2.908 10.858 11z"
          fill="#616161"
          fillRule="nonzero"
        />
        <path
          d="M134.658 36.116l31.678 44.839"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <path
          d="M166.31 66.288c-1.947-2.756-11.932 4.299-9.986 7.054l22.315 31.585a6.115 6.115 0 0 0 3.786 2.417c3.291.64 6.527-1.543 7.167-4.834a6.118 6.118 0 0 0-.969-4.638L166.31 66.288z"
          fill="#cdc7ea"
          fillRule="nonzero"
        />
        <path
          d="M140.638 43.505c2.605.002 4.748 2.145 4.749 4.75-.002 2.605-2.144 4.747-4.749 4.749-2.605-.001-4.748-2.144-4.75-4.749.001-2.605 2.145-4.749 4.75-4.75z"
          fill="#fcecef"
          fillRule="nonzero"
        />
        <path
          d="M98.488 101.496c2.446 4.425 1.312 9.829-.272 16.685l-7.887-8.893a15.23 15.23 0 0 1-8.922 1.594c1.989-2.733 9.743-4.648 8.922-9.385 3.822-1.244 6.432-.21 8.159-.001z"
          fill="#04ab9a"
          fillRule="nonzero"
        />
        <path
          d="M24.503 109.861a.544.544 0 0 0-.724.263l-.001.001a.545.545 0 0 0 .264.724l3.295 1.537a.546.546 0 0 0 .725-.264v-.001a.545.545 0 0 0-.264-.724l-3.295-1.536zM31.046 112.769a.544.544 0 0 0-.724.263l-.001.001a.545.545 0 0 0 .264.724l3.295 1.537a.546.546 0 0 0 .725-.264v-.001a.545.545 0 0 0-.264-.724l-3.295-1.536zM31.908 107.889a.544.544 0 0 0-.263-.724l-.001-.001a.545.545 0 0 0-.724.264l-1.537 3.295a.546.546 0 0 0 .264.725h.001a.545.545 0 0 0 .724-.264l1.536-3.295zM29 114.432a.544.544 0 0 0-.263-.724l-.001-.001a.545.545 0 0 0-.724.264l-1.537 3.295a.546.546 0 0 0 .264.725h.001a.545.545 0 0 0 .724-.264L29 114.432zM27.798 107.341a.545.545 0 0 0-.699-.326l-.001.001a.544.544 0 0 0-.326.698l1.244 3.418a.545.545 0 0 0 .698.326h.002a.546.546 0 0 0 .325-.699l-1.243-3.418zM30.368 114.024a.545.545 0 0 0-.699-.326l-.001.001a.544.544 0 0 0-.326.698l1.244 3.418a.545.545 0 0 0 .698.326h.002a.546.546 0 0 0 .325-.699l-1.243-3.418zM34.427 111.184a.545.545 0 0 0 .326-.699l-.001-.001a.544.544 0 0 0-.698-.326l-3.418 1.244a.545.545 0 0 0-.326.698v.002a.546.546 0 0 0 .699.325l3.418-1.243zM27.745 113.754a.545.545 0 0 0 .326-.699l-.001-.001a.544.544 0 0 0-.698-.326l-3.418 1.244a.545.545 0 0 0-.326.698v.002a.546.546 0 0 0 .699.325l3.418-1.243z"
          fill="#ffd329"
        />
        <path
          d="M1.492 65.553a.768.768 0 0 0-.648 1.391l4.638 2.163a.768.768 0 0 0 .649-1.391l-4.639-2.163zM10.703 69.647a.768.768 0 0 0-.648 1.391l4.638 2.163a.768.768 0 0 0 .649-1.391l-4.639-2.163zM11.917 62.777a.768.768 0 0 0-1.391-.648l-2.163 4.638a.768.768 0 0 0 1.391.649l2.163-4.639zM7.823 71.988a.768.768 0 0 0-1.391-.648l-2.163 4.638a.768.768 0 0 0 1.391.649l2.163-4.639zM6.129 62.006a.768.768 0 0 0-.983-.459l-.001.001a.768.768 0 0 0-.459.983l1.751 4.812a.767.767 0 1 0 1.443-.525l-1.751-4.812zM9.748 71.414a.768.768 0 0 0-.983-.459l-.001.001a.768.768 0 0 0-.459.983l1.751 4.812a.767.767 0 1 0 1.443-.525l-1.751-4.812zM15.464 67.414a.768.768 0 0 0 .459-.983l-.001-.001a.768.768 0 0 0-.983-.459l-4.812 1.751a.767.767 0 1 0 .525 1.443l4.812-1.751zM6.055 71.033a.768.768 0 0 0 .459-.983l-.001-.001a.768.768 0 0 0-.983-.459L.718 71.341a.767.767 0 1 0 .525 1.443l4.812-1.751z"
          fill="#56ddbf"
        />
        <path
          d="M20.175 38.652a.386.386 0 0 0-.513.186l-.001.001a.386.386 0 0 0 .187.513l2.335 1.089c.193.09.423.007.513-.186l.001-.001a.386.386 0 0 0-.187-.513l-2.335-1.089zM24.812 40.713a.386.386 0 0 0-.513.186l-.001.001a.386.386 0 0 0 .187.513l2.335 1.089c.193.09.423.007.513-.186l.001-.001a.386.386 0 0 0-.187-.513l-2.335-1.089zM25.423 37.254a.386.386 0 0 0-.186-.513l-.001-.001a.386.386 0 0 0-.513.187l-1.089 2.335a.386.386 0 0 0 .186.513l.001.001c.193.09.423.006.513-.187l1.089-2.335zM23.362 41.891a.386.386 0 0 0-.186-.513l-.001-.001a.386.386 0 0 0-.513.187l-1.089 2.335a.386.386 0 0 0 .186.513l.001.001c.193.09.423.006.513-.187l1.089-2.335zM22.51 36.865a.386.386 0 0 0-.495-.23h-.001a.387.387 0 0 0-.231.495l.881 2.422a.386.386 0 0 0 .495.231h.001a.387.387 0 0 0 .231-.495l-.881-2.423zM24.332 41.602a.386.386 0 0 0-.495-.23h-.001a.387.387 0 0 0-.231.495l.881 2.422a.386.386 0 0 0 .495.231h.001a.387.387 0 0 0 .231-.495l-.881-2.423zM27.209 39.589a.386.386 0 0 0 .23-.495v-.001a.387.387 0 0 0-.495-.231l-2.422.881a.386.386 0 0 0-.231.495v.001c.073.2.295.304.495.231l2.423-.881zM22.473 41.411a.386.386 0 0 0 .23-.495v-.001a.387.387 0 0 0-.495-.231l-2.422.881a.386.386 0 0 0-.231.495v.001c.073.2.295.304.495.231l2.423-.881z"
          fill="#ffd329"
        />
        <path
          d="M85.174 2.236a.404.404 0 0 0-.535.195v.001a.402.402 0 0 0 .194.534L87.265 4.1c.201.094.44.006.534-.195l.001-.001a.403.403 0 0 0-.195-.534l-2.431-1.134zM90.002 4.382a.404.404 0 0 0-.535.195v.001a.402.402 0 0 0 .194.534l2.432 1.134c.201.094.44.006.534-.195l.001-.001a.403.403 0 0 0-.195-.534l-2.431-1.134zM90.638.782a.404.404 0 0 0-.195-.535h-.001a.402.402 0 0 0-.534.194l-1.134 2.432a.402.402 0 0 0 .195.534l.001.001c.201.093.44.006.534-.195L90.638.782zM88.493 5.61a.404.404 0 0 0-.195-.535h-.001a.402.402 0 0 0-.534.194l-1.134 2.432a.402.402 0 0 0 .195.534l.001.001c.201.093.44.006.534-.195l1.134-2.431zM87.605.377a.402.402 0 0 0-.515-.24l-.001.001a.402.402 0 0 0-.241.515l.918 2.522a.402.402 0 0 0 .515.24h.001a.402.402 0 0 0 .241-.515L87.605.377zM89.501 5.308a.402.402 0 0 0-.515-.24l-.001.001a.402.402 0 0 0-.241.515l.918 2.522a.402.402 0 0 0 .515.24h.001a.402.402 0 0 0 .241-.515l-.918-2.523zM92.497 3.213a.402.402 0 0 0 .24-.515l-.001-.001a.402.402 0 0 0-.515-.241l-2.522.918a.402.402 0 0 0-.24.515v.001a.402.402 0 0 0 .515.241l2.523-.918zM87.566 5.109a.402.402 0 0 0 .24-.515l-.001-.001a.402.402 0 0 0-.515-.241l-2.522.918a.402.402 0 0 0-.24.515v.001a.402.402 0 0 0 .515.241l2.523-.918z"
          fill="#ffd329"
        />
        <path
          d="M148.932 29.336a.47.47 0 0 0-.396.85l2.832 1.321a.47.47 0 0 0 .397-.85l-2.833-1.321zM154.557 31.836a.47.47 0 0 0-.396.85l2.832 1.321a.47.47 0 0 0 .397-.85l-2.833-1.321zM155.298 27.641a.47.47 0 0 0-.85-.396l-1.321 2.832a.47.47 0 0 0 .85.397l1.321-2.833zM152.798 33.266a.47.47 0 0 0-.85-.396l-1.321 2.832a.47.47 0 0 0 .85.397l1.321-2.833zM151.765 27.171a.469.469 0 1 0-.882.321l1.069 2.937a.47.47 0 0 0 .601.281h.001a.47.47 0 0 0 .28-.601l-1.069-2.938zM153.974 32.916a.469.469 0 1 0-.882.321l1.069 2.937a.47.47 0 0 0 .601.281h.001a.47.47 0 0 0 .28-.601l-1.069-2.938zM157.464 30.474a.469.469 0 1 0-.321-.882l-2.937 1.069a.47.47 0 0 0-.281.601v.001a.47.47 0 0 0 .601.28l2.938-1.069zM151.719 32.683a.469.469 0 1 0-.321-.882l-2.937 1.069a.47.47 0 0 0-.281.601v.001a.47.47 0 0 0 .601.28l2.938-1.069z"
          fill="#56ddbf"
        />
      </g>
    </svg>
  );
};

export default giveKeeper;
