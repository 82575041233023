import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';
import PropTypes from 'prop-types';

import GreenButton from '../../shared/components/buttons/GreenButton';

import { clickedInviteNeighbours } from '../../helpers/tools';
import { LabelColored } from '../../layout/theme/components';

const LabelWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  bottom: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.button};
  z-index: 1;
  padding: 0 2em;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 0 3em;
  }
`;
const Name = styled(LabelColored).attrs({
  bold: true
})`
  flex: 1;
  ${ellipsis('100%')};
  text-align: left;
  width: 50%;
`;
const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;

  svg {
    width: 2.5em;
  }
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const CurrentResource = ({ currentResource, isRegularUser }) => (
  <LabelWrapper>
    <Name>{currentResource}</Name>
    <RightSide>
      {isRegularUser && (
        <StyledLink to="zaproszenie">
          <GreenButton onClick={clickedInviteNeighbours('listing')} small>
            ZAPROŚ SĄSIADÓW!
          </GreenButton>
        </StyledLink>
      )}
    </RightSide>
  </LabelWrapper>
);

CurrentResource.propTypes = {
  currentResource: PropTypes.string,
  isRegularUser: PropTypes.bool
};

CurrentResource.defaultProps = {
  currentResource: '',
  isRegularUser: false
};

const mapStateToProps = state => ({
  currentResource:
    state.user.currentDataResource.data &&
    state.user.currentDataResource.data.display_name
});

export default connect(mapStateToProps)(CurrentResource);
