import React, { Component } from 'react';

import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import Category from './components/CategoryItem';
import CategoriesList from './components/CategoriesList';

import { fetchSubCategories } from '../helpers/api/categories';

export default class SubcategoriesContainer extends Component {
  state = {
    categories: [],
    isLoading: true,
    error: null
  };

  componentDidMount() {
    this.getSubcategories();
  }

  goToStepContainer(categoryId) {
    const location = {
      pathname: '/sprawa/dodaj',
      state: { categoryId }
    };
    this.props.history.push(location);
  }

  skipSubcategoriesView(categoryId) {
    const { location } = this.props;
    const shouldGoBack = this.props.history.action === 'POP';

    if ((location.state && location.state.goBack) || shouldGoBack) {
      this.props.history.push('/kategorie');
    } else {
      this.goToStepContainer(categoryId);
    }
  }

  getSubcategories() {
    const id = this.props.match.params.id;

    fetchSubCategories(id)
      .then(data => {
        const categories = data.data;
        if (categories.length === 1)
          this.skipSubcategoriesView(categories[0].id);
        else {
          this.setState({
            categories: data.data,
            isLoading: false
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderCategories() {
    return this.state.categories.map(category => {
      return (
        <Category
          key={category.id}
          background={category.parent_color}
          title={category.name}
          iconPath={category.picture ? category.picture.path : 'default icon'}
          onClick={() => this.goToStepContainer(category.id)}
        />
      );
    });
  }

  render() {
    const { categories, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <CategoriesList header="Wybierz podkategorię" goBackTo="/kategorie">
        {categories.length > 0 ? this.renderCategories() : 'Brak kategorii.'}
      </CategoriesList>
    );
  }
}
