import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const getColor = ({ theme, primary, invert }) => {
  if (primary) return theme.buttonColors.primary;
  else if (invert) return theme.buttonColors.tertiary;
  else return theme.buttonColors.tertiary;
};

const getHoverColor = ({ theme, primary, invert }) => {
  if (primary) return theme.buttonColors.primary;
  else if (invert) return theme.backgroundColors.primary;
  else return theme.buttonColors.secondary;
};

const Button = styled.button`
  border: none;
  background: none;
  margin: ${({ small }) => (small ? '0.5em' : '1em')};
  color: ${getColor};
  font-weight: ${({ small }) => (small && '500') || '600'};
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  font-size: ${({ small }) => (small && '0.9em') || '1.1em'};
  text-transform: ${({ primary }) => primary && 'uppercase'};
  &:hover {
    color: ${getHoverColor};
  }
`;
const ButtonLabel = styled.span`
  color: ${({ theme }) => theme.textColors.tertiary};
  cursor: default;
  font-weight: 500;
  margin-right: 0.3em;
`;

const TextButton = memo(
  ({
    children,
    onClick,
    disabled,
    type,
    small,
    primary,
    invert,
    label,
    className
  }) => {
    return (
      <Button
        className={className}
        type={type}
        onClick={onClick}
        disabled={disabled}
        small={small}
        primary={primary}
        invert={invert}
      >
        {label && <ButtonLabel>{label}</ButtonLabel>}
        {children}
      </Button>
    );
  }
);

TextButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string
};

TextButton.defaultProps = {
  onClick: null,
  disabled: false,
  type: 'button',
  small: false,
  primary: false,
  label: null,
  className: ''
};

export default TextButton;
