import React from 'react';

const ThreeDotsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path fill="none" d="M0 24V0h24v24z" />
      <path
        fill="#675e97"
        d="M8 12a2 2 0 10-2 2 2.006 2.006 0 002-2zm2 0a2 2 0 102-2 2.006 2.006 0 00-2 2zm6 0a2 2 0 102-2 2.006 2.006 0 00-2 2z"
      />
    </svg>
  );
};

export default ThreeDotsIcon;
