import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, premium }) =>
    premium ? theme.backgroundColors.premium : theme.backgroundColors.primary};
  z-index: 101;
`;

const ModalWrapper = ({ children, premium }) => {
  return <Wrapper premium={premium}>{children}</Wrapper>;
};

ModalWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default ModalWrapper;
