import { pl } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { css } from 'styled-components';
import { H2 } from '../../layout/theme/components';
import Button from '../../shared/components/buttons/PrimaryButton';
import TextInput from '../../shared/components/forms/TextInput';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  ${sectionStyle};
  span {
    font-size: 0.7em;
    font-weight: 400;
  }
`;

const Form = styled.form`
  ${sectionStyle};
  flex: 1;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  margin: 1em 0;
  width: 100%;

  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DatePickerInput = styled.input`
  display: block;
  width: 100%;
  border-width: 0 0 1px 0;
  padding: 0.4em;
  text-align: left;
  font-size: 1.6em;
  border-color: ${({ theme }) => theme.borderColor};
  outline: 0;
`;

const MeetingComponent = props => {
  registerLocale('pl', pl);

  const CustomDateInput = ({ value, onClick, placeholder }) => (
    <DatePickerInput value={value} placeholder={placeholder} onClick={onClick} />
  );

  return (
    <>
      <Header>
        <H2>Zebranie:</H2>
      </Header>
      <Form onSubmit={e => props.submitHandler(e)}>
        <InputsWrapper>
          <DatePickerWrapper>
            <DatePicker
              placeholderText="Data"
              selected={props.date}
              onChange={date => props.handleChange('date', date)}
              locale="pl"
              minDate={new Date()}
              dateFormat="dd.MM.yyyy"
              customInput={<CustomDateInput />}
            />
          </DatePickerWrapper>
          <DatePickerWrapper>
            <DatePicker
              placeholderText="Godzina"
              selected={props.time}
              onChange={date => props.handleChange('time', date)}
              locale="pl"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Godzina"
              dateFormat="HH:mm"
              customInput={<CustomDateInput />}
            />
          </DatePickerWrapper>
          <TextInput
            name="place"
            value={props.place}
            placeholder="Miejsce"
            onChange={e => props.handleChange('place', e.target.value)}
          />
        </InputsWrapper>
        <Button type="submit">DALEJ</Button>
      </Form>
    </>
  );
};

MeetingComponent.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  time: PropTypes.instanceOf(Date),
  place: PropTypes.string,
};

MeetingComponent.defaultProps = {
  date: '',
  time: '',
  place: '',
};

export default MeetingComponent;
