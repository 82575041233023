import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import RangeIconTutorial from '../../../../assets/tutorial/rangeIconTutorial';
import ToolboxTutorial from '../../../../assets/tutorial/toolboxTutorial';
import { Text } from '../../theme/components';

const CaseItem = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e8e8e8;
  padding: 5%;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 57px;
  height: 57px;
  background: #ffd13f;
  opacity: 0.7;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  svg {
    width: 70%;
    height: 70%;
  }
`;
const CaseDetails = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  word-break: break-word;
  flex: 1;
  text-align: left;
`;
const StyledText = styled(Text)`
  margin: 0;
  flex: 1;
`;

const ExampleCaseItem = ({ children }) => (
  <CaseItem>
    <IconWrapper>
      <ToolboxTutorial />
    </IconWrapper>
    <CaseDetails>
      <StyledText defaultColor>Naprawa windy</StyledText>
      <RangeIconTutorial />
    </CaseDetails>
  </CaseItem>
);

ExampleCaseItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default ExampleCaseItem;
