import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import UserIcon from '../../../../assets/user';
import CommentIcon from '../../../../assets/comment';
import { Caption } from '../../../layout/theme/components';

const Header = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.textColors.tertiary};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding-bottom: 4px;
  margin-bottom: 15px;
`;
const HeaderName = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;
const sectionStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 1em;
  font-weight: 700;
  color: ${({ theme }) => theme.textColors.primary};
  font-size: 1.2em;
`;
const UserCount = styled.div`
  ${sectionStyle};
  svg {
    height: 15px;
    margin-left: 4px;
  }
`;
const CommentsCount = styled.div`
  ${sectionStyle};
  svg {
    height: 13px;
    margin-left: 4px;
  }
`;

const ActivitiesSummary = memo(({ usersCount, commentsCount }) => {
  return (
    <Header>
      <HeaderName>
        <Caption default>Oś czasu sprawy</Caption>
      </HeaderName>
      <UserCount>
        {usersCount} <UserIcon />
      </UserCount>
      <CommentsCount>
        {commentsCount} <CommentIcon />
      </CommentsCount>
    </Header>
  );
});

ActivitiesSummary.propTypes = {
  commentsCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired
};

export default ActivitiesSummary;
