import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';

const RegistrationLinkWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  margin: 2em 0;
`;

const RegistrationLink = props => {
  const { buttonText } = props;
  return (
    <RegistrationLinkWrapper>
      <Link to="/rejestracja" target="_blank">
        <SecondaryButton>{buttonText}</SecondaryButton>
      </Link>
    </RegistrationLinkWrapper>
  );
};

RegistrationLink.propTypes = {
  buttonText: PropTypes.string.isRequired
};

export default RegistrationLink;
