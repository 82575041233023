import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { createTextLinks } from '../../../helpers/tools';
import { Text } from '../../../layout/theme/components';
import PreviewSection from './PreviewSection';

const Description = styled(Text).attrs({
  defaultColor: true,
})`
  a {
    color: #1670d6;
  }
`;
const EditorContent = styled.div`
  font-size: 10px;
  a {
    color: #1670d6;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.2em;
  }
  th,
  td {
    border: 1px solid black;
    padding: 0.5em;
  }
`;

const PreviewDescription = ({ description, resolutionContent }) => {
  const resolutionDescription = resolutionContent && createTextLinks(resolutionContent);
  return (
    <>
      {description ? (
        <PreviewSection title="Opis:">
          <Description dangerouslySetInnerHTML={{ __html: createTextLinks(description) }} />
        </PreviewSection>
      ) : (
        <EditorContent dangerouslySetInnerHTML={{ __html: resolutionDescription }} />
      )}
    </>
  );
};

PreviewDescription.propTypes = {
  description: PropTypes.string,
  resolutionContent: PropTypes.string,
};

PreviewDescription.defaultProps = {
  description: null,
  resolutionContent: null,
};

export default PreviewDescription;
