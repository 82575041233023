import * as ModalActions from './actionsTypes';

export const showModal = (payload, isWide) => dispatch => {
  dispatch({
    type: ModalActions.SHOW_MODAL,
    payload,
    isWide,
  });
};

export const hideModal = () => dispatch => {
  dispatch({
    type: ModalActions.HIDE_MODAL,
  });
};
