import React from 'react';
import styled from 'styled-components/macro';
import Cancel from '../../../../assets/cancel';

const CancelBox = styled.label`
  position: absolute;
  right: 0.5em;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.textColors.secondary};
    width: 1.2em;
    height: 1.2em;
  }
`;

const DeleteButton = ({ onClick }) => {
  return (
    <CancelBox onClick={onClick}>
      <Cancel />
    </CancelBox>
  );
};

export default DeleteButton;
