import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import DefaultAvatarIcon from '../../../../assets/defaultAvatar';

const DefaultAvatarBox = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.textColors.primary};
  background: ${({ theme }) => theme.textColors.tertiary};
  cursor: pointer;
  &:after {
    content: '';
    display: inline-block;
    margin-left: 0.5em;
    font-size: 2.2em;
    font-weight: 700;
  }
  input[type='file'] {
    display: none;
  }
`;

const DefaultAvatar = ({ onChange }) => {
  return (
    <DefaultAvatarBox>
      <DefaultAvatarIcon />
      <input
        type="file"
        onChange={onChange}
        name="newImage"
        accept=".png, .jpg, .jpeg"
      />
    </DefaultAvatarBox>
  );
};

DefaultAvatar.propTypes = {
  onChange: PropTypes.func
};

DefaultAvatar.defaultProps = {
  onChange: null
};

export default DefaultAvatar;
