import React from 'react';

const postPigeon = () => {
  return (
    <svg width="179.052" height="148.5">
      <defs />
      <path
        d="M53.179 25.658c-13.807 0-25.015 11.71-25.015 26.133a26.905 26.905 0 0 0 3.162 12.72h-6.311C11.209 64.511 0 76.218 0 90.643s11.209 26.133 25.015 26.133h100.131c13.806 0 25.015-11.71 25.015-26.133A26.917 26.917 0 0 0 147 77.923h7.038c13.806 0 25.015-11.709 25.015-26.133s-11.208-26.132-25.015-26.133z"
        fillRule="evenodd"
        fill="#756ba4"
      />
      <path
        d="M128.607 3.293s-8.556 43.327-18.775 57a19.1 19.1 0 0 1-26.72 3.86 19.1 19.1 0 0 1-3.86-26.72c10.22-13.668 49.355-34.14 49.355-34.14z"
        fill="#ccc"
        fillRule="evenodd"
      />
      <path
        className="c"
        d="M96.66 71.933h-.046l-.046.006-.046.008-.045.011-.044.014-.044.016-.042.019-.041.02-.04.023-.039.025-.037.028-.035.03-.034.031-.033.033-.03.035-.028.037-.026.038-.024.04-.021.041-.02.042-.017.043-.014.044-.012.045-.009.046-.007.046v.184l.006.046.009.046.011.045.014.044.016.044.018.042.021.041.023.04.025.039.028.037 5.162 6.622.03.035.031.034.034.033.035.03.037.028.038.026.04.024.041.021.042.02.043.017.044.014.045.012.046.009.046.007h.184l.046-.006.046-.009.045-.011.044-.014.044-.016.042-.018.041-.021.04-.023.039-.025.037-.028.035-.029.034-.032.033-.033.03-.035.028-.036.026-.039.024-.04.021-.041.02-.042.017-.043.014-.044.012-.045.009-.045.007-.046v-.184l-.006-.046-.009-.045-.011-.045-.014-.045-.016-.044-.018-.042-.021-.041-.023-.04-.025-.039-.028-.037-5.162-6.621-.03-.036-.031-.034-.034-.032-.035-.03-.037-.029-.038-.026-.04-.024-.041-.021-.042-.019-.043-.017-.044-.015-.045-.012-.046-.009-.046-.007h-.092z"
        fill="#fbaf16"
      />
      <path
        className="c"
        d="M97.095 83.394a4.32 4.32 0 0 1 .173-2.08 3.906 3.906 0 0 1 4.832-2.65 3.905 3.905 0 0 1 2.246 5.033 4.325 4.325 0 0 1-1.12 1.761z"
        fill="#fbaf16"
      />
      <path
        className="d"
        d="M98.944 84.017a1.716 1.716 0 0 1 .068-.825 1.549 1.549 0 0 1 1.916-1.051 1.55 1.55 0 0 1 .891 2 1.72 1.72 0 0 1-.445.7z"
        fill="#f3f1fc"
      />
      <path
        d="M36.336 126.209l88.491 22.141a4.82 4.82 0 0 0 5.843-3.5l11.792-47.129a4.831 4.831 0 0 0-3.5-5.844l-88.495-22.14a4.83 4.83 0 0 0-5.843 3.5L32.831 120.37a4.831 4.831 0 0 0 3.505 5.839zm48.214-4.789l57.388-21.607L44.1 75.337z"
        fill="#e3e2ec"
        fillRule="evenodd"
      />
      <path
        className="f"
        d="M44.622 73.241l97.838 24.481a4.831 4.831 0 0 0-3.5-5.844L50.467 69.737a4.83 4.83 0 0 0-5.845 3.504zm-.524 2.1l97.838 24.481z"
        fill="#f3f1fc"
      />
      <path
        d="M87.28 110.514l-45.39 17.089 77.385 19.363z"
        fill="#f3f1fc"
        fillRule="evenodd"
      />
      <path
        className="f"
        d="M142.56 97.33L44.721 72.85l-.622 2.487 40.45 46.088 57.388-21.608z"
        fill="#f3f1fc"
      />
      <path
        d="M87.648 109.046a2.618 2.618 0 0 0-3.174 1.9 2.618 2.618 0 0 0 1.9 3.174 2.618 2.618 0 0 0 3.174-1.9 2.618 2.618 0 0 0-1.9-3.174z"
        fill="#f86677"
        fillRule="evenodd"
      />
      <path
        className="i"
        d="M42.469 28.889c1.637 2.227 28.079 37.94 43.156 43.016 13.611 4.583 28.532-3.194 33.3-17.357L42.389 28.78z"
        fill="#e5e5e5"
      />
      <path
        className="i"
        d="M106.03 36.837a8.94 8.94 0 0 1 11.32-5.616 8.941 8.941 0 0 1 5.618 11.321l-5.051 15a8.941 8.941 0 0 1-11.321 5.618 8.94 8.94 0 0 1-5.618-11.321z"
        fill="#e5e5e5"
      />
      <path
        d="M46.299 0S86.954 17.255 98.24 30.059a19.1 19.1 0 0 1-1.7 26.944 19.1 19.1 0 0 1-26.944-1.7C58.312 42.499 46.299 0 46.299 0z"
        fill="#f2f2f2"
        fillRule="evenodd"
      />
      <path
        className="c"
        d="M127.632 45.39s-4.61.67-6.3.1a2.107 2.107 0 0 1-1.324-2.668 2.107 2.107 0 0 1 2.668-1.324c1.69.566 4.956 3.892 4.956 3.892z"
        fill="#fbaf16"
      />
      <path
        d="M116.36 38.06a1.245 1.245 0 0 0-1.577.782 1.246 1.246 0 0 0 .782 1.577 1.246 1.246 0 0 0 1.577-.783 1.244 1.244 0 0 0-.782-1.576z"
        fill="#454545"
        fillRule="evenodd"
      />
      <path
        className="c"
        d="M87.846 68.97l-.06.006-.06.01-.058.014-.057.019-.056.023-.054.027-.052.031-.05.034-.047.038-.044.041-.041.044-.037.047-.034.05-.03.052-.026.055-.022.056-.018.057-.014.059-.009.06-.006.06v.121l.008.06.012.059.017.058.021.057.025.055 5.537 11.158.029.053.033.051.036.049.04.045.043.042.046.04.049.036.051.032.053.029.055.024.057.02.058.016.059.012.06.008h.121l.06-.006.06-.01.058-.014.058-.019.056-.023.054-.027.052-.031.05-.034.047-.038.044-.041.041-.044.037-.047.034-.05.03-.052.026-.055.022-.056.018-.057.014-.059.009-.06.006-.06v-.121l-.008-.06-.013-.059-.017-.058-.021-.057-.025-.055-5.537-11.159-.029-.053-.033-.051-.036-.049-.04-.045-.043-.043-.046-.039-.049-.036-.051-.032-.053-.029-.055-.024-.057-.02-.058-.016-.059-.012-.06-.007h-.06z"
        fill="#fbaf16"
      />
      <path
        className="c"
        d="M88.667 83.42a4.322 4.322 0 0 1 .173-2.081 3.905 3.905 0 0 1 4.832-2.65 3.905 3.905 0 0 1 2.246 5.033 4.319 4.319 0 0 1-1.12 1.761z"
        fill="#fbaf16"
      />
      <path
        className="d"
        d="M90.519 84.04a1.718 1.718 0 0 1 .068-.825 1.549 1.549 0 0 1 1.916-1.051 1.549 1.549 0 0 1 .891 2 1.714 1.714 0 0 1-.445.7z"
        fill="#f3f1fc"
      />
      <path
        d="M121.59 31.277c1.894-1.926 3.164-2.288 2.646-4.097-1.08-3.762-7.968-7.397-14.622-5.489s-10.57 8.641-9.49 12.403c.518 1.809 1.787 1.443 4.415 2.073z"
        fill="#6d79cd"
        fillRule="evenodd"
      />
      <path
        d="M110.837 25.679a1.02 1.02 0 1 1-.699 1.261 1.02 1.02 0 0 1 .7-1.261z"
        fill="#fabb4a"
        fillRule="evenodd"
      />
      <path
        d="M104.604 33.84a2.617 2.617 0 0 0-.707 2.305c.615 2.144 5.344 2.672 10.552 1.179s8.938-4.447 8.323-6.592a2.617 2.617 0 0 0-1.82-1.58z"
        fill="#404363"
        fillRule="evenodd"
      />
      <path
        d="M121.883 27.628a67.452 67.452 0 0 0-9.58 1.886 61.92 61.92 0 0 0-9.297 3.527l.832 2.9a68.031 68.031 0 0 1 9.16-3.49 70.359 70.359 0 0 1 9.716-1.924z"
        fill="#a8b1f1"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default postPigeon;
