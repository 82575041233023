import React from 'react';
import styled from 'styled-components/macro';

import { Caption } from '../../layout/theme/components';

const StyledCaption = styled(Caption)`
  position: absolute;
  right: 0;
  top: -0.1em;
`;

const CharsCounter = ({ chars, maxChars }) => (
  <StyledCaption>
    {chars}/{maxChars}
  </StyledCaption>
);

export default CharsCounter;
