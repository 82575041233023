import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Text } from '../../../layout/theme/components';
import PrimaryButton from '../../../shared/components/buttons/PrimaryButton';
import ProgressBar from '../../../shared/components/ProgressBar';

import { clickedInviteNeighbours, floatNumbers } from '../../../helpers/tools';

const StatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: ${({ lower }) => (lower ? '6em' : '8em')};
  background: ${({ theme }) => theme.backgroundColors.premium};
  padding: 1em 2em;
  bottom: 0;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  top: -1.5em;
  width: 100%;
  max-width: 600px;
  padding-right: 2em;
  text-align: right;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 600px;
  }
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  width: 100%;
  max-width: 600px;
  text-align: left;
`;
const ResidentsAmount = styled.span`
  color: ${({ theme }) => theme.textColors.quaternary};
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 0.4em;
`;

const StatusFooter = memo(({ residentsNumber, residentsPercent, isRegularUser }) => {
  const progressBarTooltip =
    residentsPercent > 50
      ? `Możecie podejmować decyzje (głosować uchwały) ONLINE. Większość (${residentsPercent}%) mieszkańców ma konto w aplikacji.`
      : `Aż ${residentsPercent} mieszkańców ma konto w aplikacji. Zaproś sąsiadów by większość mieszkańców miała konta ONLINE. Podejmujcie sprawnie decyzje (głosujcie uchwały) ONLINE. `;

  return (
    <StatusWrapper lower={!residentsPercent}>
      {isRegularUser && (
        <ButtonWrapper>
          <Link to="/zaproszenie">
            <PrimaryButton onClick={clickedInviteNeighbours()} small>
              ZAPROŚ SĄSIADÓW
            </PrimaryButton>
          </Link>
        </ButtonWrapper>
      )}
      <>
        <StyledText small>
          {residentsPercent ? 'Procent' : 'Liczba'} użytkowników:
          <ResidentsAmount>
            {residentsPercent ? `${floatNumbers(residentsPercent, 2)}%` : residentsNumber}
          </ResidentsAmount>
        </StyledText>
        {residentsPercent > 0 && <ProgressBar percent={residentsPercent} tooltip={progressBarTooltip} />}
      </>
    </StatusWrapper>
  );
});

StatusFooter.propTypes = {
  residentsNumber: PropTypes.number,
  residentsPercent: PropTypes.number,
  isRegularUser: PropTypes.bool,
};

StatusFooter.defaultProps = {
  residentsNumber: 0,
  residentsPercent: null,
  isRegularUser: false,
};

export default StatusFooter;
