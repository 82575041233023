import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import AvatarIcon from './AvatarIcon';
import AvatarDetails from './components/AvatarDetails';

import { userLabels, userRoles } from '../../../helpers/constants';
import { Text } from '../../../layout/theme/components';
import ExitIcon from '../buttons/ExitIcon';

const AvatarEventWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? 'initial' : '25px')};
  bottom: ${({ top }) => (top ? '25px' : 'initial')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 10;
  background-color: transparent;
`;
const AvatarWrapper = styled.div`
  width: 350px;
  min-height: 100px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  @media (max-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 0 auto;
    width: calc(100vw - 10em);
  }
`;
const AvatarLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  padding: 10px 0 0 20px;
  background: ${({ userRole, theme }) => {
    switch (userRole) {
      case userRoles.ROLE_WARDEN: {
        return theme.userColors.warden;
      }
      case userRoles.ROLE_KEEPER: {
        return theme.userColors.keeper;
      }
      case userRoles.ROLE_COMPANY: {
        return theme.userColors.company;
      }
      case userRoles.ROLE_USER: {
        return theme.userColors.user;
      }
      default: {
        return theme.userColors.anonymous;
      }
    }
  }};
  border-radius: 5px;
`;
const AvatarIconWrapper = styled.div`
  position: relative;
  top: 10px;
  left: -5px;
`;
const AvatarIconTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  height: 100%;
  margin: 10px 10px 10px 40px;
`;
const AvatarIconText = styled(Text)`
  font-family: 'Montserrat', sans-serif;
  margin: 0;
`;
const AvatarDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const AvatarCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  display: block;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    display: none;
  }
`;

const AvatarDetailsModal = ({ isAnonymous, details, top, closeModal, onMouseEnter, onMouseLeave }) => {
  const { avatar, role, email, phone, display_name: username, apartments } = details[0];
  const displayRole = userRole => {
    const role = userRoles[userRole];
    return userLabels[role];
  };

  return (
    <AvatarEventWrapper top={top} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <AvatarWrapper>
        <AvatarLabelWrapper userRole={role}>
          <AvatarIconWrapper>
            <AvatarIcon
              path={!avatar ? '' : avatar.path}
              userRole={role}
              userName={username}
              width="70px"
              height="70px"
              showBadge={false}
              borderWidth="3px"
            />
          </AvatarIconWrapper>
          <AvatarIconTextWrapper>
            <AvatarIconText small>{username}</AvatarIconText>
            <Text small>{displayRole(role)}</Text>
          </AvatarIconTextWrapper>
          <AvatarCloseIcon>
            <ExitIcon onClick={closeModal} fill="#ffffff" />
          </AvatarCloseIcon>
        </AvatarLabelWrapper>
        <AvatarDetailsWrapper>
          {!isAnonymous && <AvatarDetails apartments={apartments} email={email} phone={phone} />}
        </AvatarDetailsWrapper>
      </AvatarWrapper>
    </AvatarEventWrapper>
  );
};

AvatarDetailsModal.propTypes = {
  isAnonymous: PropTypes.bool,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({
        path: PropTypes.string,
      }),
      display_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      role: PropTypes.string,
      apartments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          display_name: PropTypes.string,
        })
      ),
    })
  ),
  top: PropTypes.bool,
  closeModal: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

AvatarDetailsModal.defaultProps = {
  isAnonymous: true,
  details: [],
  top: true,
  closeModal: null,
  onMouseEnter: null,
  onMouseLeave: null,
};

export default AvatarDetailsModal;
