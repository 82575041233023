import React from 'react';

const ToolboxTutorial = () => (
  <svg
    viewBox="0 0 65 56"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h64.627v55.634H0z" />
    <g fill="#ffffff">
      <path d="M17.302 17.576a1.633 1.633 0 0 1 2.317 0l31.325 28.307c.64.643.64 1.687 0 2.329l-5.476 5.504a1.633 1.633 0 0 1-2.317 0L14.985 22.233a1.654 1.654 0 0 1 0-2.329l2.317-2.328zM41.834 44.56a2.636 2.636 0 0 1 3.739 0 2.668 2.668 0 0 1 0 3.758 2.636 2.636 0 0 1-3.739 0 2.668 2.668 0 0 1 0-3.758zM22.242 1.938a1.703 1.703 0 0 0-2.416 0l-2.415 2.427a1.723 1.723 0 0 0 0 2.428l7.246 7.282c.667.67 1.749.67 2.415 0l2.416-2.427a1.725 1.725 0 0 0 0-2.428l-7.246-7.282z" />
      <path d="M19.991 7.576a1.143 1.143 0 0 0-1.623 0l-1.623 1.631a1.16 1.16 0 0 0 0 1.631l3.888 3.906a1.141 1.141 0 0 0 1.622 0l1.623-1.631c.448-.45.448-1.18 0-1.631l-3.887-3.906z" />
      <path d="M13.78 8.014a2.4 2.4 0 0 1 3.402 0l7.96 8.001c-3.454.59-7.71 2.471-11.331 5.644l-2.221 2.233c-.036.036-.072.07-.109.103-3.185 3.65-5.072 7.952-5.661 11.441C1.085 30.677.554 23.284 4.227 17.935c.116-.322.303-.623.559-.881l8.994-9.04z" />
      <path d="M62.457 12.197a11.684 11.684 0 0 1-3.053 11.085c-4.541 4.564-11.916 4.564-16.458 0-4.541-4.565-4.541-11.977 0-16.542a11.55 11.55 0 0 1 11.029-3.068l-8.11 8.152 1.372 7.145 7.11 1.38 8.11-8.152z" />
      <path d="M48.624 19.904c.64.642.64 1.686 0 2.329L20.458 53.716a1.633 1.633 0 0 1-2.317 0l-5.476-5.504a1.653 1.653 0 0 1 0-2.329L43.99 17.575a1.633 1.633 0 0 1 2.317 0l2.317 2.329zM21.775 44.56a2.668 2.668 0 0 1 0 3.758 2.636 2.636 0 0 1-3.739 0 2.668 2.668 0 0 1 0-3.758 2.636 2.636 0 0 1 3.739 0z" />
    </g>
  </svg>
);

export default ToolboxTutorial;
