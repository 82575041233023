import React, { Component } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Text, H1, LinkedItem } from '../../../layout/theme/components';
import LevelImages from './LevelImage';
import LevelThumbnail from './LevelThumbnail';

import { statusLabels } from '../../../helpers/constants';
import { levelLabels } from '../../../helpers/constants';
import TrialButton from './TrialButton';
import CheckMark from '../../../../assets/features/checkMark';

const PanelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 1em 0;
  padding: 1.5em 1.8em;
  border-radius: 15px;
  background: #f5f5f5;
  background: ${({ invert, theme }) =>
    invert ? theme.buttonColors.secondary : '#FFFFFF'};
  color: ${({ invert, theme }) =>
    invert ? '#FFFFFF' : theme.textColors.secondary};
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.16);
  flex: 1;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 600px;
    margin: 1.5em 0;
  }
`;
const PictureBox = styled.div`
  margin-bottom: 0.5em;

  svg {
    height: 10vh;
  }
`;
const LevelStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;
const StyledHeader = styled(H1)`
  margin: 0;
`;
const StyledAlert = styled(Text)`
  color: ${({ highlighted, theme }) =>
    highlighted ? '#FABB4A' : theme.labels.positive};

  svg {
    margin-left: 0.4em;
  }
`;
const StatusAlert = styled.div`
  flex-direction: column;
  text-align: right;
`;
const MoreLink = styled(LinkedItem)`
  font-weight: 400;
  color: ${({ invert }) => (invert ? '#ffffff' : 'inherit')};
`;

class StatusPanel extends Component {
  render() {
    const { level, status, premium } = this.props;
    const active = status === 'active';
    const trial = status === 'trial';
    const verification = status === 'verification';

    return (
      <PanelWrapper invert={premium}>
        <LevelStatus>
          <StyledHeader>{levelLabels[level]}</StyledHeader>
          {premium && !active ? (
            <StatusAlert>
              <TrialButton showInfo />
            </StatusAlert>
          ) : (
            <StyledAlert small highlighted={verification}>
              {statusLabels[status]}
              {active && <CheckMark />}
            </StyledAlert>
          )}
        </LevelStatus>
        <PictureBox>
          {active || trial ? (
            <LevelImages level={level} />
          ) : (
            <LevelThumbnail level={level} status={status} />
          )}
        </PictureBox>
        <Link to={`/poziomy/${level}`}>
          <MoreLink invert={premium}>Dowiedz się więcej</MoreLink>
        </Link>
      </PanelWrapper>
    );
  }
}

StatusPanel.propTypes = {
  level: PropTypes.string.isRequired,
  status: PropTypes.string,
  premium: PropTypes.bool
};

StatusPanel.defaultProps = {
  premium: false,
  status: null
};

export default StatusPanel;
