import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Profile from './ProfileComponent';
import { fetchUser } from '../data/user/actions';

class ProfileContainer extends Component {
  handleResourceChange = resourceId => {
    const { fetchUser } = this.props;
    fetchUser(resourceId);
  };

  render() {
    const { profile, currentResource, currentResourceStreet } = this.props;
    if (profile.isLoading || profile.error) return null;
    const avatarPath = profile.data.avatar && profile.data.avatar.path;
    return (
      <Profile
        name={profile.data.display_name}
        resources={profile.data.resources}
        role={profile.data.role}
        avatarPath={avatarPath}
        clickHandler={this.handleResourceChange}
        selected={currentResource}
        currentResourceStreet={currentResourceStreet}
      />
    );
  }
}

ProfileContainer.propTypes = {
  profile: PropTypes.object.isRequired,
  currentResourceStreet: PropTypes.string.isRequired,
  currentResource: PropTypes.number
};

ProfileContainer.defaultProps = {
  currentResource: null
};

const mapStateToProps = state => ({
  profile: state.user.profile,
  currentResource: state.user.currentResource,
  currentResourceStreet: state.user.currentDataResource.data
    ? state.user.currentDataResource.data.display_name
    : ''
});

const mapDispatchToProps = dispatch => ({
  fetchUser: resourceId => dispatch(fetchUser(resourceId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
