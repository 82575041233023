import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TextInput from '../../shared/components/forms/TextInput';
import Button from '../../shared/components/buttons/PrimaryButton';
import Detective from '../../../assets/detective';
import Textarea from '../../shared/components/forms/Textarea';
import { H2 } from '../../layout/theme/components';

const sectionStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  ${sectionStyle};
  span {
    font-size: 0.7em;
    font-weight: 400;
  }
`;

const Form = styled.form`
  ${sectionStyle};
  flex: 1;
  svg {
    margin: 2% 0;
    height: 22vh;
    flex: 1;
  }
`;

const CulpritComponent = props => (
  <Fragment>
    <Header>
      <H2>
        Wiesz kto był sprawcą?
        <br />
        <span>(Ta informacja wyświetli się tylko Zarządcy)</span>
      </H2>
    </Header>
    <Form onSubmit={e => props.submitHandler(e)}>
      <TextInput
        name="address"
        value={props.address}
        placeholder="Adres lub miejsce zdarzenia"
        onChange={e => props.handleChange('address', e.target.value)}
      />
      <Textarea
        value={props.description}
        placeholder="Opis sprawcy (np. imię i nazwisko, numer mieszkania, rysopis)"
        changeHandler={value => props.handleChange('description', value)}
      />
      <Detective />
      <Button type="submit">DALEJ</Button>
    </Form>
  </Fragment>
);

CulpritComponent.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default CulpritComponent;
