import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import PrinterIcon from '../../../../assets/PrinterIcon';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;

  button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    margin-left: 1em;
  }
`;

const ReportButton = memo(({ text }) => (
  <ButtonWrapper>
    <SecondaryButton small inverted>
      {text}
      <PrinterIcon />
    </SecondaryButton>
  </ButtonWrapper>
));

ReportButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default ReportButton;
