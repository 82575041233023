import PropTypes from 'prop-types';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components/macro';
import ThumbDunno from '../../../../assets/thumbDunno';
import ThumbNo from '../../../../assets/thumbNo';
import ThumbYes from '../../../../assets/thumbYes';
import { VOTES } from '../../../helpers/constants';
import { formatVote } from '../../../helpers/tools';

const THUMB_ICONS = {
  positive: <ThumbYes />,
  neutral: <ThumbDunno />,
  negative: <ThumbNo />
};

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  margin: 1em 0;
`;
const ChartBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 47%;
  margin-right: 3%;
`;
const TotalApartmentsBox = styled.div`
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 25px);
  text-align: center;
  font-size: 1.6em;
  width: 100px;
`;
const TotalApartments = styled.div`
  font-size: 26px;
  font-weight: 600;
  margin: 0;

  small {
    margin: 0 3px;
    font-size: 16px;
    font-weight: 400;
  }
`;
const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 50%;
`;

const ResultChart = props => {
  const {
    totalApartments,
    votedApartments,
    positiveVotes,
    negativeVotes,
    neutralVotes
  } = props;

  const positiveVotesPercent = { name: 'za', value: positiveVotes };
  const negativeVotesPercent = { name: 'przeciw', value: negativeVotes };
  const noVotedPercent = { name: 'brak głosu', value: neutralVotes };

  const circleColors = [
    VOTES['positive'].color,
    VOTES['negative'].color,
    VOTES['neutral'].color
  ];

  const data = [positiveVotesPercent, negativeVotesPercent, noVotedPercent];
  const newData = data.filter(el => el !== null);
  const totalApartmentsTextEnd =
    totalApartments === 1 ? '' : totalApartments === 2 ? 'e' : 'i';
  return (
    <>
      <ChartWrapper>
        <ChartBox>
          {totalApartments && (
            <TotalApartmentsBox>
              <TotalApartments>
                {votedApartments}
                {votedApartments && <small>z</small>}
                {totalApartments}
              </TotalApartments>
              Lokal
              {totalApartmentsTextEnd}
            </TotalApartmentsBox>
          )}
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={newData}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
                cx="50%"
                cy="50%"
                innerRadius={'70%'}
                outerRadius={'100%'}
                paddingAngle={0}
                isAnimationActive={false}
                valueKey="name"
                labelLine={false}
              >
                {circleColors.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={circleColors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </ChartBox>
        <Legend>
          <VotePanel
            type="positive"
            vote={positiveVotes}
            label={'głosowało za'}
          />
          <VotePanel
            type="negative"
            vote={negativeVotes}
            label={'głosowało przeciw'}
          />
          <VotePanel type="neutral" vote={neutralVotes} label={'brak głosu'} />
        </Legend>
      </ChartWrapper>
    </>
  );
};

ResultChart.propTypes = {
  positiveVotes: PropTypes.number.isRequired,
  neutralVotes: PropTypes.number.isRequired,
  negativeVotes: PropTypes.number.isRequired,
  totalApartments: PropTypes.number,
  votedApartments: PropTypes.number
};

ResultChart.defaultProps = {
  totalApartments: null,
  votedApartments: null
};

export default ResultChart;

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 1.3em;
  margin: 0.5em 0;
  width: 100%;
  min-height: 50px;
  color: #ffffff;
  background: ${({ color }) => color};
`;
const PanelThumb = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
    max-height: 30px;
    max-width: 30px;
    fill: #ffffff;
  }
`;
const PanelLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: right;
  font-size: 1.3em;
`;
const Vote = styled.span`
  font-size: 1.4em;
`;

const VotePanel = props => {
  const { vote, label, type } = props;

  return (
    <PanelWrapper color={VOTES[type].color}>
      <PanelThumb>{THUMB_ICONS[type]}</PanelThumb>
      <PanelLabel>
        <Vote>{formatVote(vote)}</Vote>
        {label}
      </PanelLabel>
    </PanelWrapper>
  );
};

VotePanel.propTypes = {
  vote: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
