import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import FinancesComponent from './FinancesComponent';

import FinancesLoginContainer from './login/FinancesLoginContainer';
import FinancesBalanceContainer from './balance/FinancesBalanceContainer';

import { financesStatus } from '../helpers/constants';

const FinancesContainer = ({
  profile: {
    data: { financial_data_status: status },
    isLoading
  }
}) => {
  const renderFinancePage = () => {
    switch (status) {
      case financesStatus.LACK_OF_INTEGRATION:
        return <Redirect push to="/funkcje/finances" />;
      case financesStatus.NO_INTEGRATION_WITH_WELES:
        return <FinancesLoginContainer />;
      case financesStatus.SUCCESS_INTEGRATION:
        return <FinancesBalanceContainer />;
      default:
        return <Error />;
    }
  };

  const isPremiumColor = () =>
    status === financesStatus.NO_INTEGRATION_WITH_WELES;

  if (isLoading) return <Loader />;

  return (
    <FinancesComponent premiumColor={isPremiumColor()}>
      {renderFinancePage()}
    </FinancesComponent>
  );
};

FinancesContainer.propTypes = {
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.user.profile
});

export default connect(
  mapStateToProps,
  null
)(FinancesContainer);
