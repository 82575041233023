import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 100%;
  margin-left: 0.5em;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: auto;
  }
`;
const Label = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.3em;
  color: #ffffff;
`;

const ResolutionEvent = ({ eventType, triggeredBy }) => {
  function getEventContent() {
    const { display_name, gender } = triggeredBy;
    const closeEvent = gender === 'F' ? 'zamknęła' : 'zamknął';
    const invalidateEvent = gender === 'F' ? 'unieważniła' : 'unieważnił';
    const eventName = eventType === 'close' ? closeEvent : invalidateEvent;
    return `${display_name} ${eventName} głosowanie`;
  }

  return (
    <Wrapper>
      {triggeredBy ? (
        <Label>{getEventContent()}</Label>
      ) : (
        <Label>
          {eventType === 'close' ? 'Zamknięcie' : 'Unieważnienie'} głosowania
        </Label>
      )}
    </Wrapper>
  );
};

ResolutionEvent.propTypes = {
  eventType: PropTypes.string.isRequired,
  triggeredBy: PropTypes.shape({
    display_name: PropTypes.string,
    gender: PropTypes.string
  })
};
ResolutionEvent.defaultProps = {
  triggeredBy: null
};

export default ResolutionEvent;
