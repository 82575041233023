import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import PageBreak from './PageBreak';

const Content = styled.div`
  text-align: left;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.2em;
  }
  th,
  td {
    border: 1px solid black;
    padding: 0.5em;
  }
`;

const ReportContent = ({ content }) => (
  <>
    <Content dangerouslySetInnerHTML={{ __html: content }} />
    {content && <PageBreak />}
  </>
);

ReportContent.propTypes = {
  content: PropTypes.string
};

ReportContent.defaultProps = {
  content: ''
};

export default ReportContent;
