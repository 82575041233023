import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-google';
import styled from 'styled-components';
import Button from './buttons/PrimaryButton';
import Loader from './Loader';

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2em;
`;
const CaptchaWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 10% 0;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 5% 0;
  }
`;

const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;

class Captcha extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isHuman: false
    };

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.render();
    }
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.render();
    }
  }

  verifyCallback(recaptchaToken) {
    recaptchaToken && this.setState({ isHuman: true });
  }

  render() {
    const { isHuman } = this.state;
    const { children, loading } = this.props;

    return (
      <CaptchaWrapper>
        <ReCaptcha
          ref={el => {
            this.captchaDemo = el;
          }}
          size="small"
          data-badge="inline"
          render="explicit"
          sitekey={captchaKey}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <Button disabled={!isHuman} type="submit">
            {children}
          </Button>
        )}
      </CaptchaWrapper>
    );
  }
}
export default Captcha;
