import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fetchCasePreview } from '../../helpers/api/case';
import Loader from '../../shared/components/Loader';
import Error from '../../shared/Error';
import VotePremiumComponent from './VotePremiumComponent';

class VotePremiumContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: {},
      isLoading: true,
      error: null
    };
  }

  componentDidMount() {
    this.getCasePreview();
  }

  getCasePreview() {
    const { caseId } = this.props;
    this.setState({ isLoading: true });

    fetchCasePreview(caseId)
      .then(data => this.setState({ preview: data.data, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { preview, isLoading, error } = this.state;
    const { caseId } = this.props;

    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <VotePremiumComponent
        soundingQuestion={preview.sounding_question}
        soundingResults={preview.sounding}
        userVote={preview.user_vote}
        caseId={caseId}
      />
    );
  }
}

VotePremiumContainer.propTypes = {
  caseId: PropTypes.number.isRequired
};

export default VotePremiumContainer;
