import React from 'react';

const Attachment = () => (
  <svg
    viewBox="0 0 23 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <path fill="none" d="M0 0h22.587v25H0z" />
    <clipPath id="a">
      <path d="M0 0h22.587v25H0z" />
    </clipPath>
    <g>
      <path
        d="M19.396 15.401L9.8 5.436c-1.599-1.658-3.601-1.797-5-.343-1.393 1.445-1.26 3.532.33 5.185l8.639 8.97a.741.741 0 0 0 1.061 0 .806.806 0 0 0 0-1.108L6.199 9.17c-.773-.805-1.267-2.005-.332-2.976.928-.964 2.091-.451 2.866.352l9.594 9.965c2.755 2.86 3.441 4.182 1.807 5.887-1.665 1.721-3.426 1.294-6.069-1.453L3.399 9.865c-1.723-1.79-2.93-4.711-.662-7.058C5.426.015 8.49 2.76 9.366 3.67l9.802 10.179a.728.728 0 0 0 1.06 0 .804.804 0 0 0 0-1.109L10.433 2.562C7.473-.514 4.114-.843 1.67 1.697c-2.473 2.562-2.202 6.294.662 9.276l10.664 11.075c.914.939 2.85 2.952 5.083 2.952 1.001 0 2.068-.405 3.122-1.5 2.997-3.112.22-5.996-1.805-8.099z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Attachment;
