import React from 'react';

const layerCake = () => {
  return (
    <svg
      viewBox="0 0 84 72"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h83.906v71.038H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h83.906v71.038H0z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="M41.953 41.889c23.154 0 41.953 6.531 41.953 14.575s-18.8 14.575-41.953 14.575C18.8 71.039 0 64.507 0 56.462s18.798-14.573 41.953-14.573z"
          fill="#56dbbe"
          fillRule="nonzero"
        />
        <path
          d="M75.236.531H50.16v14.594h25.076l-5.11-7.541 5.11-7.053z"
          fill="#56dabe"
          fillRule="nonzero"
        />
        <path
          d="M50.236.005l.074.016.071.027.067.036.061.046.053.053.046.061.036.067.027.071.016.074.006.075V29.72l-.006.075-.016.074-.027.071-.036.067-.046.061-.053.054-.061.045-.067.036-.071.027-.074.016-.075.005-.076-.005-.074-.016-.071-.027-.067-.036-.061-.045-.053-.054-.045-.061-.036-.067-.027-.071-.016-.074-.005-.075V.531l.005-.075.016-.074.027-.071.036-.067.045-.061.053-.053.061-.046.067-.036.071-.027.074-.016.076-.005.075.005z"
          fill="#4b5081"
          fillRule="nonzero"
        />
        <text
          x="55.62"
          y="10.177"
          fontFamily="'Quicksand-Bold','Quicksand'"
          fontWeight={700}
          fontSize={8}
          fill="#fff"
        >
          1%
        </text>
        <path
          d="M21.316 26.634l46.19.464v31.145c-2.372 2.51-7.2 4.646-11.976 6.184V32.364l-34.214-5.73z"
          fill="#857abc"
          fillRule="nonzero"
        />
        <path
          d="M21.225 26.603l34.353 5.381v32.441l-34.353-5.381V26.603z"
          fill="#fbcc6b"
          fillRule="nonzero"
        />
        <path
          d="M21.225 36.993l34.353 5.785v10.065l-34.353-5.784V36.993z"
          fill="#f498ac"
          fillRule="nonzero"
        />
        <path
          d="M67.031 26.633c-.261.053-.5.181-.689.368-.161.151-.185.17-.274.245a20.816 20.816 0 0 1-6.9 3.428c-2.586.886-4.829 1.5-4.829 1.5a1.165 1.165 0 0 0-.719 1.589 1.06 1.06 0 0 0 1.085.526c6.422-1 2.358-.192 8.313-2.817a16.402 16.402 0 0 0 4.822-2.967c.225-.204.354-.494.354-.798a1.082 1.082 0 0 0-1.163-1.074z"
          fill="#f8bf56"
          fillRule="nonzero"
        />
        <path
          d="M52.338 47.788a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm-3.411-4.143a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm-3.407 4.357a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001z"
          fill="#59e9c9"
          fillRule="nonzero"
        />
        <path
          d="M42.94 45.534a.363.363 0 0 0-.348.359v.001c0 .185.152.336.336.336h.012a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm2.735-1.788a.363.363 0 0 0-.348.359v.002c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.001a.337.337 0 0 0-.336-.336h-.012zm-6.43-2.584a.363.363 0 0 0-.348.359v.002c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm10.078 7.558a.363.363 0 0 0-.348.359v.002c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm3.839-4.51a.363.363 0 0 0-.348.359v.001c0 .185.152.336.336.336h.012a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm0 5.902a.363.363 0 0 0-.348.359v.001c0 .185.152.336.336.336h.012a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zM22.82 39.92a.363.363 0 0 0-.348.359v.002c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm12.981.507h.012c.184 0 .336.151.336.336v.001a.363.363 0 0 1-.348.359h-.013a.336.336 0 0 1-.335-.335v-.002a.363.363 0 0 1 .348-.359zm-7.246 1.042a.363.363 0 0 0-.348.359v.002c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm1.512.865h.013c.184 0 .335.151.335.335v.002a.363.363 0 0 1-.348.359h-.013a.336.336 0 0 1-.335-.335v-.002a.363.363 0 0 1 .348-.359zm2.255 3.313h.013c.184 0 .335.151.335.335v.002a.363.363 0 0 1-.348.359h-.012a.337.337 0 0 1-.336-.336v-.001a.363.363 0 0 1 .348-.359zm10.618-3.424a.363.363 0 0 0-.348.359v.001c0 .185.152.336.336.336h.012a.363.363 0 0 0 .348-.359v-.002a.336.336 0 0 0-.335-.335h-.013zm-4.415 4.276a.363.363 0 0 0-.348.359v.001c0 .185.152.336.336.336h.012a.363.363 0 0 0 .348-.359v-.001a.337.337 0 0 0-.336-.336h-.012zM23.52 43.4a.363.363 0 0 0-.348.359v.006c0 .184.151.335.335.335h.013a.363.363 0 0 0 .348-.359v-.006a.336.336 0 0 0-.335-.335h-.013zm11.586.554h.013c.184 0 .335.151.335.335v.002a.363.363 0 0 1-.348.359h-.013a.336.336 0 0 1-.335-.335v-.002a.363.363 0 0 1 .348-.359z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M40.625 43.206a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm-15.417-2.04a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm8.206.656l.029-.001c.368 0 .671.303.671.671v.004a.726.726 0 0 1-.7.717l-.029.001a.674.674 0 0 1-.671-.671v-.004a.726.726 0 0 1 .7-.717zm-4.043 3.221l.029-.001c.368 0 .671.303.671.671v.004a.726.726 0 0 1-.7.717l-.029.001a.674.674 0 0 1-.671-.671v-.004a.726.726 0 0 1 .7-.717zm-2.64-6.349l.029-.001c.368 0 .671.303.671.671v.004a.726.726 0 0 1-.7.717l-.029.001a.674.674 0 0 1-.671-.671v-.004a.726.726 0 0 1 .7-.717zm14.589 8.762a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm-17.912-2.444a.726.726 0 0 0-.7.717v.004c0 .368.303.671.671.671l.029-.001a.726.726 0 0 0 .7-.717v-.004a.674.674 0 0 0-.671-.671l-.029.001zm11.806.55l.029-.001c.368 0 .671.303.671.671v.004a.726.726 0 0 1-.7.717l-.029.001a.674.674 0 0 1-.671-.671v-.004a.726.726 0 0 1 .7-.717z"
          fill="#59e9c9"
          fillRule="nonzero"
        />
        <path
          d="M68.154 27.672l-1.007.031-3.139 2.448-7.733 2.855s-.757-.075-.744.261c-.353-.315-2.253 7.425 1.208 9.225 1.991 2.487.328 5.781 1.761 5.641 3.224-.315-2.081-15.833 5.508-13.474 1.595.5 1.493 2.358 1.171 5.535 0 0-.2 3.989 1.487 3.937a1.551 1.551 0 0 0 1.488-1.534V27.672z"
          fill="#f8bf56"
          fillRule="nonzero"
        />
        <path
          d="M60.622 51.296l3.1-1.4v6.639l-3.1 1.4v-6.639z"
          fill="#fff"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default layerCake;
