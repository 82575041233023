import React, { Component } from 'react';
import { fetchContacts } from '../helpers/api/community';
import { userLabels } from '../helpers/constants';
import Loader from '../shared/components/Loader';
import Error from '../shared/Error';
import Contact from './components/Contact';
import ContactsComponent from './ContactsComponent';

class ContactsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emergencyNumbers: [],
      keepers: [],
      globalContact: {},
      isLoading: false,
      error: null,
      header: ''
    };
  }

  componentDidMount() {
    this.getContacts();
  }

  getContacts() {
    this.setState({ isLoading: true, header: 'Numery kontaktowe' });

    fetchContacts()
      .then(
        data =>
          data.data
            ? this.setState({
                emergencyNumbers: data.data.emergency_numbers,
                keepers: data.data.keepers,
                globalContact: data.data.global_contacts,
                isLoading: false
              })
            : this.setState({ isLoading: false })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }

  renderEmergencyNumbers() {
    return this.state.emergencyNumbers.map((contact, i) => {
      return (
        <Contact
          key={i}
          responsibility={contact.scope_of_responsibility}
          firstName={contact.first_name}
          lastName={contact.last_name}
          phone={contact.phone}
          email={contact.email}
        />
      );
    });
  }

  renderKeepers() {
    return this.state.keepers.map((contact, i) => {
      return (
        <Contact
          key={i}
          responsibility={userLabels[contact.role]}
          firstName={contact.first_name}
          lastName={contact.last_name}
          phone={contact.phone}
          email={contact.email}
        />
      );
    });
  }
  renderGlobalContact() {
    const { globalContact } = this.state;

    return (
      <Contact
        responsibility={globalContact.company_name}
        phone={globalContact.phone}
        email={globalContact.email}
        global
      />
    );
  }

  render() {
    const {
      emergencyNumbers,
      keepers,
      globalContact,
      isLoading,
      error,
      header
    } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <Error />;

    return (
      <ContactsComponent header={header}>
        {emergencyNumbers.length > 0 && this.renderEmergencyNumbers()}
        {keepers.length > 0 && this.renderKeepers()}
        {globalContact && this.renderGlobalContact()}
      </ContactsComponent>
    );
  }
}

export default ContactsContainer;
