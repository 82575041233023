import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { LabelColored, Label } from '../../../layout/theme/components';

const MediaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 5%;
  line-height: 150%;
`;
const BoxLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
`;
const ColorLabel = styled(LabelColored).attrs({
  bold: true
})`
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.textColors.highlighted : theme.textColors.secondary};
`;

const PreviewMedia = ({ media, meterValue }) => (
  <MediaWrapper>
    {media.length > 0 && (
      <BoxLabel>
        <Label>Media:&nbsp;</Label>
        <ColorLabel>{media.join(', ')}</ColorLabel>
      </BoxLabel>
    )}
    {meterValue && (
      <BoxLabel>
        <Label>Stan licznika:&nbsp;</Label>
        <ColorLabel>{meterValue}</ColorLabel>
      </BoxLabel>
    )}
  </MediaWrapper>
);

PreviewMedia.propTypes = {
  media: PropTypes.array,
  meterValue: PropTypes.string
};
PreviewMedia.defaultProps = {
  media: null,
  meterValue: ''
};

export default PreviewMedia;
