import * as Session from '../localStorage';
import { getAuthHeader, getRequestHeaders, handleResponse } from '../request';

const apiUrl = process.env.REACT_APP_API_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_API_SECRET;

export const fetchRegister = ({
  name,
  email,
  town,
  address,
  termsAccepted
}) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      register: {
        first_name: name,
        email: email,
        town: town,
        address: address,
        terms_accepted: termsAccepted
      }
    })
  };

  return fetch(`${apiUrl}/user/register`, requestOptions).then(handleResponse);
};

export const fetchLogin = (username, password) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
      grant_type: 'password'
    })
  };

  return fetch(`${apiUrl}/oauth/v2/token`, requestOptions)
    .then(handleResponse)
    .then(json => {
      const token = json.data.access_token;
      if (token) {
        Session.saveAccessToken(JSON.stringify(token));
      }
      return json;
    });
};

export const fetchProfile = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/user/profile`, requestOptions).then(handleResponse);
};

export const fetchLogout = () => {
  const requestOptions = {
    method: 'DELETE',
    headers: getAuthHeader()
  };
  return fetch(`${apiUrl}/oauth/v2/logout`, requestOptions).then(response => {
    Session.resetLocalStorage();
    return response;
  });
};

export const fetchPassword = (username, remindType) => {
  const requestOptions = {
    method: 'POST',
    headers: getAuthHeader(),
    body: JSON.stringify({
      remind_pin: {
        username: username,
        remind_type: remindType
      }
    })
  };
  return fetch(`${apiUrl}/oauth/v2/remind`, requestOptions).then(
    handleResponse
  );
};

export const fetchCompleteTutorial = () => {
  const requestOptions = {
    method: 'PUT',
    headers: getAuthHeader()
  };
  return fetch(`${apiUrl}/user/tutorial/complete`, requestOptions).then(
    handleResponse
  );
};

export const changePassword = (oldPassword, newPassword, newPasswordRepeat) => {
  const requestOptions = {
    method: 'PUT',
    headers: getAuthHeader(),
    body: JSON.stringify({
      change_password: {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_repeat: newPasswordRepeat
      }
    })
  };
  return fetch(`${apiUrl}/user/change-password`, requestOptions).then(
    handleResponse
  );
};

export const saveAvatar = avatar => {
  const requestOptions = {
    method: 'PUT',
    headers: getAuthHeader(),
    body: JSON.stringify({
      user_avatar: {
        avatar: avatar
      }
    })
  };
  return fetch(`${apiUrl}/user/profile`, requestOptions).then(handleResponse);
};

export const deleteAccount = () => {
  const requestOptions = {
    method: 'DELETE',
    headers: getAuthHeader()
  };
  return fetch(`${apiUrl}/user`, requestOptions).then(handleResponse);
};

export const fetchDataError = desc => {
  const requestOptions = {
    method: 'POST',
    headers: getAuthHeader(),
    body: JSON.stringify({
      data_error: {
        content: desc
      }
    })
  };
  return fetch(`${apiUrl}/application/data-error`, requestOptions).then(
    handleResponse
  );
};

export const fetchFriend = (name, email) => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      friend_invitation: {
        sender_name: name,
        friends: email
      }
    })
  };
  return fetch(`${apiUrl}/user/friend`, requestOptions).then(handleResponse);
};

export const notifyKeeper = () => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/user/keeper/notification`, requestOptions).then(
    handleResponse
  );
};

export const activeUser = token => {
  const requestOptions = {
    method: 'PUT',
    headers: getAuthHeader(),
    body: JSON.stringify({
      register_token: {
        token: token
      }
    })
  };
  return fetch(`${apiUrl}/user/register`, requestOptions).then(handleResponse);
};

export const fetchUsersToMention = query => {
  const urlEnd = query ? `/${query}` : '';
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/user/company/search${urlEnd}`, requestOptions).then(
    handleResponse
  );
};

export const tagUser = (type, users, communityCase, comment) => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      users_community_case: {
        type: type,
        users: users,
        community_case: communityCase,
        comment: comment
      }
    })
  };
  return fetch(`${apiUrl}/user/tag`, requestOptions).then(handleResponse);
};

export const untagUser = communityCase => {
  const requestOptions = {
    method: 'DELETE',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/user/untag/${communityCase}`, requestOptions).then(
    response => handleResponse(response)
  );
};

export const fetchUserDetails = id => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };
  return fetch(`${apiUrl}/users?users[]=${id}`, requestOptions).then(response =>
    handleResponse(response)
  );
};
