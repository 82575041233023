import { getRequestHeaders, handleResponse } from '../request';
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCases = (page, filter, archived = 0) => {
  let filterQuery = '';
  switch (filter) {
    case 'allCases':
      filterQuery = 'my_cases=false';
      break;
    case 'myCases':
      filterQuery = 'my_cases=true';
      break;
    case 'resolutions':
      filterQuery = 'resolutions=true';
      break;
    default:
      filterQuery = 'my_cases=false';
  }
  if (archived) filterQuery = `${filterQuery}&archived=1`;

  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case?page=${page}&${filterQuery}`, requestOptions).then(handleResponse);
};
export const searchCases = (page, query) => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/search?query=${query}&page=${page}`, requestOptions).then(handleResponse);
};
export const createCase = category => {
  const requestOptions = {
    method: 'POST',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      community_case: { category },
    }),
  };
  return fetch(`${apiUrl}/case`, requestOptions).then(handleResponse);
};
export const removeCase = caseId => {
  const requestOptions = {
    method: 'DELETE',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}`, requestOptions).then(handleResponse);
};
export const fetchCurrentStep = caseId => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/step`, requestOptions).then(handleResponse);
};
export const fetchLastStep = caseId => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/last`, requestOptions).then(handleResponse);
};
export const fetchNextStep = (caseId, stepName) => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/${stepName}/next`, requestOptions).then(handleResponse);
};
export const fetchPreviousStep = (caseId, stepName) => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/${stepName}/previous`, requestOptions).then(handleResponse);
};
export const fetchPreviewStep = caseId => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/preview`, requestOptions).then(handleResponse);
};
export const fetchCasePreview = id => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${id}`, requestOptions).then(response => handleResponse(response));
};
export const publishCase = caseId => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/publish`, requestOptions).then(handleResponse);
};
export const changeCaseStatus = (caseId, status) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/status/${status}`, requestOptions).then(handleResponse);
};
export const saveAnonymousStep = (caseId, anonymous) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_anonymous: { anonymous },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/anonymous`, requestOptions).then(handleResponse);
};
export const saveTopicStep = (caseId, topic, description, attachmentIds) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_topic: {
        topic,
        description,
        attachments: attachmentIds,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/topic`, requestOptions).then(handleResponse);
};
export const saveSoundingStep = (caseId, sounding, question) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_sounding: {
        sounding,
        question,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/sounding`, requestOptions).then(handleResponse);
};
export const savePlaceStep = (caseId, placeId) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_place: {
        place: placeId,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/place`, requestOptions).then(handleResponse);
};
export const saveCulpritStep = (caseId, description, address) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_culprit: {
        description,
        address,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/culprit`, requestOptions).then(handleResponse);
};

export const saveMeetingStep = (caseId, datetime, place) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_meeting: {
        time: datetime,
        place,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/meeting`, requestOptions).then(handleResponse);
};

export const saveRangeStep = (caseId, rangeId) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_range: {
        range: rangeId,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/range`, requestOptions).then(handleResponse);
};
export const saveMediaStep = (caseId, mediaId, value, attachmentId) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_media: {
        media: mediaId,
        meter_value: value,
        meter_photo: attachmentId,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/media`, requestOptions).then(handleResponse);
};
export const saveResolutionTemplate = (caseId, template, subtype, file) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_choice_resolution_template: {
        template,
        subtype,
        file,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/choice_resolution_template`, requestOptions).then(handleResponse);
};
export const saveResolution = (caseId, title, content, attachments) => {
  const requestData = {
    title,
    attachments,
  };
  if (content) requestData.content = content;
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      step_view_prepare_resolution: requestData,
    }),
  };
  return fetch(`${apiUrl}/case/${caseId}/step/prepare_resolution`, requestOptions).then(handleResponse);
};
export const voteInSounding = (vote, caseId) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({ sounding: { vote } }),
  };
  return fetch(`${apiUrl}/case/${caseId}/sounding`, requestOptions).then(handleResponse);
};
export const saveCost = (communityCase, cost) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      community_case_cost: { cost },
    }),
  };
  return fetch(`${apiUrl}/case/${communityCase}/cost `, requestOptions).then(handleResponse);
};
export const fetchTutorial = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/tutorial/`, requestOptions).then(response => handleResponse(response));
};
export const fetchTaggedCompany = caseId => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/case/${caseId}/company`, requestOptions).then(response => handleResponse(response));
};
export const voteInResolution = (vote, communityCase) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      resolution: { vote },
    }),
  };
  return fetch(`${apiUrl}/case/${communityCase}/resolution`, requestOptions).then(handleResponse);
};
export const fetchUnvotedResolutions = () => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/waiting`, requestOptions).then(response => handleResponse(response));
};
export const getOccupantsVotes = communityCase => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/${communityCase}/apartments`, requestOptions).then(handleResponse);
};
export const voteForSomeone = (communityCase, user, attachment, vote) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      resolution_other_user: {
        user,
        attachment,
        vote,
      },
    }),
  };
  return fetch(`${apiUrl}/case/${communityCase}/resolution/other-user`, requestOptions).then(handleResponse);
};
export const closeVoting = communityCase => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/${communityCase}/close`, requestOptions).then(handleResponse);
};
export const fetchResolutionReport = communityCase => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/${communityCase}/report`, requestOptions).then(handleResponse);
};

export const fetchUserVote = communityCase => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders(),
  };
  return fetch(`${apiUrl}/resolution/${communityCase}/resource-vote`, requestOptions).then(handleResponse);
};

export const invalidateVoting = (communityCase, invalidateDescription) => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders(),
    body: JSON.stringify({
      invalidate: {
        invalidate_description: invalidateDescription,
      },
    }),
  };
  return fetch(`${apiUrl}/resolution/${communityCase}/invalidate`, requestOptions).then(handleResponse);
};
