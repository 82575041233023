import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2em 0;
`;

export const ArchivesWrapper = props => {
  return <Wrapper>{props.children}</Wrapper>;
};
