import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

const resolutionStyle = css`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  text-align: center;
  z-index: 100;
  background: ${({ theme }) => theme.backgroundColors.primary};
  padding: 1em 0.8em;
  box-shadow: ${({ theme }) => theme.boxShadow.button};
  color: ${({ theme }) => theme.textColors.secondary};
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 400px;
  }
`;
const noResolutionStyle = css`
  padding: 5%;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 2% 15%;
  }
`;
const Wrapper = styled.div`
  ${({ resolution }) => (resolution ? resolutionStyle : noResolutionStyle)};
`;

const VotingWrapper = ({ resolution, children }) => (
  <Wrapper resolution={resolution}>{children}</Wrapper>
);

VotingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  resolution: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

VotingWrapper.defaultProps = {
  resolution: false
};

export default VotingWrapper;
