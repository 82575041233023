import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import pdfIcon from './../../../assets/pdfIcon.svg';

const thumbnailStyle = css`
  width: ${({ small }) => (small ? '30px' : '40px')};
  height: ${({ small }) => (small ? '30px' : '40px')};
  margin: ${({ small }) => (small ? '0' : '.5em')};
`;
const ImageThumbnail = styled.div`
  ${thumbnailStyle};
  position: relative;
  background: ${({ path }) => `url(${path}) no-repeat center`};
  background-size: cover;
  cursor: pointer;
`;
const PdfThumbnail = styled.div`
  cursor: pointer;
  ${thumbnailStyle};
  background: ${({ path }) => `url(${path}) no-repeat center`};
  background-size: cover;
`;

const Thumbnail = ({ path, onClick, small, type }) => {
  return (
    <React.Fragment>
      {type === 'application/pdf' ? (
        <PdfThumbnail onClick={onClick} small={small} path={pdfIcon} />
      ) : (
        <ImageThumbnail path={path} onClick={onClick} small={small} />
      )}
    </React.Fragment>
  );
};

Thumbnail.propTypes = {
  path: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  type: PropTypes.string
};

Thumbnail.defaultProps = {
  path: '',
  onClick: null,
  small: false,
  type: ''
};

export default Thumbnail;
