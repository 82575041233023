import React from 'react';

function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.657 22.738"
      fill="#574D85"
    >
      <path d="M9.329 22.738a2.339 2.339 0 002.332-2.332H7a2.331 2.331 0 002.329 2.332zm7-7v-5.83c0-3.58-1.912-6.577-5.247-7.37v-.789a1.75 1.75 0 00-3.5 0v.793c-3.347.793-5.247 3.778-5.247 7.37v5.83L0 18.074v1.166h18.657v-1.166z" />
    </svg>
  );
}

export default NotificationIcon;
