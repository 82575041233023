import React from 'react';

const correctIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.12"
    height="11.01"
    viewBox="0 0 14.12 11.01"
  >
    <defs />
    <path
      fill="none"
      stroke="#57c543"
      strokeLinecap="round"
      strokeWidth="2px"
      d="M4445.185,880.94l3.11,3.111,8.182-8.182"
      transform="translate(-4443.771 -874.454)"
    />
  </svg>
);

export default correctIcon;
