import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import VotingContainer from '../../case/sounding/VotingContainer';
import { levelLabels } from '../../helpers/constants';
import { clickedInviteNeighbours } from '../../helpers/tools';
import { H0, Text } from '../../layout/theme/components';
import PrimaryButton from '../../shared/components/buttons/PrimaryButton';
import UpgradeStepWrapper from './components/UpgradeStepWrapper';

const StyledHeader = styled(H0)`
  color: ${({ theme }) => theme.backgroundColors.primary};
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
  }
`;
const StyledText = styled(Text)`
  span {
    color: ${({ theme }) => theme.textColors.quaternary};
    font-weight: bold;
  }
`;

const VotePremiumComponent = ({ soundingQuestion, soundingResults, userVote, caseId }) => {
  return (
    <Fragment>
      <StyledHeader>
        Wypróbuj <span>{levelLabels['premium']}</span>
      </StyledHeader>
      <UpgradeStepWrapper>
        <VotingContainer
          caseId={caseId}
          question={soundingQuestion}
          canVote
          soundingResults={soundingResults}
          userVote={userVote}
          showResults
        />
        {/* TODO add resolution */}
        {/* <VoteThumbnail path="/" /> */}
      </UpgradeStepWrapper>
      <StyledText>
        Czy wiesz, że do podjęcia uchwały <br />
        potrzeba <span>powyżej 50% głosów na tak</span>?
      </StyledText>
      <Link to="/zaproszenie">
        <PrimaryButton onClick={clickedInviteNeighbours()} small>
          ZAPROŚ SĄSIADÓW
        </PrimaryButton>
      </Link>
    </Fragment>
  );
};

VotePremiumComponent.propTypes = {
  soundingQuestion: PropTypes.string.isRequired,
  soundingResults: PropTypes.object.isRequired,
  caseId: PropTypes.number.isRequired,
  userVote: PropTypes.number,
};

VotePremiumComponent.defaultProps = {
  userVote: null,
};

export default VotePremiumComponent;
