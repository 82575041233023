import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearQuery } from '../../data/case/actions';
import { saveTopicStep } from '../../helpers/api/case';
import { postMedia } from '../../helpers/api/media';
import { tagUser } from '../../helpers/api/user';
import { mentionAlert } from '../../helpers/constants';
import { maxChars } from '../../helpers/validation';
import TopicComponent from './TopicComponent';

class TopicContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        topic: props.topic ? props.topic : '',
        description: props.description ? props.description : ''
      },
      attachments: [],
      usersToMention: [],
      savedAttachemnts: props.attachments ? props.attachments : [],
      topicValid: true,
      descriptionValid: true,
      attachSizeValid: true,
      error: null
    };

    this.saveStep = this.saveStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { query, clearQuery } = this.props;
    query.length > 0 && this.setState({ form: { topic: query } });
    clearQuery(true);
  }

  saveStep(attachments) {
    const { caseId, handleNextStep } = this.props;
    const {
      usersToMention,
      form: { topic, description }
    } = this.state;
    const mentions = usersToMention.map(user => user.id);

    saveTopicStep(caseId, topic, description, attachments).then(() =>
      tagUser('community_case', mentions, caseId, null).then(() =>
        handleNextStep()
      )
    );
  }

  updateMentions = mentions => this.setState({ usersToMention: mentions });

  getMentionMessage() {
    const { usersToMention } = this.state;
    const usersNames = usersToMention.map(user => user.display);
    let usersToDisplay = `${usersNames.join(', ')}`;

    return mentionAlert(usersToDisplay, usersToMention.length > 1);
  }

  handleChange = (value, field) =>
    value.length < maxChars[field] &&
    this.setState({ form: { ...this.state.form, [field]: value } });

  handleFileChange = (value, sizeValid) =>
    this.setState({
      attachments: [...this.state.attachments, ...value],
      attachSizeValid: sizeValid
    });

  handleRemoveFile = (fileIndex, sizeValid) => {
    const { attachments, savedAttachemnts } = this.state;
    this.setState({ attachSizeValid: sizeValid });

    if (attachments.length > 0) {
      const filteredAttachments = attachments.filter(
        (_, index) => fileIndex !== index
      );
      this.setState({ attachments: filteredAttachments });
    } else {
      const filteredAttachemnts = savedAttachemnts.filter(
        (_, index) => fileIndex !== index
      );
      this.setState({ savedAttachemnts: filteredAttachemnts });
    }
  };

  handleFilesUpload = files => {
    const promises = files.map(file =>
      postMedia(file).then(({ data: { id } }) => id)
    );

    return Promise.all(promises);
  };

  handleSubmit(e) {
    const { attachments, savedAttachemnts } = this.state;
    const { setLoading } = this.props;
    e.preventDefault();
    const savedIds = savedAttachemnts.map(({ id }) => id);

    if (attachments.length > 0) {
      setLoading(true);
      this.handleFilesUpload(attachments)
        .then(data => this.saveStep([...data, ...savedIds]))
        .catch(error => this.setState({ error: error }));
    } else this.saveStep(savedIds);
  }

  render() {
    const {
      form,
      topicValid,
      descriptionValid,
      attachments,
      savedAttachemnts,
      attachSizeValid,
      error,
      usersToMention
    } = this.state;
    const { caseId, communityCost } = this.props;
    const isValid =
      form.topic && topicValid && descriptionValid && attachSizeValid;

    return (
      <TopicComponent
        handleSubmit={this.handleSubmit}
        handleChange={(value, field) => this.handleChange(value, field)}
        handleFileChange={(value, isFileValid) =>
          this.handleFileChange(value, isFileValid)
        }
        isValid={isValid || false}
        topic={form.topic}
        description={form.description}
        files={[...attachments, ...savedAttachemnts]}
        topicValid={topicValid}
        descriptionValid={descriptionValid}
        removeFile={(index, sizeValid) =>
          this.handleRemoveFile(index, sizeValid)
        }
        error={error}
        updateMentions={this.updateMentions}
        mentionMessage={usersToMention.length > 0 && this.getMentionMessage()}
        caseId={caseId}
        cost={communityCost}
      />
    );
  }
}

TopicContainer.propTypes = {
  caseId: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  clearQuery: PropTypes.func.isRequired,
  topic: PropTypes.string,
  description: PropTypes.string,
  attachments: PropTypes.array,
  query: PropTypes.string,
  communityCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TopicContainer.defaultProps = {
  topic: null,
  description: null,
  attachments: [],
  query: '',
  communityCost: null
};

const mapStateToProps = state => ({
  query: state.case.query.query
});

const mapDispatchToProps = dispatch => ({
  clearQuery: bool => {
    dispatch(clearQuery(bool));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicContainer);
