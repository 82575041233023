import React from 'react';

const contactNumbersIcon = () => {
  return (
    <svg
      viewBox="0 0 69 47"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsserif="http://www.serif.com/"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
    >
      <path fill="none" d="M0 0h68.767v46.104H0z" />
      <clipPath id="a">
        <path serifid="Artboard1" d="M0 0h68.767v46.104H0z" />
      </clipPath>
      <g fillRule="nonzero" clipPath="url(#a)">
        <path
          d="M46.197 23.787L9.08 35.776a2.065 2.065 0 0 1-2.594-1.327L.101 14.681a2.075 2.075 0 0 1 1.327-2.594L38.545.098a2.075 2.075 0 0 1 2.594 1.327l6.385 19.768a2.076 2.076 0 0 1-1.327 2.594zm-20.708-.658l-25.1-7.57L41.427 2.304 25.489 23.129z"
          fill="#eaeaea"
        />
        <path
          d="M41.139 1.424L.101 14.68a2.075 2.075 0 0 1 1.327-2.594L38.545.097a2.075 2.075 0 0 1 2.594 1.327zm.284.879L.385 15.558 41.423 2.303z"
          fill="#e5e5e5"
        />
        <path
          d="M24.011 18.551l19.857 5.987-32.459 10.484 12.602-16.471z"
          fill="#f2f2f2"
        />
        <path
          d="M.047 14.516L41.085 1.26l.337 1.043-15.933 20.825-25.1-7.57-.342-1.042z"
          fill="#d8d8d8"
        />
        <path
          d="M23.81 17.936a1.122 1.122 0 0 1 1.409.721 1.126 1.126 0 0 1-1.065 1.463 1.122 1.122 0 0 1-1.119-1.119c0-.484.314-.916.775-1.065z"
          fill="#f86677"
        />
        <path
          d="M30.362 28.07c0-1.48 12.035-1.48 12.035 0 .002 3.825 3.149 6.972 6.974 6.974.244 0 .487-.013.73-.038V40.6c-.004 3.016-2.484 5.496-5.5 5.5H28.162c-3.016-.004-5.496-2.484-5.5-5.5v-5.594c.243.025.487.038.731.038 3.823-.004 6.967-3.151 6.969-6.974z"
          fill="#fc90a8"
        />
        <path
          d="M36.372 33.707c2.768.002 5.045 2.279 5.047 5.047-.001 2.768-2.279 5.046-5.047 5.047-2.768-.001-5.046-2.279-5.047-5.047.002-2.768 2.279-5.045 5.047-5.047z"
          fill="#fbd1da"
        />
        <path
          d="M36.38 34.725a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.787.787 0 0 1-.782-.782c0-.429.353-.782.782-.782zM36.372 41.446a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.786.786 0 0 1-.782-.782.787.787 0 0 1 .782-.782zM39.703 37.972c.429 0 .782.353.782.782a.787.787 0 0 1-.782.782.787.787 0 0 1-.782-.782c0-.429.353-.782.782-.782zM33.069 37.972c.429 0 .782.353.782.782a.787.787 0 0 1-.782.782.787.787 0 0 1-.782-.782.787.787 0 0 1 .782-.782zM34.112 35.741a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.786.786 0 0 1-.782-.782.787.787 0 0 1 .782-.782zM38.573 35.741a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.786.786 0 0 1-.782-.782.787.787 0 0 1 .782-.782zM38.573 40a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.787.787 0 0 1-.782-.782.787.787 0 0 1 .782-.782zM34.112 40a.787.787 0 0 1 .782.782.787.787 0 0 1-.782.782.787.787 0 0 1-.782-.782.787.787 0 0 1 .782-.782z"
          fill="#9c95bd"
        />
        <path
          d="M36.371 37.757c.549 0 1 .451 1 1 0 .549-.451 1-1 1-.549 0-1-.451-1-1 0-.549.451-1 1-1z"
          fill="#fff"
        />
        <path
          d="M22.644 36.154c4.828 0 8.8-3.972 8.8-8.8h-1.6c0 3.95-3.25 7.2-7.2 7.2v1.6zM50.101 35.765c-4.828 0-8.8-3.972-8.8-8.8h1.6c0 3.95 3.25 7.2 7.2 7.2v1.6z"
          fill="#ee5a7b"
        />
        <path
          d="M42.987 26.613a.627.627 0 0 0-.622-.623H30.481a.627.627 0 0 0-.622.623v1.245a.627.627 0 0 0 .622.622h11.883a.625.625 0 0 0 .622-.622l.001-1.245z"
          fill="#ed5072"
        />
        <path
          d="M49.602 26.378c1-1.611.227-4.315.024-4.8l-.04-.168-.124-.149c-3.762-5.417-16.165-2.673-17.68-2.316-1.177.277-10.843 2.776-13.9 7.713a6.227 6.227 0 0 0-.87 2.336l-.045.188.04.168c.074.693.706 4.317 3.091 4.643a11.468 11.468 0 0 0 7.71-3.636l.181-.176.077-.24a10.28 10.28 0 0 0 .437-3.61 18.508 18.508 0 0 1 4.675-1.634 17.22 17.22 0 0 1 4.861-.657 13.324 13.324 0 0 0 1.95 3l.176.181.24.077a11.674 11.674 0 0 0 8.555-.24c.264-.173.484-.405.642-.678v-.002z"
          fill="#f96f8d"
        />
        <path
          d="M28.423 28.269c.088-1.67-10.971 3.954-9.686 5.112.375.35.848.576 1.356.649a11.472 11.472 0 0 0 7.711-3.636l.257-.416a4.781 4.781 0 0 0 .362-1.709zM38.955 25.716c-.843-1.444 11.564-1.51 10.951.108-.173.483-.49.902-.908 1.2a11.467 11.467 0 0 1-8.52.3l-.419-.251a4.808 4.808 0 0 1-1.104-1.357z"
          fill="#cc4966"
        />
        <path
          d="M68.767 4.913a3.685 3.685 0 0 0-3.665-3.665H45.211a3.685 3.685 0 0 0-3.665 3.665v7.33a3.685 3.685 0 0 0 3.665 3.665h5.182l-3.4 6.282 7.486-6.282h10.625a3.685 3.685 0 0 0 3.665-3.665l-.002-7.33z"
          fill="#2fe6c7"
        />
        <path
          d="M59.717 7.14a1.2 1.2 0 0 0-1.193 1.193 1.2 1.2 0 0 0 1.193 1.192 1.199 1.199 0 0 0 1.192-1.192 1.2 1.2 0 0 0-1.192-1.193zM54.949 7.14a1.2 1.2 0 0 0-1.192 1.193 1.199 1.199 0 0 0 1.192 1.192 1.199 1.199 0 0 0 1.192-1.192 1.2 1.2 0 0 0-1.192-1.193zM50.595 7.14a1.2 1.2 0 0 0-1.192 1.193 1.199 1.199 0 0 0 1.192 1.192 1.2 1.2 0 0 0 1.193-1.192 1.2 1.2 0 0 0-1.193-1.193z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default contactNumbersIcon;
