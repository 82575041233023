import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../../layout/theme/components';
import ResultChart from './ResultsChart';

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;
const FrequencyBarBox = styled.div`
  width: 100%;
  text-align: left;
  word-break: break-word;
  padding: 0.5em 1em 1em;
  margin: 1em 0;
  background: ${({ theme }) => theme.backgroundColors.secondary};
`;
const FrequencyChart = styled.div`
  position: relative;
  height: 1.8em;
  background: #ffffff;
  width: 100%;
`;
const FrequencyHalfPoint = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border-right: 4px solid ${({ theme }) => theme.backgroundColors.primary};
`;
const FrequencyBar = styled.div`
  width: ${({ frequency }) => `${frequency}%`};
  max-width: 100%;
  height: 100%;
  background: ${({ frequency }) => (frequency > 50 ? '#56DABE' : '#FFC864')};
`;
const FrequencyHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Results = memo(props => {
  const {
    positiveVotes,
    negativeVotes,
    neutralVotes,
    frequency,
    totalApartments,
    votedApartments
  } = props;

  return (
    <ResultsWrapper>
      <FrequencyBarBox>
        <FrequencyHeader>
          <Text small>Frekwencja:</Text>
          <Text small>{frequency}%</Text>
        </FrequencyHeader>
        <FrequencyChart>
          <FrequencyHalfPoint />
          <FrequencyBar frequency={frequency} />
        </FrequencyChart>
      </FrequencyBarBox>
      {frequency !== 0 && (
        <ResultChart
          positiveVotes={positiveVotes}
          negativeVotes={negativeVotes}
          neutralVotes={neutralVotes}
          totalApartments={totalApartments}
          votedApartments={votedApartments}
        />
      )}
    </ResultsWrapper>
  );
});

Results.propTypes = {
  positiveVotes: PropTypes.number.isRequired,
  neutralVotes: PropTypes.number.isRequired,
  negativeVotes: PropTypes.number.isRequired,
  frequency: PropTypes.number.isRequired,
  totalApartments: PropTypes.number,
  votedApartments: PropTypes.number
};

Results.defaultProps = {
  totalApartments: null,
  votedApartments: null
};

export default Results;
