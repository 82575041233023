import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setUnvotedResolutionsNumber } from '../../data/case/actions';
import { voteForSomeone, voteInResolution, voteInSounding } from '../../helpers/api/case';
import { postMedia } from '../../helpers/api/media';
import { VOTES } from '../../helpers/constants';
import { daysBetweenDates, formatDate } from '../../helpers/date';
import { floatNumbers } from '../../helpers/tools';
import ThumbDown from '../../shared/components/ThumbDown';
import ThumbUp from '../../shared/components/ThumbUp';
import PreviewSection from '../preview/components/PreviewSection';
import RegistrationLink from '../preview/components/RegistrationLink';
import ReportButton from './components/ReportButton';
import ResolutionStatistics from './components/ResolutionStatistics';
import ResolutionThumbs from './components/ResolutionThumbs';
import ResolutionVoted from './components/ResolutionVoted';
import Results from './components/Results';
import SoundingLabel from './components/SoundingLabel';
import SoundingQuestion from './components/SoundingQuestion';
import SoundingThumbs from './components/SoundingThumbs';
import Voted from './components/Voted';
import VotingWrapper from './components/VotingWrapper';

class VotingContainer extends Component {
  state = {
    chosenThumb: 0,
    userVote: null,
    votedBy: null,
    attachment: [],
    positiveVotes: 0,
    neutralVotes: 0,
    negativeVotes: 0,
    frequency: 0,
    error: null, //todo: handle errors
  };

  componentDidMount() {
    const { userVote, votedBy, soundingResults } = this.props;

    if (soundingResults) {
      const { frequency_percent, negative_votes_percent, positive_votes_percent } = soundingResults;
      this.setState({
        userVote,
        votedBy,
        positiveVotes: positive_votes_percent,
        neutralVotes: 100 - positive_votes_percent - negative_votes_percent,
        negativeVotes: negative_votes_percent,
        frequency: frequency_percent,
      });
    }
  }

  chooseThumb = chosenThumb => this.setState({ chosenThumb });

  voteHandler = () => {
    const { chosenThumb } = this.state;
    const { caseId, resolution, setUnvotedResolutionsNumber, voteCallback } = this.props;

    resolution
      ? voteInResolution(chosenThumb, caseId)
          .then(res => {
            this.setState({
              userVote: res.data.user_vote,
              votedBy: res.data.voted_by,
              positiveVotes: res.data.positive_votes_percent,
              negativeVotes: res.data.negative_votes_percent,
              frequency: res.data.frequency_percent,
            });
            voteCallback && voteCallback();
          })
          .then(() => setUnvotedResolutionsNumber())
      : voteInSounding(chosenThumb, caseId).then(res => {
          this.setState({
            userVote: res.data.user_vote,
            votedBy: res.data.voted_by,
            positiveVotes: res.data.positive_votes_percent,
            negativeVotes: res.data.negative_votes_percent,
            frequency: res.data.frequency_percent,
          });
        });
  };

  voteForUserHandler = e => {
    e.preventDefault();
    const { attachment } = this.state;

    this.handleFilesUpload(attachment)
      .then(data => this.voteForUser(...data))
      .catch(error => this.setState({ error: error }));
  };

  voteForUser = attachment => {
    const { chosenThumb } = this.state;
    const { caseId, userId, voteForUserCallback } = this.props;

    voteForSomeone(caseId, userId, attachment, chosenThumb)
      .then(() => voteForUserCallback())
      .catch(error => this.setState({ error: error }));
  };

  handleFilesUpload = files => {
    const promises = files.map(file => {
      return postMedia(file).then(data => data.data.id);
    });
    return Promise.all(promises);
  };

  handleFileChange = value => this.setState({ attachment: value });
  handleRemoveFile = () => this.setState({ attachment: [] });

  changeVote = () => this.setState({ userVote: null, chosenThumb: 0 });

  userVoteLabel = vote => Object.values(VOTES).find(elem => elem.value === vote).label;

  userVoteIcon = vote => {
    if (vote === 1) return <ThumbUp highlighted={1} />;
    if (vote === -1) return <ThumbDown highlighted={-1} />;
  };

  renderResolutionVoting = () => {
    const { chosenThumb, userVote, votedBy, attachment } = this.state;
    const { isVotingClosed, openSoundingPlug, allowCommunityVote, userId } = this.props;
    const isThumbChosen = chosenThumb !== 0;

    return (
      <>
        {userVote !== 0 && userVote !== null ? (
          <ResolutionVoted
            changeVote={this.changeVote}
            userVote={userVote}
            isVotingClosed={isVotingClosed}
            votedBy={votedBy}
          />
        ) : (
          !isVotingClosed && (
            <ResolutionThumbs
              chooseThumb={c => this.chooseThumb(c)}
              isThumbChosen={isThumbChosen}
              isVotingClosed={isVotingClosed}
              allowCommunityVote={allowCommunityVote}
              openSoundingPlug={openSoundingPlug}
              voteHandler={userId ? this.voteForUserHandler : this.voteHandler}
              attachment={userId ? attachment : null}
              handleFileChange={userId ? this.handleFileChange : null}
              handleRemoveFile={userId ? this.handleRemoveFile : null}
            />
          )
        )}
      </>
    );
  };

  renderSoundingVoting = () => {
    const { chosenThumb, userVote } = this.state;
    const { isVotingClosed, openSoundingPlug, allowCommunityVote } = this.props;
    const isThumbChosen = chosenThumb !== 0;

    return (
      <>
        {userVote !== 0 && userVote !== null ? (
          <Voted
            changeVote={this.changeVote}
            icon={this.userVoteIcon(userVote)}
            label={this.userVoteLabel(userVote)}
            isSoundingClosed={isVotingClosed ? true : false}
          />
        ) : (
          !isVotingClosed && (
            <SoundingThumbs
              chooseThumb={c => this.chooseThumb(c)}
              isThumbChosen={isThumbChosen}
              allowCommunityVote={allowCommunityVote}
              openSoundingPlug={openSoundingPlug}
              voteHandler={this.voteHandler}
            />
          )
        )}
      </>
    );
  };

  getResolutionDuration = () => {
    const { resolution } = this.props;
    const endDate = resolution.closed_at ? resolution.closed_at.date : new Date().toISOString();

    return daysBetweenDates(endDate, resolution.start_date.date);
  };

  render() {
    const { positiveVotes, neutralVotes, negativeVotes, frequency } = this.state;
    const {
      showResults,
      question,
      isVotingClosed,
      canVote,
      canVoteByUser,
      userId,
      resolution,
      caseArchived,
      caseStatus,
      inModal,
      totalApartments,
      votedApartments,
      caseId,
      headerTitle,
      open,
    } = this.props;
    const roundedFrequency = floatNumbers(frequency, 2);
    const roundedPositiveVotes = floatNumbers(positiveVotes, 2);
    const roundedNeutralVotes = floatNumbers(neutralVotes, 2);
    const roundedNegativeVotes = floatNumbers(negativeVotes, 2);
    const defaultTitle = resolution ? 'Wyniki głosowania:' : 'Pytanie sondażowe:';

    return (
      <PreviewSection title={headerTitle ? headerTitle : defaultTitle}>
        {question && <SoundingQuestion question={question} />}
        {!caseArchived && (canVote || (canVoteByUser && userId)) ? (
          <VotingWrapper resolution={resolution && !inModal}>
            {resolution ? this.renderResolutionVoting() : this.renderSoundingVoting()}
          </VotingWrapper>
        ) : (
          <>
            {!resolution && !open && <SoundingLabel>Nie możesz głosować.</SoundingLabel>}
            {open && <RegistrationLink buttonText="Oddaj głos" />}
          </>
        )}
        {showResults && (
          <Results
            positiveVotes={roundedPositiveVotes}
            neutralVotes={roundedNeutralVotes}
            negativeVotes={roundedNegativeVotes}
            frequency={roundedFrequency}
            totalApartments={totalApartments}
            votedApartments={votedApartments}
          />
        )}
        {showResults &&
          resolution && (
            <>
              {caseStatus !== 'invalidated' && (
                <ResolutionStatistics
                  startDate={formatDate(resolution.start_date.date)}
                  duration={this.getResolutionDuration()}
                  closed={resolution.closed_at ? true : false}
                />
              )}
              <Link to={`/raport/${caseId}`} target="_blank">
                <ReportButton text={isVotingClosed ? 'Protokół z głosowania' : 'Wydrukuj raport'} />
              </Link>
            </>
          )}
      </PreviewSection>
    );
  }
}

VotingContainer.propTypes = {
  caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  caseArchived: PropTypes.bool,
  showResults: PropTypes.bool,
  question: PropTypes.string,
  userVote: PropTypes.number,
  votedBy: PropTypes.string,
  userId: PropTypes.string,
  soundingResults: PropTypes.objectOf(PropTypes.number),
  isVotingClosed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  canVote: PropTypes.bool.isRequired,
  openSoundingPlug: PropTypes.func,
  resolution: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  caseStatus: PropTypes.string,
  inModal: PropTypes.bool,
  totalApartments: PropTypes.number,
  votedApartments: PropTypes.number,
  allowCommunityVote: PropTypes.bool,
  allowResolutionVoting: PropTypes.bool,
  headerTitle: PropTypes.string,
  voteCallback: PropTypes.func,
  voteForUserCallback: PropTypes.func,
  open: PropTypes.bool,
};

VotingContainer.defaultProps = {
  showResults: false,
  userVote: null,
  votedBy: null,
  userId: null,
  question: '',
  soundingResults: null,
  isVotingClosed: null,
  allowCommunityVote: false,
  resolution: false,
  caseArchived: false,
  caseStatus: '',
  inModal: false,
  totalApartments: null,
  votedApartments: null,
  openSoundingPlug: null,
  allowResolutionVoting: false,
  headerTitle: null,
  voteCallback: null,
  voteForUserCallback: null,
  open: false,
};

const mapStateToProps = state => ({
  allowCommunityVote: state.user.accessControlList.data
    ? state.user.accessControlList.data.community.sounding_vote
    : '',
  canVote: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.resolution_voting || state.user.accessControlList.data.user.sounding_voting
    : '',
  canVoteByUser: state.user.accessControlList.data ? state.user.accessControlList.data.user.voting_by_user : false,
});

const mapDispatchToProps = dispatch => ({
  setUnvotedResolutionsNumber: () => dispatch(setUnvotedResolutionsNumber()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VotingContainer);
