import React from 'react';

const rejectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.021 12.02">
    <path
      fill="#f86060"
      d="M6.01 8.132l-3.889 3.889L0 9.9l3.889-3.889L0 2.121 2.121 0 6.01 3.889 9.9 0l2.121 2.121L8.132 6.01l3.889 3.889L9.9 12.02z"
    />
  </svg>
);

export default rejectedIcon;
